import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {POST_CPU_CHECKER_REVIEW_URL} from 'src/app/queries/api/apiEndPoints/EndPoints'

type typePayload = {
  id: string
  status: string
  remarks: string
  is_file_path_exists?: boolean
  files?: any
}

const postLinkedEntitiesCpuCheckerReview = async (payload: typePayload) => {
  const {id, status, remarks, is_file_path_exists, files} = payload
  try {
    const response = await axios.post(`${POST_CPU_CHECKER_REVIEW_URL}`.replace(':id', id), {
      status,
      remarks,
      is_file_path_exists,
      files,
    })
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostLinkedEntitiesCPUCheckerReviewForm = () => {
  const queryClient = useQueryClient()
  return useMutation(postLinkedEntitiesCpuCheckerReview, {
    onSuccess: (response) => {
      if (response?.data?.resCod === 200) {
        toast.success(response?.data?.resDesc ?? 'Review status submitted successfully!')
        queryClient.invalidateQueries('LinkedEntitiesList')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostLinkedEntitiesCPUCheckerReviewForm
