import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {usePostCorporateQaReviewCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCorporateQaReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {REVIEW_VALIDATION_SCHEMA} from '../../utils/ValidationSchemas'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'

interface RouteParams {
  id: string
}

const ExpressCorporateReviewCIF = () => {
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)
  const {mutate: qaCorporateReview, isLoading: postRespLoading} = usePostCorporateQaReviewCIF()

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')

  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    qaCorporateReview(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
    })
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  const allStatusType = [
    {label: 'FORWARD TO CPU MAKER', value: 'approve'},
    {label: 'REJECT', value: 'reject'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
  ]

  const handleScreening = () => {
    setScreeningLoading(true) // Set screening loading to true

    const checkAllPayload: any = {
      customer_name: corporateDataById?.data?.institution_name,
      pan_number: corporateDataById?.data?.pan_number,
      model_name: 'NewCIFNewAccount',
    }

    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  const data = corporateDataById?.data
  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              {' '}
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='modal-container '>
            <div className='card-body' style={{maxHeight: '640px', overflowY: 'auto'}}>
              <div className='card p-5'>
                <div className='row'>
                  <section>
                    <div className='row'>
                      {' '}
                      <FormDetails
                        key='branch_code'
                        label='Branch Code'
                        value={corporateDataById?.data?.branch_code}
                      />
                      <FormDetails
                        key='customer_code'
                        label='Customer Code'
                        value={corporateDataById?.data?.customer_code}
                      />
                      <h3 className='fw-bolder mb-4 text-capitalize'>Company Information</h3>
                      <FormDetails
                        key='institution_name'
                        label='Name of Institution'
                        value={corporateDataById?.data?.institution_name}
                      />
                      {corporateDataById?.data?.constitution_id?.title === 'Other (specify)' ? (
                        <>
                          <FormDetails
                            key='constitution_id'
                            label='Constitution'
                            value={corporateDataById?.data?.constitution_id.title}
                          />
                          <FormDetails
                            key='other_constitution'
                            label='Other Constitution'
                            value={corporateDataById?.data?.other_constitution}
                          />
                        </>
                      ) : corporateDataById?.data?.constitution_id.title ===
                        'FOREIGN ENTERPRISE' ? (
                        <>
                          <FormDetails
                            key='constitution_id'
                            label='Constitution'
                            value={corporateDataById?.data?.constitution_id.title}
                          />
                          <FormDetails
                            key='foreign_company_name'
                            label='Foreign Institution Name'
                            value={corporateDataById?.data.foreign_company_name}
                          />
                          <FormDetails
                            key='foreign_company_address'
                            label='Foreign Institution Address'
                            value={corporateDataById?.data?.foreign_company_address}
                          />
                        </>
                      ) : (
                        <FormDetails
                          key='constitution_id'
                          label='Constitution'
                          value={corporateDataById?.data?.constitution_id.title}
                        />
                      )}
                      <FormDetails
                        key='registration_number'
                        label='Registration Number'
                        value={corporateDataById?.data?.registration_number}
                      />
                      <FormDetails
                        key='registration_type'
                        label='Registered With'
                        value={corporateDataById?.data?.registration_with_id?.title}
                      />
                      <FormDetails
                        key='registration_date_ad'
                        label='Registration Date (AD)'
                        value={corporateDataById?.data?.registration_date_ad}
                      />
                      <FormDetails
                        key='registration_date_bs'
                        label='Registration Date (BS)'
                        value={corporateDataById?.data?.registration_date_bs}
                      />
                      {corporateDataById?.data?.registration_expiry_date_ad && (
                        <>
                          {' '}
                          <FormDetails
                            key='registration_expiry_date_ad'
                            label='Registration Expiry Date (AD)'
                            value={corporateDataById?.data?.registration_expiry_date_ad}
                          />
                          <FormDetails
                            key='registration_expiry_date_bs'
                            label='Registration Expiry Date (BS)'
                            value={corporateDataById?.data?.registration_expiry_date_bs}
                          />
                        </>
                      )}
                      <FormDetails
                        key='aml_risk_rating'
                        label='AML Risk Rating'
                        value={corporateDataById?.data?.aml_risk_rating?.title}
                      />
                      <FormDetails
                        key='sub_risk_category_id'
                        label='AML Risk Sub Rating'
                        value={corporateDataById?.data?.sub_risk_category_id?.title}
                      />
                    </div>
                  </section>
                  <hr />
                  <section>
                    <h3 className='fw-bolder mb-4 text-capitalize'>Registered Address</h3>
                    <div className='row'>
                      <FormDetails
                        key='country'
                        label='Country'
                        value={corporateDataById?.data?.registered_office_country.title}
                      />
                      {corporateDataById?.data?.registered_office_country.title === 'NEPAL' ? (
                        <>
                          <FormDetails
                            key='province'
                            label='Province'
                            value={corporateDataById?.data?.registered_office_province?.title}
                          />
                          <FormDetails
                            key='zone'
                            label='Zone'
                            value={corporateDataById?.data?.registered_office_zone.title}
                          />
                          <FormDetails
                            key='district'
                            label='District'
                            value={corporateDataById?.data?.registered_office_district.title}
                          />
                          <FormDetails
                            key='municipality'
                            label='Municipality'
                            value={corporateDataById?.data?.registered_office_municipality.title}
                          />
                          <FormDetails
                            key='city'
                            label='City'
                            value={corporateDataById?.data?.registered_office_city?.title}
                          />
                          <FormDetails
                            key='ward_no'
                            label='Ward Number'
                            value={corporateDataById?.data?.registered_office_ward_number}
                          />
                          <FormDetails
                            key='registered_office_street_name'
                            label=' Tole'
                            value={corporateDataById?.data?.registered_office_street_name}
                          />
                          <FormDetails
                            key='registered_office_house_number'
                            label=' House Number'
                            value={corporateDataById?.data?.registered_office_house_number}
                          />

                          <FormDetails
                            key='registered_office_mobile_number'
                            label=' Mobile Number'
                            value={corporateDataById?.data?.registered_office_mobile_number}
                          />
                          <FormDetails
                            key='registered_office_email'
                            label=' Email'
                            value={corporateDataById?.data?.registered_office_email}
                          />
                        </>
                      ) : (
                        <>
                          <FormDetails
                            key='registered_office_outside_country_post_box'
                            label='Foreign Country Post Box No.'
                            value={
                              corporateDataById?.data?.registered_office_outside_country_post_box
                            }
                          />
                          <FormDetails
                            key='registered_office_outside_country_full_address'
                            label='Foreign Country Address  '
                            value={
                              corporateDataById?.data
                                ?.registered_office_outside_country_full_address
                            }
                          />
                          <FormDetails
                            key='registered_office_mobile_number'
                            label=' Mobile Number'
                            value={corporateDataById?.data?.registered_office_mobile_number}
                          />
                          <FormDetails
                            key='registered_office_email'
                            label=' Email'
                            value={corporateDataById?.data?.registered_office_email}
                          />
                        </>
                      )}
                    </div>
                  </section>
                  <hr />
                  <section>
                    <div className='row'>
                      <h3 className='fw-bolder mb-4 text-capitalize'>Business Details</h3>
                      <FormDetails
                        key='business_nature_id'
                        label='Nature of Industry/ Business'
                        value={corporateDataById?.data?.business_nature_id?.title}
                      />
                      {corporateDataById?.data?.pan_number && (
                        <FormDetails
                          key='pan_number'
                          label='Pan Number'
                          value={corporateDataById?.data?.pan_number}
                        />
                      )}
                      {corporateDataById?.data?.vat_number && (
                        <FormDetails
                          key='vat_number'
                          label='Vat Number'
                          value={corporateDataById?.data?.vat_number}
                        />
                      )}
                      <FormDetails
                        key='estimated_annual_sales_revenue'
                        label='Estimated Annual Sales / Revenue'
                        value={corporateDataById?.data?.expected_annual_turnover}
                      />
                      <FormDetails
                        key='expected_annual_transaction_number'
                        label='Annual Transaction Number'
                        value={corporateDataById?.data?.expected_annual_transaction_number?.title}
                      />
                    </div>
                  </section>
                  <hr />
                  <section>
                    <div className='row'>
                      <h3 className='fw-bolder mb-4 text-capitalize'>Screening and Risk Info</h3>

                      <FormDetails
                        key='pep_status'
                        label='Pep Status'
                        value={corporateDataById?.data?.pep_status}
                      />
                      {corporateDataById?.data?.pep_information && (
                        <FormDetails
                          key='pep_information'
                          label='Pep Information'
                          value={corporateDataById?.data?.pep_information}
                        />
                      )}
                    </div>
                  </section>
                  <hr />
                  <div className='row'>
                    {screeningPerformed ? (
                      checkAllData && (
                        <Button
                          type='button'
                          onClick={handleOpenPdf}
                          className='col-md-3 col-sm-6 col-xs-12'
                        >
                          View Details
                        </Button>
                      )
                    ) : screeningLoading ? (
                      <Spinner animation='border' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    ) : (
                      <Button
                        type='button'
                        onClick={handleScreening}
                        className='col-md-3 col-sm-6 col-xs-12'
                      >
                        Perform Screening
                      </Button>
                    )}
                    <div className='my-7'>
                      <h3>Screening Files</h3>
                      {corporateDataById?.data?.screening_files?.screening_files?.length > 0 ? (
                        corporateDataById?.data?.screening_files?.screening_files?.map(
                          (file: any, index: any) => (
                            <button
                              key={index}
                              onClick={() => handlePdfFileClick(file.file_path)}
                              style={{display: 'block', marginBottom: '10px'}}
                            >
                              {file.file_name}
                            </button>
                          )
                        )
                      ) : (
                        <p>No files available</p>
                      )}
                    </div>
                  </div>
                  <Formik
                    initialValues={{status: '', remarks: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={REVIEW_VALIDATION_SCHEMA}
                  >
                    {({handleChange, errors, touched, values, setFieldValue}) => (
                      <Form>
                        <div className='row '>
                          <div className='col-md-8'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Status Type'
                              name='status'
                              isClearable={true}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allStatusType.map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                              required={true}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className='border my-2 mb-6'></div>
                          <FormTextBox
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            type='text'
                            placeholder='Remarks'
                            label='Remarks'
                            name='remarks'
                            disabled={false}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-id-number-text-field'
                            as='textarea'
                          />
                          <div className='d-flex justify-content-end mt-3'>
                            <button
                              type='button'
                              onClick={handleBack}
                              className='btn btn-secondary mx-4'
                            >
                              Back
                            </button>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={postRespLoading}
                            >
                              {postRespLoading ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccountStatusLog data={corporateDataById?.data} />
        <UserActivityLogAll caseId={id} modalName={'Amnil\\SharedModels\\NewCIF'} />
      </div>
    </>
  )
}

export default ExpressCorporateReviewCIF
