import React, {useEffect, useState} from 'react'
import {Formik, Form} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import DatePickerForGrievance from 'src/cms/helpers/components/forms/DatePickerForGrievance'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import {CORPORATE_FORM_VALIDATION} from '../../../../utils/validations/ValidationSchemas'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {IOptions} from '../../../../types/CommonTypes'
import {useGetAllDistrictData} from 'src/app/queries/getMasterData/districts/useGetAllDistrictData'
import {useGetAllMunicipalityOption} from 'src/app/queries/getMasterData/municipality/useGetAllMunicipality'
import {useGetAllProvinceData} from 'src/app/queries/getMasterData/provinces/useGetAllProvinces'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import usePostCreateNewExpressPreliminaryCorporate from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCreateNewExpressPreliminaryCorporate'

const NewCifNewAccountExpressCorporate = () => {
  const [masterData, setMasterData] = useState<any>(null)
  const [statesData, setStatesData] = useState<any>({
    provinces: [],
    districts: [],
    zones: [],
    municipalities: [],
  })

  const {data: allMasterData, isLoading} = useGetAllMasterData()
  const {data: provinceData} = useGetAllProvinceData()
  const {data: districtData} = useGetAllDistrictData()
  const {data: zoneData} = useGetAllZoneData()
  const {data: municipalityData} = useGetAllMunicipalityOption(districtData?.id)

  useEffect(() => {
    setStatesData((prevState: any) => ({
      ...prevState,
      provinces: provinceData || prevState.provinces,
      districts: districtData || prevState.districts,
      zones: zoneData || prevState.zones,
      municipalities: municipalityData || prevState.municipalities,
    }))
  }, [provinceData, districtData, zoneData, municipalityData])

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const extractOptions = (masterData: any) => {
    const options: {[key: string]: any[]} = {}
    masterData?.data?.forEach((item: any) => {
      const key = Object.keys(item)[0]
      if (key) {
        options[key] = item[key]
      }
    })
    return options
  }

  const allMasterDataOptions = extractOptions(masterData)

  const constutionDatas: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(allMasterDataOptions?.constitutions)
    ? allMasterDataOptions.constitutions.map((constitutionData: any) => ({
        value: constitutionData.id,
        label: constitutionData.title,
      }))
    : []

  const corporateRegistrations: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(allMasterDataOptions?.corporate_registrations)
    ? allMasterDataOptions.corporate_registrations.map((corporate_registration: any) => ({
        value: corporate_registration.id,
        label: corporate_registration.title,
      }))
    : []

  const countriesData: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(allMasterDataOptions?.countries)
    ? allMasterDataOptions.countries.map((country: any) => ({
        value: country.id,
        label: country.title,
      }))
    : []

  const zoneOptions: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(statesData?.zones)
    ? statesData.zones.map((zone: any) => ({
        value: zone.id,
        label: zone.title,
      }))
    : []

  const provinceOptions: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(statesData?.provinces)
    ? statesData.provinces.map((province: any) => ({
        value: province.id,
        label: province.title,
      }))
    : []

  const districtOptions: IOptions[] = isLoading
    ? [{value: '', label: 'Loading...'}]
    : Array.isArray(statesData?.districts)
    ? statesData.districts.map((district: any) => ({
        value: district.id,
        label: district.title,
      }))
    : []

  const menuOptions = [
    {value: 'test1', label: 'Test 1'},
    {value: 'test2', label: 'Test 2'},
  ]

  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState(null)
  const [seletctedAccountType] = useState('corporate') // Adjust based on your logic
  const [uniqueID] = useState('unique-id') // Adjust based on your logic
  const [isSave, setIsSave] = useState(false) // Adjust based on your logic
  const [missingDocs, setMissingDocs] = useState([]) // Adjust based on your logic
  const [documentTypeLoading, setDocumentTypeLoading] = useState(false) // Adjust based on your logic

  const formHeading = 'Express Corporate Account Form'
  const initialValues = {
    action: '',
    opening_mode: '',
    branch_code: '',
    account_type_id: '',
    institution_name: '',
    constitution_id: '',
    other_constitution: '',
    foreign_company_name: '',
    foreign_company_address: '',
    registration_number: '',
    registration_with_id: '',
    registration_date_ad: '',
    registration_date_bs: '',
    registration_expiry_date_ad: '',
    registration_expiry_date_bs: '',
    registered_office_country: '',
    registered_office_zone: '',
    registered_office_province: '',
    registered_office_district: '',
    registered_office_city: '',
    registered_office_municipality: '',
    registered_office_ward_number: '',
    registered_office_street_name: '',
    registered_office_house_number: '',
    registered_office_landline: '',
    registered_office_mobile_number: '',
    registered_office_email: '',
    registered_office_fax_number: '',
    registered_office_website: '',
    business_office_country: '',
    business_office_zone: '',
    business_office_province: '',
    business_office_district: '',
    business_office_city: '',
    business_office_municipality: '',
    business_office_ward_number: '',
    business_office_street_name: '',
    business_office_house_number: '',
    business_office_landline: '',
    business_office_mobile_number: '',
    business_office_email: '',
    business_office_fax_number: '',
    business_office_website: '',
    working_area: '',
    business_nature_id: '',
    income_tax_assess: '',
    pan_number: '',
    vat_number: '',
    expected_annual_turnover: '',
    expected_annual_transaction_number: '',
    location_map: '',
    screening_id: '23435',
    blacklist_screening: false,
    sanction_screening: false,
    customer_category_id: '',
    aml_risk_rating: '',
    pep_status: '',
    pep_information: '',
    crime_conviction: false,
    crime_details: '',
    blacklisting_inclusion: false,
    blacklist_release_date: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CORPORATE_FORM_VALIDATION}
      onSubmit={(values, {setSubmitting}) => {
        setSubmitting(false)
      }}
    >
      {({values, isSubmitting, touched, handleChange, handleSubmit, errors, setFieldValue}) => (
        <Form className='bg-white'>
          <div className='card-body border-top pt-5 px-5'>
            <label style={{fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem'}}>
              {formHeading}
            </label>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Branch Code'
                  name='branch_code'
                  label='I. Branch Code'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  // value={values?.branch_code}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Account Type ID'
                  name='account_type_id'
                  label='Account Type ID'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  // value={values?.account_type_id}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Institution Name'
                  name='institution_name'
                  label='Institution Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  // value={values?.institution_name}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Constitution'
                  label='Constitution'
                  name='constitution_id'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={constutionDatas}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Other Constitution'
                  name='other_constitution'
                  label='Other Constitution'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // value={values?.other_constitution}
                />
              </div>
            </div>
            <div className='row'>
              <label className='form-label fw-bolder text-dark fs-6 required'>
                If the associates of foreign company/entity, details of foreign company/entity
              </label>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Foreign Company Name'
                  name='foreign_company_name'
                  label='Foreign Company Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // value={values?.foreign_company_name}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Foreign Company Address'
                  name='foreign_company_address'
                  label='Foreign Company Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // value={values?.foreign_company_address}
                />
              </div>
            </div>

            <label className='form-label fw-bolder text-dark fs-6 required'>
              C. Details of Registration
            </label>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registration Number'
                  name='registration_number'
                  label='Registration Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                  // value={values?.registration_number}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Registered With'
                  label='Registered With'
                  name='registration_with_id'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={corporateRegistrations}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <DatePickerForGrievance
                  containerClassName='col-12'
                  name='registration_date_ad'
                  placeholderText='Date of Registration'
                  setFieldValue={setFieldValue}
                  label='Date of Registration'
                  touched={touched}
                  required={true}
                  showIcon={true}
                  errors={errors}
                  value={values?.registration_date_ad || ''}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <DatePickerForGrievance
                  containerClassName='col-12'
                  name='registration_date_bs'
                  placeholderText='Date of Registration (BS)'
                  label='Date of Registration (BS)'
                  setFieldValue={setFieldValue}
                  touched={touched}
                  required={true}
                  showIcon={true}
                  errors={errors}
                  value={values?.registration_date_bs || ''}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <DatePickerForGrievance
                  containerClassName='col-12'
                  name='registration_expiry_date_ad'
                  placeholderText='Registration Valid Upto'
                  label='Registration Valid Upto'
                  setFieldValue={setFieldValue}
                  touched={touched}
                  required={true}
                  showIcon={true}
                  errors={errors}
                  value={values?.registration_expiry_date_ad}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <DatePickerForGrievance
                  containerClassName='col-12'
                  name='registration_expiry_date_bs'
                  placeholderText='Registration Valid Upto (BS)'
                  label='Registration Valid Upto (BS)'
                  setFieldValue={setFieldValue}
                  touched={touched}
                  required={true}
                  showIcon={true}
                  errors={errors}
                  value={values?.registration_expiry_date_bs}
                />
              </div>
            </div>

            <div className='row'>
              <label className='form-label fw-bolder text-dark fs-6 required'>
                C. Details of Registration
              </label>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Country'
                  label='Registered Office Country'
                  name='registered_office_country'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={countriesData}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Zone'
                  label='Registered Office Zone'
                  name='registered_office_zone'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={zoneOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Province'
                  label='Registered Office Province'
                  name='registered_office_province'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={provinceOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select District'
                  label='Registered Office District'
                  name='registered_office_district'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={districtOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registered Office City'
                  name='registered_office_city'
                  label='Registered Office City'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registered Office Municipality'
                  name='registered_office_municipality'
                  label='Registered Office Municipality'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Registered Office Ward Number'
                  name='registered_office_ward_number'
                  label='Registered Office Ward Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registered Office Street Name'
                  name='registered_office_street_name'
                  label='Registered Office Street Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Registered Office House Number'
                  name='registered_office_house_number'
                  label='Registered Office House Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Registered Office Landline'
                  name='registered_office_landline'
                  label='Registered Office Landline'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Registered Office Mobile Number'
                  name='registered_office_mobile_number'
                  label='Registered Office Mobile Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registered Office Email'
                  name='registered_office_email'
                  label='Registered Office Email'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Registered Office Fax Number'
                  name='registered_office_fax_number'
                  label='Registered Office Fax Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Registered Office Website'
                  name='registered_office_website'
                  label='Registered Office Website'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <label className='form-label fw-bolder text-dark fs-6 required'>
              Business Office Address
            </label>
            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Country'
                  label='Business Office Country'
                  name='business_office_country'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={countriesData}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Zone'
                  label='Business Office Zone'
                  name='business_office_zone'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={zoneOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Province'
                  label='Business Office Province'
                  name='business_office_province'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={provinceOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select District'
                  label='Business Office District'
                  name='business_office_district'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={districtOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office City'
                  name='business_office_city'
                  label='Business Office City'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  value={values.business_office_city}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Municipality'
                  name='business_office_municipality'
                  label='Business Office Municipality'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Ward Number'
                  name='business_office_ward_number'
                  label='Business Office Ward Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Street Name'
                  name='business_office_street_name'
                  label='Business Office Street Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office House Number'
                  name='business_office_house_number'
                  label='Business Office House Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Landline'
                  name='business_office_landline'
                  label='Business Office Landline'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Mobile Number'
                  name='business_office_mobile_number'
                  label='Business Office Mobile Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Email'
                  name='business_office_email'
                  label='Business Office Email'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Fax Number'
                  name='business_office_fax_number'
                  label='Business Office Fax Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Business Office Website'
                  name='business_office_website'
                  label='Business Office Website'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Working Area'
                  name='working_area'
                  label='Working Area'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Nature of Business'
                  label='Nature of Business'
                  name='business_nature_id'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={menuOptions}
                  setFieldValue={setFieldValue}
                  required
                  values={values}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Expected Annual Turnover'
                  name='expected_annual_turnover'
                  label='Expected Annual Turnover'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Expected Annual Transaction Number'
                  name='expected_annual_transaction_number'
                  label='Expected Annual Transaction Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-4 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Location Map'
                  name='location_map'
                  label='Location Map'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>

            <div className='my-5 px-5'>
              <button className='btn btn-primary mx-5' disabled={isSubmitting}>
                Perform Screening
              </button>
              <button className='btn btn-primary' disabled={isSubmitting}>
                Save Screening Response
              </button>
            </div>
           
            <label className='form-label fw-bolder text-dark fs-6 required'>Acknowledgement</label>
            <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
              <FormCheckbox
                labelClassName='col-sm-12'
                containerClassName='col-sm-12'
                name='blacklist_screening'
                touched={touched}
                errors={errors}
                onChange={handleChange}
                checkBoxText={
                  'I hereby verified screening result and I will be responsible in further for this.'
                }
                required={true}
              />
            </div>
            <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
              <FormCheckbox
                labelClassName='col-sm-12'
                containerClassName='col-sm-12'
                name='sanction_screening'
                touched={touched}
                errors={errors}
                onChange={handleChange}
                checkBoxText={
                  'I hereby verified sanction screening result and I will be responsible in further for this.'
                }
                required={true}
              />
            </div>
          </div>

          <div className='d-flex justify-content-end my-5 px-5'>
            <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default NewCifNewAccountExpressCorporate
