import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import {
    PATCH_CPU_MAKER_UPDATE_URL
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${PATCH_CPU_MAKER_UPDATE_URL}`

type UpdatePayloadType = {
  id: string
  data: any
}

const updateLinkedEntitiesDetails = async (payload: UpdatePayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${API_URL}`.replace(':id', id), data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || 'error updating account'}`)
  }
}

const usePatchLinkedEntitiesDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(updateLinkedEntitiesDetails, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Linked Entities updated successfully!')
      }
      queryClient.invalidateQueries('LinkedEntitiesList')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default usePatchLinkedEntitiesDetails