import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
// @ts-ignore
import ImageZoom from 'react-image-zooom'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {BASE_API_URL, BASE_APP_URL} from '../../constants/common'
import {Viewer} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import type {ToolbarProps, ToolbarSlot, TransformToolbarSlot} from '@react-pdf-viewer/toolbar'

interface DocumentsPreviewProps {
  data?: any
}

const DocumentsPreview = ({data}: DocumentsPreviewProps) => {
  const [index, setIndex] = useState('1')

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => {
    const {Zoom} = slot
    return Object.assign({}, slot, {
      Zoom: () => <Zoom levels={[0.5, 1.0, 1.5, 2.0, 2.5, 3.0]} />, // Custom zoom levels
    })
  }

  // Initialize the default layout plugin with a custom toolbar
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    ),
  })

  const {renderDefaultToolbar} = defaultLayoutPluginInstance.toolbarPluginInstance
  const titleCounts: {[key: string]: number} = {}

  // Function to determine file type
  const isPdf = (filePath: string) => {
    return filePath.toLowerCase().endsWith('.pdf')
  }
  const accountDocs = data?.account_documents?.documents ?? []
  const additionalDocs = data?.additional_documents?.documents ?? []
  const extraAdditionalDocs = data?.additional_documents?.additional_documents ?? []
  const generalDocs = data?.documents?.documents ?? []

  const mergedDocuments = [
    ...accountDocs,
    ...additionalDocs,
    ...extraAdditionalDocs,
    ...generalDocs,
  ]
  const getUniqueTitle = (title: string, index: number) => {
    if (!titleCounts[title]) {
      titleCounts[title] = 1 // If the title is encountered for the first time, initialize the count
    } else {
      titleCounts[title] += 1 // Increment the count for repeated titles
    }
    return `${title} ${titleCounts[title] > 1 ? titleCounts[title] : ''}` // Append number if repeated
  }
  return (
    <div>
      <div className='d-flex justify-content-end '>
        {index}/{mergedDocuments?.length ?? 0}
      </div>
      <Tabs
        defaultActiveKey={mergedDocuments?.[0]?.documentType?.cbs_code}
        id='uncontrolled-tab-example'
      >
        {mergedDocuments?.map(
          (
            document: {
              id: string
              file_path: string
              documentType: {title: string; cbs_code: string}
            },
            index: number
          ) => (
            <Tab
              eventKey={document?.id + '-' + document?.documentType?.cbs_code} // Ensure unique eventKey
              // title={`${document?.documentType?.title} ${index}`} // Add index for better identification
              title={getUniqueTitle(document?.documentType?.title, index)}
              onEntered={() => setIndex((index + 1).toString())}
              key={document?.id}
            >
              {isPdf(document?.file_path) ? (
                <div
                  style={{
                    border: '2px solid rgba(0, 0, 0, 0.3)',
                    height: '750px',
                  }}
                >
                  <Viewer
                    fileUrl={`${BASE_APP_URL}/framework/${document?.file_path}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              ) : (
                // <div className='fixed-preview' style={{overflow: 'hidden'}}>
                // {isPdf(document?.file_path) ? (
                // <button
                //   onClick={() =>
                //     window.open(`${BASE_APP_URL}/framework/${document?.file_path}`, '_blank')
                //   }
                //   style={{
                //     width: '100%',
                //     height: '500px',
                //     border: '1px solid gray',
                //     borderRadius: '10px',
                //     marginTop: '10px',
                //     backgroundColor: '#f0f0f0',
                //     cursor: 'pointer',
                //     textAlign: 'center',
                //     display: 'flex',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                //   }}
                // >
                //   Open PDF in a New Tab
                // </button>
                <ImageZoom
                  src={`${BASE_APP_URL}/framework/${document?.file_path}`}
                  alt='Document Preview'
                  zoomStyle={{
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                  zoomWidth={700}
                  zoomHeight={600}
                  style={{
                    width: '100%',
                    height: '400px',
                    objectFit: 'contain',
                    border: '1px solid gray',
                    borderRadius: '10px',
                    marginTop: '10px',
                  }}
                />
              )}
            </Tab>
          )
        )}
      </Tabs>
    </div>
  )
}

export default DocumentsPreview
