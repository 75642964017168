import axios from 'axios'
import {useQuery, UseQueryResult} from 'react-query'
import {GET_ALL_MASTER_DATA_MUNICIPALITY} from '../../api/apiEndPoints/EndPoints'

const QUERY_KEY = 'municipality'

interface MunicipalityData {
  id: string
  title: string
  titleNp: string
}

interface ErrorResponse {
  message: string
}

const fetchAllMunicipalityOption = async ({
  districtId,
}: {
  districtId: string
}): Promise<MunicipalityData[]> => {
  try {
    const {data} = await axios.get<MunicipalityData[]>(GET_ALL_MASTER_DATA_MUNICIPALITY, {
      params: {districtId}, // Pass districtId as a query parameter if required
    })
    return data
  } catch (error: any) {
    throw new Error(`Error fetching master data: ${error.message}`)
  }
}

export const useGetAllMunicipalityOption = (
  districtId: string
): UseQueryResult<MunicipalityData[], ErrorResponse> => {
  return useQuery<MunicipalityData[], ErrorResponse>(
    [QUERY_KEY, districtId],
    () => fetchAllMunicipalityOption({districtId})
    // {staleTime: 6000000}
  )
}

export const MunicipilityOption = () => {
  return useQuery(
    ['MunicipilityGetList'],
    async () => {
      const {data} = await axios.get(`${GET_ALL_MASTER_DATA_MUNICIPALITY}`)
      return data
    },
    {
      onError: (error: any) => {},
    }
  )
}
