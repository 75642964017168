// import axios from 'axios'
// import { useQuery, useMutation } from 'react-query'
// import { GET_CORPORATE_NEW_CIF_NEW_ACCOUNT, GET_NORMAL_NEW_CIF_NEW_ACCOUNT } from '../../api/apiEndPoints/EndPoints'
// // import { ParamsModel } from 'src/app/modules/common/Model'

// const QUERY_KEY = 'branchCheckerCorporateNewCIFAndAccountList'

// export const useGetBranchCheckerCorporateNewCIFandAccountList = (params: any) => {
//     return useQuery([QUERY_KEY, params], async () => {
//         const { data } = await axios.get(GET_CORPORATE_NEW_CIF_NEW_ACCOUNT, {
//             params,
//         })
//         return data
//     })
// }


import axios from 'axios'
import {useQuery} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'
import { GET_CORPORATE_NEW_CIF_NEW_ACCOUNT } from 'src/app/queries/api/apiEndPoints/EndPoints'



const fetchCorporateAccountLists = async (params: ParamsModel) => {
  try {
    const {data} = await axios.get(GET_CORPORATE_NEW_CIF_NEW_ACCOUNT, {
      params,
    })
    return data
  } catch (error: any) {
    throw new Error(`Error fetching branch checker new CIF and account list: ${error.message}`)
  }
}

const useGetBranchCheckerCorporateAccountLists = (params: ParamsModel) => {
  return useQuery(['getCorporateBranchMakerAccountLists', params], () =>
    fetchCorporateAccountLists(params)
  )
}

export default useGetBranchCheckerCorporateAccountLists
