import axios from 'axios'
import { useQuery } from 'react-query'
import { AccountSchemaType } from 'src/app/modules/common/Model'
import { GET_TAX_CATEGORY } from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'getTaxCategoryList'

export const useGetTaxCategoryList = (params: AccountSchemaType) => {
  return useQuery(
    [QUERY_KEY, params],
    async () => {
      const {data} = await axios.get(GET_TAX_CATEGORY, {params})
      return data
    },
    {
      onError: (error) => {
        console.error('Error fetching account schema:', error)
      },
    }
  )
}
