import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UploadState {
  allMasterDataOptions: any
}

let localStorageData = localStorage.getItem('allMasterDataOptions')
const initialState: UploadState = {
  allMasterDataOptions: localStorageData ? JSON.parse(localStorageData) : null,
}

const MasterDataSlice = createSlice({
  name: 'allMasterDataOptions',
  initialState,
  reducers: {
    MasterDataStore(state, action: PayloadAction<any>) {
      state.allMasterDataOptions = action.payload
      // if (!localStorageData) {
      localStorage.setItem('allMasterDataOptions', JSON.stringify(action.payload))
      // }
    },
  },
})

export const {MasterDataStore} = MasterDataSlice.actions
export default MasterDataSlice.reducer
