// import { FC, useEffect, useState } from 'react'
// import { useIntl } from 'react-intl'

// import { PageTitle } from 'src/cms/layout/core'
// import { ISettingTypeState } from 'src/app/modules/siteSettings/components/settings'
// import { useDispatch, useSelector } from 'react-redux'
// import { isEmpty } from 'lodash'
// import * as key from 'src/app/modules/siteSettings/components/keySetting'

// interface Props {
//   applicationName: string | null
//   gTrackCode: any
// }

// const DashboardPage = ({ applicationName, gTrackCode }: Props) => {
//   const dispatch = useDispatch()
//   const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
//   return (
//     <>
//       <div className='card mb-5 mb-xl-10'>
//         <div className='card-body pt-9 pb-9'>
//           <span className='d-flex text-gray-800 text-hover-primary justify-content-center fs-2 fw-bolder me-1'>
//             <h1> Welcome to {applicationName}</h1>
//           </span>
//         </div>
//       </div>
//     </>
//   )
// }

// const DashboardWrapper: FC = () => {
//   const intl = useIntl()
//   const dispatch = useDispatch()
//   const [color, setColor] = useState({ r: 255, g: 255, b: 255, a: 1 })
//   const [applicationName, setAplicationName] = useState('')
//   const keyData: any = useSelector((state: any) => state.key)

//   const gaTrackId = keyData?.data?.keySetting.filter(
//     (item: any) => item?.name === 'GA-Tracking-ID'
//   )[0]?.key

//   const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
//   const onChange = (color: any) => {
//     setColor(color.rgb)
//   }
//   useEffect(() => {
//     dispatch(key.actions.getKey())
//   }, [])

//   useEffect(() => {
//     if (!isEmpty(settingTypeData?.backendData)) {
//       settingTypeData?.backendData &&
//         settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
//           if (item?.name === 'applicationName') {
//             setAplicationName(item?.value)
//           }
//         })
//     }
//   }, [settingTypeData])

//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
//       <DashboardPage applicationName={applicationName} gTrackCode={gaTrackId} />
//     </>
//   )
// }

// export { DashboardWrapper }
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {isEmpty} from 'lodash'
import {key} from 'src/app/modules/siteSettings'
import CIFCheck from './component.tsx/CIFCheck'
import useGetMasterData from 'src/hooks/PostMasterData/UsePostGetMasterData'
import {Flex} from 'antd'
import {useGetCount} from 'src/hooks/getDashBoardCount/useGetDashBoardCount'
const DashboardWrapper = () => {
  const dispatch = useDispatch()
  let timeoutId: NodeJS.Timeout | null = null

  const intl = useIntl()
  const [applicationName, setAplicationName] = useState('')
  const [color, setColor] = useState({r: 255, g: 255, b: 255, a: 1})
  const keyData: any = useSelector((state: any) => state.key)
  // let hasDispatched = false
  // const {allMasterDataOptions, isLoading} = usePlaceMasterData({
  //   allMasterData: true,
  // })
  const gaTrackId = keyData?.data?.keySetting.filter(
    (item: any) => item?.name === 'GA-Tracking-ID'
  )[0]?.key

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const onChange = (color: any) => {
    setColor(color.rgb)
  }
  useEffect(() => {
    dispatch(key.actions.getKey())
  }, [])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setAplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp

  // !isLoading && dispatch(MasterDataStore(allMasterDataOptions))
  // if (!timeoutId) {
  //   timeoutId = setTimeout(() => {
  //     if (!isLoading) {
  //       dispatch(MasterDataStore(allMasterDataOptions))
  //     }
  //   }, 7000)
  // }

  // useEffect(() => {
  //   if (!isLoading && !hasDispatched && allMasterDataOptions) {
  //     // Use setTimeout to push this to the next event loop tick
  //     setTimeout(() => {
  //       dispatch(MasterDataStore(allMasterDataOptions))
  //       hasDispatched = true
  //     }, 0)
  //   }
  // }, [isLoading, allMasterDataOptions, dispatch])
  const [individualCount, SetIndividualCount] = useState({model_name: 'individual'})
  const {data: counIndividualData, isLoading: isCountLoading} = useGetCount(individualCount)

  const [corporateCount, setCorporateCount] = useState({model_name: 'corporate'})
  const {data: corporateCountountData, isLoading: isCorporateCountLoading} =
    useGetCount(corporateCount)
  const {mutate} = useGetMasterData()

  useEffect(() => {
    let masterData = localStorage.getItem('allMasterDataOptions')
    if (masterData) {
      return
    } else {
      mutate()
    }
  }, [])

  return (
    <div>
      <div className='heading'>
        <h1>Welcome to {applicationName} </h1>
        <CIFCheck />
      </div>
      <h3 className='text-danger'>New CIF New Account</h3>
      <hr />
      <div className='row gy-5'>
        <div className='col-md-6 col-sm-12'>
          <div className='card text-center shadow-sm'>
            <div className='card-body'>
              <h5 className='card-title'>Individual/Joint/Minor Overview</h5>
              <p className='text-muted'>
                Total Count:{' '}
                <span className='text-primary'>{counIndividualData?.data?.total_count}</span>
              </p>
              <p className='text-muted'>
                Pending:{' '}
                <span className='text-warning'>{counIndividualData?.data?.total_pending}</span>
              </p>
              <p className='text-muted'>
                Completed:{' '}
                <span className='text-success'>{counIndividualData?.data?.total_completed}</span>
              </p>
              <p className='text-muted'>
                Rejected:{' '}
                <span className='text-danger'>{counIndividualData?.data?.total_rejected}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-sm-12'>
          <div className='card text-center shadow-sm'>
            <div className='card-body'>
              <h5 className='card-title'>Corporate Overview</h5>
              <p className='text-muted'>
                Total Count:{' '}
                <span className='text-primary'>{corporateCountountData?.data?.total_count}</span>
              </p>
              <p className='text-muted'>
                Pending:{' '}
                <span className='text-warning'>{corporateCountountData?.data?.total_pending}</span>
              </p>
              <p className='text-muted'>
                Completed:{' '}
                <span className='text-success'>
                  {corporateCountountData?.data?.total_completed}
                </span>
              </p>
              <p className='text-muted'>
                Rejected:{' '}
                <span className='text-danger'>{corporateCountountData?.data?.total_rejected}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='row gy-4'>
          {/* Branch Maker */}
          <div className='col-md-4 col-sm-12'>
            <h3 className='mt-5 text-center text-danger'>Branch Details</h3>
            <hr />
            <div className='card shadow-sm'>
              <div className='card-body'>
                <h4 className='card-title mb-3'>Branch Maker</h4>
                <p className='card-text'>
                  Individual/Joint/Minor Count:{' '}
                  <span>{counIndividualData?.data?.total_in_branch_maker}</span>
                </p>
                <p className='card-text'>
                  Corporate Count:{' '}
                  <span>{corporateCountountData?.data?.total_in_branch_maker}</span>
                </p>
              </div>
            </div>
          </div>

          {/* Branch Checker */}
          <div className='col-md-4 col-sm-12'>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <h4 className='card-title mb-3'>Branch Checker</h4>
                <p className='card-text'>
                  Individual/Joint/Minor Count:{' '}
                  <span>{counIndividualData?.data?.total_in_branch_checker}</span>
                </p>
                <p className='card-text'>
                  Corporate Count:{' '}
                  <span>{corporateCountountData?.data?.total_in_branch_checker}</span>
                </p>
                <p className='card-text'>
                  Individual/Joint/Minor Rejected:{' '}
                  <span className='text-danger'>
                    {counIndividualData?.data?.total_rejected_by_branch_checker}
                  </span>
                </p>
                <p className='card-text'>
                  Corporate Rejected:{' '}
                  <span className='text-danger'>
                    {corporateCountountData?.data?.total_rejected_by_branch_checker}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* QA */}
          <div className='col-md-4 col-sm-12'>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <h4 className='card-title mb-3'>QA</h4>
                <p className='card-text'>
                  Individual/Joint/Minor Count: <span>{counIndividualData?.data?.total_in_qa}</span>
                </p>
                <p className='card-text'>
                  Corporate Count: <span>{corporateCountountData?.data?.total_in_qa}</span>
                </p>
                <p className='card-text'>
                  Individual/Joint/Minor Rejected:{' '}
                  <span className='text-danger'>
                    {counIndividualData?.data?.total_rejected_by_qa}
                  </span>
                </p>
                <p className='card-text'>
                  Corporate Rejected:{' '}
                  <span className='text-danger'>
                    {corporateCountountData?.data?.total_rejected_by_qa}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* CPU Maker */}
          <div className='col-md-4 col-sm-12'>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <h4 className='card-title mb-3'>CPU Maker</h4>
                <p className='card-text'>
                  Individual/Joint/Minor Count:{' '}
                  <span>{counIndividualData?.data?.total_in_cpu_maker}</span>
                </p>
                <p className='card-text'>
                  Corporate Count: <span>{corporateCountountData?.data?.total_in_cpu_maker}</span>
                </p>
              </div>
            </div>
          </div>

          {/* CPU Checker */}
          <div className='col-md-4 col-sm-12'>
            <div className='card shadow-sm'>
              <div className='card-body'>
                <h4 className='card-title mb-3'>CPU Checker</h4>
                <p className='card-text'>
                  Individual/Joint/Minor Count:{' '}
                  <span>{counIndividualData?.data?.total_in_cpu_checker}</span>
                </p>
                <p className='card-text'>
                  Corporate Count: <span>{corporateCountountData?.data?.total_in_cpu_checker}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DashboardWrapper}
