import axios from 'axios'
import {useQuery, UseQueryResult} from 'react-query'
import {GET_ALL_MASTER_DATA_DISTRICT} from '../../api/apiEndPoints/EndPoints'

const QUERY_KEY = 'districtmasterdata'

interface ErrorResponse {
  message: string
}

const fetchDistrictMasterData = async (zoneCode: string): Promise<any> => {
  try {
    const {data} = await axios.get(GET_ALL_MASTER_DATA_DISTRICT, {
      params: {
        zoneCode,
      },
    })
    return data
  } catch (error: any) {
    throw new Error(`Error fetching master data: ${error.message}`)
  }
}

export const useGetAllDistrictData = (zoneCode?: any): UseQueryResult<any, ErrorResponse> => {
  return useQuery<any, ErrorResponse>(
    [QUERY_KEY, zoneCode],
    () => fetchDistrictMasterData(zoneCode)
    // {staleTime: 6000000}
  )
}

export const DistrictOption = () => {
  return useQuery(
    ['DistrictOptionGetList'],
    async () => {
      const {data} = await axios.get(`${GET_ALL_MASTER_DATA_DISTRICT}`)
      return data
    },
    {
      onError: (error: any) => {},
    }
  )
}
