import React, {useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {RcFile} from 'antd/lib/upload'
import {useGetDOcumentMasterData} from 'src/app/queries/getMasterData/documentType/documentTypeMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import FileUploadComponent from '../../../../../common/form/fileUpload'
import {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS} from '../../../../../constants/Contants'
import {Button, Spinner} from 'react-bootstrap-v5'
import axios from 'axios'
import {toast} from 'react-toastify'
import {QueryClient} from 'react-query'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {Row} from 'antd'

const DocumentUploadJoint: React.FC = () => {
  interface RouteParams {
    id: string
  }
  const history = useHistory()
  const {id: uniqueId} = useParams<RouteParams>()
  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<{id: string}[]>([])
  const [existingFiles, setExistingFiles] = useState<{id: string}[]>([])
  const [hasOptionalDocument, setHasOptionalDocument] = useState(false) // New state for tracking optional documents
  const [btnFlag, setBtnFlag] = useState<string>('')
  const [loading, setLoading] = useState(false)

  // Track multiple CIF Form file uploads
  const [cifFormFiles, setCifFormFiles] = useState<Array<{id: string; file: RcFile | null}>>([
    {id: '', file: null},
  ])
  const [otherFiles, setOtherFiles] = useState<Array<{id: string; file: RcFile | null}>>([
    {id: '', file: null},
  ])

  const {
    data: preliminariesDetails,
    isLoading: loadingPreliminaryData,
    invalidateQuery,
    refetch,
  } = useGetPreliminariesDetailsById(uniqueId)
  const {data: documentTypeList, isLoading: loadingDocumentTypes} = useGetDOcumentMasterData()

  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

  useEffect(() => {
    if (preliminariesDetails?.data?.account_documents?.documents) {
      const existingFilesData = preliminariesDetails.data.account_documents.documents.map(
        (doc: any) => ({id: doc.documentType.id})
      )
      setExistingFiles(existingFilesData)
      const existingOptionalDocIds = preliminariesDetails.data.account_documents.documents
        .filter((doc: any) => optionalDocuments.includes(doc.documentType.cbs_code))
        .map((doc: any) => doc.documentType.id)
      if (existingOptionalDocIds.length > 0) {
        setHasOptionalDocument(true)
      }
    }

    if (preliminariesDetails?.data?.additional_documents?.documents) {
      const otherDocFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc.documentType.id}))

      const cifFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code !== 'other_documents')
        .map((doc: any) => ({id: doc.documentType.id}))
      setCifFormFiles(cifFiles)

      setOtherFiles(otherDocFiles)
    }
  }, [preliminariesDetails])

  const optionalDocuments = [
    'address_verifying_documents',
    'poa_signature',
    'poa_documents',
    'map_documents',
  ]

  const requiredDocuments: string[] = [
    'identification_certificate',
    'upload_pp_size_photo',
    'signature',
    'upload_AOF',
  ]
  const handleCifFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setCifFormFiles((prev) => {
      const newCifFiles = [...prev]
      newCifFiles[index] = {id: documentTypeId, file}
      return newCifFiles
    })
  }

  const handleOtherFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setOtherFiles((prev) => {
      const newOtherFiles = [...prev]
      newOtherFiles[index] = {id: documentTypeId, file}
      return newOtherFiles
    })
  }

  const handleFileUpload = (file: RcFile, documentTypeId: string) => {
    // const uploadedDoc = documentTypeList?.data?.document_types.find(
    //   (doc: any) => doc.id === documentTypeId
    // )

    // // Check if the uploaded document is one of the optional ones
    // if (uploadedDoc && optionalDocuments.includes(uploadedDoc.cbs_code)) {
    //   setHasOptionalDocument(true) // Set to true if any optional document is uploaded
    // }

    setUploadedFiles((prevState) => {
      const existingFileIndex = prevState.findIndex((file) => file.id === documentTypeId)
      if (existingFileIndex !== -1) {
        const newState = [...prevState]
        newState[existingFileIndex] = {id: documentTypeId}
        return newState
      } else {
        return [...prevState, {id: documentTypeId}]
      }
    })
  }

  const validateRequiredDocuments = () => {
    const requiredDocIds = documentTypeList?.data?.document_types
      .filter((doc: any) => requiredDocuments.includes(doc.cbs_code))
      .map((doc: any) => doc.id)

    const uploadedDocIds = uploadedFiles.map((file) => file?.id)
    const existingDocIds = existingFiles.map((file) => file?.id)

    const missingRequiredDocs = requiredDocIds.filter(
      (docId: any) => !uploadedDocIds.includes(docId) && !existingDocIds.includes(docId)
    )

    setMissingDocs(missingRequiredDocs)

    return missingRequiredDocs.length === 0
  }

  const handleSubmit = async (isSubmit: boolean) => {
    isSubmit && setBtnFlag('save')
    !isSubmit && setBtnFlag('save_and_continue')

    if (isSubmit && !validateRequiredDocuments()) {
      toast.error('Please upload all required documents.')
      return
    }

    const documentIdsToSubmit = [
      ...new Set([
        ...uploadedFiles?.map((file) => file.id),
        ...existingFiles?.map((file) => file.id),
        ...cifFormFiles?.map((file) => file.id),
        ...otherFiles?.map((file) => file.id),
      ]),
    ]

    const payload = {
      action: isSubmit ? 'save_and_continue' : 'save',
      documentIds: documentIdsToSubmit,
    }

    try {
      setLoading(true)
      const queryClient = new QueryClient()
      const response = await axios.post(
        `${API_URL}/new-cif-new-account/${uniqueId}/branch-maker-document-form`,
        payload
      )

      if (response) {
        toast.success(response?.data?.resDesc)
      }

      invalidateQuery()
      refetch()
      setLoading(false)
      history.push('/new-cif-new-accounts')
    } catch (error: any) {
      setLoading(false)
      setBtnFlag('')
      toast.error('Failed to upload documents.')
      console.error('Error uploading documents:', error)
    }
  }

  if (loadingDocumentTypes || loadingPreliminaryData) {
    return (
      <div>
        Loading... <Spinner animation={'border'} />
      </div>
    )
  }

  const getExistingFile = (documentTypeId: string, index?: number) => {
    // If CIF Form or Other Documents, fetch from additional_documents
    // const additionalDoc = preliminariesDetails?.data?.additional_documents?.documents?.find(
    //   (doc: any) => doc.documentType.id === documentTypeId
    // )[index]
    const additionalDoc = index
      ? preliminariesDetails?.data?.additional_documents?.documents?.filter(
          (doc: any) => doc.documentType.id === documentTypeId
        )[index]
      : preliminariesDetails?.data?.additional_documents?.documents?.find(
          (doc: any) => doc.documentType.id === documentTypeId
        )

    if (additionalDoc) {
      return {name: additionalDoc.file_name, url: additionalDoc.file_url}
    }
    // if (!isEmpty(additionalDoc)) {
    //   const allOtherDoc = additionalDoc?.map((doc: any) => ({
    //     name: doc?.file_name,
    //     url: doc?.file_url,
    //   }))
    //   return allOtherDoc
    // }

    // For all other documents, fetch from account_documents
    const document = preliminariesDetails?.data?.account_documents?.documents.find(
      (doc: any) => doc.documentType.id === documentTypeId
    )

    return document ? {name: document.file_name, url: document.file_url} : null
  }

  const individualDocs = documentTypeList?.data?.document_types.filter(
    (documentType: any) =>
      requiredDocuments.includes(documentType.cbs_code) ||
      optionalDocuments.includes(documentType.cbs_code)
  )
  const data = preliminariesDetails?.data

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <h2 style={{color: 'brown'}}>Account Details</h2>
            <FormDetails
              key='account_type_id'
              label='Account Type'
              value={data?.account_type_id?.title}
            />
            <FormDetails
              key='aml_risk_rating'
              label='AML Risk Rating'
              value={data?.aml_risk_rating?.title}
            />
            <FormDetails
              key='sub_risk_category_id'
              label='AML Risk Sub Rating'
              value={data?.sub_risk_category_id?.title}
            />
          </div>
          <div className='row'>
            <h2 style={{color: 'brown'}}>Personal Details</h2>
            <FormDetails key='salutation' label='Salutation' value={data?.salutation} />
            <FormDetails key='first_name' label='First Name' value={data?.first_name} />
            <FormDetails key='middle_name' label='Middle Name' value={data?.middle_name || '-'} />
            <FormDetails key='last_name' label='Last Name' value={data?.last_name} />
            <FormDetails key='gender' label='Gender' value={data?.gender} />
            <FormDetails
              key='marital_status'
              label='Marital Status'
              value={data?.marital_status?.title}
            />
            {data?.marital_status?.title === 'MARRIED' && (
              <>
                <FormDetails key='spouse_name' label='Spouse Name' value={data?.spouse_name} />
                <FormDetails
                  key='father_in_law'
                  label='Father In Law Name'
                  value={data?.father_in_law}
                />
              </>
            )}
            <FormDetails
              key='date_of_birth_ad'
              label='Date of Birth (AD)'
              value={data?.date_of_birth_ad}
            />
            <FormDetails
              key='date_of_birth_bs'
              label='Date of Birth (BS)'
              value={data?.date_of_birth_bs}
            />
            <FormDetails
              key='nationality_id'
              label='Nationality'
              value={data?.nationality_id?.title}
            />
            {data.refugee_details && (
              <FormDetails
                key='refugee_details'
                label='Others'
                value={data.refugee_details === 'REFUGEES' ? 'REFUGEES' : 'NON CITIZENSHIP HOLDER'}
              />
            )}
            <FormDetails key='id_type_id' label='ID Type' value={data?.id_type_id?.title} />
            <FormDetails
              key='identification_number'
              label='Identification Number'
              value={data?.identification_number}
            />
            <FormDetails
              key='id_issued_date_ad'
              label='Issue date(AD)'
              value={data?.id_issued_date_ad}
            />
            <FormDetails key='id_issued_date' label='Issue date(BS)' value={data?.id_issued_date} />
            {data?.national_id_number && (
              <FormDetails
                key='national_id_number'
                label='National Id Number'
                value={data?.national_id_number}
              />
            )}
            {data?.id_type_id?.title !== 'CITIZENSHIP' &&
              data?.id_type_id?.title !== 'PAN CARD' &&
              data?.id_type_id?.title !== 'BIRTH CERTIFICATE' && (
                <>
                  <FormDetails
                    key='id_expiry_date'
                    label='Expiry date'
                    value={data?.id_expiry_date || '-'}
                  />
                </>
              )}
            {data?.marital_status?.title === 'MARRIED' && (
              <>
                <FormDetails key='spouse_name' label='Spouse Name' value={data?.spouse_name} />
                <FormDetails
                  key='father_in_law'
                  label='Father In Law Name'
                  value={data?.father_in_law}
                />
              </>
            )}
            <FormDetails key='father_name' label="Father's Name" value={data?.father_name} />
            <FormDetails
              key='grandfather_name'
              label="Grandfather's Name"
              value={data?.grandfather_name}
            />
          </div>
          <div className='row'>
            <h2 style={{color: 'brown'}}>Income Details</h2>
            <FormDetails
              key='occupation_id'
              label='Occupation'
              value={data?.occupation_id?.title}
            />
            {data?.other_occupation && (
              <FormDetails
                key='other_occupation'
                label='Other Occupation'
                value={data?.other_occupation}
              />
            )}
            <FormDetails
              key='income_source_id'
              label='Income Source'
              value={data?.income_source_id?.title}
            />
            {data?.other_income_source && (
              <FormDetails
                key='other_income_source'
                label='Other Income Source'
                value={data.other_income_source}
              />
            )}
            <FormDetails
              key='total_annual_income'
              label='Total Annual Income'
              value={data?.total_annual_income?.title}
            />
            <FormDetails
              key='expected_annual_turnover'
              label='Expected Annual Transaction In Amount'
              value={data?.expected_annual_turnover}
            />
          </div>

          <section className=''>
            <h3 className='fw-bolder mb-4 text-capitalize'>Communication/ Present Address</h3>
            <div className='row'>
              {/* <h2 style={{color: 'brown'}}>Communication/Present Address Details</h2> */}
              <FormDetails
                key='present_country'
                label='Country'
                value={data?.present_country?.title}
              />
              {data?.present_country?.title === 'NEPAL' ? (
                <>
                  <FormDetails
                    key='present_province'
                    label='Province'
                    value={data?.present_province?.title}
                  />
                  <FormDetails key='present_zone' label='Zone' value={data?.present_zone?.title} />
                  <FormDetails
                    key='present_district'
                    label='District'
                    value={data?.present_district?.title}
                  />
                  <FormDetails
                    key='present_municipality'
                    label='Municipality'
                    value={data?.present_municipality?.title}
                  />
                  <FormDetails key='present_city' label='City' value={data?.present_city?.title} />
                  <FormDetails
                    key='present_street_name'
                    label='Street'
                    value={data?.present_street_name}
                  />
                  {data?.present_fax_number && (
                    <FormDetails
                      key='present_fax_number'
                      label='Present Fax Number'
                      value={data?.present_fax_number}
                    />
                  )}
                  <FormDetails
                    key='present_house_number'
                    label='House Number'
                    value={data?.present_house_number}
                  />
                  <FormDetails
                    key='present_ward_number'
                    label='Ward Number'
                    value={data?.present_ward_number}
                  />
                  <FormDetails
                    key='present_mobile_number'
                    label='Mobile Number'
                    value={data?.present_mobile_number}
                  />
                  <FormDetails key='' label='Email Address' value={data?.present_email} />
                </>
              ) : (
                <>
                  <FormDetails
                    key='present_outside_country_post_box'
                    label='Foreign Country Post Box No.'
                    value={data?.present_outside_country_post_box}
                  />
                  <FormDetails
                    key='present_outside_country_full_address'
                    label='Foreign Country Address  '
                    value={data?.present_outside_country_full_address}
                  />
                  <FormDetails
                    key='present_mobile_number'
                    label=' Mobile Number'
                    value={data?.present_mobile_number}
                  />{' '}
                  <FormDetails key='present_email' label=' Email' value={data?.present_email} />
                </>
              )}
            </div>
          </section>
          {data?.account_type_id?.title === 'Joint' && (
            <>
              <div className='row'>
                <h2 style={{color: 'brown'}}>Partner's Details</h2>
                <FormDetails
                  key='first_name'
                  label='First Name'
                  value={data?.account_holders?.account_holders[0]?.first_name}
                />
                <FormDetails
                  key='middle_name'
                  label='Middle Name'
                  value={data?.account_holders?.account_holders[0]?.middle_name}
                />
                <FormDetails
                  key='last_name'
                  label='Last Name'
                  value={data?.account_holders?.account_holders[0]?.last_name}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div>
            <div className='heading'>
              <h2 style={{color: 'brown'}}>Document Requirements - Joint Account</h2>
              <i>Please upload file of size mentioned here:</i>
              <ul>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleIdentificationCertificate}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePP}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAOF}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleSignature}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleCIFFORM}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePOASignature}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAccountVerify}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleOtherDocuments}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleMapDocuments}</li>
              </ul>
            </div>
            {loadingPreliminaryData ? (
              <div>
                <h4>Please Wait...</h4>
                <Spinner animation={'border'} />
              </div>
            ) : (
              <div>
                <Row gutter={16}>
                  {individualDocs?.map((documentType: any) => (
                    // <div key={documentType.id}>
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={documentType.title}
                      maxSize={Number(documentType.max_size)}
                      onChange={(file: RcFile) => handleFileUpload(file, documentType.id)}
                      documentTypeId={documentType.id}
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(documentType.id)}
                      initialFile={getExistingFile(documentType.id)}
                    />
                    // </div>
                  ))}
                </Row>
                {/* Render multiple CIF Form fields */}
                {cifFormFiles.map((_, index) => (
                  <div key={`cif_form_${index}`} style={{marginBottom: '20px', width: '30%'}}>
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={'CIF Form'}
                      maxSize={2000}
                      onChange={(file: RcFile) =>
                        handleCifFormFileUpload(
                          index,
                          file,
                          documentTypeList?.data?.document_types.find(
                            (item: any) => item?.cbs_code === 'cif_form'
                          )?.id
                        )
                      }
                      documentTypeId={
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id
                      }
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id
                      )}
                      initialFile={getExistingFile(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id,
                        index
                      )}
                    />
                  </div>
                ))}

                {/* Button to add more CIF Forms */}
                <div className='col-12'>
                  <Button
                    onClick={() => setCifFormFiles((prev) => [...prev, {id: '', file: null}])}
                    variant='outline-primary'
                  >
                    Add More CIF Form
                  </Button>
                </div>

                {/* Render multiple Other Documents fields */}
                {otherFiles.map((_: any, index: number) => (
                  <div
                    key={`other_documents_${index}`}
                    style={{marginBottom: '20px', width: '30%'}}
                  >
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={'Other Documents'}
                      maxSize={2000}
                      onChange={(file: RcFile) =>
                        handleOtherFormFileUpload(
                          index,
                          file,
                          documentTypeList?.data?.document_types?.find(
                            (item: any) => item?.cbs_code === 'other_documents'
                          )?.id
                        )
                      }
                      documentTypeId={
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id
                      }
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id
                      )}
                      initialFile={getExistingFile(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id,
                        index
                      )}
                    />
                  </div>
                ))}

                {/* Button to add more Other Documents */}
                <div className='col-12'>
                  <Button
                    onClick={() => setOtherFiles((prev) => [...prev, {id: '', file: null}])}
                    // onClick={() => setOtherDocumentsCount(otherDocumentsCount + 1)}
                    variant='outline-primary'
                  >
                    Add More Other Documents
                  </Button>
                </div>
              </div>
            )}
            <Button
              type='primary'
              onClick={() => handleSubmit(false)}
              style={{margin: '20px 10px'}}
            >
              {loading && btnFlag === 'save_and_continue' ? 'Saving as draft...' : 'Save as draft'}
            </Button>
            <Button
              type='primary'
              onClick={() => handleSubmit(true)}
              style={{marginTop: '20px 10px'}}
            >
              {loading && btnFlag === 'save' ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentUploadJoint
