import Edit2 from '@rsuite/icons/Edit'
import ImageIcon from '@rsuite/icons/Image'
import TrashIcon from '@rsuite/icons/Trash'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import Table from 'rsuite/Table'
import Toggle from 'rsuite/Toggle'
import Tooltip from 'rsuite/Tooltip'
import Whisper from 'rsuite/Whisper'
import IconButton from 'rsuite/esm/IconButton'

// includes
import {Popover} from 'rsuite'
import Tag from 'rsuite/esm/Tag'
import {ItemType, StateParamsModel} from 'src/app/modules/common/Model'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import CheckPermissions from 'src/app/modules/common/checkPermission'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {IModuleModel} from '../Model'
import * as homepageSettingRedux from '../index'
import AddHomepageSetting from './AddHomepageSetting'
import AddHomepageSettingModal from './AddHomepageSettingModal'
const Cell = Table.Cell

const HomepageSetting = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const handleAlertClose = () => setAlertOpen(false)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const {
    data: {homePage, meta},
    success,
    sortSuccess,
    loading,
    toggleLoading,
    singleEnableSuccess,
    singleDisableSuccess,
    sortHomepageSettingData,
    deleteSuccess,
  }: any = useSelector((state: any) => state.homepageSetting)
  const [editSelectedData, setEditCheckedData] = useState<IModuleModel | {}>()

  const [openSortModal, setOpenSortModal] = useState(false)

  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const homepageData = homePage
    ? homePage.map((item: any, i: number) => ({
        sn: (params?.page - 1) * params?.limit + (i + 1),
        id: item?.id,
        title: item.title,
        section: item.section,
        sectionId: item.section?.id,
        layout: item.layout,
        layoutImage: item.layoutImage,
        layoutId: item.layout?.id,
        topmenu: item.topmenu,
        topmenuId: item.topmenu?.id,
        textEn: item.textEn,
        textNp: item.textNp,
        subtextEn: item.subtextEn,
        subtextNp: item.subtextNp,
        imageEn: item.imageEn,
        imageNp: item.imageNp,
        highlightedTextEn: item.highlightedTextEn,
        highlightedTextNp: item.highlightedTextNp,
        buttonTextEn: item.buttonTextEn,
        buttonTextNp: item.buttonTextNp,
        buttonUrl: item.buttonUrl,
        buttonClass: item.buttonClass,
        secondbuttonTextEn: item.secondbuttonTextEn,
        secondbuttonTextNp: item.secondbuttonTextNp,
        secondbuttonUrl: item.secondbuttonUrl,
        secondbuttonClass: item.secondbuttonClass,
        services: item.services.service.map((item: {[key: string]: string}) => ({
          id: item.id,
          serviceTitle: item.title,
          serviceTitleNp: item.titleNp,
          serviceLink: item.link,
          serviceIcon: item.icon,
          description: item.description,
          descriptionNp: item.descriptionNp,
          firstButton: item.firstButton,
          firstButtonNp: item.firstButtonNp,
          firstButtonUrl: item.firstButtonUrl,
          secondButton: item.secondButton,
          secondButtonNp: item.secondButtonNp,
          secondButtonUrl: item.secondButtonUrl,
          order: item.order,
        })),
        mobileAppSections: item.mobileAppSections.mobileAppSections.map(
          (item: {[key: string]: string}) => ({
            id: item.id,
            title: item.title,
            link: item.title,
            image: item.image,
            order: item.order,
          })
        ),
        status: item.status,
        order: item.order,
      }))
    : []

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
  }, [params])

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Homepage setting enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Homepage setting disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess])

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    return (
      <Cell {...props} className='link-group'>
        <CheckPermissions type='Edit'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                setEditCheckedData(rowData)
                setActionType('Edit')
                setOpen(true)
              }}
              data-cy='edit-button'
              icon={<Edit2 />}
            />
          </Whisper>
        </CheckPermissions>
        <CheckPermissions type='Delete'>
          <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
            <IconButton
              appearance='subtle'
              onClick={() => {
                handleAlertOpen()
                let keySettingId = [rowData.id]
                setCheckedValues(keySettingId)
              }}
              data-cy='delete-button'
              icon={<TrashIcon />}
            />
          </Whisper>
        </CheckPermissions>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={toggleLoading}
            checked={rowData.status}
            onClick={() => handleToggleAction(rowData)}
            data-cy='toggle-button'
          />
        </Whisper>
      </Cell>
    )
  }

  const handleToggleAction = (data: {[key: string]: string}) => {
    data?.status
      ? dispatch(homepageSettingRedux.actions.singleDisableHomepageSetting({id: data.id}))
      : dispatch(homepageSettingRedux.actions.singleEnableHomepageSetting({id: data.id}))
  }

  const handleClose = () => setOpen(false)

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Title',
      dataKey: 'title',
      flexGrow: 1,
      cell: (
        <Cell className='link-group'>
          {(rowData) => {
            return (
              <>
                {rowData?.title}
                <Whisper
                  placement='top'
                  trigger='hover'
                  speaker={
                    <Popover>
                      {
                        <img
                          src={`${imageBaseUrl}/${rowData?.layoutImage}`}
                          alt=''
                          height={'auto'}
                          width={300}
                        />
                      }
                    </Popover>
                  }
                >
                  <ImageIcon className='ms-3 fs-3' />
                </Whisper>
              </>
            )
          }}
        </Cell>
      ),
      sortable: true,
    },
    {
      label: 'Top Menu',
      dataKey: 'topmenu.displayName',
      width: 180,
      cell: <Cell dataKey='topmenu.displayName' />,
    },
    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
      sortable: false,
    },
    {
      label: 'Action',
      width: 120,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]
  // const handleToggleEnableDisableAction = (data: IModuleModel) => {
  //   let body = {
  //     moduleId: [
  //       {
  //         id: data.id,
  //       },
  //     ],
  //   }
  //   data?.status
  //     ? dispatch(homepageSettingRedux.actions.disableModuleStatus(body))
  //     : dispatch(homepageSettingRedux.actions.enableModuleStatus(body))
  // }

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  const modalSubmit = () => {
    let id = checkedValues?.map((value) => ({id: value}))
    dispatch(homepageSettingRedux?.actions?.deleteHomepageSetting(id))
    handleAlertClose()
  }

  const handleRefresh = () => {
    dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: meta?.total})
    dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
  }

  const handleSubmitSort = (data: Array<ItemType>) => {
    let body = {
      homepageId: data?.map((item) => ({id: item?.keyId})),
    }
    dispatch(homepageSettingRedux.actions.sortHomepageSetting(body))
  }

  useEffect(() => {
    if (deleteSuccess) {
      toast.success('Homepage deleted successfully')
      dispatch(homepageSettingRedux?.actions.getHomepageSetting(params))
    }
  }, [deleteSuccess])

  useEffect(() => {
    if (!isEmpty(sortHomepageSettingData) && sortSuccess) {
      toast.success('Homepage setting sorted successfully')
      dispatch(homepageSettingRedux.actions.sortHomepageSettingReset())
      setParams({...params, limit: 10})
      dispatch(homepageSettingRedux.actions.getHomepageSetting(params))
      setOpenSortModal(false)
    }
  }, [sortHomepageSettingData, sortSuccess])

  const settings: ISettingTypeState = useSelector((state: any) => state.settingType)

  const showAddButton =
    settings?.data?.setting?.filter((d) => d.name === 'showAddButton')[0]?.value === '1'
      ? true
      : false
  const showDeleteButton =
    settings?.data?.setting?.filter((d) => d.name === 'showDeleteButton')[0]?.value === '1'
      ? true
      : false
  const multiLanguage = checkMultiLanguage()
  return (
    <>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Homepage Setting'
          params={params}
          setParams={setParams}
          handleRefresh={handleRefresh}
          handleAddModal={handleAddModal}
          handleAlertOpen={handleAlertOpen}
          addShow={true}
          deleteShow={true}
          exportShow={false}
          importShow={false}
          toggleMultipleShow={false}
          data={homepageData}
          sortShow={true}
          sortButtonName='Sort'
          handleSubmitSort={handleSubmitSort}
          handleGetAllData={handleGetAllData}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          loading={loading}
        />

        <div className='datatable'>
          <RSuiteTable
            onChecked={handleChecked}
            showCheckbox={true}
            columns={columns}
            data={homepageData}
            height={500}
            checkedValues={checkedValues}
            showLoading={loading}
            handleSort={handleSort}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            className='mt-5'
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
          />
          {open && multiLanguage ? (
            <AddHomepageSettingModal
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          ) : (
            <AddHomepageSetting
              open={open}
              params={params}
              handleClose={handleClose}
              actionType={actionType}
              editSelectedData={editSelectedData}
            />
          )}

          {alertOpen &&
            (!isEmpty(checkedValues) ? (
              <DeleteModal
                handleClick={() => modalSubmit()}
                isOpen={alertOpen}
                handleClose={() => handleAlertClose()}
              />
            ) : (
              toast.error('No data selected') && setAlertOpen(false)
            ))}
        </div>
      </div>
    </>
  )
}
export default HomepageSetting
