export const splitName = (fullName: string) => {
  //
  if (!fullName) return {firstName: '', middleName: '', lastName: ''}

  const nameParts = fullName?.trim()?.split(' ')
  let firstName = ''
  let middleName = ''
  let lastName = ''
  let shortCode = ''

  if (nameParts?.length && nameParts?.length > 0) {
    firstName = nameParts[0]
    shortCode = firstName.charAt(0).toUpperCase()
  }

  if (nameParts?.length && nameParts?.length === 2) {
    lastName = nameParts[1]
  } else if (nameParts?.length && nameParts.length > 2) {
    lastName = nameParts.pop() as string
    middleName = nameParts.slice(1).join(' ')
  }

  return {
    shortCode: shortCode,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
  }
}

import {bsToAd} from '@sbmdkl/nepali-date-converter'

export function convertNepaliDateToEnglish(nepaliDate: any) {
  // Split the Nepali date into day, month, and year
  //
  if (!nepaliDate) return null
  const [day, month, year] = nepaliDate.split('-')

  // Format the Nepali date to 'YYYY-MM-DD' which is expected by the bsToAd function
  const formattedNepaliDate = `${year}-${month}-${day}`
  // Convert the Nepali date to English date
  const englishDate: any = bsToAd(formattedNepaliDate)

  return englishDate
}

interface EntityRelationShip {
  PERSON_RELTN_NAME: string
  CUST_RELTN_CODE: string
  DEL_FLG: string
}
interface IOccupation {
  CUST_OCCP_CODE: string
}
export const getRelationshipNameByCode = (
  relationArray: EntityRelationShip[],
  code: string
): string => {
  const relation = relationArray?.find((rel) => rel.CUST_RELTN_CODE === code && rel.DEL_FLG === 'N')
  return relation ? relation.PERSON_RELTN_NAME : '-'
}
export const getOptionValueByID = (options: any[], id: string): string => {
  const matchedOption = options?.find((option: any) => option?.id === id)?.title
  return matchedOption || ''
}
export const getOptionValueByKey = (options: any[], code: string): string => {
  const matchedOption = options?.find((option: any) => option?.code === code)?.title
  return matchedOption || ''
}

export const getOptionValueByKeyAddress = (options: any[], code: string): string => {
  const matchedOption = options?.find((option: any) => option?.value === code)?.label
  return matchedOption || ''
}

export const getOptionValueByKeyCBS = (options: any[], cbs_code: string): string => {
  const matchedOption = options?.find((option: any) => option?.cbs_code === cbs_code)?.title
  return matchedOption || ''
}
export const getOptionValueByKeyZone = (options: any[], code: string): string => {
  const matchedOption = options?.find((option: any) => option?.code === code)?.label
  return matchedOption || ''
}

export const getOptionValueByKeyNew = (options: any[], code: string): string => {
  const matchedOption = options?.find((option: any) => option?.code === code)?.title
  return matchedOption || ''
}
export const getOptionValueByKeyDistrict = (options: any[], code: string): string => {
  const matchedOption = options?.find((option: any) => option?.code === code)?.label
  return matchedOption || '-'
}

export const getOptionValueByKeyMunicipility = (options: any[], title: string): string => {
  const matchedOption = options?.find((option: any) =>
    option?.title?.toLowerCase().includes(title.toLowerCase())
  )

  return matchedOption?.title || ''
}

export const formatDateToInput = (dateString: string) => {
  if (!dateString) return ''

  const [day, month, year] = dateString.split('-')
  return `${year}-${month}-${day}`
}
