import React, {useState, useEffect} from 'react'
import {useGetAllDistrictData} from 'src/app/queries/getMasterData/districts/useGetAllDistrictData'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {useGetAllMunicipalityOption} from 'src/app/queries/getMasterData/municipality/useGetAllMunicipality'
import {useGetAllProvinceData} from 'src/app/queries/getMasterData/provinces/useGetAllProvinces'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'

interface UsePlaceMasterDataOptions {
  allMasterData?: boolean
  constitutions?: boolean
  corporateRegistrations?: boolean
  countries?: boolean
  zones?: boolean
  provinces?: boolean
  districts?: boolean
  municipalities?: boolean
}

function usePlaceMasterData(options: UsePlaceMasterDataOptions = {}) {
  const [masterData, setMasterData] = useState<any>(null)
  const [statesData, setStatesData] = useState<any>({
    provinces: [],
    districts: [],
    zones: [],
    municipalities: [],
  })

  const {data: allMasterData, isLoading: isLoadingAllMasterData} =
    options.allMasterData ||
    options.constitutions ||
    options.corporateRegistrations ||
    options.countries
      ? useGetAllMasterData()
      : {data: null, isLoading: false}
  const {data: provinceData, isLoading: isLoadingProvinceData} = options.provinces
    ? useGetAllProvinceData()
    : {data: null, isLoading: false}
  const {data: districtData, isLoading: isLoadingDistrictData} = options.districts
    ? useGetAllDistrictData()
    : {data: null, isLoading: false}
  const {data: zoneData, isLoading: isLoadingZoneData} = options.zones
    ? useGetAllZoneData()
    : {data: null, isLoading: false}
  const {data: municipalityData, isLoading: isLoadingMunicipalityData} = options.municipalities
    ? useGetAllMunicipalityOption(districtData?.id)
    : {data: null, isLoading: false}

  useEffect(() => {
    if (options.provinces || options.districts || options.zones) {
      setStatesData((prevState: any) => ({
        ...prevState,
        provinces: provinceData || prevState.provinces,
        districts: districtData || prevState.districts,
        zones: zoneData || prevState.zones,
        municipalities: municipalityData || prevState.municipalities,
      }))
    }
  }, [provinceData, districtData, zoneData, municipalityData])

  useEffect(() => {
    if (allMasterData && options.allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const extractOptions = (masterData: any) => {
    const options: {[key: string]: any[]} = {}
    masterData?.data?.forEach((item: any) => {
      const key = Object.keys(item)[0]
      if (key) {
        options[key] = item[key]
      }
    })
    return options
  }

  const allMasterDataOptions = extractOptions(masterData)
  const result: {[key: string]: any} = {
    isLoading:
      isLoadingAllMasterData ||
      isLoadingProvinceData ||
      isLoadingDistrictData ||
      isLoadingZoneData ||
      isLoadingMunicipalityData,
  }
  if (options.allMasterData) {
    result.allMasterDataOptions = allMasterDataOptions
  }

  if (options?.constitutions) {
    result.constutionDatas = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(allMasterDataOptions?.constitutions)
      ? allMasterDataOptions.constitutions.map((constitutionData: any) => ({
          value: constitutionData.id,
          label: constitutionData.title,
          cbs_code: constitutionData.cbs_code,
        }))
      : []
  }

  if (options.corporateRegistrations) {
    result.corporateRegistrations = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(allMasterDataOptions?.corporate_registrations)
      ? allMasterDataOptions.corporate_registrations.map((corporate_registration: any) => ({
          value: corporate_registration.id,
          label: corporate_registration.title,
          cbs_code: corporate_registration.cbs_code,
        }))
      : []
  }

  if (options.countries) {
    result.countriesData = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(allMasterDataOptions?.countries)
      ? allMasterDataOptions.countries.map((country: any) => ({
          value: country.id,
          label: country.title,
          cbs_code: country.cbs_code,
        }))
      : []
  }

  if (options.zones) {
    result.zoneOptions = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(statesData?.zones?.data?.zone)
      ? statesData?.zones?.data?.zone?.map((zone: any) => ({
          value: zone.id,
          label: zone.title,
          code: zone.code,
        }))
      : []
  }

  if (options?.provinces) {
    result.provinceOptions = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(statesData?.provinces?.data?.province)
      ? statesData?.provinces?.data?.province?.map((province: any) => ({
          value: province.id,
          label: province.title,
          cbs_code: province.cbs_code,
        }))
      : []
  }

  if (options?.districts) {
    result.districtOptions = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(statesData?.districts?.data?.district)
      ? statesData?.districts?.data?.district?.map((district: any) => ({
          value: district.id,
          label: district.title,
          code: district.code,
        }))
      : []
  }
  if (options?.municipalities) {
    result.municipalitiesOptions = result.isLoading
      ? [{value: '', label: 'Loading...'}]
      : Array.isArray(statesData?.municipalities?.data?.municipality)
      ? statesData?.municipalities?.data?.municipality?.map((municipality: any) => ({
          value: municipality.id,
          label: municipality.title,
          cbs_code: municipality.cbs_code,
        }))
      : []
  }

  return result
}

export default usePlaceMasterData
