import axios from 'axios';
import {useQuery, useQueryClient} from 'react-query';
import {GET_LINKED_ENTITIES_BY_ID} from 'src/app/queries/api/apiEndPoints/EndPoints';

const fetchLinkedEntitesDetails = async (id: any) => {
  try {
    const {data} = await axios.get(GET_LINKED_ENTITIES_BY_ID.replace(':id', id))
    return data
  } catch (error: any) {
    throw new Error(`Error fetching linked entities by ID: ${error.message}`)
  }
};

const useGetLinkedEntitiesDetailsById = (id: any) => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(['LinkedEntitiesList', id])
  }
  const query = useQuery(['LinkedEntitiesList', id], () => fetchLinkedEntitesDetails(id))
  return {...query, invalidateQuery}
};

export default useGetLinkedEntitiesDetailsById;