import axios from 'axios'
import {toast} from 'react-toastify'
import {useMutation} from 'react-query'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const GENERATE_OTP = `${BASE_API_URL}/new-cif-new-account/generate-otp/{newCIFNewAccount}/{type}`
const GENERATE_OTP_CORPORATE = `${BASE_API_URL}/corporate-new-cif-new-account/generate-otp/{newCIFNewAccount}/{type}`

const postToGenerateCode = async (payload: object) => {
  try {
    return await axios.patch(`${GENERATE_OTP}`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message ?? 'An error occurred')
    }
  }
}

export const usePostToGenerateCode = () =>
  useMutation({
    mutationFn: async ({payload}: {payload: object}) => postToGenerateCode(payload),
    onSuccess: (data) => {
      toast.success('OTP generated successfully')
    },
    onError: (error: any) => {
      toast.error(error?.message ?? 'Failed to generate OTP')
    },
  })

const postToGenerateCodeCorporate = async (payload: object) => {
  try {
    return await axios.patch(`${GENERATE_OTP_CORPORATE}`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message ?? 'An error occurred')
    }
  }
}

export const usePostToGenerateCodeCorporate = () =>
  useMutation({
    mutationFn: async ({payload}: {payload: object}) => postToGenerateCodeCorporate(payload),
    onSuccess: (data) => {
      toast.success('OTP generated successfully')
    },
    onError: (error: any) => {
      toast.error(error?.message ?? 'Failed to generate OTP')
    },
  })
const postToGenerateCodeCIF = async (payload: object) => {
  try {
    return await axios.patch(`${GENERATE_OTP_CORPORATE}`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.data?.message ?? 'An error occurred')
    }
  }
}

export const usePostToGenerateCodeCIF = () =>
  useMutation({
    mutationFn: async ({payload}: {payload: object}) => postToGenerateCodeCIF(payload),
    onSuccess: (data) => {
      toast.success('OTP generated successfully')
    },
    onError: (error: any) => {
      toast.error(error?.message ?? 'Failed to generate OTP')
    },
  })
