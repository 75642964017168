export const OFFLINE_ACCOUNT_OPENING_MODULES_TITLE = 'Offline Account Opening Modules'

export const OFFLINE_ACCOUNT_OPENING_MODULES = [
  {name: 'New CIF New Account', path: '/account/offline-account/new-cif-new-account'},
  {name: 'Existing CIF New Account', path: '/existing-cif-new-account'},
  {name: 'KYC Update', path: '/kyc-update'},
  {name: 'Bulk Account Opening', path: '/bulk-account-opening'},
  {name: 'Linked Entities & New CIF Only', path: '/linked-entities-new-cif-only'},
]

export const OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE = 'Preliminary Form'
export const OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_SUB_TITLE = 'Individual/ Joint/ Minor'
export const OFFLINE_ACCOUNT_OPENING_PRELIMINARY_CORPORATE_SUB_TITLE = 'Corporate'
export const OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_TITLE = 'Express Form'
export const OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_SUB_TITLE = 'Individual/ Joint/ Minor'
export const OFFLINE_ACCOUNT_OPENING_EXPRESS_CORPORATE_SUB_TITLE = 'Corporate'


