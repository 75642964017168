import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${CORPORATE_NEW_CIF_NEW_ACCOUNT_ACCOUNT_OPENING}/`

const postCorporateAccountOpening = async (id: string, data: any) => {
  try {
    const response = await axios.post(`${API_URL}${id}/account-opening`, data)
    return response.data
  } catch (error: any) {
    throw new Error(error.response.data.message || 'An error occurred while submitting the data')
  }
}

const usePostAccountOpening = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: {id: string; data: any}) => postCorporateAccountOpening(payload.id, payload.data),
    {
      onSuccess: (response) => {
        if (response?.resCod === 200) {
          toast.success(
            response?.resDesc ?? 'Corporate account Opening form submitted successfully!'
          )
          queryClient.invalidateQueries('corporateAccountList')
          queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
        }
      },
      onError: (error: Error) => {
        toast.error(`Error: ${error.message}`)
      },
    }
  )
}

export default usePostAccountOpening
