import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UploadState {
  localStorageDataZone: any
  localStorageDataProvience: any
  localStorageDataDistrict: any
  localStorageDataMunicipility: any
}

let localStorageDataZone = localStorage.getItem('masterOptionDataZone')
let localStorageDataProvience = localStorage.getItem('masterOptionDataProvience')
let localStorageDataDistrict = localStorage.getItem('masterOptionDataDistrict')
let localStorageDataMunicipility = localStorage.getItem('masterOptionDataMunicipality')

const initialState: UploadState = {
  localStorageDataZone: localStorageDataZone ? JSON.parse(localStorageDataZone) : null,
  localStorageDataProvience: localStorageDataProvience
    ? JSON.parse(localStorageDataProvience)
    : null,
  localStorageDataDistrict: localStorageDataDistrict ? JSON.parse(localStorageDataDistrict) : null,
  localStorageDataMunicipility: localStorageDataMunicipility
    ? JSON.parse(localStorageDataMunicipility)
    : null,
}

const MasterDataAllSlice = createSlice({
  name: 'masterOptionDataZDPM',
  initialState,
  reducers: {
    MasterDataStoreZone(state, action: PayloadAction<any>) {
      state.localStorageDataZone = action.payload
      localStorage.setItem('localStorageDataZone', JSON.stringify(action.payload))
    },
    MasterDataStoreProvience(state, action: PayloadAction<any>) {
      state.localStorageDataZone = action.payload
      localStorage.setItem('localStorageDataProvience', JSON.stringify(action.payload))
    },
    MasterDataStoreDistrict(state, action: PayloadAction<any>) {
      state.localStorageDataZone = action.payload
      localStorage.setItem('localStorageDataDistrict', JSON.stringify(action.payload))
    },
    MasterDataStoreMunicipility(state, action: PayloadAction<any>) {
      state.localStorageDataZone = action.payload
      localStorage.setItem('localStorageDataMunicipility', JSON.stringify(action.payload))
    },
  },
})

export const {
  MasterDataStoreZone,
  MasterDataStoreProvience,
  MasterDataStoreDistrict,
  MasterDataStoreMunicipility,
} = MasterDataAllSlice.actions
export default MasterDataAllSlice.reducer
