import axios from 'axios'
import { useQuery } from 'react-query'
import { GET_USER_BY_ID } from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${GET_USER_BY_ID}`
const fetchCorporatePreliminariesDetailsById = async (id: any) => {
  try {
    const {data} = await axios.get(API_URL.replace(':id', id))
    return data
  } catch (error: any) {
    throw new Error(
      `Error fetching branch checker new CIF and account list by ID: ${error.message}`
    )
  }
}

const useGetUserById = (id: any) => {
  return useQuery(['userList', id], () =>
    fetchCorporatePreliminariesDetailsById(id)
  )
}

export default useGetUserById
