import {Form, Formik} from 'formik'
import React, {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {Table} from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import FormDetail from 'src/app/modules/kycUpdate/CPUMaker/FormDetails'
import {REVIEW_VALIDATION_SCHEMA} from 'src/app/modules/new-cif-new-account/qa/utils/ValidationSchemas'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {usePostCorporateCpuCheckerReviewCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCorporateCpuCheckerReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {RootState} from 'src/setup'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {Modal} from 'antd'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'

const {Column, HeaderCell, Cell} = Table

interface RouteParams {
  id: string
}

const CpuReviewFormCorporateCIF = () => {
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: allMasterData} = useGetAllMasterData()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const {
    mutate: cpuCheckerCorporateReview,
    data: respData,
    isLoading: postRespLoading,
  } = usePostCorporateCpuCheckerReviewCIF()
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const allMasterDataOption = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const allStatusType = [
    {label: 'APPROVED', value: 'approve'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert_to_branchmaker'},
    {label: 'REVERT BACK TO CPU MAKER', value: 'revert_to_cpumaker'},
  ]
  const handleSubmit = (values: any) => {
    let modifiedFilePath = ''
    let accumulatedFilePaths: {file_name: any; file_path: any}[] = []
    if (checkAllData?.data?.data?.file_path) {
      const parts = checkAllData.data.data.file_path.split('/public/')
      modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      // Add the current file to the accumulated file paths
      accumulatedFilePaths.push({
        file_name: modifiedFilePath.split('/').pop(),
        file_path: modifiedFilePath,
      })
    }
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    cpuCheckerCorporateReview(payload, {
      onSuccess: (data) => {
        // history.go(-1)
        if (values?.status === 'approve') {
          setIsModalOpen(true)
        } else {
          history.go(-1)
        }
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleOk = () => {
    setIsModalOpen(false)
    history.go(-1)
  }

  const handleBack = () => {
    history.go(-1)
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body text-center '>
          <h3>
            <Spinner animation='border' variant='danger' className='mb-3' />
            <br />
            Please Wait...
          </h3>
        </div>
      </div>
    )
  }

  const accountHolderFields = corporateDataById?.data?.account_holders?.account_holders
    ?.map((holder: any, index: number) => [
      {
        key: `account_holders.account_holders.${index}.first_name`,
        label: `First Name (Holder ${index + 1})`,
      },
    ])
    .flat()

  const sections = {
    AccountDetails: [
      {key: 'branch_code', label: 'Branch Code'},
      {key: 'customer_code', label: 'Customer Code'},
    ],
    CompanyInfo: [
      {key: 'institution_name', label: 'Name of Institution'},
      {key: 'constitution_id', label: 'Constitution'},
      {key: 'other_constitution', label: 'Other Constitution'},
      {key: 'foreign_company_name', label: 'Foreign Company Name'},
      {key: 'foreign_company_address', label: 'Foreign Company Address'},
      {key: 'registration_number', label: 'Registration Number'},
      {key: 'registration_with_id', label: 'Registration With'},
      {key: 'registration_date_ad', label: 'Registration Date (AD)'},
      {key: 'registration_date_bs', label: 'Registration Date (BS)'},
      {key: 'registration_expiry_date_ad', label: 'Registration Expiry Date (AD)'},
      {key: 'registration_expiry_date_bs', label: 'Registration Expiry Date (BS)'},
      {key: 'aml_risk_rating', label: 'AML Risk Rating'},
      {key: 'sub_risk_category_id', label: 'Risk Rating SubCategory'},
      {key: 'address_type', label: 'Address Type'},
    ],
    RegisteredAddress: [
      {key: 'registered_office_country', label: ' Country'},
      {key: 'registered_office_zone', label: ' Zone'},
      {key: 'registered_office_province', label: ' Province'},
      {key: 'registered_office_district', label: ' District'},
      {key: 'registered_office_municipality', label: ' Municipality'},
      {key: 'registered_office_city', label: ' City'},
      {key: 'registered_office_ward_number', label: ' Ward Number'},
      {key: 'registered_office_street_name', label: ' Tole'},
      {key: 'registered_office_house_number', label: ' House Number'},
      {key: 'registered_office_mobile_number', label: ' Mobile Number'},
      {key: 'registered_office_email', label: ' Email'},
      {key: 'registered_office_outside_country_post_box', label: 'Foreign Post Box No.'},
      {key: 'registered_office_outside_country_full_address', label: 'Foreign Address'},
    ],
    BusinessAddress: [
      {key: 'business_office_country', label: 'Business Office Country'},
      {key: 'business_office_zone', label: 'Business Office Zone'},
      {key: 'business_office_province', label: 'Business Office Province'},
      {key: 'business_office_district', label: 'Business Office District'},
      {key: 'business_office_municipality', label: 'Business Office Municipality'},
      {key: 'business_office_city', label: 'City'},
      {key: 'business_office_ward_number', label: 'Business Office Ward Number'},
      {key: 'business_office_street_name', label: 'Business Office Tole'},
      {key: 'business_office_house_number', label: 'Business Office House Number'},
      {key: 'business_office_mobile_number', label: 'Business Office Mobile Number'},
      {key: 'business_office_email', label: 'Business Office Email'},
      {key: 'business_office_outside_country_post_box', label: 'Foreign Post Box No.'},
      {key: 'business_office_outside_country_full_address', label: 'Foreign Address'},
      {key: 'working_area', label: 'Working Area'},
    ],
    BusinessDetails: [
      {key: 'business_nature_id', label: 'Nature of Industry / Business'},
      {key: 'expected_annual_turnover', label: 'Expected Annual Turnover'},
      {key: 'expected_annual_transaction_number', label: 'Expected Annual Transaction Number'},
      ...(corporateDataById?.data?.pan_number ? [{key: 'pan_number', label: 'PAN'}] : []),
      ...(corporateDataById?.data?.vat_number ? [{key: 'vat_number', label: 'VAT'}] : []),
    ],

    ScreeningAndRiskInfo: [
      {key: 'customer_category_id', label: 'Customer Category ID'},
      {key: 'aml_risk_rating', label: 'AML Risk Rating'},
      {key: 'pep_status', label: 'PEP Status'},
      ...(corporateDataById?.data?.pep_status !== 'No'
        ? [{key: 'pep_information', label: 'PEP Information'}]
        : []),
      {key: 'crime_conviction', label: 'Crime Conviction'},
      ...(corporateDataById?.data?.crime_conviction !== false
        ? [{key: 'crime_details', label: 'Crime Details'}]
        : []),
      ...(corporateDataById?.data?.blacklisting_inclusion !== false
        ? [
            {key: 'blacklisting_inclusion', label: 'Blacklisting Inclusion'},
            {key: 'blacklist_release_date', label: 'Blacklist Release Date'},
          ]
        : []),
    ],
    // AccountOpening: [
    //   {key: 'account_opening.account_title', label: 'Account Title'},
    //   {key: 'account_opening.initial_deposit', label: 'Initial Deposit'},
    //   {key: 'account_opening.account_category_id', label: 'Account Category ID'},
    //   {key: 'account_opening.account_currency_id', label: 'Account Currency ID'},
    //   {key: 'account_opening.other_account_currency', label: 'Other Account Currency'},
    //   {key: 'account_opening.tax_category_id', label: 'Tax Category ID'},
    //   {key: 'account_opening.tax_group_id', label: 'Tax Group ID'},
    //   {key: 'account_opening.cheque_book_name', label: 'Cheque Book Name'},
    //   {key: 'account_opening.mode_of_operation', label: 'Mode of Operation'},
    //   ...(corporateDataById?.data?.account_opening?.mode_of_operation === 'other'
    //     ? [{key: 'account_opening.other_mode_of_operation', label: 'Other Mode of Operation'}]
    //     : []),
    //   {key: 'account_opening.blacklist_screening', label: 'Blacklist Screening'},
    //   {key: 'account_opening.sanction_screening', label: 'Sanction Screening'},
    //   {key: 'account_opening.duplication_screening', label: 'Duplication Screening'},
    //   {key: 'account_opening.adverse_media', label: 'Adverse Media'},
    //   {key: 'account_opening.regulatory_block', label: 'Regulatory Block'},
    // ],
    AccountHolder: accountHolderFields,

    // NomineeInformation: [
    //   {key: 'account_opening.nomination', label: 'Nomination'},
    //   {key: 'account_opening.nominee_first_name', label: 'Nominee First Name'},
    //   {key: 'account_opening.nominee_middle_name', label: 'Nominee Middle Name'},
    //   {key: 'account_opening.nominee_last_name', label: 'Nominee Last Name'},
    //   {key: 'account_opening.nominee_citizenship_number', label: 'Nominee Citizenship Number'},
    //   {key: 'account_opening.nominee_address', label: 'Nominee Address'},
    //   {key: 'account_opening.nominee_relationship_id', label: 'Nominee Relationship ID'},
    //   {key: 'account_opening.nominee_place_of_issue', label: 'Nominee Place of Issue'},
    // ],
    // IntroducersInformation: [
    //   {key: 'account_opening.introducer_first_name', label: 'Introducer First Name'},
    //   {key: 'account_opening.introducer_middle_name', label: 'Introducer Middle Name'},
    //   {key: 'account_opening.introducer_last_name', label: 'Introducer Last Name'},
    //   {key: 'account_opening.introducer_customer_id', label: 'Introducer Customer ID'},
    //   {key: 'account_opening.introducer_address', label: 'Introducer Address'},
    //   {
    //     key: 'account_opening.introducer_confirmation_period',
    //     label: 'Introducer Confirmation Period',
    //   },
    // ],
    // Other: [{key: 'remarks', label: 'Remarks'}],
  }

  const data = corporateDataById?.data

  const handleScreening = () => {
    setScreeningLoading(true) // Set screening loading to true
    const checkAllPayload: any = {
      customer_name: corporateDataById?.data?.institution_name,
      pan_number: corporateDataById?.data?.pan_number,
      model_name: 'NewCIFNewAccount',
    }

    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningPerformed(true)
        setScreeningLoading(false)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const DirectorsTable = ({data}: any) => (
    <>
      <Table data={data?.directors || []} autoHeight>
        <Column flexGrow={1} align='center' fixed>
          <HeaderCell>Full Name</HeaderCell>
          <Cell dataKey='full_name' />
        </Column>
        <Column flexGrow={1} align='center' fixed>
          <HeaderCell>CIF Number</HeaderCell>
          <Cell dataKey='cif_number' />
        </Column>
        <Column flexGrow={1} align='center' fixed>
          <HeaderCell>Designation</HeaderCell>
          <Cell dataKey='designation' />
        </Column>
      </Table>
    </>
  )

  const ShareholdersTable = ({data}: any) => (
    <Table data={data?.share_holders} autoHeight width={500}>
      <Column width={130} align='center'>
        <HeaderCell>Shareholder Name</HeaderCell>
        <Cell dataKey='shareholder_name' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell fullText>Ownership Percentage</HeaderCell>
        <Cell dataKey='ownership_percentage' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Shareholder Address</HeaderCell>
        <Cell fullText dataKey='shareholder_address' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Name</HeaderCell>
        <Cell dataKey='entity_name' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell fullText>Entity Registration Office</HeaderCell>
        <Cell fullText dataKey='entity_registration_office' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell fullText>Entity Registration Address</HeaderCell>
        <Cell fullText dataKey='entity_registration_address' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell fullText>Entity Business Address</HeaderCell>
        <Cell fullText dataKey='entity_business_address' />
      </Column>

      <Column width={140} align='center'>
        <HeaderCell fullText>Entity Registration Number</HeaderCell>
        <Cell fullText dataKey='entity_registration_number' />
      </Column>

      <Column width={155} align='center'>
        <HeaderCell fullText>Entity Ownership Percentage</HeaderCell>
        <Cell dataKey='entity_ownership_percentage' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Country</HeaderCell>
        <Cell fullText>{(rowData) => rowData.entity_country?.title || '-'}</Cell>
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Address</HeaderCell>
        <Cell fullText dataKey='entity_address' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Incorporate Date</HeaderCell>
        <Cell fullText dataKey='entity_incorporation_date' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Incorporate Date</HeaderCell>
        <Cell fullText dataKey='entity_incorporation_date' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity PAN Number</HeaderCell>
        <Cell fullText dataKey='entity_pan_number' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity VAT Number</HeaderCell>
        <Cell fullText dataKey='entity_vat_number' />
      </Column>
      <Column width={140} align='center'>
        <HeaderCell>Entity Phone Number</HeaderCell>
        <Cell fullText dataKey='entity_phone_number' />
      </Column>
      <Column width={160} align='center'>
        <HeaderCell>Entity Website</HeaderCell>
        <Cell fullText dataKey='entity_website' />
      </Column>
    </Table>
  )

  const FactaDeclarationsTable = ({data}: any) => (
    <Table data={data?.facta_declarations} autoHeight width={500}>
      <Column width={100} align='center'>
        <HeaderCell>US Person</HeaderCell>
        <Cell dataKey='us_person'>{(rowData) => (rowData.us_person ? 'Yes' : 'No')}</Cell>
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>US Person Remarks</HeaderCell>
        <Cell dataKey='us_person_remarks' />
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>US Indicia Remarks</HeaderCell>
        <Cell dataKey='us_indicia_remarks' />
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>US Telephone Number</HeaderCell>
        <Cell dataKey='us_telephone_number' />
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>TIN Number</HeaderCell>
        <Cell dataKey='tin_number' />
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>GIIN Number</HeaderCell>
        <Cell dataKey='giin_number'>{(rowData) => (rowData.giin_number ? 'Yes' : 'No')}</Cell>
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>GIIN Number Remarks</HeaderCell>
        <Cell dataKey='giin_number_remarks' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>W9 Form Submitted</HeaderCell>
        <Cell dataKey='w9_form_submitted' />
      </Column>
    </Table>
  )

  const OtherBanksTable = ({data}: any) => (
    <Table data={data?.other_banks} autoHeight width={500}>
      <Column width={125} align='center'>
        <HeaderCell>Bank Name</HeaderCell>
        <Cell dataKey='bank_name' />
      </Column>
      <Column width={135} align='center'>
        <HeaderCell>Saving Account</HeaderCell>
        <Cell dataKey='saving_ac' />
      </Column>
      <Column width={135} align='center'>
        <HeaderCell>Current Account</HeaderCell>
        <Cell dataKey='current_ac' />
      </Column>
      <Column width={135} align='center'>
        <HeaderCell>Overdraft Account</HeaderCell>
        <Cell dataKey='od_ac' />
      </Column>
      <Column width={120} align='center'>
        <HeaderCell>Term Loan</HeaderCell>
        <Cell dataKey='term_loan' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Others</HeaderCell>
        <Cell dataKey='others' />
      </Column>
    </Table>
  )

  const getValue = (data: any, keyPath: string) => {
    if (!data) return '-'

    const value = keyPath.split('.').reduce((acc, key) => {
      if (acc && Array.isArray(acc)) {
        return acc[parseInt(key, 10)]
      }
      return acc ? acc[key] : null
    }, data)

    if (value === null || value === undefined || value === '') {
      return '-'
    }
    if (value === 'P') {
      return 'Permanent Address'
    } else if (value === 'N') {
      return 'Communication Address'
    }

    return value
  }

  const shouldRenderNomineeInformation =
    corporateDataById?.data?.account_opening?.nomination !== 'no' &&
    corporateDataById?.data?.account_opening?.nomination !== false

  const shouldRenderOtherConstitution = corporateDataById?.data?.constitution_id === 'other_specify'

  const shouldHideForeignCompanyFields =
    corporateDataById?.data?.constitution_id?.title === 'FOREIGN ENTERPRISE'

  const country = corporateDataById?.data?.registered_office_country?.title
  const businessOfficeCountry = corporateDataById?.data?.business_office_country?.title
  const shouldHideAddressFields = country && country === 'NEPAL'
  const shouldHideBusinessOfficeAddressFields =
    businessOfficeCountry && businessOfficeCountry === 'NEPAL'

  const shouldRenderAccountHolder =
    corporateDataById?.data?.account_holders?.account_holders?.length > 0
  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              {' '}
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6 my-2'>
          <div className='modal-container '>
            <div className='card p-5'>
              <div className='card-body' style={{maxHeight: '640px', overflowY: 'auto'}}>
                <div className='row'>
                  <div className='row'>
                    {Object.entries(sections).map(([sectionKey, fields]) => {
                      // Conditional rendering for the Nominee Information section
                      if (sectionKey === 'NomineeInformation' && !shouldRenderNomineeInformation) {
                        return null // Hide the Nominee Information section
                      }

                      if (sectionKey === 'AccountHolder' && !shouldRenderAccountHolder) {
                        return null // Hide the Account Holder section if there's no data
                      }

                      return (
                        <React.Fragment key={sectionKey}>
                          <h3>{sectionKey.replace(/([A-Z])/g, ' $1').trim()}</h3>

                          {fields.map((field: any) => {
                            // Conditional rendering for the Other Constitution field

                            if (
                              field.key === 'other_constitution' &&
                              !shouldRenderOtherConstitution
                            ) {
                              return null // Hide the Other Constitution field
                            }

                            // Conditional rendering for Foreign Company fields
                            if (
                              (field.key === 'foreign_company_name' ||
                                field.key === 'foreign_company_address') &&
                              !shouldHideForeignCompanyFields
                            ) {
                              return null // Hide the Foreign Company Name and Address fields
                            }

                            // Conditional rendering for address fields when country is not Nepal
                            if (
                              [
                                'registered_office_zone',
                                'registered_office_province',
                                'registered_office_district',
                                'registered_office_municipality',
                                'registered_office_city',
                                'registered_office_ward_number',
                                'registered_office_street_name',
                                'registered_office_house_number',
                              ].includes(field.key) &&
                              !shouldHideAddressFields
                            ) {
                              return null // Hide the address fields if country is not Nepal
                            }

                            if (
                              [
                                'registered_office_outside_country_post_box',
                                'registered_office_outside_country_full_address',
                              ].includes(field.key) &&
                              shouldHideAddressFields
                            ) {
                              return null // Hide these fields if the country is Nepal
                            }
                            if (
                              [
                                'business_office_zone',
                                'business_office_province',
                                'business_office_district',
                                'business_office_municipality',
                                'business_office_city',
                                'business_office_ward_number',
                                'business_office_street_name',
                                'business_office_house_number',
                              ].includes(field.key) &&
                              !shouldHideBusinessOfficeAddressFields
                            ) {
                              return null // Hide the business office address fields if country is not Nepal
                            }

                            // Ensure Foreign Post Box No. and Foreign Address are shown if business office country is not Nepal
                            if (
                              [
                                'business_office_outside_country_post_box',
                                'business_office_outside_country_full_address',
                              ].includes(field.key) &&
                              shouldHideBusinessOfficeAddressFields
                            ) {
                              return null // Hide these fields if the business office country is Nepal
                            }

                            return (
                              <React.Fragment key={field.key}>
                                <FormDetails
                                  label={field.label}
                                  value={(() => {
                                    const value = getValue(corporateDataById?.data, field.key)

                                    if (typeof value === 'object') {
                                      return value?.title || JSON.stringify(value)
                                    }
                                    if (typeof value === 'boolean') {
                                      return value ? 'Yes' : 'No'
                                    }
                                    return value
                                  })()}
                                />
                              </React.Fragment>
                            )
                          })}
                        </React.Fragment>
                      )
                    })}
                  </div>

                  <div>
                    <h3>Directors</h3>
                    <DirectorsTable data={corporateDataById?.data?.directors} />
                    <h3 className='mt-5'>Shareholders</h3>
                    <ShareholdersTable data={corporateDataById?.data?.share_holders} />
                    <h3 className='mt-5'>FACTA Declarations</h3>
                    <FactaDeclarationsTable data={corporateDataById?.data?.facta_declarations} />
                    <h3 className='mt-5'>Other Banks</h3>
                    <OtherBanksTable data={corporateDataById?.data?.other_banks} />
                  </div>
                  <div className='mt-5'>
                    <h4>MIS DETAILS</h4>
                    <div className='row'>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'bank_code_id'}
                          label='Bank Code'
                          value={corporateDataById?.data?.bank_code_id?.title}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'customer_category_id'}
                          label='Customer Category'
                          value={corporateDataById?.data?.customer_category_id?.title}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'customer_group_id'}
                          label='Customer Group'
                          value={corporateDataById?.data?.customer_group_id?.title}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'customer_status_id'}
                          label='Customer Status'
                          value={corporateDataById?.data?.customer_status_id?.title}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'customer_free_text'}
                          label='Customer Status Details'
                          value={corporateDataById?.data?.customer_free_text || '-'}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'cdd_edd_date'}
                          label='CDD & EDD date'
                          value={corporateDataById?.data?.cdd_edd_date || '-'}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'annual_turn_over_as_on'}
                          label='Annual Turnover As On'
                          value={corporateDataById?.data?.annual_turn_over_as_on || '-'}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'aml_risk_rating'}
                          label='AML Risk Rating'
                          value={corporateDataById?.data?.aml_risk_rating?.title || '-'}
                        />
                      </div>
                      <div className='col-md-6 col-sm-12'>
                        <FormDetail
                          key={'sub_risk_category_id'}
                          label='Risk Rating SubCategory'
                          value={corporateDataById?.data?.sub_risk_category_id?.title || '-'}
                        />
                      </div>
                    </div>
                  </div>
                  {screeningPerformed ? (
                    checkAllData && (
                      <Button
                        type='button'
                        onClick={handleOpenPdf}
                        className='col-md-3 col-sm-6 col-xs-12'
                      >
                        View Details
                      </Button>
                    )
                  ) : screeningLoading ? (
                    <Spinner animation='border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      type='button'
                      onClick={handleScreening}
                      className='col-md-6 col-sm-6 col-xs-12'
                    >
                      Perform Screening
                    </Button>
                  )}
                  <div className='my-7'>
                    <h3>Screening Files</h3>
                    {corporateDataById?.data?.screening_files?.screening_files?.length > 0 ? (
                      corporateDataById?.data?.screening_files?.screening_files?.map(
                        (file: any, index: any) => (
                          <button
                            key={index}
                            onClick={() => handlePdfFileClick(file.file_path)}
                            style={{display: 'block', marginBottom: '10px'}}
                          >
                            {file.file_name}
                          </button>
                        )
                      )
                    ) : (
                      <p>No files available</p>
                    )}
                  </div>

                  <Formik
                    initialValues={{status: '', remarks: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={REVIEW_VALIDATION_SCHEMA}
                  >
                    {({handleChange, errors, touched, values, setFieldValue}) => (
                      <Form>
                        <div className='row '>
                          <div className='col-md-6'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Status Type'
                              name='status'
                              isClearable={true}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allStatusType.map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                              required={true}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className='border my-2 mb-6'></div>
                          <FormTextBox
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            type='text'
                            placeholder='Remarks'
                            label='Remarks'
                            name='remarks'
                            disabled={false}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-id-number-text-field'
                            as='textarea'
                          />
                          <div className='d-flex justify-content-end mt-3'>
                            <button
                              type='button'
                              onClick={handleBack}
                              className='btn btn-secondary mx-4'
                            >
                              Back
                            </button>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={postRespLoading}
                            >
                              {postRespLoading ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccountStatusLog data={corporateDataById?.data} />
        <UserActivityLogAll caseId={id} modalName={'Amnil\\SharedModels\\NewCIF'} />
      </div>
      <Modal
        title='Processing Request'
        open={isModalOpen}
        onOk={handleOk}
        footer={[
          <Button key='submit' type='primary' onClick={handleOk}>
            OK
          </Button>,
        ]}
        centered
      >
        <p>Your request is being processed, thank you for your patience.</p>
      </Modal>
    </>
  )
}

export default CpuReviewFormCorporateCIF
