import React from 'react'
import {
  POST_NORMAL_NEW_CIF,
  POST_NORMAL_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import axios from 'axios'

const API_URL = `${POST_NORMAL_NEW_CIF_NEW_ACCOUNT}`

type PayloadType = {
  data: any
}

const postPreliminariesDetails = async (payload: PayloadType) => {
  try {
    const response = await axios.post(API_URL, payload)
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message
  }
}

const usePostPreliminariesDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(postPreliminariesDetails, {
    onSuccess: (data) => {
      if (data) {
        toast.success(data?.resDesc ?? 'Individual Preliminary Account Created successfully!')
      }
      queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
      queryClient.invalidateQueries('ListingNewCIFNewAccount')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default usePostPreliminariesDetails

const postCIFDetails = async (payload: PayloadType) => {
  try {
    const response = await axios.post(POST_NORMAL_NEW_CIF, payload)
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message
  }
}

export const usePostNewCIFDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(postCIFDetails, {
    onSuccess: (data) => {
      toast.success(data?.resDesc ?? 'Individual Preliminary CIF Created successfully!')
      queryClient.invalidateQueries('preliminariesDetailsByIdCIF')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}
