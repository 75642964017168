import React, {Suspense, useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../cms/partials'
import {RouteList, RouteModel} from './RoutesList'
import {useSelector} from 'react-redux'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {isEmpty} from 'lodash'
import {ProfileHeader} from '../modules/profileComponent/components/ProfileHeader'
import FileManager from '../modules/common/components/fileManager/components'
import {ErrorsPage} from '../modules/errors/ErrorsPage'

export function PrivateRoutes() {
  const module: any = useSelector((state: any) => state.auth.module)
  const [authorizedRoute, setAuthorizedRoute] = useState<Array<string>>([])
  useEffect(() => {
    if (isEmpty(authorizedRoute)) {
      let newAuthorizedRoute = [...authorizedRoute]
      module?.moduleData?.map((modules: any) =>
        modules.children
          ? modules.children?.map((route: any) => {
              newAuthorizedRoute?.push(route?.alias)
            })
          : newAuthorizedRoute?.push(modules?.alias)
      )
      setAuthorizedRoute(newAuthorizedRoute)
    }
  }, [module])

  const renderRoute = RouteList?.filter((route, i: number) => {
    return authorizedRoute?.some((authPath) => route?.path?.includes(authPath))
  })

  /* {RouteList?.map(
          (route: any, i: number) =>
            authorizedRoute?.includes(route?.path) && (
              <Route path={route?.path} component={route?.component} key={i} exact={route?.exact} />
            )
        )} */

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/media-manager' component={FileManager} />
        <Route path='/profile' component={ProfileHeader} />
        {RouteList?.filter((route) => {
          return authorizedRoute?.some((authPath) => route?.path?.includes(authPath))
        }).map((item, i: number) => (
          <Route path={item?.path} component={item?.component} key={i} exact={item?.exact} />
        ))}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
      </Switch>
    </Suspense>
  )
}
