import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {
  GET_CORPORATE_NEW_CIF_NEW_ACCOUNT,
  GET_NEW_CIF_Corporate,
} from 'src/app/queries/api/apiEndPoints/EndPoints'
// import { ParamsModel } from 'src/app/modules/common/Model'

const QUERY_KEY = 'corporateAccountList'

export const useGetCorporateNewCIFandAccountList = (params: any) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(GET_CORPORATE_NEW_CIF_NEW_ACCOUNT, {
      params,
    })
    return data
  })
}

const QUERY_KEYCIF = 'corporateCIFList'

export const useGetCorporateNewCIF = (params: any) => {
  return useQuery([QUERY_KEYCIF, params], async () => {
    const {data} = await axios.get(GET_NEW_CIF_Corporate, {
      params,
    })
    return data
  })
}
