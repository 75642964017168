import React, {useEffect} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {FaPlus} from 'react-icons/fa'

const Ancillaries = ({values, handleChange, errors, touched, setFieldValue}: any) => {
  const {setFieldValue: setFieldData} = useFormikContext()
  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  useEffect(() => {
    if (values.ancillary_services?.length === 0) {
      setFieldData('ancillary_services', [
        {
          status: '',
          reason: '',
          service_id: '',
        },
      ])
    }
  }, [setFieldData, values.ancillary_services])

  // Get all selected service IDs
  const selectedServiceIds = values?.ancillary_services?.map((service: any) => service.service_id)

  return (
    <div className=''>
      <FieldArray name='ancillary_services'>
        {({remove, push}) => (
          <div>
            {values?.ancillary_services?.length > 0 &&
              values?.ancillary_services?.map((details: any, index: any) => (
                <div className='row mb-3' key={index}>
                  <div className='col-md-4'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='Status'
                      name={`ancillary_services.${index}.status`}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      arrValue={values?.ancillary_services[index]?.status}
                      values={values?.ancillary_services[index]?.status}
                      options={[
                        {label: 'Yes', value: 'Yes'},
                        {label: 'No', value: 'No'},
                      ]}
                      setFieldValue={setFieldValue}
                      required
                    />
                  </div>

                  {values?.ancillary_services[index]?.status === 'Yes' && (
                    <div className='col-md-6'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Ancillary Services'
                        name={`ancillary_services.${index}.service_id`}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        arrValue={values?.ancillary_services[index]?.service_id}
                        options={allMasterDataOptions?.ancillary_services
                          ?.filter(
                            (ancillary_services: any) =>
                              !selectedServiceIds.includes(ancillary_services.id) ||
                              ancillary_services.id ===
                                values?.ancillary_services[index]?.service_id
                          )
                          .map((ancillary_services: any) => ({
                            value: ancillary_services.id,
                            label: ancillary_services.title || ancillary_services.label,
                          }))}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                  )}

                  {values?.ancillary_services[index]?.status === 'No' && (
                    <div className='col-md-12 col-sm-6 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Reasons'
                        name={`ancillary_services.${index}.reason`}
                        label='Reasons'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        // uppercase={true}
                        required
                      />
                    </div>
                  )}

                  {index > 0 && (
                    <div className='col-md-2 d-flex align-items-center'>
                      <button
                        type='button'
                        className='dt-btn dt-btn-primary'
                        data-cy='add-button'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              ))}
            <button
              type='button'
              className='dt-btn dt-btn-primary w-100'
              data-cy='add-button'
              onClick={() => {
                push({
                  status: '',
                  reason: '',
                  service_id: '',
                })
              }}
            >
              <FaPlus className='me-2' />
              Add Ancillaries
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  )
}

export default Ancillaries
