import {useParams} from 'react-router-dom'
import {IParams} from 'src/app/modules/linkedEntities/common/interface/Interfaces'
import useGetLinkedEntitiesDetailsById from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesById'
import IndividualDetailsView from '../../detailsView/individualDetailsView'
import {useEffect, useState} from 'react'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import ToBeLinkedEntitiesRevertCase from './entitiesToBeLinked'

const IndividualRevertedCase = () => {
  const {id} = useParams<IParams>()
  const {data: linkedEntitiesDatas} = useGetLinkedEntitiesDetailsById(id)
  const {primary_account_number, is_corporate} = linkedEntitiesDatas?.data || {}

  const [parameters, setParameters] = useState({
    account_number: '',
    is_corporate: false,
  })

  useEffect(() => {
    if (primary_account_number && is_corporate !== undefined) {
      setParameters({
        account_number: primary_account_number,
        is_corporate: is_corporate,
      })
    }
  }, [primary_account_number, is_corporate])

  const {data: onSubmitCIFDatas} = useGetLinkedEntitesData(parameters, {
    enabled: Boolean(parameters?.account_number),
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <p className='heading__component__title fw-bolder text-uppercase'>Customer Details</p>
        <div className='row mb-4'>
          <div className='col-md-3'>
            <IndividualDetailsView
              label={'Primary CIF Number'}
              value={linkedEntitiesDatas?.data?.primary_cif_number || '-'}
            />
            <IndividualDetailsView
              label={'Date of Birth'}
              value={linkedEntitiesDatas?.data?.date_of_birth || '-'}
            />
          </div>
          <div className='col-md-3'>
            <IndividualDetailsView
              label={'Customer Name'}
              value={linkedEntitiesDatas?.data?.primary_customer_name || '-'}
            />
            <IndividualDetailsView
              label={'Address'}
              value={linkedEntitiesDatas?.data?.address || '-'}
            />
          </div>
          <div className='col-md-3'>
            <IndividualDetailsView
              label={'Father Name'}
              value={linkedEntitiesDatas?.data?.father_name || '-'}
            />
            <IndividualDetailsView
              label={'Occupation'}
              value={linkedEntitiesDatas?.data?.occupation || '-'}
            />
          </div>
          <div className='col-md-3'>
            <IndividualDetailsView
              label={'Grand Father Name'}
              value={linkedEntitiesDatas?.data?.grandfather_name || '-'}
            />
          </div>
        </div>
        <div className='d-flex justify-content-start'>
          <button className='btn btn-dark'>Perform Screening</button>
        </div>
        <div>
          <ToBeLinkedEntitiesRevertCase onSubmitValues={parameters} />
        </div>
      </div>
    </div>
  )
}

export default IndividualRevertedCase
