import React from 'react'
import {Table} from 'rsuite'
import {IoClose} from 'react-icons/io5'

const {Column, HeaderCell, Cell} = Table

const CustomCell = ({rowData, dataKey, ...props}: any) => <Cell {...props}>{rowData[dataKey]}</Cell>

const RemarksCell = ({rowData, ...props}: any) => {
  const mainColumns = ['name', 'DOB', 'gender', 'fatherName', 'sector']
  const remarksData = Object.entries(rowData)
    .filter(([key]) => !mainColumns.includes(key))
    .map(([key, value]) => `${key}: ${value || 'None'}`)
    .join('\n')

  return (
    <Cell {...props} style={{whiteSpace: 'pre-wrap'}}>
      {remarksData}
    </Cell>
  )
}

const CibCheck = ({data = []}: any) => {
  return (
    <div className='shadow p-3 bg-white rounded my-8'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
        <div className='heading__component__title fs-2 fw-bolder mb-2'>
          Blacklisting Check Lists
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
        <div className='heading__component__title fs-6 fw-bolder mb-2'>
          {/* Found result({data?.data?.data?.cib_screening_lists?.length}) */}
          Found result({data?.data?.result?.screening_lists?.length})
        </div>
      </div>
      <div className='datatable'>
        <Table
          // data={data?.data?.data?.cib_screening_lists}
          data={data?.data?.result?.screening_lists}
          wordWrap='break-word'
          rowHeight={90}
          height={600}

        >
          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Name</HeaderCell>
            <CustomCell dataKey='name' />
          </Column>
          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Date Of Birth</HeaderCell>
            <CustomCell dataKey='DOB' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Gender</HeaderCell>
            <CustomCell dataKey='gender' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Sector</HeaderCell>
            <CustomCell dataKey='sector' />
          </Column>

          <Column align='left' flexGrow={2}>
            <HeaderCell>Remarks</HeaderCell>
            <RemarksCell dataKey='remarks' />
          </Column>
        </Table>
      </div>
    </div>
  )
}

export default CibCheck
