import React, {useEffect} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const ShareholdersTable = ({values, handleChange, errors, touched}: any) => {
  const {setFieldValue} = useFormikContext()

  // useEffect(() => {
  //   if (values.share_holders.length === 0) {
  //     setFieldValue('share_holders', [
  //       {
  //         shareholder_name: '',
  //         ownership_percentage: '',
  //         shareholder_address: '',
  //         entity_name: '',
  //         entity_ownership_percentage: '',
  //         entity_address: '',
  //         entity_registration_number: '',
  //         entity_registration_office: '',
  //         entity_incorporation_date: '',
  //         entity_pan_number: '',
  //         entity_vat_number: '',
  //         entity_country: '',
  //         entity_registration_address: '',
  //         entity_business_address: '',
  //         entity_phone_number: '',
  //         entity_website: '',
  //       },
  //     ])
  //   }
  // }, [setFieldValue, values.share_holders])

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  return (
    <>
      <h3 className=' heading__component__title fw-bolder text-uppercase'>
        G. Share Holding and Beneficial Owner Details:
      </h3>{' '}
      <FieldArray name='share_holders'>
        {({remove, push}) => (
          <div>
            {values?.share_holders?.length > 0 ? (
              values?.share_holders?.map((shareholder: any, index: any) => (
                <div className='row mb-3' key={index}>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Shareholder Name'
                      name={`share_holders.${index}.shareholder_name`}
                      label='Shareholder Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='number'
                      placeholder='Ownership Percentage'
                      name={`share_holders.${index}.ownership_percentage`}
                      label='Ownership Percentage'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Shareholder Address'
                      name={`share_holders.${index}.shareholder_address`}
                      label='Shareholder Address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Name'
                      name={`share_holders.${index}.entity_name`}
                      label='Entity Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='number'
                      placeholder='Entity Ownership Percentage'
                      name={`share_holders.${index}.entity_ownership_percentage`}
                      label='Entity Ownership Percentage'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Address'
                      name={`share_holders.${index}.entity_address`}
                      label='Entity Address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  {/* New fields start here */}
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Registration Number'
                      name={`share_holders.${index}.entity_registration_number`}
                      label='Entity Registration Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Registration Office'
                      name={`share_holders.${index}.entity_registration_office`}
                      label='Entity Registration Office'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='date'
                      placeholder='Entity Incorporation Date'
                      name={`share_holders.${index}.entity_incorporation_date`}
                      label='Entity Incorporation Date'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity PAN Number'
                      name={`share_holders.${index}.entity_pan_number`}
                      label='Entity PAN Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={9}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity VAT Number'
                      name={`share_holders.${index}.entity_vat_number`}
                      label='Entity VAT Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={9}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Entity Country'
                      label='Entity Country'
                      name={`share_holders.${index}.entity_country`}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={allMasterDataOptions?.countries?.map((country: any) => ({
                        value: country?.id || country?.value,
                        label: country?.title || country?.label,
                      }))}
                      setFieldValue={setFieldValue}
                      arrValue={values?.share_holders[0]?.entity_country?.toString()}
                      values={values}
                      // required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Registration Address'
                      name={`share_holders.${index}.entity_registration_address`}
                      label='Entity Registration Address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Business Address'
                      name={`share_holders.${index}.entity_business_address`}
                      label='Entity Business Address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Phone Number'
                      name={`share_holders.${index}.entity_phone_number`}
                      label='Entity Phone Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Entity Website'
                      name={`share_holders.${index}.entity_website`}
                      label='Entity Website'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-2 d-flex align-items-center'>
                    <button type='button' className='btn btn-danger' onClick={() => remove(index)}>
                      Remove
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No ShareHolders added. Click 'Add ShareHolders' to add.</p>
            )}
            <button
              type='button'
              className='dt-btn dt-btn-primary mb-8'
              onClick={() =>
                push({
                  shareholder_name: '',
                  ownership_percentage: '',
                  shareholder_address: '',
                  entity_name: '',
                  entity_ownership_percentage: '',
                  entity_address: '',
                  entity_registration_number: '',
                  entity_registration_office: '',
                  entity_incorporation_date: '',
                  entity_pan_number: '',
                  entity_vat_number: '',
                  entity_country: '',
                  entity_registration_address: '',
                  entity_business_address: '',
                  entity_phone_number: '',
                  entity_website: '',
                })
              }
            >
              Add Shareholder
            </button>
          </div>
        )}
      </FieldArray>
    </>
  )
}

export default ShareholdersTable
