import React, {useEffect, useState} from 'react'
import {FieldArray} from 'formik'
import {FaPlus} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

const FamilyDetailsForm = ({values, handleChange, errors, touched, setFieldValue}: any) => {
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const [initiallyLoadedIndexes, setInitiallyLoadedIndexes] = useState<number[]>([])

  // Identify initially loaded fields based on values when the component mounts
  useEffect(() => {
    // Only run once to set the initial indexes
    if (initiallyLoadedIndexes.length === 0) {
      const indexes = values?.family_details?.reduce(
        (acc: number[], details: any, index: number) => {
          if (details?.name || details?.relationship_id || details?.profession) {
            acc.push(index)
          }
          return acc
        },
        []
      )
      setInitiallyLoadedIndexes(indexes)
    }
  }, [values.family_details, initiallyLoadedIndexes.length])

  return (
    <div className=''>
      <FieldArray name='family_details'>
        {({remove, push}) => {
          // Check if family_details is empty and add one initial detail if empty
          if (values?.family_details?.length === 0) {
            push({
              name: '',
              profession: '',
              relationship_id: '',
            })
          }

          return (
            <div>
              {values?.family_details?.length > 0 ? (
                values?.family_details?.map((details: any, index: any) => {
                  // Check if this index corresponds to an initially loaded field
                  const isInitialLoad = initiallyLoadedIndexes.includes(index)

                  return (
                    <div className='row mb-3' key={index}>
                      <div className='col-md-4'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='Name'
                          uppercase={true}
                          name={`family_details.${index}.name`}
                          label='Name'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          required
                          value={details?.name}
                          disabled={isInitialLoad} // Disable if it was loaded initially
                          className={isInitialLoad ? 'bg-warning w-100 p-3 rounded-2' : ''}
                        />
                      </div>

                      <div className='col-md-4'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-lg-12'
                          placeholder='Select'
                          label='Relationship'
                          name={`family_details.${index}.relationship_id`}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          arrValue={details?.relationship_id}
                          options={allMasterDataOptions?.relationships?.map((relations: any) => ({
                            value: relations.id,
                            label: relations.title || relations.label,
                          }))}
                          setFieldValue={setFieldValue}
                          required
                          colors={isInitialLoad}
                          disabled={isInitialLoad} // Disable if it was loaded initially
                        />
                      </div>
                      <div className='col-md-4'>
                        <FormSelect
                          labelClassName='col-md-12'
                          containerClassName='col-lg-12'
                          placeholder='Select'
                          label='Profession'
                          name={`family_details.${index}.profession`}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          values={values}
                          arrValue={details?.profession}
                          options={allMasterDataOptions?.occupations?.map((occupation: any) => ({
                            value: occupation.id || occupation.value,
                            label: occupation.title || occupation.label,
                          }))}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {/* Only show the remove button for newly added data */}
                      {!isInitialLoad && (
                        <div className='col-md-2 d-flex align-items-center'>
                          <button
                            type='button'
                            className='dt-btn dt-btn-primary'
                            data-cy='remove-button'
                            onClick={() => remove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  )
                })
              ) : (
                <p>No family members added. Click 'Add Member' to start.</p>
              )}
              <button
                type='button'
                className='dt-btn dt-btn-primary'
                data-cy='add-button'
                onClick={() =>
                  push({
                    name: '',
                    profession: '',
                    relationship_id: '',
                  })
                }
              >
                <FaPlus className='me-2' />
                Add Member
              </button>
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default FamilyDetailsForm
