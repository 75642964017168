import {useParams} from 'react-router-dom'
import {useGetPreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'

import {Spinner} from 'react-bootstrap-v5'
import {useEffect} from 'react'
import DocumentUploadIndividual from './documentUpload/DocumentUploadIndividual'
import DocumentUploadJoint from './documentUpload/DocumentUploadJoint'
import DocumentUploadMinor from './documentUpload/DocumentUploadMinor'

const NormalIndividualUploadDocsCIF = () => {
  interface IId {
    id: string
  }
  const {id}: IId = useParams()
  const {data: preliminariesDetails, isLoading} = useGetPreliminariesDetailsByIdCIF(id)
  const cbs_codeData = preliminariesDetails?.data?.account_type_id?.cbs_code.toLowerCase()
  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>Upload Documents</div>
            </div>
          </div>
          <div>
            {isLoading ? (
              <h4>
                Please wait...
                <Spinner animation={'border'} />{' '}
              </h4>
            ) : cbs_codeData === 'individual' ? (
              <DocumentUploadIndividual />
            ) : cbs_codeData === 'joint' ? (
              <DocumentUploadJoint />
            ) : cbs_codeData === 'minor' ? (
              <DocumentUploadMinor />
            ) : (
              <div>Page Not Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NormalIndividualUploadDocsCIF
