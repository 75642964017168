import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {FaPlus} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useGetAllLinkedEntitesLists} from 'src/app/queries/linkedEntitiesQuery/get/useGetAllLinkedEntitiesList'
import CheckPermissions from '../common/checkPermission'
import CorporateLinkedEntitiesTab from './common/components/table/corporate/corporateEntitiesTab'
import IndividualLinkedEntitiesTab from './common/components/table/individual/individualLinkedTab'

const LinkedEntities = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState('individual');
  const roleName = useSelector((state: any) => state.auth?.rolePermissions?.name);
  const [params, setParams] = useState({
    user_type: roleName?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/_two/g, ''),
    is_corporate: 'false',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    keyword: '',
    orderBy: 'updated_at',
    order: 'asc',
  })
  const [corporateParams, setcorporateParams] = useState({
    user_type: roleName?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/_two/g, ''),
    is_corporate: 'true',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    keyword: '',
    orderBy: 'updated_at',
    order: 'asc',
  })

  const [completeParams, setCompleteParams] = useState({
    user_type: roleName?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/_two/g, ''),
    is_corporate: '',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    keyword: '',
    orderBy: 'updated_at',
    order: 'asc',
  })
  const {data: linkedEntitiesdataLists, isLoading: linkedInitiesLoading} =
    useGetAllLinkedEntitesLists(
      activeKey === 'individual'
        ? params
        : activeKey === 'is_corporate'
        ? corporateParams
        : completeParams
    )

  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>Linked Entites List</div>
            </div>
            <div className='col-lg-6'>
              <div className='d-flex justify-content-end'>
                <CheckPermissions type='Add' moduleName='Linked Entities'>
                  {roleName === 'CPU Maker' ? (
                    <div
                      className='sub_button'
                      onClick={() =>
                        history.push('/linked-entities/cpu-maker/create-linked-entities')
                      }
                    >
                      <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                        <FaPlus className='me-2' />
                        Create Linked Entities
                      </button>
                    </div>
                  ) : null}
                </CheckPermissions>
              </div>
            </div>
          </div>
          <div>
            <Tabs
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k ?? 'individual')}
              id='cpuMaker-linked-entities-tabs'
              className='mb-5'
              data-cy='linked-entities-account-list-tabs'
            >
              <Tab eventKey='individual' title='Individual/Joint/Minor'>
                <div className='card-body bg-white'>
                  <IndividualLinkedEntitiesTab
                    linkedEnititesAccountLists={linkedEntitiesdataLists}
                    setParams={setParams}
                    params={params}
                    loading={false}
                    roleName={roleName}
                  />
                </div>
              </Tab>
              <Tab eventKey='is_corporate' title='Corporate'>
                <div className='card-body bg-white'>
                  <CorporateLinkedEntitiesTab
                    linkedEnititesAccountLists={linkedEntitiesdataLists}
                    setParams={setcorporateParams}
                    params={corporateParams}
                    loading={false}
                    roleName={roleName}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkedEntities
