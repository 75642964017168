import React, {useState, useEffect} from 'react'
import {Upload, Button, Col} from 'antd'
import {RcFile} from 'antd/lib/upload'
import {PlusCircleOutlined, DeleteOutlined} from '@ant-design/icons'
import {toast} from 'react-toastify'
import axios from 'axios'
import {UploadRequestOption} from 'rc-upload/lib/interface'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import {useQueryClient} from 'react-query'

interface FileUploadProps {
  documentType: string
  maxSize: number
  onChange: (file: RcFile, documentType: string) => void
  documentTypeId?: string
  uniqueID?: string
  resetUploads?: boolean
  setResetUploads?: (resetUploads: boolean) => void
  setUploadDocumentsTypeId?: any
  uploadDocumentsTypeId?: {id: string}[]
  isMissing?: boolean
  initialFile?: any
  fileName?: string
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  documentType,
  maxSize,
  onChange,
  documentTypeId = '',
  uniqueID,
  resetUploads = false,
  setResetUploads,
  setUploadDocumentsTypeId,
  isMissing = false,
  initialFile,
  fileName,
}) => {
  const queryClient = useQueryClient()
  const [fileList, setFileList] = useState<any[]>(
    initialFile
      ? [
          {
            uid: '-1',
            name: initialFile.name,
            status: 'done',
            url: initialFile.url,
          },
        ]
      : []
  )

  const uploadDocument = async (file: RcFile) => {
    const formData = new FormData()
    formData.append('documentTypeId', documentTypeId)
    formData.append('image', file)
    const url =
      documentType === 'Other Documents' || documentType === 'CIF Form'
        ? `${BASE_API_URL}/corporate-new-cif/${uniqueID}/additional-documents`
        : `${BASE_API_URL}/corporate-new-cif/${uniqueID}/documents`

    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      queryClient.invalidateQueries('corporatePreliminariesDetailsByIdCIF')
      return response.data
    } catch (error) {
      toast.error('File upload failed.')
      throw error
    }
  }

  useEffect(() => {
    if (fileName) {
      setUploadDocumentsTypeId((prev: string) => [...prev, documentTypeId])
    }
  }, [fileName, documentTypeId, setUploadDocumentsTypeId])

  const customRequest = async (options: UploadRequestOption) => {
    const {file, onSuccess, onError} = options

    if (file instanceof File || file instanceof Blob) {
      try {
        await uploadDocument(file as RcFile)
        if (onSuccess) {
          onSuccess('ok')
        }
        onChange(file as RcFile, documentType)
        if (setUploadDocumentsTypeId) {
          setUploadDocumentsTypeId((prev: any) => [...prev, {id: documentTypeId}])
        }
      } catch (error: any) {
        if (onError) {
          onError(error)
        }
      }
    } else {
      toast.error('Invalid file type: ' + typeof file)
    }
  }

  useEffect(() => {
    if (resetUploads) {
      setFileList([])
      if (setResetUploads) setResetUploads(false)
    }
  }, [resetUploads, setResetUploads])

  return (
    <Col md={8} sm={12} xs={24}>
      <Upload
        customRequest={customRequest}
        maxCount={1}
        accept='.jpg,.jpeg,.png,.pdf'
        fileList={fileList}
        onChange={(info) => {
          const {file} = info
          setFileList(info.fileList)
          if (file.status === 'done') {
            onChange(file.originFileObj as RcFile, documentType)
          }
        }}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <DeleteOutlined style={{color: 'red'}} />,
        }}
      >
        <div
          style={{
            color: 'red',
            marginBottom: '4px',
            fontSize: '12px',
            visibility: isMissing ? 'visible' : 'hidden',
          }}
        >
          {documentType} is required
          {/* Document Required. */}
        </div>

        <Button
          style={{
            width: '20vw',
            maxWidth: '100%',
            minWidth: '260px',
            fontSize: '12px',
            minHeight: '44px',
            whiteSpace: 'normal',
            height: 'auto',
            padding: '8px 15px',
            lineHeight: '1.2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          icon={<PlusCircleOutlined />}
        >
          {documentType}
        </Button>
      </Upload>
    </Col>
  )
}

export default FileUploadComponent
