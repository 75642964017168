import {Form, Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import usePostLinkedEntitiesCPUCheckerReviewForm from 'src/app/queries/linkedEntitiesQuery/post/usePostCPUCheckerReview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

const ReviewStatusFormCPUChecker = () => {
  interface RouteParams {
    id: string
  }
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const allStatusType = [
    {label: 'APPROVED', value: 'approve'},
    {label: 'REVERT BACK TO CPU MAKER', value: 'revert_to_cpumaker'},
  ]
  const {mutate: postCPUCheckerLinkedEntities} = usePostLinkedEntitiesCPUCheckerReviewForm()
  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: false,
      files: [
        {
          file_name: 'test.pdf',
          file_path: '/uploads/test.pdf',
        },
      ],
    }
    postCPUCheckerLinkedEntities(payload, {
      onSuccess: () => {
        history.push('/dashboard/cpu-checker')
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }
  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <h3 className='fw-bolder mt-5 mb-5'>Update Status</h3>
          <Formik
            initialValues={{status: '', remarks: ''}}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
            // validationSchema={REVIEW_VALIDATION_SCHEMA}
          >
            {({handleChange, errors, touched, values, setFieldValue}) => (
              <Form>
                <div className='row '>
                  <div className='col-md-12'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-md-12'
                      label='Status Type'
                      name='status'
                      // isClearable={true}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={allStatusType?.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      required={true}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <FormTextBox
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    type='text'
                    placeholder='Remarks'
                    label='Remarks'
                    name='remarks'
                    disabled={false}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={true}
                    data-cy='add-id-number-text-field'
                    as='textarea'
                  />
                </div>
                <div className='d-flex justify-content-end mt-3'>
                  <button type='submit' className='btn btn-secondary mx-4'>
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default ReviewStatusFormCPUChecker
