// export default IndividualLinkedEntitiesTab

import React, {useCallback, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {isEmpty} from 'lodash'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'

const Cell = Table.Cell

interface IndividualLinkedEntitiesTabProps {
  linkedEnititesAccountLists: any
  setParams: (params: any) => void
  params: any
  loading: boolean
  roleName: string
}

interface NewAccount {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  account_status?: {
    updated_date: string
    remarks: string
    current_status: string
  }
  registration_detail?: {
    account_number: string
    cif_number: string
  }
  btn_status?: string
  branchLocation?: string
}

const IndividualLinkedEntitiesTab: React.FC<IndividualLinkedEntitiesTabProps> = ({
  linkedEnititesAccountLists,
  setParams,
  params,
  loading,
  roleName,
}) => {
  const history = useHistory()
  const data = useMemo(() => {
    try {
      return linkedEnititesAccountLists?.data?.linked_entities
        ? linkedEnititesAccountLists.data.linked_entities.map(
            (linkedEnitiesDetails: any, i: number) => ({
              ...linkedEnitiesDetails,
              sn: (params?.page - 1) * params?.limit + (i + 1),
              account_name: `${linkedEnitiesDetails?.created_by?.name.toUpperCase()}`,
              updated_date: linkedEnitiesDetails?.account_status?.updated_date,
              remarks: linkedEnitiesDetails?.remarks ?? '',
              current_status: linkedEnitiesDetails?.account_status?.current_status ?? '',
              accountNumber: linkedEnitiesDetails?.primary_account_number ?? '',
              cifNumber: linkedEnitiesDetails?.primary_cif_number ?? '',
              btn_status: linkedEnitiesDetails?.btn_status ?? '',
              branchLocation: linkedEnitiesDetails?.created_by?.branch?.title ?? '',
            })
          )
        : []
    } catch (error) {
      console.error('Error processing data:', error)
      return []
    }
  }, [linkedEnititesAccountLists, params?.page, params?.limit])

  const handleChangeLimit = useCallback(
    (dataKey: number) => {
      setParams({
        ...params,
        limit: dataKey,
      })
    },
    [params, setParams]
  )

  const ActionCell = React.memo(({rowData, ...props}: any) => {
    const handleButtonClick = () => {
      let path = ''
      switch (roleName) {
        case 'CPU Checker':
          path = `/linked-entities/cpu-checker/individual/review-and-update/${rowData?.id}`
          break
        case 'CPU Maker':
          path = `/linked-entities/cpu-maker/individual/review-and-update/${rowData?.id}`
          break
        default:
          path = `ok`
      }
      history.push({
        pathname: path,
        state: rowData,
      })
    }

    return (
      <Cell {...props} className='link-group'>
        <button className={'btn btn-primary btn-sm'} onClick={handleButtonClick}>
          Review
        </button>
      </Cell>
    )
  })

  const columns = [
    {
      label: 'Primary Customer Name',
      dataKey: 'primary_customer_name',
      width: 180,
      cell: <Cell dataKey='primary_customer_name' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      width: 160,
      cell: <Cell dataKey='cifNumber' />,
    },
    {
      label: 'Primary Account Number',
      dataKey: 'primary_account_number',
      width: 250,
      cell: <Cell dataKey='primary_account_number' />,
    },
    {
      label: 'Primary Customer Email',
      dataKey: 'primary_customer_email',
      width: 250,
      cell: <Cell dataKey='primary_customer_email' />,
    },
    {
      label: 'Updated Date',
      dataKey: 'updated_date',
      width: 160,
      cell: <Cell dataKey='updated_date' />,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      width: 230,
      cell: <Cell fullText dataKey='remarks' />,
    },
    {
      label: 'Status',
      dataKey: 'current_status',
      width: 200,
      cell: <Cell dataKey='current_status' />,
    },

    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell />,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params?.keyword}
          onChange={(value) => setParams({...params, keyword: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.keyword) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, keyword: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={linkedEnititesAccountLists?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default React.memo(IndividualLinkedEntitiesTab)
