import React, {useEffect} from 'react'
import {ErrorMessage, Field} from 'formik'

interface Props {
  containerClassName?: string
  label?: string
  name: any
  validate?: any
  type?: any
  placeholder?: any
  onChange?: any
  className?: any
  errors?: any
  touched?: any
  labelClassName?: string
  required?: boolean
  disabled?: boolean
  value?: any
  as?: string
  min?: string
  max?: string // Add the max prop to control the maximum selectable date
  avoidKey?: any
  onKeyUp?: any
  mainClassName?: string
  defaultValue?: number | string
  uppercase?: boolean
  maxLength?: any
  preventFutureDate?: boolean // Add this prop to enable future date prevention
}

const FormTextBox = React.memo((props: Props) => {
  const {
    containerClassName,
    label,
    name,
    type,
    className,
    errors,
    touched,
    labelClassName,
    required,
    onKeyUp,
    avoidKey,
    value,
    as,
    min,
    max, // Destructure max prop
    defaultValue,
    mainClassName,
    uppercase,
    maxLength,
    preventFutureDate,
    disabled,
    ...rest
  } = props

  const inputStyle = uppercase ? {textTransform: 'uppercase'} : {}

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0]

  return (
    <div className={label ? 'row mb-6' : mainClassName || 'col-lg-6'}>
      {label && (
        <label className={`mb-1 fw-bolder fs-6 ${labelClassName || 'col-lg-4'}`}>
          <span className={required ? 'required' : ''}>{label}</span>
        </label>
      )}

      <div className={containerClassName ?? 'col-lg-6'}>
        <Field
          name={name}
          label={label}
          type={type || 'text'}
          className={
            disabled ? 'bg-warning w-100 p-3 rounded-2' : className || 'form-control border'
          }
          disabled={disabled}
          autoComplete='off'
          as={as || 'input'}
          min={min}
          format={'YYYY-MM-DD'}
          max={preventFutureDate ? today : max} // Apply max date only if preventFutureDate is true
          onKeyUp={onKeyUp}
          maxLength={maxLength}
          defaultValue={defaultValue}
          {...(avoidKey && {avoidKey: avoidKey})}
          {...(value && {value: value})}
          {...rest}
          style={inputStyle}
          data-cy={`${name}-text-field`}
        />
        <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
          <ErrorMessage name={name} component='div' className='field-error-message' />
        </div>
      </div>
    </div>
  )
})

export default FormTextBox
