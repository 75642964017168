import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import * as Yup from 'yup'

export const CORPORATE_FORM_VALIDATION = Yup.object().shape({
  institution_name: Yup.string().required('Institution Name is required'),
  constitution: Yup.string().required('Constitution is required'),
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  registration: Yup.string().required('Registration No. is required'),
  registered_with: Yup.string().required('Registered With is required'),
  regis_date: Yup.date().required('Date of Registration is required'),
  Regis_Date_in_BS: Yup.date().required('Date of Registration (BS) is required'),
  Date_in_BS: Yup.date().required('Registration Valid Upto (BS) is required'),
  house_no: Yup.string().required('House No. is required'),
  tole: Yup.string().required('Tole is required'),
  district: Yup.string().required('District is required'),
  municipality: Yup.string().required('Municipality is required'),
  ward_no: Yup.string()
    .required('The ward number number is required.')
    .min(0, 'The ward number must be a positive number.'),
  phone_no: Yup.string().required('Phone No. is required'),
  fax_number: Yup.string().required('Fax Number is required'),
  mail_id: Yup.string().email('Invalid email').required('Email is required'),
  website: Yup.string().url('Invalid URL').required('Website is required'),
  area: Yup.string().required('Working Area is required'),
  nature_of_business: Yup.string().required('Nature of Business is required'),
  list_of_offices: Yup.string().required('List of Offices is required'),
  branches: Yup.string().required('Number of Branches is required'),
  pan_number: Yup.string().when('status', {
    is: 'yes',
    then: Yup.string()
      .required('The PAN number is required.')
      .matches(/^\d{9}$/, 'PAN number must be exactly 9 digits and should not have characters.')
      .max(9, 'PAN number must be 9 characters long.'),
  }),

  vat_number: Yup.string().when('status', {
    is: 'yes',
    then: Yup.string()
      .required('The VAT number is required.')
      .matches(/^\d{9}$/, 'VAT number must be exactly 9 digits and should not have characters.')
      .max(9, 'VAT number must be 9 characters long.'),
  }),
  screening_verify: Yup.boolean().oneOf([true], 'Acknowledgement is required'),
})

export const OPENING_ACCOUNT_TYPE = Yup.object().shape({
  accountType: Yup.string().required('Account Type is required'),
  customerType: Yup.string().required('Customer Type is required'),
})
export const OPENING_ACCOUNT_TYPE_UPDATE = Yup.object().shape({
  customerType: Yup.string().required('Customer Type is required'),
})
const isCountryNepal = (value: string) => value === '48691d0c-2489-4718-8596-10833123fce9'

const isNotNepal = (value: string) => value !== '48691d0c-2489-4718-8596-10833123fce9'
const married = (value: string) => value === 'a705c6c7-8da8-4c65-b52d-887b019c7a4a'
const account_type_id = (value: string) => value === 'ad785597-b100-4e3d-b86c-d0f9e161ff60'
const panCardID = (value: string) => value === '9610704f-a3d8-46b2-8414-07afe6dafce3'
const citizenshipID = (value: string) => value === '8b2c2aec-9843-4646-8cb9-8f507637a2ae'
const birthID = (value: string) => value === 'd2f43beb-2e8c-4836-bd70-ac414a09f2fb'
const nationality_id = '4785497c-56b4-4745-a15b-6bbd20ada494'

// const isCountryNepal = (country:any) => country && country.toUpperCase() === 'NEPAL';
const numericRegex = /^[0-9a-zA-Z-/]*$/
// const uuidRegex: RegExp =
//   /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
//FOR INDIVIDUAL
export const SaveAndContinueValidation = Yup.object().shape({
  first_name: Yup.string()
    .required('The first name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The first name should not contain any numbers or special characters.'
    ),
  middle_name: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    'The middle name should not contain any numbers or special characters.'
  ),
  last_name: Yup.string()
    .required('The last name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The last name should not contain any numbers or special characters.'
    ),
  salutation: Yup.string().required('Salutation is required.'),
  total_annual_income: Yup.string().required('Total annual income is required'),
  // expected_annual_income: Yup.number()
  //   .required('Expected Annual Turnover is required')
  //   .min(0, 'Expected Annual Turnover cannot be negative')
  //   .typeError('Must be a numeric value.'),
  spouse_name: Yup.string().when('marital_status', {
    is: married,
    then: Yup.string()
      .required('Spouse name is required when married.')
      .matches(/^[A-Za-z\s]+$/, 'Should not contain any numbers or special characters.'),
    otherwise: Yup.string().nullable(),
  }),
  father_in_law: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    'Should not contain any numbers or special characters.'
  ),

  other_occupation: Yup.string().when('occupation_id', {
    is: 'ffae19fa-793f-4d51-b793-a4d7124575f2',
    then: Yup.string().required('Other occupation details is required.'),
    otherwise: Yup.string().nullable(),
  }),
  other_income_source: Yup.string().when('occupation_id', {
    is: '500e887d-cbc4-43d8-bc7a-17f1985241dd',
    then: Yup.string().required('Other income source details is required.'),
    otherwise: Yup.string().nullable(),
  }),
  id_expiry_date: Yup.string().when('id_type_id', {
    is: !panCardID || !birthID || !citizenshipID,
    then: Yup.string().required('The ID expiry date is required.'),
    otherwise: Yup.string().nullable(),
  }),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_country: Yup.string().required('The present country is required.'),
      present_province: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Province is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_city: Yup.string().matches(
        /^[,a-zA-Z /_,-]+$/,
        'Present City Name should not contain numbers'
      ),

      present_zone: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Zone is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_district: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('District is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_municipality: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Municipality is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_ward_number: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_house_number: Yup.string().matches(
        numericRegex,
        'House Number must contain alphanumerics and slashed'
      ),

      present_outside_country_post_box: Yup.string().when('present_country', {
        is: isNotNepal,
        then: Yup.string()
          .required('Post Box No. is required')
          .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
        otherwise: Yup.string()
          .notRequired()
          .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
      }),
      present_outside_country_full_address: Yup.string().when('present_country', {
        is: isNotNepal,
        then: Yup.string()
          .required('Foreign Address is required')
          .matches(
           /^[.,a-zA-Z /\\_-]+$/,
            'Foreign Address name should contain only letters and spaces'
          ),
        otherwise: Yup.string()
          .notRequired()
          .matches(
           /^[.,a-zA-Z /\\_-]+$/,
            'Foreign Address name should contain only letters and spaces'
          ),
      }),
      present_email: Yup.string().email('The present email must be a valid email.'),
      present_mobile_number: Yup.string()
        .required('The present mobile number is required.')
        .typeError('Must be a numeric value.')
        .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 and must be 10 digits'),
    })
  ),
  branch_code: Yup.string()
    .max(6, 'Branch code must be 6 digits long.')
    .min(3, 'Branch code must be atleast 3 digits'),
  // present_house_number: Yup.string().length(6, 'House number must be 6 digits long.'),
  gender: Yup.string().required('The gender is required.'),
  marital_status: Yup.string().required('The marital status is required.'),
  date_of_birth_ad: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),
  date_of_birth_bs: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (BS) is required'),
    otherwise: Yup.date().nullable(),
  }),
  national_id_number: Yup.string()
    .matches(/^[0-9]+$/, 'The National ID number must be a positive integer.')
    .typeError('The National ID number must be a number.'),
  id_type_id: Yup.string().required('The ID type  is required.'),
  identification_number: Yup.string()
    .required('The identification number is required.')
    .matches(/^[0-9\/\-]+$/, 'The identification number must contain only numbers, /, and -.'),
  father_name: Yup.string()
    .required('The father name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The father name should not contain any numbers or special characters.'
    ),
  grandfather_name: Yup.string()
    .required('The grandfather name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The grandfather name should not contain any numbers or special characters.'
    ),
  expected_annual_turnover: Yup.number()
    .required('Expected Annual Turnover is required')
    .min(0, 'Expected Annual Turnover cannot be negative')
    .typeError('Must be a numeric value.'),
  occupation_id: Yup.string().required('The occupation is required.'),
  income_source_id: Yup.string().required('The income source  is required.'),
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  // account_type_id: Yup.string().required('The account type is required.'),

  account_type_id: Yup.string().required('The account type is required.'),
  // account_holders: Yup.array().of(
  //   Yup.object().shape({
  //     cif_number: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string().required('CIF Number is required for Joint Account'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //     first_name: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string()
  //         .required('First Name is required for Joint Account')
  //         .matches(/^[A-Za-z\s]+$/, 'First Name cannot contain numbers'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //     middle_name: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string()
  //         .required('Middle Name is required for Joint Account')
  //         .matches(/^[A-Za-z\s]+$/, 'Middle Name cannot contain numbers'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //     last_name: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string()
  //         .required('Last Name is required for Joint Account')
  //         .matches(/^[A-Za-z\s]+$/, 'Last Name cannot contain numbers'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //     id_type_id: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string().required('Partner Identification is required for Joint Account'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //     id_number: Yup.string().when('account_type_id', {
  //       is: account_type_id,
  //       then: Yup.string().required('Identification Number is required for Joint Account'),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   })
  // ),
  account_holders: Yup.array().when('account_type_id', {
    is: (value: string) => value === 'ad785597-b100-4e3d-b86c-d0f9e161ff60', // Replace 'joint' with the actual value for a joint account type
    then: Yup.array()
      .of(
        Yup.object().shape({
          cif_number: Yup.string()
            .required('CIF Number is required for Joint Account')
            .matches(/^[0-9]+$/, 'CIF Number should contain only numbers'),
          first_name: Yup.string()
            .required('First Name is required for Joint Account')
            .matches(/^[A-Za-z\s]+$/, 'First Name cannot contain numbers'),
          middle_name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Middle Name cannot contain numbers'),
          last_name: Yup.string()
            .required('Last Name is required for Joint Account')
            .matches(/^[A-Za-z\s]+$/, 'Last Name cannot contain numbers'),
          id_type_id: Yup.string().required('Partner Identification is required for Joint Account'),
          id_number: Yup.string()
            .required('Identification Number is required for Joint Account')
            .matches(/^[0-9 /-]+$/, 'identification Number should contain only numbers'),
        })
      )
      .required('Joint account holder details are required.'),
    otherwise: Yup.array().notRequired(),
  }),
})

export const saveValidations = Yup.object().shape({
  account_type_id: Yup.string().required('The account type is required.'),
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  salutation: Yup.string().required('The salutation is required.'),
  first_name: Yup.string()
    .required('The first name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The first name should not contain any numbers or special characters.'
    ),
  middle_name: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    'The middle name should not contain any numbers or special characters.'
  ),
  last_name: Yup.string()
    .required('The last name is required.')
    .matches(
      /^[A-Za-z\s]+$/,
      'The first name should not contain any numbers or special characters.'
    ),
  spouse_name: Yup.string().when('marital_status', {
    is: married,
    then: Yup.string()
      .required('Spouse name is required when married.')
      .matches(/^[A-Za-z\s]+$/, 'Should not contain any numbers or special characters.'),
    otherwise: Yup.string().nullable(),
  }),
  father_in_law: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    'Should not contain any numbers or special characters.'
  ),

  date_of_birth_ad: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),
  date_of_birth_bs: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (BS) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),

  gender: Yup.string().required('The gender is required.'),
  id_type_id: Yup.string().required('The ID type is required.'),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_country: Yup.string().required('The present country is required.'),
      present_zone: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present zone is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_city: Yup.string().matches(
        /^[,a-zA-Z /_,-]+$/,
        'Present City Name should not contain numbers'
      ),
      present_ward_number: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required when the present country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      present_house_number: Yup.string().matches(
        numericRegex,
        'House Number must contain alphanumerics and slashed'
      ),

      present_municipality: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present municipality is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
})
//FOR MINOR
export const SaveAndContinueValidationMinor = Yup.object().shape({
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  // total_annual_income: Yup.number()
  //   .required('Total annual income is required')
  //   .min(0, 'Total annual income cannot be negative')
  //   .typeError('Must be a numeric value.'),
  // expected_monthly_income: Yup.number()
  //   .required('Expected Annual Turnover is required')
  //   .min(0, 'Expected Annual Turnover cannot be negative')
  //   .typeError('Must be a numeric value.'),
  // expected_annual_income: Yup.number()
  //   .required('Expected Annual Turnover is required')
  //   .min(0, 'Expected Annual Turnover cannot be negative')
  //   .typeError('Must be a numeric value.'),
  other_occupation: Yup.string().when('occupation_id', {
    is: 'ffae19fa-793f-4d51-b793-a4d7124575f2',
    then: Yup.string().required('Other occupation details is required.'),
    otherwise: Yup.string().nullable(),
  }),
  other_income_source: Yup.string().when('occupation_id', {
    is: '500e887d-cbc4-43d8-bc7a-17f1985241dd',
    then: Yup.string().required('Other income source details is required.'),
    otherwise: Yup.string().nullable(),
  }),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_country: Yup.string().required('The present country is required.'),
      present_province: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present province is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_zone: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present zone is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      id_expiry_date: Yup.string().when('id_type_id', {
        is: !panCardID || !birthID || !citizenshipID,
        then: Yup.string().required('The ID expiry date is required.'),
        otherwise: Yup.string().nullable(),
      }),
      present_district: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present district is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_municipality: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present municipality is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_ward_number: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required when the present country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      present_house_number: Yup.string().matches(
        numericRegex,
        'House Number must contain only numbers'
      ),

      present_outside_country_post_box: Yup.string().when('present_country', {
        is: isNotNepal,
        then: Yup.string()
          .required('Post Box No. is required')
          .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
        otherwise: Yup.string()
          .notRequired()
          .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
      }),
      present_outside_country_full_address: Yup.string().when('present_country', {
        is: isNotNepal,
        then: Yup.string()
          .required('Foreign Address is required')
          .matches(
           /^[.,a-zA-Z /\\_-]+$/,
            'Foreign Address name should contain only letters and spaces'
          ),
        otherwise: Yup.string()
          .notRequired()
          .matches(
           /^[.,a-zA-Z /\\_-]+$/,
            'Foreign Address name should contain only letters and spaces'
          ),
      }),
      present_email: Yup.string().email('The present email must be a valid email.'),
      present_mobile_number: Yup.string()
        .required('The present mobile number is required.')
        .typeError('Must be a numeric value.')
        .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 or 10 digits long.'),
    })
  ),
  account_type_id: Yup.string().required('The account type is required.'),
  guardian_id_number: Yup.string()
    .required('The guardian ID number is required.')
    .matches(
      /^[0-9/-]+$/,
      'The guardian ID number must contain only numbers, slashes ("/"), or hyphens ("-").'
    ),
  guardian_first_name: Yup.string()
    .required('Guardian first name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Only characters are allowed.'),
  guardian_middle_name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Only characters are allowed.'),
  guardian_last_name: Yup.string()
    .required('Guardian last name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Please enter only alphabetic characters.'),
  guardian_cif_number: Yup.number()
    .typeError('Must be a numeric value.')
    .required('Guardian CIF number is required.'),
  guardian_relationship_id: Yup.string().required('Relationship declaration is required.'),
  guardian_relationship_other: Yup.string(),
  guardian_address: Yup.string()
    .required('Guardian address is required.')
    .matches(/^[A-Za-z0-9\s,-/]+$/, 'Please enter only alphabetic characters.'),
  minor_certificate_number: Yup.number()
    .required('Minor Certificate is required.')
    .typeError('Must be a numeric value.'),
  // certificate_issued_by: Yup.string()
  // .required('Minor Certificate issue by is required.')
  // .matches(/^[A-Za-z\s]+$/, 'Only characters are allowed.'),
  minor_certificate_issue_date_ad: Yup.string().required(
    'Minor Certificate Issued(AD) is required.'
  ),
  minor_certificate_issue_date_bs: Yup.string().required(
    'Minor Certificate Issued(BS) is required.'
  ),
  salutation: Yup.string().required('The salutation is required.'),
  first_name: Yup.string()
    .required('The first name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Please enter only alphabetic characters.'),
  last_name: Yup.string()
    .required('The last name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Please enter only alphabetic characters.'),
  gender: Yup.string().required('The gender is required.'),
  marital_status: Yup.string().required('The marital status is required.'),
  date_of_birth_ad: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),
  date_of_birth_bs: Yup.date().when('nationality_id', {
    is: nationality_id,
    then: Yup.date().required('Date of Birth (BS) is required when nationality is Nepal'),
    otherwise: Yup.date().nullable(),
  }),
  id_type_id: Yup.string().required('The ID type ID is required.'),
  identification_number: Yup.string()
    .required('The identification number is required.')
    .matches(/^[0-9\/\-]+$/, 'The identification number must contain only numbers, /, and -.'),
  national_id_number: Yup.number()
    .min(0, 'The National Id number must be a positive number.')
    .integer('The National Id number must be an integer.')
    .typeError('The National Id number must be a number.'),
  father_name: Yup.string()
    .required('The father name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Please enter only alphabetic characters.'),
  grandfather_name: Yup.string()
    .required('The grandfather name is required.')
    .matches(/^[A-Za-z\s]+$/, 'Please enter only alphabetic characters.'),
  occupation_id: Yup.string().required('The occupation is required.'),
  income_source_id: Yup.string().required('The income source is required.'),
  spouse_name: Yup.string().when('marital_status', {
    is: married,
    then: Yup.string()
      .required('Spouse name is required when married.')
      .matches(/^[A-Za-z\s]+$/, 'Should not contain any numbers or special characters.'),
    otherwise: Yup.string().nullable(),
  }),
  father_in_law: Yup.string().matches(
    /^[A-Za-z\s]+$/,
    'Should not contain any numbers or special characters.'
  ),
})

export const saveValidationsMinor = Yup.object().shape({
  account_type_id: Yup.string().required('The account type is required.'),
  salutation: Yup.string().required('The salutation is required.'),
  first_name: Yup.string().required('The first name is required.'),
  last_name: Yup.string().required('The last name is required.'),
  gender: Yup.string().required('The gender is required.'),
  id_type_id: Yup.string().required('The ID type is required.'),
  guardian_id_number: Yup.string()
    .required('The guardian ID number is required.')
    .matches(
      /^[0-9/-]+$/,
      'The guardian ID number must contain only numbers, slashes ("/"), or hyphens ("-").'
    ),
  aml_risk_rating: Yup.string().required('Risk Rating is required'),
  guardian_first_name: Yup.string().required('Guardian First Name is required.'),
  guardian_middle_name: Yup.string(),
  guardian_last_name: Yup.string().required('Guardian Last Name is required.'),
  guardian_relationship_id: Yup.string().required('Required.'),
  guardian_relationship_other: Yup.string(),
  guardian_address: Yup.string().required('Required.'),
  minor_certificate_number: Yup.number()
    .required('Required.')
    .typeError('Must be a numeric value.'),
  certificate_issued_by: Yup.string().required('Required.'),
  minor_certificate_issue_date_ad: Yup.string().required('Required.'),
  minor_certificate_issue_date_bs: Yup.string().required('Required.'),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_country: Yup.string().required('The present country is required.'),
      present_zone: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present zone is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_ward_number: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required('Ward Number is required when the present country is Nepal.'),
        otherwise: Yup.string().nullable(),
      }),
      present_house_number: Yup.string().matches(
        numericRegex,
        'House Number must contain alphanumerics and slashed'
      ),

      present_district: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present district is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
      present_municipality: Yup.string().when('present_country', {
        is: isCountryNepal,
        then: Yup.string().required(
          'The present municipality is required when the present country is Nepal.'
        ),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
  expected_annual_turnover: Yup.string()
    .required('Expected Annual Turnover is required')
    .min(0, 'Expected Annual Turnover cannot be negative')
    .typeError('Must be a numeric value.'),
  total_annual_income: Yup.string().required('Total annual income is required'),
})
