import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const postCustomerEnquiryInfo = async (payload: any) => {
  try {
    const transformedPayload = {
      customer_id: payload.values ? payload.values : payload.cifNumber,
    }
    const response = await axios.post(`${BASE_API_URL}/customer-enquiry-info`, transformedPayload)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCustomerEnquiryInfo = (setEnquiryData: any) => {
  return useMutation(postCustomerEnquiryInfo, {
    onSuccess: (response) => {
      if (response) {
        setEnquiryData(response.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCustomerEnquiryInfo

// import {useMutation} from 'react-query'
// import {toast} from 'react-toastify'

// const postmockCustomerEnquiryCheck = async (payload: any) => {
//   // Mock data
//   const {cifNumber, index} = payload
//   console.log('helllll', payload)
//   const mockResponse = {
//     resCod: '200',
//     resDesc: 'Get Customer Enquiry Detail.',
//     data: {
//       generalDetails: [
//         {
//           CUST_ID: cifNumber,
//           CUST_TITLE_CODE: 'MR',
//           CUST_NAME: 'SHANKER POKHREL',
//           CUST_SHORT_NAME: 'SHANKER PO',
//           CUST_SEX: 'M',
//           CUST_MINOR_FLG: 'N',
//           DATE_OF_BIRTH: '16-06-1984',
//           CUST_MARITAL_STATUS: '002',
//           CUST_EMP_ID: '00540PF',
//           MOBILE_NO: '9851061560',
//           PSPRT_NUM: '2278228',
//           PSPRT_ISSU_DATE: '23-07-2004',
//           PSPRT_DET: 'NEPAL',
//           PSPRT_EXP_DATE: '22-07-2014',
//           ADDRESS_TYPE: 'P',
//           CUST_NRE_FLG: 'N',
//           NAME_SCREENING_ID_NO: '1234',
//           IDTYPE: 'CTZ',
//           IDNO: '16998',
//           IDISSUEDATE: '12-03-2059',
//           ISSUEDISTRICT: 'KATH',
//           IDREGISTEREDIN: null,
//         },
//       ],
//       addressInfo: {
//         permanentAddress: {
//           ADDRESS_TYPE: 'CUSTPERMADD',
//           ADDRESS1: 'DHAPASI, BASUNDHARA-9',
//           ADDRESS2: 'KATHMANDU, NEPAL',
//           MUNICIPALITY_VDC_NAME: 'BASUNDHARA',
//           WARD_NO: '09',
//           ZONEE: 'BAGM',
//           CITY_CODE: 'KATH',
//           DISTRICT_CODE: 'KATH',
//           EMAIL_ID: 'shankerpokhrel@gmail.com',
//           CNTRY_CODE: 'NP',
//           PHONE_NUM1: '01-4355273',
//           PHONE_NUM2: '9851061560',
//           DEL_FLG: 'N',
//         },
//         temporaryAddress: {
//           ADDRESS_TYPE: 'CUSTEMPADD',
//           ADDRESS1: null,
//           ADDRESS2: null,
//           MUNICIPALITY_VDC_NAME: null,
//           WARD_NO: null,
//           ZONEE: null,
//           CITY_CODE: null,
//           DISTRICT_CODE: null,
//           EMAIL_ID: null,
//           CNTRY_CODE: null,
//           PHONE_NUM1: null,
//           PHONE_NUM2: null,
//           DEL_FLG: 'N',
//         },
//         communicationAddress: {
//           ADDRESS_TYPE: 'CUSTCOMMADD',
//           ADDRESS1: 'DHAPASI, BASUNDHARA-9',
//           ADDRESS2: 'KATHMANDU, NEPAL',
//           MUNICIPALITY_VDC_NAME: 'BASUNDHARA',
//           WARD_NO: '09',
//           ZONEE: 'BAGM',
//           CITY_CODE: 'KATH',
//           DISTRICT_CODE: 'KATH',
//           EMAIL_ID: null,
//           CNTRY_CODE: 'NP',
//           PHONE_NUM1: null,
//           PHONE_NUM2: null,
//           DEL_FLG: 'N',
//         },
//       },
//       misInformation: [
//         {
//           CUST_OCCP_CODE: '1',
//           CUST_OTHR_BANK_CODE: '011',
//           CUST_GRP: '1',
//           CUST_STATUS: '1',
//           CDD_ECDD_DATE: '24-08-2020',
//           CONSTITUTION: '010',
//           CUST_FREE_TEXT: '50000.00',
//           ANNUAL_TURN_OVER: '1',
//           EDUCATION_QUALIFACTION: '3',
//           REGILION: '1',
//           ANNUAL_TURN_OVER_AS_ON: '18-08-2008',
//           RM_CODE: '002',
//           RISK_CATEGORY: 'A',
//           TOTAL_NO_OF_ANNUAL_TXN: 'A',
//         },
//       ],
//       entityRelationship: [
//         {
//           PERSON_RELTN_NAME: 'DAN BAHADUR POKHREL',
//           CUST_RELTN_CODE: 'FATH',
//           DEL_FLG: 'N',
//         },
//         {
//           PERSON_RELTN_NAME: 'MEENA POKHREL',
//           CUST_RELTN_CODE: 'MOTH',
//           DEL_FLG: 'N',
//         },
//         {
//           PERSON_RELTN_NAME: 'HEERA BDR POKHREL',
//           CUST_RELTN_CODE: 'GFAT',
//           DEL_FLG: 'N',
//         },
//       ],
//       currencyInfo: [
//         {
//           crncy_code: 'NPR',
//           del_flg: 'N',
//         },
//       ],
//     },
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => resolve(mockResponse), 1000) // Simulate network delay
//   })
// }

// const usePostCustomerEnquiryInfo = (setEnquiryData: any) => {
//   return useMutation(postmockCustomerEnquiryCheck, {
//     onSuccess: (response: any) => {
//       if (response) {
//         setEnquiryData(response.data)
//         toast.success(response?.data?.resDesc)
//       }
//     },
//     onError: (error: Error) => {
//       toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
//     },
//   })
// }

// export default usePostCustomerEnquiryInfo
