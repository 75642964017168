import {nanoid} from '@reduxjs/toolkit'
import {adToBs, bsToAd} from '@sbmdkl/nepali-date-converter'
import axios from 'axios'
import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Modal} from 'rsuite'
import {
  OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_SUB_TITLE,
  OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE,
} from 'src/app/modules/new-cif-new-account/bankMaker/createNewAccountCorporate/constants/Contants'
import VerificationForm from 'src/app/modules/new-cif-new-account/common/optVerification'
import CIFCheck from 'src/app/pages/dashboard/component.tsx/CIFCheck'
import usePostCustomerEnquiryInfo from 'src/app/queries/apiMS/usePostCUstomerEnquiryInfo'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetAllRiskCategoriesList} from 'src/app/queries/getMasterData/riskCategories/get/useGetAllRiskCategoriesList'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import useUpdateIndividualAccountPreliminary from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/patch/usePatchIndividualPreliminary'
import usePostKYCAdditionalDetails from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostAdditionalForm'
import {usePostToGenerateCode} from 'src/app/queries/new-cif-new-account-queries/usePostGenerateOtp'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {BASE_API_URL, BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {RootState} from 'src/setup'
import PartnersDetailsFormJoint from '../../../../components/additionalForm/PartnersDetailsForm'
import {COLOR} from '../../../../constants/Contants'
import {
  SaveAndContinueValidation,
  SaveAndContinueValidationMinor,
} from 'src/app/modules/NewCIFOnly/bankMaker/CeateNewCIF/utils/validations/ValidationSchemas'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import useGetMasterDataFilter from 'src/app/queries/getMasterData/getAllMasterData'
import {useQueryClient} from 'react-query'
import {Spin} from 'antd'

const ReviewAndUpdate = () => {
  interface RouteParams {
    id: string
  }

  const {id} = useParams<RouteParams>()
  const {data: updatePreliminariesDetails, isLoading, refetch} = useGetPreliminariesDetailsById(id)
  const {mutate: updatePreliminariesAccountDetails, isLoading: isLoadingFormPost} =
    useUpdateIndividualAccountPreliminary()
  const {mutate: postAddtionalPartner} = usePostKYCAdditionalDetails()
  const history = useHistory()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code

  interface RouteParams {
    id: string
  }

  const [btnFlag, setBtnFlag] = useState('')
  const [validationCheck, setValidationCheck] = useState('')

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setcheckAllDataJoint] = useState<any>([])
  const [flag, setFlag] = useState<string>('default')
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [masterFilterData, setMasterFilterData] = useState<any>(null)
  const [isDisabled, setISDisabled] = useState<boolean>(false)

  const {mutate: masterDataFilter, isLoading: masterFilterLoading} =
    useGetMasterDataFilter(setMasterFilterData)

  const update = useRef<any>('default')
  const {mutate: mutateCheckAll, isLoading: pdfLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setcheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [verificationType, setVerificationType] = useState(null)
  const [verificationDetails, setVerificationDetails] = useState({
    email: '',
    mobile: '',
  })
  const [uniqueID, setUniqueID] = useState('')
  const [regenrateID, setRegenrateID] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  interface RouteParams {
    id: string
  }

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('form_addresses[0].present_outside_country_post_box', '')
      setFieldValue('form_addresses[0].present_outside_country_full_address', '')
      setFieldValue('form_addresses[0].present_mobile_number', '')
      setFieldValue('form_addresses[0].present_email', '')
      setTouched({
        'form_addresses[0].present_outside_country_post_box': false,
        'form_addresses[0].present_outside_country_full_address': false,
        'form_addresses[0].present_mobile_number': false,
        'form_addresses[0].present_email': false,
      })
    } else {
      setFieldValue('form_addresses[0].present_province', '')
      setFieldValue('form_addresses[0].present_zone', '')
      setFieldValue('form_addresses[0].present_district', '')
      setFieldValue('form_addresses[0].present_municipality', '')
      setFieldValue('form_addresses[0].present_city', '')
      setFieldValue('form_addresses[0].present_ward_number', '')
      setFieldValue('form_addresses[0].present_street_name', '')
      setFieldValue('form_addresses[0].present_house_number', '')
      setFieldValue('form_addresses[0].present_fax_number', '')
      setFieldValue('form_addresses[0].present_mobile_number', '')
      setFieldValue('form_addresses[0].present_email', '')
      setTouched({
        'form_addresses[0].present_province': false,
        'form_addresses[0].present_zone': false,
        'form_addresses[0].present_district': false,
        'form_addresses[0].present_municipality': false,
        'form_addresses[0].present_city': false,
        'form_addresses[0].present_ward_number': false,
        'form_addresses[0].present_street_name': false,
        'form_addresses[0].present_house_number': false,
        'form_addresses[0].present_mobile_number': false,
        'form_addresses[0].present_fax_number': false,
        'form_addresses[0].present_email': false,
      })
    }
  }
  const {
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: false,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  useEffect(() => {
    let individualData = allMasterDataOptions?.account_types?.find(
      (type: any) => type?.cbs_code === updatePreliminariesDetails?.data?.account_type_id?.cbs_code
    )?.id
    if (individualData && !masterLoading && !isLoading) {
      masterDataFilter(individualData)
    }
  }, [masterLoading, isLoading])
  const {data: zoneData} = useGetAllZoneData()
  const [zoneCode, setZoneCode] = useState<string | null>(null)
  const [districtData, setDistrictData] = useState<any>(null)

  useEffect(() => {
    if (zoneCode) {
      const fetchDistrictData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/district`, {
              params: {zoneCode},
            })
            .then((res: any) => {
              setDistrictData(res?.data)
            })
          // setDistrictData(data) // Store the district data in state
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchDistrictData()
    }
  }, [zoneCode])
  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(id ? id : generatedID)
  }, [regenrateID, id])

  const {mutate: generateOtp, isLoading: generateOtpLoading} = usePostToGenerateCode()
  const {data: riskData} = useGetAllRiskCategoriesList()
  const [riskCode, setRiskCode] = useState<string | null>(null)
  const [riskSubCat, setRiskSubCat] = useState<any>(null)
  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)
  const queryClient = useQueryClient()

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)

  useEffect(() => {
    if (riskCode) {
      fetchRiskData()
    }
  }, [riskCode])
  const fetchRiskData = async () => {
    try {
      await axios
        .get(`${BASE_API_URL}/risk-sub-categories`, {
          params: {risk_category_id: riskCode},
        })
        .then((res: any) => {
          setRiskSubCat(res?.data)
        })
    } catch (error) {
      console.error('Error fetching district data:', error)
    }
  }
  const getSaluationData = (id: string) => {
    masterDataFilter(id)
  }
  const onMarriageChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    setFieldValue('spouse_name', '')
    setFieldValue('father_in_law', '')
    setTouched({
      spouse_name: false,
      father_in_law: false,
    })
  }

  useEffect(() => {
    !isLoading && setValidationCheck(updatePreliminariesDetails?.data?.account_type_id?.id)
  }, [updatePreliminariesDetails])

  const initialValues = {
    opening_mode: 'normal',
    branch_code: updatePreliminariesDetails?.data?.branch_code || '',
    customer_code: updatePreliminariesDetails?.data?.customer_code || '',
    action: '',
    account_type_id: updatePreliminariesDetails?.data?.account_type_id?.id || '',
    salutation: updatePreliminariesDetails?.data?.salutation || '',
    first_name: updatePreliminariesDetails?.data?.first_name || '',
    middle_name: updatePreliminariesDetails?.data?.middle_name || '',
    last_name: updatePreliminariesDetails?.data?.last_name || '',
    full_name: updatePreliminariesDetails?.data?.full_name || '',
    short_name: updatePreliminariesDetails?.data?.short_name || '',
    gender: updatePreliminariesDetails?.data?.gender || '',
    marital_status: updatePreliminariesDetails?.data?.marital_status?.id || '',
    date_of_birth_ad: updatePreliminariesDetails?.data?.date_of_birth_ad || '',
    date_of_birth_bs: updatePreliminariesDetails?.data?.date_of_birth_bs || '',
    nationality_id:
      updatePreliminariesDetails?.data?.nationality_id?.id ||
      allMasterDataOptions?.nationalities?.find((item: any) => item?.code === 'NP')?.id,
    refugee_details: updatePreliminariesDetails?.data?.refugee_details,
    id_type_id: updatePreliminariesDetails?.data?.id_type_id?.id || '',
    identification_number: updatePreliminariesDetails?.data?.identification_number || '',
    father_in_law: updatePreliminariesDetails?.data?.father_in_law || '',
    issuing_office: null,
    id_issued_date: updatePreliminariesDetails?.data?.id_issued_date || '',
    id_issued_date_ad: updatePreliminariesDetails?.data?.id_issued_date_ad || '',
    id_expiry_date: updatePreliminariesDetails?.data?.id_expiry_date || '',
    issuing_district: null,

    recommended_local_body: updatePreliminariesDetails?.data?.recommended_local_body || '',
    recommended_date: '',
    account_scheme_id: '',
    father_name: updatePreliminariesDetails?.data?.father_name || '',
    spouse_name: updatePreliminariesDetails?.data?.spouse_name || '',
    grandfather_name: updatePreliminariesDetails?.data?.grandfather_name || '',
    blacklist_screening: false,
    sanction_screening: false,
    aml_risk_rating: updatePreliminariesDetails?.data?.aml_risk_rating?.id || '',
    sub_risk_category_id: updatePreliminariesDetails?.data?.sub_risk_category_id?.id || '',
    national_id_number: updatePreliminariesDetails?.data?.national_id_number || '',
    id_expiry_date_ad: updatePreliminariesDetails?.data?.id_expiry_date_ad || '',
    occupation_id: updatePreliminariesDetails?.data?.occupation_id?.id || '',
    other_occupation: updatePreliminariesDetails?.data?.other_occupation || '',
    income_source_id: updatePreliminariesDetails?.data?.income_source_id?.id || '',
    other_income_source: updatePreliminariesDetails?.data?.other_income_source || '',
    total_annual_income: updatePreliminariesDetails?.data?.total_annual_income?.id || '',
    expected_annual_turnover: updatePreliminariesDetails?.data?.expected_annual_turnover || '',
    guardian_id_number: updatePreliminariesDetails?.data?.guardian_id_number || '',
    guardian_cif_number: updatePreliminariesDetails?.data?.guardian_cif_number || '',
    guardian_first_name: updatePreliminariesDetails?.data?.guardian_first_name || '',
    guardian_middle_name: updatePreliminariesDetails?.data?.guardian_middle_name || '',
    guardian_last_name: updatePreliminariesDetails?.data?.guardian_last_name || '',
    guardian_relationship_id: updatePreliminariesDetails?.data?.guardian_relationship_id?.id || '',
    guardian_relationship_other:
      updatePreliminariesDetails?.data?.guardian_relationship_other || '',
    guardian_address: updatePreliminariesDetails?.data?.guardian_address || '',
    minor_certificate_number: updatePreliminariesDetails?.data?.minor_certificate_number || '',
    minor_certificate_issue_date_ad:
      updatePreliminariesDetails?.data?.minor_certificate_issue_date_ad || '',
    minor_certificate_issue_date_bs:
      updatePreliminariesDetails?.data?.minor_certificate_issue_date_bs || '',
    certificate_issued_by: updatePreliminariesDetails?.data?.certificate_issued_by?.id || '',
    account_holders: updatePreliminariesDetails?.data?.account_holders?.account_holders?.map(
      (holder: any) => ({
        first_name: holder?.first_name || '',
        middle_name: holder?.middle_name || '',
        last_name: holder?.last_name || '',
        cif_number: holder?.cif_number || '',
        id_number: holder?.id_number || '',
        id_type_id: holder?.id_type_id?.id || '', // assuming id_type_id is an object with an id field
      })
    ) || [
      {
        first_name: '',
        middle_name: '',
        last_name: '',
        cif_number: '',
        id_number: '',
        id_type_id: '',
      },
    ],
    form_addresses: [
      {
        // permanent_country: '',
        // permanent_province: '',
        // permanent_zone: '',
        // permanent_district: '',
        // permanent_municipality: '',
        // permanent_ward_number: '',
        // permanent_city: '',
        // permanent_street_name: '',
        // permanent_house_number: '',
        // permanent_fax_number: '',
        // permanent_mobile_number: '',
        // permanent_email: '',
        // permanent_google_plus_code: '',
        // permanent_outside_country_post_box: '',
        // permanent_outside_country_full_address: '',
        // is_present_address_different: false,
        present_country:
          updatePreliminariesDetails?.data?.present_country?.id ||
          allMasterDataOptions?.countries?.find((item: any) => item?.cbs_code === 'NP')?.id,
        present_province: updatePreliminariesDetails?.data?.present_province?.id || '',
        present_zone: updatePreliminariesDetails?.data?.present_zone?.id || '',
        present_city: updatePreliminariesDetails?.data?.present_city?.id || '',
        present_district: updatePreliminariesDetails?.data?.present_district?.id || '',
        present_municipality: updatePreliminariesDetails?.data?.present_municipality?.id || '',
        present_ward_number: updatePreliminariesDetails?.data?.present_ward_number || '',
        present_street_name: updatePreliminariesDetails?.data?.present_street_name || '',
        present_house_number: updatePreliminariesDetails?.data?.present_house_number || '',
        present_fax_number: updatePreliminariesDetails?.data?.present_fax_number || '',
        present_mobile_number: updatePreliminariesDetails?.data?.present_mobile_number || '',
        present_email: updatePreliminariesDetails?.data?.present_email || '',
        present_google_plus_code: updatePreliminariesDetails?.data?.present_google_plus_code || '',
        present_outside_country_post_box:
          updatePreliminariesDetails?.data?.present_outside_country_post_box,
        present_outside_country_full_address:
          updatePreliminariesDetails?.data?.present_outside_country_full_address,
      },
    ],
  }

  const [enquiryData, setEnquiryData] = useState<any>()
  const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)
  const transformValues = (values: any) => {
    const isJointAccount =
      values?.account_type_id &&
      allMasterDataOptions?.account_types?.some(
        (type: {id: string; title: string}) =>
          type.id === values.account_type_id && type.title === 'Joint'
      )

    return {
      account_holders: values?.account_holders?.map((holder: any) => ({
        ...holder,
      })),
    }
  }
  const handleFormSubmit = (values: any, actions: any) => {
    const fullName = [values.first_name, values.middle_name, values.last_name]
      .filter(Boolean) // Remove empty strings
      .join(' ')
    const userFullName = fullName
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, ' ')
      .trim()
    const generatedShortName = userFullName?.slice(0, 10)

    const capitalizeName = (name: string) => {
      return name.toLowerCase().replace(/\b\w/g, (char: string) => char.toUpperCase())
    }
    const payload = {
      ...values,
      id,
      action: btnFlag === 'save' ? 'save' : 'save_and_continue',
      full_name: userFullName?.toUpperCase() || '',
      short_name: generatedShortName?.toUpperCase() || '',
      first_name: values?.first_name?.toUpperCase() || '',
      middle_name: values?.middle_name ? values?.middle_name?.toUpperCase() : '',
      last_name: values?.last_name?.toUpperCase() || '',
      // father_name: values?.father_name ? capitalizeName(values?.father_name) : '',
      // spouse_name: values?.spouse_name ? capitalizeName(values?.spouse_name) : '',
      // father_in_law: values?.father_in_law ? capitalizeName(values?.father_in_law) : '',
      // grandfather_name: values?.grandfather_name ? capitalizeName(values?.grandfather_name) : '',
      // guardian_first_name: values?.guardian_first_name
      //   ? capitalizeName(values?.guardian_first_name)
      //   : '',
      // guardian_middle_name: values?.guardian_middle_name
      //   ? capitalizeName(values?.guardian_middle_name)
      //   : '',
      // guardian_last_name: values?.guardian_last_name
      //   ? capitalizeName(values?.guardian_last_name)
      //   : '',
      // guardian_address: values?.guardian_address ? capitalizeName(values?.guardian_address) : '',
      // other_occupation: values?.other_occupation ? capitalizeName(values?.other_occupation) : '',
      // other_income_source: values?.other_income_source
      //   ? capitalizeName(values?.other_income_source)
      //   : '',
      date_of_birth_ad: values?.date_of_birth_ad
        ? moment(values?.date_of_birth_ad).format('YYYY-MM-DD')
        : '',
      id_expiry_date_ad: values?.id_expiry_date_ad
        ? moment(values?.id_expiry_date_ad).format('YYYY-MM-DD')
        : '',
      id_issued_date_ad: values?.id_issued_date_ad
        ? moment(values?.id_issued_date_ad).format('YYYY-MM-DD')
        : '',
      minor_certificate_issue_date_ad: values?.minor_certificate_issue_date_ad
        ? moment(values?.minor_certificate_issue_date_ad).format('YYYY-MM-DD')
        : '',
      verification_type: verificationType,
      form_addresses: {...values?.form_addresses?.[0]},
      // form_addresses: {
      //   ...values?.form_addresses?.[0],
      //   present_street_name: capitalizeName(values?.form_addresses?.[0]?.present_street_name) || '',
      //   present_outside_country_full_address: values?.form_addresses?.[0]
      //     ?.present_outside_country_full_address
      //     ? capitalizeName(values?.form_addresses?.[0]?.present_outside_country_full_address)
      //     : '',
      // },
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }

    const additionalFormPayload: any = transformValues(values)

    updatePreliminariesAccountDetails(payload, {
      onSuccess: () => {
        history.push('/new-cif-new-accounts')
        refetch()
        queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')

        // if (btnFlag === 'save') {
        //   toast.success('Saved successfully.')
        // } else {
        //   toast.success('Submitted to Branch Checker.')
        // }
        actions.setSubmitting(false)
        const checkAccountType = allMasterDataOptions?.account_types?.find(
          (type: {title: string; id: string}) => type.id === values.account_type_id
        )
        if (checkAccountType?.title?.toLowerCase() === 'joint') {
          // const id: any = localStorage.getItem('accountId')
          postAddtionalPartner(
            {
              id: id,
              additionalFormPayload,
            },
            {
              onSuccess: () => {
                history.push('/new-cif-new-accounts')
                actions.setSubmitting(false)
              },
            }
          )
        }
      },
      onError: (error) => {
        actions.setSubmitting(false)
        toast.error('Failed')
      },
    })
    actions.setSubmitting(false)
  }
  const handleButtonClick = (flag: string) => {
    setBtnFlag(flag)
  }

  const getMunicipilities = (id: string, setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue('form_addresses[0].present_district', response?.data?.district?.id)
        setFieldValue('form_addresses[0].present_zone', response?.data?.district?.zone?.id)
        setFieldValue('form_addresses[0].present_province', response?.data?.district?.province?.id)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }
  const allValidationSchema = () => {
    switch (validationCheck) {
      case allMasterDataOptions?.account_types?.find((item: any) => item?.cbs_code === 'Individual')
        ?.id: //checking individual
        return btnFlag === 'save' ? undefined : SaveAndContinueValidation(allMasterDataOptions)
      case allMasterDataOptions?.account_types?.find((item: any) => item?.cbs_code === 'Minor')?.id: //Checking Minor
        // return btnFlag === 'save' ? SaveAndContinueValidationMinor : saveValidationsMinor
        return btnFlag === 'save' ? undefined : SaveAndContinueValidationMinor(allMasterDataOptions)
      default:
        // return btnFlag !== 'save' ? SaveAndContinueValidation : saveValidations
        return btnFlag === 'save' ? undefined : SaveAndContinueValidation(allMasterDataOptions)
    }
  }

  const [showCIFCheck, setShowCIFCheck] = useState(false)

  const handleScreeningClick = () => {
    setShowCIFCheck((prevState) => !prevState)
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }

  const getZoneCode = (e: any, values: any) => {
    const presentZone = e
    const zoneList = zoneData?.data?.zone
    const matchingZone = zoneList?.find((zone: any) => zone?.id === presentZone)
    if (matchingZone) {
      setZoneCode(matchingZone.code)
    } else {
      console.log('No matching zone found.')
    }
  }

  const getSubRiskCode = (id: string, setFieldValue: any) => {
    const selectedSubCategory = allMasterDataOptions?.risk_sub_categories?.find(
      (option: any) => option?.id === id
    )
    const riskCategoryId = selectedSubCategory?.risk_category?.id || ''
    // const riskCategoryTitle = selectedSubCategory?.risk_category?.title || 'null'
    const riskCategoryTitle = selectedSubCategory?.title || 'null'

    setFieldValue('aml_risk_rating', riskCategoryId)
    if (riskCategoryTitle == 'Others') {
      setISDisabled(false)
    } else if (riskCategoryTitle == 'null') {
      setISDisabled(false)
    } else {
      setISDisabled(true)
    }
  }

  const openVerificationModal = (type: any) => {
    setVerificationType(type)
    setIsVerificationModalOpen(true)
    if (type === 'email_verification') {
      generateOtp({
        payload: {
          type: 'email_verification',
          case: 'update',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: '',
        },
      })
    } else if (type === 'mobile_verification') {
      generateOtp({
        payload: {
          type: 'mobile_verification',
          case: 'update',
          temp_customer_id: uniqueID,
          email: '',
          mobile_number: verificationDetails?.mobile,
        },
      })
    } else {
      generateOtp({
        payload: {
          type: 'both',
          case: 'update',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: verificationDetails?.mobile,
        },
      })
    }
  }

  const handleReset = (setFieldValue: (field: string, value: any) => void) => {
    setcheckAllDataMinor(undefined) // Reset the checkAllData state
    setFieldValue('guardian_cif_number', '')
    setFieldValue('guardian_first_name', '')
    setFieldValue('guardian_middle_name', '')
    setFieldValue('guardian_last_name', '')
    setFieldValue('guardian_id_number', '')
    setFieldValue('guardian_address', '')
  }
  return (
    <>
      <div>
        {isLoading || masterLoading ? (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body text-center '>
              <h3>
                <Spinner animation='border' variant='danger' className='mb-3' />
                <br />
                Please Wait...
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className=''>
              {/* <div className='text-end'>
                <Button onClick={handleScreeningClick} className='mb-3'>
                  {showCIFCheck ? 'Hide CIF Check' : 'Show CIF Check'}
                </Button>
              </div> */}
              {/* {showCIFCheck && ( */}
              <CIFCheck
                mutateCheckAll={mutateCheckAll}
                model_name='NewCIFNewAccount'
                checkAllData={checkAllData}
                handleOpenPdf={handleOpenPdf}
                setScreeningPerformed={setScreeningPerformed}
              />
              {/* )} */}
            </div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
              validationSchema={allValidationSchema()}
              validateOnChange
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                isSubmitting,
                setTouched,
              }) => {
                const otpVerification = (type: any) => {
                  setVerificationType(type)
                  setIsVerificationModalOpen(true)
                  if (type === 'email_verification') {
                    generateOtp({
                      payload: {
                        type: 'email_verification',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: values?.form_addresses[0].present_email,
                        mobile_number: '',
                      },
                    })
                  } else if (type === 'mobile_verification') {
                    generateOtp({
                      payload: {
                        type: 'mobile_verification',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: '',
                        mobile_number: values?.form_addresses[0].present_mobile_number,
                      },
                    })
                  } else {
                    generateOtp({
                      payload: {
                        type: 'both',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: values?.form_addresses[0].present_email,
                        mobile_number: values?.form_addresses[0].present_mobile_number,
                      },
                    })
                  }
                }
                useEffect(() => {
                  if (enquiryData) {
                    const data: any = enquiryData

                    const checkAllPayload: any = {
                      customer_name: data?.data?.generalDetails[0]?.CUST_NAME,
                      date_of_birth_bs: data?.data?.generalDetails[0]?.DATE_OF_BIRTH,
                      unique_id_no: data?.data?.generalDetails[0]?.CUST_ID,
                      citizenship: data?.data?.generalDetails[0]?.IDNO,
                      gender: data?.data?.generalDetails[0]?.CUST_SEX,
                      model_name: 'NewCIFNewAccount',
                    }

                    if (update.current === 'minor') {
                      mutateCheckAll(checkAllPayload, {
                        onSuccess: (response) => {
                          update.current = 'default' // Reset after success
                        },
                        onError: (error) => {
                          update.current = 'default' // Reset after error
                        },
                      })
                    }
                    const fullName = data?.data?.generalDetails[0]?.CUST_NAME || ''

                    const nameParts = fullName.split(' ')

                    const first_name = nameParts[0] || '' // First part as first name
                    const middle_name = nameParts.length > 2 ? nameParts.slice(1, -1).join(' ') : '' // Middle parts as middle name
                    const last_name = nameParts[nameParts.length - 1] || '' // Last part as last name

                    setFieldValue(`guardian_first_name`, first_name || '')
                    setFieldValue(`guardian_middle_name`, middle_name || '')
                    setFieldValue(`guardian_last_name`, last_name || '')

                    const enteredCifNumber = values.guardian_cif_number

                    setFieldValue(`guardian_cif_number`, enteredCifNumber)

                    const idType = data?.data?.generalDetails[0]?.IDTYPE
                    const matchedOption = allMasterDataOptions?.id_types?.find(
                      (option: any) => option.code === idType
                    )
                    if (matchedOption) {
                      setFieldValue(`id_type_id`, matchedOption.id)
                    }

                    setFieldValue(`guardian_id_number`, data?.data?.generalDetails[0]?.IDNO || '')
                    setFieldValue(
                      `guardian_address`,
                      data?.data?.addressInfo?.permanentAddress?.ADDRESS1 || ''
                    )
                  }
                }, [enquiryData, setFieldValue])

                const handleEnquiry = (values: any) => {
                  if (values) {
                    customerEnquiry({values})
                  }
                  update.current = 'minor'
                  setScreeningPerformed(true)
                }
                return (
                  <Spin spinning={masterFilterLoading}>
                    <Form className='bg-white'>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body border-top '>
                          <div className='d-flex border-bottom mb-4 pb-2'>
                            <h1 className=' heading__component__title fw-bolder text-uppercase'>
                              {OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE}
                            </h1>
                            <span>{OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_SUB_TITLE}</span>
                          </div>
                          <div className=''>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Branch Code'
                                  name='branch_code'
                                  label='Branch Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Customer Code'
                                  name='customer_code'
                                  label='Customer Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <section className='py-3'>
                            <div className='row'>
                              <div className='col-md-6 col-xs-12 mb-5'>
                                <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                  Select Account Type
                                </h1>
                                <div className='d-flex'>
                                  {isLoading ? (
                                    <Spinner animation={'border'} />
                                  ) : (
                                    allMasterDataOptions?.account_types
                                      ?.filter((type: {title: string}) =>
                                        ['Individual', 'Joint', 'Minor']?.includes(type.title)
                                      )
                                      ?.map(({id, title}: any) => (
                                        <FormRadio
                                          key={id}
                                          containerClassName=''
                                          label='Account Type'
                                          name='account_type_id'
                                          type='radio'
                                          onChange={(e) => {
                                            handleChange(e)
                                            getSaluationData(e.target.value)
                                            setValidationCheck(e.target.value)

                                            if (title === 'Minor') {
                                              // Find the correct ID for 'UNMARRIED' status
                                              const unmarriedStatus =
                                                allMasterDataOptions?.marital_statuses?.find(
                                                  (status: any) =>
                                                    status.title === 'UNMARRIED' ||
                                                    status.label === 'UNMARRIED'
                                                )
                                              const birthStatus =
                                                allMasterDataOptions?.id_types?.find(
                                                  (status: any) =>
                                                    status.title === 'BIRTH CERTIFICATE' ||
                                                    status.label === 'BIRTH CERTIFICATE'
                                                )
                                              if (unmarriedStatus && birthStatus) {
                                                setFieldValue(
                                                  'marital_status',
                                                  unmarriedStatus.id || unmarriedStatus.value
                                                )
                                                setFieldValue(
                                                  'id_type_id',
                                                  birthStatus.id || birthStatus.value
                                                )
                                              }
                                            }
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          required
                                          value={id}
                                          checkBoxText={title}
                                          checked={values.account_type_id === id}
                                        />
                                      ))
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                          <hr />

                          <section className='py-3'>
                            <div className='row'>
                              <div className='col-md-12 col-xs-12 mb-5'>
                                <h1 className=' heading__component__title fw-bolder text-uppercase required'>
                                  Salutation
                                </h1>
                                {isLoading ? (
                                  <Spinner animation={'border'} />
                                ) : (
                                  <>
                                    <div className='d-flex flex-wrap'>
                                      {masterFilterData?.salutations?.map(({id, title}: any) => (
                                        <div key={id} className='col-md-3 col-xs-6 mb-2'>
                                          <FormRadio
                                            containerClassName='me-2'
                                            label='Salutation'
                                            name='salutation'
                                            type='radio'
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            required
                                            value={title}
                                            checked={values.salutation === title}
                                            checkBoxText={title}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                    <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
                                      <ErrorMessage
                                        name='salutation'
                                        component='div'
                                        className='field-error-message'
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </section>
                          <hr />
                          <section className='py-3'>
                            <h1 className=' heading__component__title fw-bolder text-uppercase'>
                              A. Customer Name:
                            </h1>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='First Name'
                                  name='first_name'
                                  label='First Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Middle Name'
                                  name='middle_name'
                                  label='Middle Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Last Name'
                                  name='last_name'
                                  label='Last Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  // required
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                />
                              </div>
                            </div>

                            <div className='row'>
                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                B. Gender:
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Gender'
                                  name='gender'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.genders?.map((gender: any) => ({
                                    value: gender.title || gender.value,
                                    label: gender.title || gender.label,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                            </div>
                            <h1 className=' heading__component__title fw-bolder text-uppercase'>
                              C. Marital Status:
                            </h1>
                            <div className='row'>
                              <div className='row'>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-md-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select'
                                    label='Marital Status'
                                    name='marital_status'
                                    onChange={(e: any) => {
                                      handleChange(e)
                                      onMarriageChange(e, setFieldValue, setTouched)
                                    }}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    options={allMasterDataOptions?.marital_statuses?.map(
                                      (status: any) => ({
                                        value: status.id || status.value,
                                        label: status.title || status.label,
                                      })
                                    )}
                                    setFieldValue={setFieldValue}
                                    required
                                    disabled={
                                      allMasterDataOptions?.account_types?.find(
                                        (type: any) => type.id === values.account_type_id
                                      )?.title === 'Minor'
                                    }
                                  />
                                </div>
                                {allMasterDataOptions?.marital_statuses
                                  ?.find((status: any) => status.id === values.marital_status)
                                  ?.title.toLowerCase() === 'married' && (
                                  <>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormTextBox
                                        // uppercase={true}
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        name='spouse_name'
                                        label='Spouse Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        required
                                        placeholder={'Enter spouse Name'}
                                        maxLength={50}
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        // uppercase={true}
                                        type='text'
                                        name='father_in_law'
                                        label='Father in Law Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        placeholder={'Enter Father In Law Name'}
                                        maxLength={50}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className='row'>
                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                D. Date Of Birth:
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='date_of_birth_ad'
                                  nepaliFormName='date_of_birth_bs'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Date of Birth(BS)'
                                  required={
                                    values?.nationality_id ===
                                    allMasterDataOptions?.nationalities?.find(
                                      (item: any) => item?.code === 'NP'
                                    )?.id
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                {/* <FormTextBox
                               // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='date'
                                name='date_of_birth_ad'
                                label='Date of Birth(AD)'
                                onChange={(e: any) => {
                                  const formattedDate = moment(e.target.value).format('YYYY-MM-DD')
                                  handleChange(e)
                                  setFieldValue('date_of_birth_bs', formattedDate)
                                  setFieldValue(
                                    'date_of_birth_bs',
                                    e.target.value && adToBs(e.target.value)
                                  )
                                }}
                                preventFutureDate={true}
                                errors={errors}
                                // preventFutureDate={true}
                                touched={touched}
                                required={
                                  values?.nationality_id ===
                                  allMasterDataOptions?.nationalities?.find(
                                    (item: any) => item?.code === 'NP'
                                  )?.id
                                    ? true
                                    : false
                                }
                              /> */}
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  label='Date of Birth(AD)'
                                  name='date_of_birth_ad'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.date_of_birth_ad || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  // disabled={values.start_date ? false : true}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'date_of_birth_bs'}
                                />
                              </div>

                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                D. Nationality:
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Nationality'
                                  name='nationality_id'
                                  values={values}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.nationalities?.map(
                                    (nationality: any) => ({
                                      value: nationality.id || nationality.value,
                                      label: nationality.title || nationality.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Others'
                                  name='refugee_details'
                                  values={values}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={[
                                    {label: 'REFUGEES', value: 'REFUGEES'},
                                    {
                                      label: 'NON CITIZENSHIP HOLDER',
                                      value: 'NON_CITIZENSHIP_HOLDER',
                                    },
                                  ]}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Identification'
                                  name='id_type_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.id_types?.map(
                                    (identityType: any) => ({
                                      value: identityType?.id || identityType?.value,
                                      label: identityType?.title || identityType?.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                  disabled={
                                    allMasterDataOptions?.account_types?.find(
                                      (type: any) => type.id === values.account_type_id
                                    )?.title === 'Minor'
                                  }
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='ID Number'
                                  name='identification_number'
                                  label='Identification Number'
                                  onChange={(e: any) => {
                                    handleChange(e)
                                    const selectedAccountType =
                                      allMasterDataOptions?.account_types?.find(
                                        (account_type: any) =>
                                          account_type.id === values?.account_type_id
                                      )

                                    if (selectedAccountType?.title?.toLowerCase() === 'minor') {
                                      setFieldValue('minor_certificate_number', e?.target?.value),
                                        handleChange(e)
                                    }
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  maxLength={15}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='National Id'
                                  name='national_id_number'
                                  label='National Id Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  maxLength={11}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='id_issued_date_ad'
                                  nepaliFormName='id_issued_date'
                                  setFieldValue={setFieldValue}
                                  onChange={(date: any) => {
                                    // Check if the date is valid
                                    if (!date) return

                                    // Date in BS (Nepali)
                                    const formattedDateBS = moment(date).format('YYYY-MM-DD')

                                    // Check if the account type is 'minor'
                                    const selectedAccountType =
                                      allMasterDataOptions?.account_types?.find(
                                        (account_type: any) =>
                                          account_type.id === values?.account_type_id
                                      )
                                    const convertedToAd = bsToAd(formattedDateBS)

                                    if (selectedAccountType?.title?.toLowerCase() === 'minor') {
                                      // Convert BS to AD using your library's function (assuming bsToAd exists)
                                      const convertedToAd = bsToAd(formattedDateBS)

                                      // Update both BS and AD fields with formatted dates
                                      setFieldValue(
                                        'minor_certificate_issue_date_bs',
                                        formattedDateBS
                                      )
                                      setFieldValue(
                                        'minor_certificate_issue_date_ad',
                                        convertedToAd
                                      )

                                      // Update id_issued_date fields based on changes in the minor certificate issued date
                                      setFieldValue('id_issued_date', formattedDateBS)
                                      setFieldValue('id_issued_date_ad', convertedToAd)
                                    } else {
                                      // If not a minor, just update the issued date in BS
                                      setFieldValue(
                                        'minor_certificate_issue_date_bs',
                                        formattedDateBS
                                      )
                                      setFieldValue('id_issued_date_ad', convertedToAd)
                                    }
                                  }}
                                  touched={touched}
                                  values={values}
                                  label='Issued Date (BS)'
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='id_issued_date_ad'
                                  label='Issued Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.id_issued_date_ad || false}
                                  // minDate={values?.start_date}
                                  onChange={(date: any) => {
                                    // Check if the date is valid
                                    if (!date) return

                                    // Date in AD (English)
                                    const formattedDateAD = moment(date).format('YYYY-MM-DD')

                                    // Check if the account type is 'minor'
                                    const selectedAccountType =
                                      allMasterDataOptions?.account_types?.find(
                                        (account_type: any) =>
                                          account_type.id === values?.account_type_id
                                      )

                                    if (selectedAccountType?.title?.toLowerCase() === 'minor') {
                                      // Convert AD to BS using your library's function
                                      const convertedToBs = adToBs(formattedDateAD)

                                      // Update both AD and BS fields with formatted dates
                                      setFieldValue('id_issued_date_ad', formattedDateAD)
                                      setFieldValue('id_issued_date', convertedToBs)

                                      // Update minor certificate fields based on changes in the issued date
                                      setFieldValue(
                                        'minor_certificate_issue_date_ad',
                                        formattedDateAD
                                      )
                                      setFieldValue(
                                        'minor_certificate_issue_date_bs',
                                        convertedToBs
                                      )
                                    } else {
                                      // If not a minor, just update the issued date in AD
                                      setFieldValue('id_issued_date_ad', formattedDateAD)
                                    }
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  // disabled={values.start_date ? false : true}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'id_issued_date'}
                                />
                              </div>

                              {/* <div className='row'> */}
                              {!(
                                allMasterDataOptions?.id_types
                                  ?.find(
                                    (account_types: any) => account_types.id === values.id_type_id
                                  )
                                  ?.title.toLowerCase() === 'citizenship' ||
                                allMasterDataOptions?.id_types
                                  ?.find(
                                    (account_type: any) => account_type.id === values.id_type_id
                                  )
                                  ?.title.toLowerCase() === 'pan card' ||
                                allMasterDataOptions?.id_types
                                  ?.find(
                                    (account_type: any) => account_type.id === values.id_type_id
                                  )
                                  ?.title.toLowerCase() === 'birth certificate'
                              ) && (
                                <>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormNepaliCalendarDatePicker
                                      englishFormName='id_expiry_date_ad'
                                      nepaliFormName='id_expiry_date'
                                      setFieldValue={setFieldValue}
                                      touched={touched}
                                      values={values}
                                      label='Expiry Date (BS)'
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    {/* <FormTextBox
                                   // uppercase={true}
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='date'
                                    name='id_expiry_date_ad'
                                    label='Expiry Date (AD)'
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        'id_expiry_date',
                                        e.target.value && adToBs(e.target.value)
                                      )
                                      handleChange(e)
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    required
                                  /> */}
                                    <FormDatepicker
                                      labelClassName='col-12'
                                      containerClassName='col-12 '
                                      name='id_expiry_date_ad'
                                      label='Expiry Date (AD)'
                                      placeholderText='YYYY-MM-DD'
                                      setFieldValue={setFieldValue}
                                      value={values?.id_expiry_date_ad || false}
                                      // minDate={values?.start_date}
                                      errors={errors}
                                      touched={touched}
                                      showIcon={true}
                                      // disabled={values.start_date ? false : true}
                                      required
                                      // isClearable={isEndDateNotRequired}
                                      data-cy='end-date-drop-down'
                                      data={'id_expiry_date'}
                                    />
                                  </div>
                                </>
                              )}
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Risk Rating'
                                  label='Risk Rating '
                                  name='aml_risk_rating'
                                  // onChange={(w: any) => {
                                  //   handleChange(w)
                                  //   getRiskCode(w, values)
                                  // }}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.risk_categories?.map(
                                    (option: any) => ({
                                      value: option?.id || option.value,
                                      label: option?.title || option.label,
                                    })
                                  )}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  required
                                  disabled={isDisabled}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Risk Rating SubCategory'
                                  label='Risk Rating SubCategory'
                                  name='sub_risk_category_id'
                                  // onChange={handleChange}
                                  onChange={(w: any) => {
                                    handleChange(w)
                                    getSubRiskCode(w, setFieldValue)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions.risk_sub_categories?.map(
                                    (option: any) => ({
                                      value: option?.id || option?.value,
                                      label: option?.title || option?.label,
                                    })
                                  )}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder="GrandFather's Name"
                                  name='grandfather_name'
                                  label="GrandFather's Name"
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                  maxLength={50}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder="Father's Name"
                                  name='father_name'
                                  label="Father's Name"
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  // uppercase={true}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </section>
                          <hr />
                          {/* Guardians In Case of Minor */}
                          {allMasterDataOptions?.account_types
                            ?.find(
                              (account_types: any) => account_types.id === values?.account_type_id
                            )
                            ?.title.toLowerCase() === 'minor' && (
                            <>
                              <section className='py-3'>
                                <div className='row'>
                                  <h2 style={{color: 'brown'}}>
                                    Guardian's Details <i>(in case of minor)</i>{' '}
                                  </h2>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      // uppercase={true}
                                      placeholder='Guardian CIF Number'
                                      name='guardian_cif_number'
                                      label='Guardian CIF Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={9}
                                      required
                                    />
                                    {checkAllDataMinor ? (
                                      <Button
                                        className='mb-5 ms-3'
                                        onClick={() => {
                                          handleOpenPdf2()
                                        }}
                                      >
                                        View PDF
                                      </Button>
                                    ) : pdfLoading ? (
                                      <Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </Spinner>
                                    ) : (
                                      <Button
                                        className='mb-5'
                                        onClick={() => {
                                          handleEnquiry(values.guardian_cif_number)
                                        }}
                                      >
                                        Enquiry
                                      </Button>
                                    )}
                                    <div>
                                      <Button
                                        type='button'
                                        onClick={() => handleReset(setFieldValue)}
                                        className='btn btn-secondary'
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      // uppercase={true}
                                      placeholder='Guardian Identification Number'
                                      name='guardian_id_number'
                                      label='Guardian Identification Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      maxLength={15}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian First Name'
                                      name='guardian_first_name'
                                      label='Guardian First Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Middle Name'
                                      name='guardian_middle_name'
                                      label='Guardian Middle Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Last Name'
                                      name='guardian_last_name'
                                      label='Guardian Last Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                      // required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-md-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select'
                                      label='Guardian Relationship'
                                      name='guardian_relationship_id'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={allMasterDataOptions?.guardian_relationships?.map(
                                        (guardian_relationship_id: any) => ({
                                          value:
                                            guardian_relationship_id?.id ||
                                            guardian_relationship_id?.value,
                                          label:
                                            guardian_relationship_id?.title ||
                                            guardian_relationship_id?.label,
                                        })
                                      )}
                                      setFieldValue={setFieldValue}
                                      required
                                    />
                                  </div>
                                </div>
                                {allMasterDataOptions?.relationships
                                  ?.find(
                                    (relationships: any) =>
                                      relationships.id === values.guardian_relationship_id
                                  )
                                  ?.title.toLowerCase() === 'others' && (
                                  <>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormTextBox
                                        // uppercase={true}
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        name='guardian_relationship_other'
                                        label='Guardian Relationship Other Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        required
                                        maxLength={50}
                                        placeholder={'Guardian Relationship Other Name'}
                                      />
                                    </div>
                                  </>
                                )}
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Address'
                                      name='guardian_address'
                                      label='Guardian Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      maxLength={50}
                                      touched={touched}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Certificate Issued By'
                                      label='Certificate Issued By'
                                      name={`certificate_issued_by`}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={allMasterDataOptions?.individual_unique_ids?.map(
                                        (certificateIssue: any) => ({
                                          value: certificateIssue.id || certificateIssue.value,
                                          label: certificateIssue.title || certificateIssue.label,
                                        })
                                      )}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                    />
                                  </div>
                                </div>

                                <div className='row'>
                                  <input
                                    type='hidden'
                                    name='minor_certificate_number'
                                    value={values.minor_certificate_number}
                                  />
                                  <input
                                    type='hidden'
                                    name='minor_certificate_issue_date_ad'
                                    value={values.minor_certificate_issue_date_ad}
                                  />
                                  <input
                                    type='hidden'
                                    name='minor_certificate_issue_date_bs'
                                    value={values.minor_certificate_issue_date_bs}
                                  />
                                </div>
                              </section>
                              <hr />
                            </>
                          )}
                          <div className='row'>
                            {allMasterDataOptions?.account_types
                              ?.find(
                                (account_types: any) => account_types.id === values?.account_type_id
                              )
                              ?.title.toLowerCase() === 'joint' && (
                              <>
                                <div className='row'>
                                  <div>
                                    <h2 style={{color: 'brown'}}>
                                      Partner's Details Section <i>(In case of Joint Account)</i>
                                    </h2>
                                  </div>
                                  <PartnersDetailsFormJoint
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    enquiryData={enquiryData}
                                    customerEnquiry={customerEnquiry}
                                    mutateCheckAll={mutateCheckAll}
                                    handlePdf={handleOpenPdf2}
                                    update={update}
                                    checkAllData={checkAllDataJoint}
                                    setcheckAllData={setcheckAllDataJoint}
                                    setScreeningPerformed={setScreeningPerformed}
                                    pdfLoading={pdfLoading}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <section className='py-3'>
                            <div>
                              <h1 className='heading__component__title fw-bolder text-uppercase'>
                                F. Communication Address
                              </h1>
                            </div>
                            <FieldArray
                              name='form_addresses'
                              render={(arrayHelpers) => (
                                <div>
                                  {values.form_addresses && values.form_addresses.length > 0
                                    ? values.form_addresses.map((address, index) => (
                                        <div key={index}>
                                          <div className='row'>
                                            <div className='col-md-4 col-sm-4 col-xs-12'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                placeholder='Select Country'
                                                label='Country'
                                                name={`form_addresses[${index}].present_country`}
                                                onChange={(e: any) => {
                                                  handleChange(e)
                                                  onCountryChange(e, setFieldValue, setTouched)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                                options={allMasterDataOptions?.countries?.map(
                                                  (country: any) => ({
                                                    value: country.id || country.value,
                                                    label: country.title || country.label,
                                                  })
                                                )}
                                                setFieldValue={setFieldValue}
                                                values={values}
                                                arrValue={
                                                  values.form_addresses[index].present_country
                                                }
                                                required
                                              />
                                            </div>
                                            {allMasterDataOptions?.countries?.find(
                                              (item: any) =>
                                                item.id ===
                                                values?.form_addresses[0]?.present_country
                                            )?.title === 'NEPAL' ? (
                                              <>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormSelect
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    placeholder='Select Province'
                                                    label='Province'
                                                    name={`form_addresses[${index}].present_province`}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    options={provinceOptions.map((prov: any) => ({
                                                      value: prov?.id || prov?.value,
                                                      label: prov?.title || prov?.label,
                                                    }))}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                    arrValue={
                                                      values.form_addresses[index].present_province
                                                    }
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormSelect
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    placeholder='Select Zone'
                                                    label='Zone'
                                                    name={`form_addresses[${index}].present_zone`}
                                                    onChange={(e: any) => {
                                                      handleChange(e)
                                                      getZoneCode(e, values)
                                                    }}
                                                    errors={errors}
                                                    touched={touched}
                                                    options={zoneOptions.map((zone: any) => ({
                                                      value: zone?.id || zone?.value,
                                                      label: zone?.title || zone?.label,
                                                    }))}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                    arrValue={
                                                      values.form_addresses[index].present_zone
                                                    }
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormSelect
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    placeholder='Select District'
                                                    label='District'
                                                    name={`form_addresses[${index}].present_district`}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    options={
                                                      !districtData
                                                        ? districtOptions.map((district: any) => ({
                                                            value: district?.id || district?.value,
                                                            label:
                                                              district?.title || district?.label,
                                                          }))
                                                        : districtData?.data?.district?.map(
                                                            (district: any) => ({
                                                              value:
                                                                district?.id || district?.value,
                                                              label:
                                                                district?.title || district?.label,
                                                            })
                                                          )
                                                    }
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                    arrValue={
                                                      values.form_addresses[index].present_district
                                                    }
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormSelect
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    placeholder='Select Municipality'
                                                    label='Municipality'
                                                    name={`form_addresses[${index}].present_municipality`}
                                                    onChange={(e: any) => {
                                                      handleChange(e)
                                                      if (districtData) {
                                                        return
                                                      } else {
                                                        getMunicipilities(e, setFieldValue)
                                                      }
                                                    }}
                                                    errors={errors}
                                                    touched={touched}
                                                    options={municipalitiesOptions}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                    arrValue={
                                                      values.form_addresses[index]
                                                        .present_municipality
                                                    }
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormSelect
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Present  City'
                                                    name={`form_addresses.[${index}].present_city`}
                                                    label='Present City'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    arrValue={
                                                      values?.form_addresses[index]?.present_city
                                                    }
                                                    options={allMasterDataOptions?.cities?.map(
                                                      (city: any) => ({
                                                        value: city?.id || city.value,
                                                        label: city?.title || city.label,
                                                      })
                                                    )}
                                                    setFieldValue={setFieldValue}
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Street Name'
                                                    name={`form_addresses[${index}].present_street_name`}
                                                    label='Present Street'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    value={
                                                      values?.form_addresses[0]?.present_street_name
                                                    }
                                                    // uppercase={true}
                                                    required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Present Fax Number'
                                                    name={`form_addresses.[${index}].present_fax_number`}
                                                    label='Present Fax Number'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    value={
                                                      values?.form_addresses[0]?.present_fax_number
                                                    }
                                                    // uppercase={true}
                                                    maxLength={10}
                                                    // required
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Ward Number'
                                                    name={`form_addresses[${index}].present_ward_number`}
                                                    label='Ward Number'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    required
                                                    maxLength={2}
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='House Number'
                                                    name={`form_addresses[${index}].present_house_number`}
                                                    label='House Number'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    maxLength={10}
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Mobile Number'
                                                    name={`form_addresses[${index}].present_mobile_number`}
                                                    label='Mobile Number'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    required
                                                    maxLength={10}
                                                  />
                                                </div>
                                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                                  <FormTextBox
                                                    labelClassName='col-lg-12'
                                                    containerClassName='col-lg-12'
                                                    type='text'
                                                    placeholder='Email'
                                                    name={`form_addresses[${index}].present_email`}
                                                    label='Email'
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    touched={touched}
                                                    // uppercase={true}
                                                    maxLength={50}
                                                    required
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className='row'>
                                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <FormTextBox
                                                      labelClassName='col-lg-12'
                                                      containerClassName='col-lg-12'
                                                      type='text'
                                                      placeholder='Post Box No.'
                                                      name={`form_addresses[${index}].present_outside_country_post_box`}
                                                      label='Post Box No.'
                                                      onChange={handleChange}
                                                      errors={errors}
                                                      touched={touched}
                                                      // uppercase={true}
                                                      required
                                                    />
                                                  </div>
                                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <FormTextBox
                                                      labelClassName='col-lg-12'
                                                      containerClassName='col-lg-12'
                                                      type='text'
                                                      placeholder='Address'
                                                      name={`form_addresses[${index}].present_outside_country_full_address`}
                                                      label='Full Address'
                                                      onChange={handleChange}
                                                      errors={errors}
                                                      touched={touched}
                                                      required
                                                      // uppercase={true}
                                                    />
                                                  </div>
                                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <FormTextBox
                                                      labelClassName='col-lg-12'
                                                      containerClassName='col-lg-12'
                                                      type='text'
                                                      placeholder='Mobile Number'
                                                      name={`form_addresses[${index}].present_mobile_number`}
                                                      label='Mobile Number'
                                                      onChange={handleChange}
                                                      errors={errors}
                                                      touched={touched}
                                                      required
                                                      maxLength={10}
                                                    />
                                                  </div>
                                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                                    <FormTextBox
                                                      labelClassName='col-lg-12'
                                                      containerClassName='col-lg-12'
                                                      type='text'
                                                      placeholder='Email'
                                                      name={`form_addresses[${index}].present_email`}
                                                      label='Email'
                                                      onChange={handleChange}
                                                      errors={errors}
                                                      touched={touched}
                                                      // uppercase={true}
                                                      maxLength={50}
                                                      required
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ))
                                    : null}
                                </div>
                              )}
                            />
                          </section>

                          <hr />
                          <section className='py-3'>
                            <div className='row'>
                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                G. Occupation Details
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Occupation'
                                  label='Occupation'
                                  name='occupation_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={masterFilterData?.occupations?.map(
                                    (occupation: any) => ({
                                      value: occupation.id || occupation.value,
                                      label: occupation.title || occupation.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              {(values.occupation_id === 'others' ||
                                allMasterDataOptions?.occupations
                                  ?.find(
                                    (occupation: any) => occupation.id === values.occupation_id
                                  )
                                  ?.title.toLowerCase() === 'others') && (
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    // uppercase={true}
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Other Occupation'
                                    name='other_occupation'
                                    label='Other Occupation'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    value={values?.other_occupation}
                                    maxLength={30}
                                    required={
                                      values.occupation_id === 'others' ||
                                      allMasterDataOptions?.occupations
                                        ?.find(
                                          (occupation: any) =>
                                            occupation.id === values.occupation_id
                                        )
                                        ?.title.toLowerCase() === 'others'
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              )}

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Expected Annual Transaction In Amount'
                                  name='expected_annual_turnover'
                                  label='Expected Annual Transaction In Amount'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={20}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Income Source'
                                  label='Source of Income'
                                  values={values}
                                  name='income_source_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  arrValue={values?.income_source_id}
                                  options={masterFilterData?.income_sources?.map(
                                    (income_source: any) => ({
                                      value: income_source?.id || income_source?.value,
                                      label: income_source?.title || income_source?.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>

                              {(values.income_source_id === 'others' ||
                                allMasterDataOptions?.income_sources
                                  ?.find((source: any) => source.id === values.income_source_id)
                                  ?.title.toLowerCase() === 'others') && (
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    // uppercase={true}
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Other Income Source'
                                    name='other_income_source'
                                    label='Other Income Source'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={50}
                                    required={
                                      values.income_source_id === 'others' ||
                                      allMasterDataOptions?.income_sources
                                        ?.find(
                                          (source: any) => source.id === values.income_source_id
                                        )
                                        ?.title.toLowerCase() === 'others'
                                        ? true
                                        : false
                                    }
                                    // value={values?.other_income_source}
                                  />
                                </div>
                              )}
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Annual Income'
                                  label='Total Annual Income'
                                  values={values}
                                  name='total_annual_income'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.annual_turn_overs?.map(
                                    (turnOver: any) => ({
                                      value: turnOver.id || turnOver.value,
                                      label: turnOver.title || turnOver.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                            </div>
                          </section>
                          <div className='my-7'>
                            <h3>Screening Files</h3>
                            {updatePreliminariesDetails?.data?.screening_files?.screening_files
                              ?.length > 0 ? (
                              updatePreliminariesDetails?.data?.screening_files?.screening_files?.map(
                                (file: any, index: any) => (
                                  <>
                                    <button
                                      key={index}
                                      onClick={() => handlePdfFileClick(file.file_path)}
                                      style={{display: 'block', marginBottom: '10px'}}
                                    >
                                      {file.file_name}
                                    </button>
                                  </>
                                )
                              )
                            ) : (
                              <p>No files available</p>
                            )}
                          </div>
                          <>
                            {(values.form_addresses[0].present_email ||
                              values?.form_addresses[0].present_mobile_number) && (
                              <div className='row mb-4 pb-4 border-bottom align-items-center'>
                                {values.form_addresses[0].present_email && (
                                  <div className='col-md-4 mb-2'>
                                    {!isEmailVerified ? (
                                      <button
                                        type='button'
                                        onClick={() => {
                                          setVerificationDetails((prev) => ({
                                            ...prev,
                                            email: values.form_addresses[0].present_email,
                                          }))
                                          otpVerification('email_verification')
                                        }}
                                        className='btn btn-primary'
                                      >
                                        Verify Email
                                      </button>
                                    ) : (
                                      <div className='heading__component__title fw-bolder mb-5'>
                                        Email Address Verified
                                      </div>
                                    )}
                                  </div>
                                )}
                                {values.form_addresses[0].present_mobile_number && (
                                  <div className='col-md-4 mb-2'>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        setVerificationDetails((prev) => ({
                                          ...prev,
                                          mobile_number:
                                            values.form_addresses[0].present_mobile_number,
                                        }))
                                        otpVerification('mobile_verification')
                                      }}
                                      className='btn btn-primary'
                                    >
                                      Verify Mobile Number
                                    </button>
                                  </div>
                                )}
                                {values.form_addresses[0].present_email &&
                                  values.form_addresses[0].present_mobile_number && (
                                    <div className='col-md-4 mb-2'>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          setVerificationDetails((prev) => ({
                                            ...prev,
                                            email: values.form_addresses[0].present_email,
                                            mobile_number:
                                              values.form_addresses[0].present_mobile_number,
                                          }))
                                          otpVerification('both')
                                        }}
                                        className='btn btn-primary'
                                      >
                                        Verify Both
                                      </button>
                                    </div>
                                  )}
                              </div>
                            )}
                          </>

                          <div className='card-footer text-end'>
                            <button
                              type='button'
                              className='btn btn-secondary me-2'
                              onClick={handleBack}
                            >
                              Back
                            </button>

                            {btnFlag === 'save_and_continue' && isLoadingFormPost ? (
                              <>
                                <button
                                  type='submit'
                                  className='btn btn-primary'
                                  disabled={isLoadingFormPost}
                                  style={{backgroundColor: `${COLOR?.PRIMARY_COLOR}`}}
                                >
                                  Submitting...
                                </button>
                              </>
                            ) : (
                              <button
                                type='submit'
                                className='btn btn-warning'
                                disabled={isSubmitting || screeningPerformed === false}
                                onClick={() => {
                                  handleButtonClick('save_and_continue')
                                }}
                              >
                                Submit
                              </button>
                            )}
                            {btnFlag === 'save' && isLoadingFormPost ? (
                              <>
                                <button
                                  type='submit'
                                  className='btn btn-primary m-2'
                                  disabled={isLoadingFormPost}
                                  style={{backgroundColor: `${COLOR?.PRIMARY_COLOR}`}}
                                >
                                  Saving as draft...
                                </button>
                              </>
                            ) : (
                              <button
                                type='submit'
                                className='btn btn-primary m-2'
                                disabled={isSubmitting}
                                onClick={() => handleButtonClick('save')}
                                style={{backgroundColor: `${COLOR?.PRIMARY_COLOR}`}}
                              >
                                Save as Draft
                              </button>
                            )}
                          </div>
                        </div>
                        <AccountStatusLog data={updatePreliminariesDetails?.data} />
                        <UserActivity caseId={id} />
                      </div>
                    </Form>
                  </Spin>
                )
              }}
            </Formik>
          </>
        )}
      </div>
      <Modal
        className='w-55'
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      >
        <Modal.Body>
          {verificationType && (
            <VerificationForm
              setIsVerificationModalOpen={setIsVerificationModalOpen}
              uniqueId={uniqueID}
              loading={generateOtpLoading}
              variant={verificationType === 'both' ? 'both' : verificationType}
              openVerificationModal={openVerificationModal}
              setIsEmailVerified={setIsEmailVerified}
              caseType={'update'}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setIsVerificationModalOpen(false)} className='btn btn-secondary'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReviewAndUpdate
