import React, {useEffect} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

const OtherBanksTable = ({values, handleChange, errors, touched}: any) => {
  const {setFieldValue} = useFormikContext()

  // useEffect(() => {
  //   if (values.other_banks.length === 0) {
  //     setFieldValue('other_banks', [
  //       {
  //         bank_name: '',
  //         saving_ac: '',
  //         current_ac: '',
  //         od_ac: '',
  //         term_loan: '',
  //         others: '',
  //       },
  //     ])
  //   }
  // }, [setFieldValue, values.other_banks])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='border-top pt-9 pb-9'
        style={{backgroundColor: '#f8f9fa', borderRadius: '5px'}}
      >
        <h3 className=' heading__component__title fw-bolder text-uppercase'>
          J. Dealing with other Banks, if Any
        </h3>{' '}
        <FieldArray name='other_banks'>
          {({remove, push}) => (
            <div>
              {values?.other_banks?.length > 0 ? (
                values?.other_banks?.map((bank: any, index: any) => (
                  <div className='row mb-3' key={index}>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Bank Name'
                        name={`other_banks.${index}.bank_name`}
                        label='Bank Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Saving Account'
                        name={`other_banks.${index}.saving_ac`}
                        label='Saving Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Current Account'
                        name={`other_banks.${index}.current_ac`}
                        label='Current Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Overdraft Account'
                        name={`other_banks.${index}.od_ac`}
                        label='Overdraft Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12 '
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Term Loan'
                        name={`other_banks.${index}.term_loan`}
                        label='Term Loan'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12 '
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Others'
                        name={`other_banks.${index}.others`}
                        label='Others'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-2 d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Dealing with other banks added. Click 'Add Bank Details' to add.</p>
              )}
              <button
                type='button'
                className='dt-btn dt-btn-primary'
                onClick={() =>
                  push({
                    bank_name: '',
                    saving_ac: '',
                    current_ac: '',
                    od_ac: '',
                    term_loan: '',
                    others: '',
                  })
                }
              >
                Add Bank
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export default OtherBanksTable
