import { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap-v5'
import { useHistory } from 'react-router-dom'
import CpuCheckerNormalIndividualJointMinorTable from './normal/cpuCheckerNormalIndividualJointMinorTable'
import CpuCheckerNormalCorporateTable from './normal/cpuCheckerNormalCorporateTable'



const CPUCheckerNewCIFAndAccountList = () => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('individualJointMinor')

  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>Account Lists</div>
            </div>
            
          </div>
          {/* Tabs Starts Here */}
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
            id='branchChecker-new-cif-account-tabs'
            className='mb-5'
            data-cy='new-cif-account-list-tabs'
          >
            <Tab
              eventKey='individualJointMinor'
              title='Normal-Individual/Joint/Minor'
              data-cy='tab-individual-joint-minor'
            >
              <div className='card-body bg-white' data-cy='tab-content-individual-joint-minor'>
                < CpuCheckerNormalIndividualJointMinorTable/>
              </div>
            </Tab>
            <Tab eventKey='corporateBank' title='Normal-Corporate' data-cy='tab-corporate-banks'>
              <div className='card-body bg-white' data-cy='tab-content-corporate-banks'>
                <CpuCheckerNormalCorporateTable/>
              </div>
            </Tab>
            {/* <Tab
              eventKey='individualExpress'
              title='Express-Individual/Joint/Minor'
              data-cy='tab-individual-express'
            >
              <div className='card-body bg-white' data-cy='tab-content-individual-express'>
                <ExpressIndividualJointMinorTable />
              </div>
            </Tab>
            <Tab
              eventKey='corporateExpress'
              title='Express-Corporate'
              data-cy='tab-corporate-express'
            >
              <div className='card-body bg-white' data-cy='tab-content-corporate-express'>
                <ExpressCorporateTable />
              </div>
            </Tab> */}
          </Tabs>
          {/* Tabs Ends Here */}
        </div>
      </div>
    </div>
  )
}

export default CPUCheckerNewCIFAndAccountList
