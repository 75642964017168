import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {masterData} from 'src/app/queries/api/apiEndPoints/EndPoints'

const postDownload = async () => {
  try {
    const response = await axios.get(`${masterData}/sample-file`)
    return response?.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const useGetDownloadSample = () => {
  return useMutation(() => postDownload(), {
    onSuccess: (response: any) => {
      const fileUrl = response?.data?.file
      const fileName = 'master_data_sample.xlsx'
      if (fileUrl) {
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      toast.success('File downloaded successfully')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default useGetDownloadSample
