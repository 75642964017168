import axios from 'axios'
import { useQuery, useMutation } from 'react-query'
import { GET_NORMAL_NEW_CIF_NEW_ACCOUNT } from 'src/app/queries/api/apiEndPoints/EndPoints'
// import { ParamsModel } from 'src/app/modules/common/Model'

const QUERY_KEY = 'branchMakerNewCIFAndAccountList'

export const useGetBranchMakerNewCIFandAccountList = (params: any) => {
    return useQuery([QUERY_KEY, params], async () => {
        const { data } = await axios.get(GET_NORMAL_NEW_CIF_NEW_ACCOUNT, {
            params,
        })
        return data
    })
}
