import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  CORPORATE_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW,
  CORPORATE_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW,
  GET_NEW_CIF_Corporate,
  POST_CORPORATE_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postCorporateAccountPreliminaryCpuReview = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(
      `${CORPORATE_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW}/${id}/cpu-checker-review`,
      data
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCorporateCpuCheckerReview = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccountPreliminaryCpuReview, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        // toast.success('Account and Cif Created Successfully!')
        toast.success(response?.data?.resDesc ?? 'Review  status submitted successfully!')
        queryClient.invalidateQueries('corporateAccountList')
        queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
        queryClient.invalidateQueries('corporatePeliminariesDetailsById')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCorporateCpuCheckerReview

const postCorporateAccountPreliminaryCpuReviewCIF = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(`${GET_NEW_CIF_Corporate}/${id}/cpu-checker-review`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

export const usePostCorporateCpuCheckerReviewCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccountPreliminaryCpuReviewCIF, {
    onSuccess: (response) => {
      // toast.success('Account and Cif Created Successfully!')
      toast.success(response?.data?.resDesc ?? 'Review  status submitted successfully!')
      // queryClient.invalidateQueries('corporateAccountList')
      // queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
      // queryClient.invalidateQueries('corporatePeliminariesDetailsById')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}
