import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  CORPORATE_NEW_CIF_NEW_ACCOUNT_UPDATE_KYC,
  GET_NEW_CIF_Corporate,
  POST_CORPORATE_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${CORPORATE_NEW_CIF_NEW_ACCOUNT_UPDATE_KYC}`
type PayloadType = {
  data: any
  id: string
}

const postCorporateKycDetails = async (payload: PayloadType) => {
  const {id, ...data} = payload

  try {
    const response = await axios.patch(`${API_URL}/${id}/update-kyc-details`, data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}
const useUpdateCorporateKycDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateKycDetails, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Corporate Kyc updated successfully!')
      }
      queryClient.invalidateQueries('corporateAccountList')
      queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
      queryClient.invalidateQueries('corporatePeliminariesDetailsById')
    },
    onError: (error: Error) => {
      console.log(error)
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default useUpdateCorporateKycDetails

const postCorporateKycDetailsCIF = async (payload: PayloadType) => {
  const {id, ...data} = payload

  try {
    const response = await axios.patch(`${GET_NEW_CIF_Corporate}/${id}/update-kyc-details`, data)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || error.message}`)
  }
}
export const useUpdateCorporateKycDetailsCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateKycDetailsCIF, {
    onSuccess: (data) => {
      // queryClient.invalidateQueries('corporateAccountList')
      // queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
      queryClient.invalidateQueries('corporatePeliminariesDetailsByIdCIF')
    },
    onError: (error: any) => {
      console.log(error)
      // toast.error(`Error: ${error.message || 'An error occurred'}`)
      if (error?.response?.data?.message) {
        const errorMessages = Object.values(error?.response?.data?.message).flat()

        errorMessages.forEach((message) => {
          toast.error(`Error: ${message}`)
        })
      } else {
        toast.error(`Error: ${error.message || 'An error occurred'}`)
      }
    },
  })
}
