import {ErrorMessage, Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import * as Yup from 'yup'
//manual import
import * as bannerRedux from 'src/app/modules/cms/components/banner/redux'
import {StateParamsModel} from 'src/app/modules/common/Model'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import {IMenuState} from '../../menu'
import {BannerOptionModel, VideoOptionModel} from '../Model'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}
const AddBannerModal = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const dispatch = useDispatch()
  const {colorOption, bannerOption, videoOption, loading, success} = useSelector(
    (state: any) => state.banner
  )
  const {topLeftMenu} = useSelector((state: IMenuState | any) => state.menuManager)
  const [imagePath, setImagePath] = useState<any>('')
  const [imageUrl, setImageUrl] = useState('')
  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    status: Yup.string().required('Status is required'),
    subtitle: Yup.string().max(250, 'Subtitle exceeds 250 characters').nullable(),
    is_featured_in_homepage: Yup.string().required('Featured in homepage is required'),
  })

  useEffect(() => {
    dispatch(bannerRedux.actions.getBannerOption())
    dispatch(bannerRedux.actions.getVideoOption())
  }, [])

  const bannerOptions = bannerOption?.data?.bannerTypeOption.map((items: BannerOptionModel) => ({
    label: items?.displayName,
    value: items?.id,
    key: items?.systemName,
  }))

  const videoOptions = videoOption?.data?.videoSourceOption?.map((items: VideoOptionModel) => ({
    label: items?.displayName,
    value: items?.id,
    key: items?.systemName,
    isReset: true,
    resetValue: 'video',
  }))

  useEffect(() => {
    if (success) {
      dispatch(bannerRedux?.actions.getBanner(params))
      isEmpty(editSelectedData)
        ? toast.success('Banner added successfully')
        : toast.success('Banner edited successfully')
      dispatch(bannerRedux?.actions.createBannerReset())
      handleClose()
    }
  }, [success])

  const statusOptions = [
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
  ]
  const homepageOptions = [
    {label: 'Yes', value: 'Active'},
    {label: 'No', value: 'Inactive'},
  ]

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} Banner </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                title: '',
                subtitle: '',
                image: '',
                video: '',
                backgroundcolorId: '',
                bannerTypeId: '',
                menus: null,
                first_button_text: '',
                second_button_text: '',
                first_button_url: '',
                second_button_url: '',
                show_first_button: true,
                show_second_button: true,
                status: '',
                video_source_id: '',
                is_featured_in_homepage: '',
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values: any) => {
                const formData = {
                  ...values,
                  menus: !isEmpty(values?.menus)
                    ? values?.menus?.map((item: string) => ({
                        id: item,
                      }))
                    : null,
                  backgroundcolorId: !isEmpty(values?.backgroundcolorId)
                    ? values?.backgroundcolorId
                    : null,
                  status: values?.status === 'Active' ? true : false,
                  is_featured_in_homepage:
                    values?.is_featured_in_homepage === 'Active' ? true : false,
                }
                if (!isEmpty(editSelectedData)) {
                  dispatch(bannerRedux.actions.updateBanner(formData, editSelectedData?.id))
                } else {
                  dispatch(bannerRedux.actions.CreateBanner(formData))
                }
              }}
            >
              {({isSubmitting, touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'title',
                      'menus',

                      'subtitle',

                      'image',
                      'video',
                      'first_button_text',

                      'first_button_url',
                      'second_button_text',

                      'second_button_url',
                      'backgroundcolorId',
                      'bannerTypeId',
                      `video_source_id`,
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                    setFieldValue(
                      'status',
                      editSelectedData?.status === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'is_featured_in_homepage',
                      editSelectedData?.is_featured_in_homepage === true ? 'Active' : 'Inactive',
                      false
                    )
                    setFieldValue(
                      'backgroundcolorId',
                      editSelectedData?.bannerbackgroundcolor,
                      false
                    )
                    setFieldValue('bannerTypeId', editSelectedData?.bannerTypeId, false)
                    setFieldValue('video_source_id', editSelectedData?.video_source_id, false)
                  }
                }, [])
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Name'
                            name='name'
                            label='Name'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                          />
                        </div>

                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select Banner type'
                            label='Banner Type'
                            name='bannerTypeId'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={bannerOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>

                        {!isEmpty(values?.bannerTypeId) ? (
                          values?.bannerTypeId === '12a6dc45-cecf-48ce-9815-e7129342f69e' ? (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormInputMediaManager
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Image'
                                  name='image'
                                  setFieldValue={setFieldValue}
                                  setImageUrl={setImageUrl}
                                  value={values?.image}
                                />

                                {!isEmpty(values?.image) ? (
                                  <>
                                    <li className='listing'>
                                      <div className='thumbImageBlock'>
                                        <button
                                          type='button'
                                          title='Remove'
                                          className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                          onClick={() => {
                                            setImageUrl('')
                                            setFieldValue('image', '')
                                          }}
                                          data-cy='modal-thumbnail-remove'
                                        >
                                          Delete
                                        </button>

                                        <img
                                          className='thumbImage w-100 h-100'
                                          src={`${imageBaseUrl}/${values?.image}`}
                                          alt=''
                                        />
                                      </div>
                                    </li>
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  placeholder='Please select video source type'
                                  label='Video Source'
                                  name='video_source_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={videoOptions}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  type='text'
                                  placeholder='Video'
                                  as='textarea'
                                  name='video'
                                  label='Video'
                                  containerClassName=''
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </>
                          )
                        ) : null}
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Enter Title'
                            name='title'
                            label='Title '
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <div className='mb-3'>
                            <em>
                              <small>
                                {
                                  'Use <span> tag for color effect. Eg. <span>Highlighted</span> non highlighted'
                                }
                              </small>
                              <small className='d-block'>
                                Results: <span className='text-danger'>Highlighted</span> non
                                highlighted
                              </small>
                            </em>
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Subtitle '
                            name='subtitle'
                            as='textarea'
                            label='Subtitle '
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button Text '
                            name='first_button_text'
                            label='First Button Text '
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='First Button URL'
                            name='first_button_url'
                            label='First Button URL'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button Text '
                            name='second_button_text'
                            label='Second Button Text '
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            placeholder='Second Button URL '
                            name='second_button_url'
                            label='Second Button URL'
                            containerClassName=''
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Featured in homepage
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {homepageOptions?.map(
                              (homepageStatus: {label: string; value: string}) => (
                                <FormRadio
                                  containerClassName=''
                                  label='Featured in homepage'
                                  name='is_featured_in_homepage'
                                  type='radio'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  checkBoxText={homepageStatus?.label}
                                  value={homepageStatus?.value}
                                  
                                  required
                                />
                              )
                            )}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
                            <ErrorMessage
                              name='is_featured_in_homepage'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Status
                          </label>

                          <div className='d-flex ms-5 mt-3'>
                            {statusOptions?.map((status: {label: string; value: string}) => (
                              <FormRadio
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={status?.label}
                                value={status?.value}
                                required={true}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end px-9 '>
                      <button
                        type='submit'
                        disabled={loading}
                        className='btn btn-primary btn-sm ms-3'
                        data-cy='modal-submit'
                      >
                        Save
                      </button>
                      <button
                        type='button'
                        onClick={handleClose}
                        className='btn btn-secondary btn-sm ms-3'
                        data-cy='modal-cancel'
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default AddBannerModal
