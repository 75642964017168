import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePatchKycDetailsIndividual from 'src/app/queries/new-cif-new-account-queries/cpuMaker/kycIndividual/patch/usePatchKycDetailsIndividual'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import CpuMakerAccountOpeningForm from '../../../newCifNewAccountOpeningForm/cpuMakerAccountOpeningForm'
import KycFormNormalIndividualJointMinor from './kycFormIndividualJointMinor'

const KycUpdateFormNormalIndividualJointMinor = () => {
  const {id} = useParams<any>()
  const history = useHistory()
  const [btnFlag, setBtnFlag] = useState('')
  const {data: preliminaryDetailsByID, isLoading: detailsLoading} =
    useGetPreliminariesDetailsById(id)
  const dataStatus = preliminaryDetailsByID?.data
  const {mutate: updateKycDetails, isLoading: updateLoading} = usePatchKycDetailsIndividual()
  const [kycFormSubmitted, setKycFormSubmitted] = useState(false) // To track if the first form is submitted
  const [activeKey, setActiveKey] = useState('updateKyc') // Active tab key

  // State to store data from both forms
  const [kycData, setKycData] = useState({})
  // const [accountOpeningData, setAccountOpeningData] = useState({})

  // Handle form data changes for KYC
  const handleKycDataChange = (data: any) => {
    setKycData(data)
  }

  // Handle form data changes for Account Opening
  // const handleAccountOpeningDataChange = (data: any) => {
  //   setAccountOpeningData(data)
  // }

  // Combined submit handler for both forms
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div className='card mb-5 mb-xl-10' style={{position: 'sticky', top: '80px'}}>
            <div className='card-body'>
              <DocumentsPreview data={dataStatus} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body border-top'>
              <div className='d-flex justify-content-start mb-3 text-align-center'>
                <h3 style={{marginRight: '0.8rem'}}>CIF FORM</h3>
                <span>Individual/ Joint/ Minor</span>
              </div>
              <Tabs
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k ?? 'updateKyc')}
                id='cpuMaker-new-cif-account-tabs'
                className='mb-5'
                data-cy='new-cif-account-list-tabs'
              >
                <Tab eventKey='updateKyc' title='Update CIF' data-cy='tab-kyc-form'>
                  <div data-cy='tab-content-kyc-form'>
                    <KycFormNormalIndividualJointMinor
                      id={id}
                      data={dataStatus}
                      setActiveKey={setActiveKey}
                      setKycFormSubmitted={setKycFormSubmitted}
                      onFormChange={handleKycDataChange}
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey='accountOpening'
                  title='Account Opening'
                  data-cy='tab-account-opening'
                  // disabled={!kycFormSubmitted} // Enable only if the first form is submitted
                >
                  <div data-cy='tab-content-account-opening'>
                    <CpuMakerAccountOpeningForm
                      // onFormChange={handleAccountOpeningDataChange} // Pass handler to update Account Opening form data
                      // accountOpeningData={accountOpeningData}
                      kycData={kycData}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <AccountStatusLog data={dataStatus} />
        <UserActivity caseId={id} />
      </div>
    </>
  )
}

export default KycUpdateFormNormalIndividualJointMinor
