import type {CollapseProps} from 'antd'
import {Collapse} from 'antd'
import {useParams} from 'react-router-dom'
import useGetLinkedEntitiesDetails from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesById'
import DetailsRow from '../components/DetailsRow'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import ReviewStatusFormCPUChecker from '../components/ReviewStatusFormCPUChecker'

const ReviewAndUpdateCPUCheckerLinkedEntities = () => {
  const {id}: any = useParams()
  const {data: linkedEntities} = useGetLinkedEntitiesDetails(id)
  const refinedLinkedEntitiesData = linkedEntities?.data
  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })
  const linkedRelationships =
    refinedLinkedEntitiesData?.relationship_data?.linked_relationships || []

  const items: CollapseProps['items'] = linkedRelationships.map(
    (relationship: {id: any; relation_cif_number: any; relationship_id: any}, index: number) => ({
      key: relationship.id,
      label: `${index + 1}. CIF Number: ${relationship.relation_cif_number}`,
      children: (
        <div>
          <DetailsRow
            label='Relation CIF Number'
            value={relationship?.relation_cif_number || '-'}
          />
          <DetailsRow label='Relationship ID' value={relationship?.relationship_id || '-'} />
        </div>
      ),
    })
  )

  const onChange = (key: string | string[]) => {
    console.log(key)
  }
  const customExpandIcon = (panelProps: any) => (
    <span style={{color: '#1890ff', cursor: 'pointer'}}>
      {panelProps.isActive ? 'Hide Details' : 'View Details'}
    </span>
  )

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <h3 className='heading__component__title fw-bolder'>Linked Entities Review</h3>
          <i className='fw-bolder mb-5'>Primary CIF Details:</i>
          <div className='pt-5'>
            <DetailsRow
              label='Primary Customer Name'
              value={refinedLinkedEntitiesData?.primary_customer_name || ''}
            />
            <DetailsRow
              label='Primary CIF Number'
              value={refinedLinkedEntitiesData?.primary_cif_number || ''}
            />
            <DetailsRow
              label='Primary Customer Email'
              value={refinedLinkedEntitiesData?.primary_customer_email || ''}
            />
            <DetailsRow
              label='Primary Account Number'
              value={refinedLinkedEntitiesData?.primary_account_number || ''}
            />
          </div>
          <div className='mt-5'>
            <i className='fw-bolder pb-5'>Entities Lists:</i>
            <Collapse
              items={items}
              defaultActiveKey={['1']}
              onChange={onChange}
              expandIcon={customExpandIcon}
              expandIconPosition='end'
            />
          </div>
          <div></div>
        </div>
      </div>
      <ReviewStatusFormCPUChecker />
    </div>
  )
}

export default ReviewAndUpdateCPUCheckerLinkedEntities
