import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {isEmpty} from 'lodash'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {useGetBranchMakerNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/getPliminariesAccountList'

const Cell = Table.Cell

const IndividualJointMinorCompleteTable = ({
  newCIFandAccountListData,
  setParams,
  params,
  loading,
  roleName,
}: any) => {
  const history = useHistory()
  const data =
    newCIFandAccountListData?.data?.preliminaries?.map((newAccount: any, i: any) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name.toUpperCase()} ${
        newAccount.middle_name ? newAccount.middle_name.toUpperCase() + ' ' : ''
      }${newAccount?.last_name.toUpperCase()}`,
      updatedOn: newAccount?.account_status?.updated_date,
      remarks: newAccount?.account_status?.remarks ?? '',
      status: newAccount?.account_status?.current_status ?? '',
      accountNumber: newAccount?.registration_detail?.account_number ?? '',
      cifNumber: newAccount?.registration_detail?.cif_number ?? '',
      btnStatus: newAccount?.btn_status ?? '',
      branchCode: newAccount?.branch_code ?? '',
      customerCode: newAccount?.customer_code ?? '',
    })) ?? []

  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 80,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 180,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Branch Location',
      dataKey: 'branchCode',
      cell: <Cell dataKey='branchCode' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Customer Code',
      dataKey: 'customerCode',
      cell: <Cell dataKey='customerCode' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 200,
    },

    // {
    //   label: 'Action',
    //   width: 160,
    //   align: 'center',
    //   cell: <ActionCell dataKey='id' />,
    // },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params?.search}
          onChange={(value) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={newCIFandAccountListData?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default IndividualJointMinorCompleteTable
