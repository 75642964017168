import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {masterData} from 'src/app/queries/api/apiEndPoints/EndPoints'

interface IProps {
  search: string
  table_name: string
  fileFormat: string
}

const postFileExport = async (payload: IProps) => {
  try {
    const {search, table_name, fileFormat} = payload

    const response = await axios.get(`${masterData}/export`, {
      params: {search, table_name, fileFormat},
      responseType: 'text',
    })

    return response.data
  } catch (error: any) {
    throw new Error(`${error.response?.data?.message || 'An error occurred'}`)
  }
}

const usePostFileExport = () => {
  return useMutation((payload: IProps) => postFileExport(payload), {
    onSuccess: async (response) => {
      const csvData = response
      downloadCSV(csvData, 'export')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

const downloadCSV = (csvData: string, filename: string) => {
  //
  const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'})
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export default usePostFileExport
