import {FieldArray, Form, Formik} from 'formik'
import React, {useState, useEffect} from 'react'
import {Button} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import usePostCPUMakerLinkedEntities from 'src/app/queries/linkedEntitiesQuery/post/usePostCPUMakerLinkedEntities'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {validationSchemaIndividual} from '../utils/validationSchemas/ValidationSchemas'
import DetailView from '../../../common/components/detailView/detailView'

interface ToBeLinkedEntitiesProps {
  onSubmitValues: any
  screeningPerformed: any
  accumulatedFilePaths: any
}

const ToBeLinkedEntities: React.FC<ToBeLinkedEntitiesProps> = ({
  onSubmitValues,
  screeningPerformed,
  accumulatedFilePaths,
}) => {
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const history = useHistory()
  const {mutate: postCPUMakerLinkedEntities} = usePostCPUMakerLinkedEntities()
  const userBranchCode = user?.data?.branch?.code
  const {allMasterDataOptions} = usePlaceMasterData({allMasterData: true})
  const [searchParams, setSearchParams] = useState<{
    relation_cif_number: string
    isCorporate: boolean
    index: number
  } | null>(null)
  const [searchResults, setSearchResults] = useState<{[key: number]: any}>({})
  const [loadingStates, setLoadingStates] = useState<{[key: number]: boolean}>({})
  const {data: mainData} = useGetLinkedEntitesData(onSubmitValues, {enabled: true})
  const accountName = mainData?.data?.AccountDetail[0]?.ACCT_NAME
  const accountCIFNumber = mainData?.data?.AccountDetail[0]?.CUST_ID
  const {
    data: searchData,
    isSuccess,
    isLoading,
  } = useGetLinkedEntitesData(
    {
      cif_number: searchParams?.relation_cif_number || '',
      is_corporate: searchParams?.isCorporate || false,
    },
    {enabled: !!searchParams}
  )

  useEffect(() => {
    if (isSuccess && searchData && searchParams !== null) {
      setSearchResults((prevState) => ({
        ...prevState,
        [searchParams.index]: searchData,
      }))
      setLoadingStates((prevState) => ({
        ...prevState,
        [searchParams.index]: false,
      }))
    }
  }, [searchData, isSuccess, searchParams])
  const searchedResults = searchData?.data
  const handleSubmit = (values: any, {setSubmitting}: any) => {
    const payload = {
      ...values,
      action: values.action,
      is_file_path_exists: false,
      files: [
        {
          file_name: 'test.pdf',
          file_path: '/uploads/test.pdf',
        },
      ],
    }
    postCPUMakerLinkedEntities(payload, {
      onSuccess: () => {
        setSubmitting(false)
        history.go(-1)
      },
      onError: (error: any) => {
        setSubmitting(false)
      },
    })
  }

  const handleSearchDetails = (
    relation_cif_number: string,
    isCorporate: boolean,
    index: number
  ) => {
    setLoadingStates((prevState) => ({...prevState, [index]: true}))
    setSearchParams({relation_cif_number, isCorporate, index})
  }

  return (
    <div className='mt-10'>
      <Formik
        initialValues={{
          action: 'save_and_continue',
          is_corporate: onSubmitValues?.is_corporate?.toString(),
          primary_customer_name: accountName || '',
          primary_cif_number: accountCIFNumber || '',
          branch_code: userBranchCode,
          primary_account_number: onSubmitValues?.account_number,
          primary_customer_email: '',
          unlink_flag: false,
          linked_relations: [{relation_cif_number: '', relationship_id: '', relation_name: ''}],
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchemaIndividual}
      >
        {({errors, handleChange, touched, values, setFieldValue}) => (
          <Form>
            <FieldArray
              name='linked_relations'
              render={(arrayHelpers) => (
                <>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='heading__component__title fw-bolder text-uppercase'>
                      Entities To Be Linked
                    </h3>
                    <Button
                      type='button'
                      className='btn'
                      onClick={() =>
                        arrayHelpers.push({relation_cif_number: '', relationship_id: ''})
                      }
                    >
                      Add Relation
                    </Button>
                  </div>
                  {values.linked_relations &&
                    values.linked_relations.length > 0 &&
                    values.linked_relations.map((relation: any, index: any) => (
                      <div key={index} className='row mb-3 align-items-center'>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation CIF Number'
                            name={`linked_relations.${index}.relation_cif_number`}
                            label='Relation CIF Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation Name'
                            name={`linked_relations.${index}.relation_name`}
                            label='Relation Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            value={
                              searchResults[index]
                                ? searchResults[index]?.AccountDetail?.[0]?.ACCT_NAME ?? ''
                                : values?.linked_relations[index]?.relation_name
                            }
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Relationship Declaration'
                            name={`linked_relations.${index}.relationship_id`}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.linked_relations[index]?.relationship_id}
                            options={allMasterDataOptions?.account_relations?.map(
                              (relationShip: any) => ({
                                value: relationShip.id || relationShip.value,
                                label: relationShip.title || relationShip.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12 d-flex justify-content-between'>
                          <Button
                            type='button'
                            className='btn btn-primary'
                            onClick={() =>
                              handleSearchDetails(
                                values.linked_relations[index]?.relation_cif_number,
                                onSubmitValues.is_corporate,
                                index
                              )
                            }
                          >
                            {loadingStates[index] ? 'Searching...' : 'Search'}
                          </Button>
                          {index > 0 && (
                            <Button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                        {searchResults[index] &&
                          searchResults[index]?.data?.GeneralDetails?.length > 0 && (
                            <>
                              <div className='row mb-4'>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'CIF Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.CUST_ID}
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'Full Name'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.CUST_NAME}
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'Contact Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.MOBILE_NO}
                                  />
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <div className='col-md-3'>
                                  <DetailView
                                    label={'ID Number'}
                                    value={searchResults[index]?.data?.GeneralDetails[0]?.IDNO}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                      </div>
                    ))}
                </>
              )}
            />
            <div className='text-end'>
              <Button
                type='button'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => {
                  setFieldValue('action', 'save')
                  handleSubmit(values, {setSubmitting: () => {}})
                }}
              >
                Save as Draft
              </Button>
              <Button
                type='submit'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => setFieldValue('action', 'save_and_continue')}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ToBeLinkedEntities
