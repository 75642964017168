import {Form, Formik} from 'formik'
import {useState} from 'react'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import useGetCorporatePreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import useUpdateCorporateAccountPreliminary from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporatePreliminaries'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {RootState} from 'src/setup'

interface RouteParams {
  id: string
}
const reviewAndUpdateCorporate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: updateExpressCorporateAccountPreliminary, isLoading: postdataloading} =
    useUpdateCorporateAccountPreliminary()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsById(id)

  const [masterData, setMasterData] = useState<any>()
  const [isSave, setIsSave] = useState(false)

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone, municipality} = useSelector((state: RootState) => state.place)
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])
  const menuOptions = [
    {value: 'test1', label: 'Test 1'},
    {value: 'test2', label: 'Test 2'},
  ]
  const statusOptions = [
    {value: 'Yes', label: 'yes'},
    {value: 'No', label: 'no'},
  ]

  const tableConfigs = [
    {
      title:
        'Name and designation of Individuals/Proprietor/Partner/Director/Trustee/Members/CEO/Senior Management/Authorised Signatory & Shareholders holding 10% & above Shares',
      headers: [
        {label: 'S.N.', key: 'sn'},
        {label: 'Full Name', key: 'fullName'},
        {label: 'Designation', key: 'designation'},
      ],
      data: [
        {sn: 1, fullName: 'John Doe', designation: 'Director'},
        {sn: 2, fullName: 'Jane Smith', designation: 'CEO'},
      ],
    },
  ]

  const documentTypeList = [
    {id: '1', title: 'Registration Certificate', max_size: '5'},
    {id: '2', title: 'MOA', max_size: '10'},
    {id: '3', title: 'AOA', max_size: '10'},
    {id: '4', title: 'Resolutionss', max_size: '5'},
    {id: '5', title: 'PAN/ Tax Registration', max_size: '5'},
    {id: '6', title: 'Tax Clearance', max_size: '5'},
    {id: '7', title: 'Board Minutes', max_size: '5'},
    {id: '8', title: 'Deeds (Partnership firm/ JV)', max_size: '10'},
    {id: '9', title: 'Audited Financials', max_size: '10'},
    {id: '10', title: 'AOF Form', max_size: '5'},
    {id: '11', title: 'CIF Form', max_size: '5'},
    {id: '12', title: 'Others', max_size: '10'},
  ]

  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState(null)
  const [seletctedAccountType] = useState('corporate') // Adjust based on your logic
  const [uniqueID] = useState('unique-id') // Adjust based on your logic
  const [missingDocs, setMissingDocs] = useState([]) // Adjust based on your logic
  const [documentTypeLoading, setDocumentTypeLoading] = useState(false) // Adjust based on your logic

  const handleFileUpload = (uploadedFiles: any) => {
    // Handle file upload logic here
  }
  const handleSubmit = (values: any) => {
    const payload = {...values, id}
    payload.action = isSave ? 'save_and_continue' : 'save'
    updateExpressCorporateAccountPreliminary(payload)
    // history.push('new-cif-new-account-lists')
  }
  const initialValues = {
    action: '',
    opening_mode: 'express',
    branch_code: corporateDataById?.data?.branch_code || '0140',
    account_type_id:
      corporateDataById?.data?.account_type_id || '7d7c4771-abd6-49b4-b527-1e2b0a88f3db',
    institution_name: corporateDataById?.data?.institution_name || '',
    constitution_id:
      corporateDataById?.data?.constitution_id?.id || '1cf0dea4-d91a-4bf0-8509-524f8e5182df',
    other_constitution: corporateDataById?.data?.other_constitution?.id || 'NGO',
    foreign_company_name: corporateDataById?.data?.foreign_company_name || '',
    foreign_company_address: corporateDataById?.data?.foreign_company_address || '',
    registration_number: corporateDataById?.data?.registration_number || '',
    registration_with_id:
      corporateDataById?.data?.registration_with_id?.id || 'c9e4b015-2313-11ef-8b38-107d1a290adf',
    registration_date_ad: corporateDataById?.data?.registration_date_ad || '',
    registration_date_bs: corporateDataById?.data?.registration_date_bs || '',
    registration_expiry_date_ad: corporateDataById?.data?.registration_expiry_date_ad || '',
    registration_expiry_date_bs: corporateDataById?.data?.registration_expiry_date_bs || '',
    registered_office_country:
      corporateDataById?.data?.registered_office_country?.id ||
      'b15aa5c3-3130-11ef-b609-107d1a290adf',
    registered_office_zone:
      corporateDataById?.data?.registered_office_zone?.id || '0f53936e-efce-4aa9-9846-09089c4aa719',
    registered_office_province:
      corporateDataById?.data?.registered_office_province?.id ||
      '9c5bd94d-2e19-4588-988a-c57759316b8a',
    registered_office_district:
      corporateDataById?.data?.registered_office_district?.id ||
      '41a1a74b-f5f9-4860-8972-c3a20dc44a28',
    registered_office_city: corporateDataById?.data?.registered_office_city || '',
    registered_office_municipality:
      corporateDataById?.data?.registered_office_municipality?.id ||
      'fee3a02b-6ae4-406b-938b-cc06dc2444af',
    registered_office_ward_number: corporateDataById?.data?.registered_office_ward_number || '',
    registered_office_street_name: corporateDataById?.data?.registered_office_street_name || '',
    registered_office_house_number: corporateDataById?.data?.registered_office_house_number || '',
    registered_office_landline: corporateDataById?.data?.registered_office_landline || '3232323',
    registered_office_mobile_number: corporateDataById?.data?.registered_office_mobile_number || '',
    registered_office_email: corporateDataById?.data?.registered_office_email || '',
    registered_office_fax_number: corporateDataById?.data?.registered_office_fax_number || '',
    registered_office_website: corporateDataById?.data?.registered_office_website || '',
    business_office_country:
      corporateDataById?.data?.business_office_country?.id ||
      'b15aa5c3-3130-11ef-b609-107d1a290adf',
    business_office_zone:
      corporateDataById?.data?.business_office_zone?.id || '0f53936e-efce-4aa9-9846-09089c4aa719',
    business_office_province:
      corporateDataById?.data?.business_office_province?.id ||
      '9c5bd94d-2e19-4588-988a-c57759316b8a',
    business_office_district:
      corporateDataById?.data?.business_office_district?.id ||
      '41a1a74b-f5f9-4860-8972-c3a20dc44a28',
    business_office_city: corporateDataById?.data?.business_office_city || '',
    business_office_municipality:
      corporateDataById?.data?.business_office_municipality?.id ||
      'fee3a02b-6ae4-406b-938b-cc06dc2444af',
    business_office_ward_number: corporateDataById?.data?.business_office_ward_number || '',
    business_office_street_name: corporateDataById?.data?.business_office_street_name || '',
    business_office_house_number: corporateDataById?.data?.business_office_house_number || '',
    business_office_landline: corporateDataById?.data?.business_office_landline || '1212121',
    business_office_mobile_number: corporateDataById?.data?.business_office_mobile_number || '',
    business_office_email: corporateDataById?.data?.business_office_email || '',
    business_office_fax_number: corporateDataById?.data?.business_office_fax_number || '',
    business_office_website: corporateDataById?.data?.business_office_website || '',
    working_area: corporateDataById?.data?.working_area || '',
    business_nature_id:
      corporateDataById?.data?.business_nature_id?.id || '06f4c190-b7dd-4ee9-92b6-74d7fe94b706',
    income_tax_assess: corporateDataById?.data?.income_tax_assess || 'Yes',
    pan_number: corporateDataById?.data?.pan_number || '',
    vat_number: corporateDataById?.data?.vat_number || '',
    expected_annual_turnover: corporateDataById?.data?.expected_annual_turnover || 500000,
    expected_annual_transaction_number:
      corporateDataById?.data?.expected_annual_transaction_number || '50',
    location_map: corporateDataById?.data?.location_map || 'Map details',
    screening_id: corporateDataById?.data?.screening_id || '23435',
    blacklist_screening: corporateDataById?.data?.blacklist_screening || true,
    sanction_screening: corporateDataById?.data?.sanction_screening || false,
    customer_category_id:
      corporateDataById?.data?.customer_category_id || 'd1e26b95-4030-11ef-9071-02420a000905',
    aml_risk_rating: corporateDataById?.data?.aml_risk_rating || 'medium',
    pep_status: corporateDataById?.data?.pep_status || 'No',
    pep_information: corporateDataById?.data?.pep_information || 'Details about PEP status',
    crime_conviction: corporateDataById?.data?.crime_conviction || false,
    crime_details: corporateDataById?.data?.crime_details || 'Details about crime',
    blacklisting_inclusion: corporateDataById?.data?.blacklisting_inclusion || false,
    blacklist_release_date: corporateDataById?.data?.blacklist_release_date || '2024-07-18',
  }
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({values, isSubmitting, touched, handleChange, errors, setFieldValue}) => (
        <Form className='bg-white'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body border-top pt-9 pb-9'>
              <div className='card-body border-top pt-5 px-5'>
                <div className='row'>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <FormTextBox
                      uppercase={true}
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Branch Code'
                      name='branch_code'
                      label='Branch Code'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      maxLength={6}
                      disabled
                    />
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <FormTextBox
                      uppercase={true}
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Customer Code'
                      name='customer_code'
                      label='Customer Code'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      maxLength={6}
                      disabled
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Institution Name'
                      name='institution_name'
                      label='A. Name of Institution'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>

                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='B. Constitution'
                      name='constitution_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={menuOptions}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    if the associates of foreign company/entity, details of foreign company/entity
                  </label>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='name'
                      name='foreign_company_name'
                      label='Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Address'
                      name='foreign_company_address'
                      label='Address'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                </div>
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  C. Details of Registration
                </label>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='registration'
                      name='registration_number'
                      label='i. Registration No.'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='ii. Registered With'
                      name='registered_with_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={menuOptions}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <div className='col-md-6 col-12'>
                      <FormTextBox
                        name='registration_date_ad'
                        label='iii. Date of Registration'
                        type='date'
                        containerClassName='col-lg-12'
                        labelClassName='col-lg-12'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      name='registration_date_bs'
                      label='iii. Date of Registration (BS)'
                      type='date'
                      containerClassName='col-lg-12'
                      labelClassName='col-lg-12'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>

                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      name='registration_expiry_date_ad'
                      label='iv. Registration Valid Upto'
                      type='date'
                      containerClassName='col-lg-12'
                      labelClassName='col-lg-12'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      name='registration_expiry_date_bs'
                      label='Registration valid Upto (BS)'
                      type='date'
                      containerClassName='col-lg-12'
                      labelClassName='col-lg-12'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div>
                  <h5 className='form-section-heading'>N. Registered Address</h5>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Country'
                      label='Country'
                      name={'registered_office_country'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={masterData?.data[19]?.countries.map((item: any) => ({
                        label: item.title?.toLocaleUpperCase() || '',
                        value: item.id,
                      }))}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Province'
                      label='Province'
                      name={'registered_office_province'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        province?.map((provinceData) => ({
                          label:
                            typeof provinceData.title === 'string'
                              ? provinceData.title
                              : provinceData.title.toString(),
                          value:
                            typeof provinceData.id === 'string'
                              ? provinceData.id
                              : provinceData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Zone'
                      label='Zone'
                      name={'registered_office_zone'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        zone?.map((zoneData) => ({
                          label:
                            typeof zoneData.title === 'string'
                              ? zoneData.title
                              : zoneData.title.toString(),
                          value:
                            typeof zoneData.id === 'string' ? zoneData.id : zoneData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select District'
                      label='District'
                      name={'registered_office_district'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        province?.map((provinceData) => ({
                          label:
                            typeof provinceData.title === 'string'
                              ? provinceData.title
                              : provinceData.title.toString(),
                          value:
                            typeof provinceData.id === 'string'
                              ? provinceData.id
                              : provinceData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Municipality'
                      label='Municipality'
                      name={'registered_office_municipality'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        municipality?.map((municipalityData) => ({
                          label:
                            typeof municipalityData.title === 'string'
                              ? municipalityData.title
                              : municipalityData.title.toString(),
                          value:
                            typeof municipalityData.id === 'string'
                              ? municipalityData.id
                              : municipalityData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Ward Number'
                      name={'registered_office_ward_number'}
                      label='Ward Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='City'
                      name={'registered_office_city'}
                      label='City'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Street Name'
                      name={'registered_office_street_name'}
                      label='Street Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='House Number'
                      name={'registered_office_house_number'}
                      label='House Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Fax Number'
                      name={'registered_office_fax_number'}
                      label='Fax Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Mobile Number'
                      name={'registered_office_mobile_number'}
                      label='Mobile Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Email'
                      name={'registered_office_email'}
                      label='Email'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Website'
                      name={'registered_office_website'}
                      label='Website'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div>
                  <h5 className='form-section-heading'>N. Business Address</h5>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Country'
                      label='Country'
                      name={'business_office_country'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={masterData?.data[19]?.countries.map((item: any) => ({
                        label: item.title?.toLocaleUpperCase() || '',
                        value: item.id,
                      }))}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Province'
                      label='Province'
                      name={'business_office_province'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        province?.map((provinceData) => ({
                          label:
                            typeof provinceData.title === 'string'
                              ? provinceData.title
                              : provinceData.title.toString(),
                          value:
                            typeof provinceData.id === 'string'
                              ? provinceData.id
                              : provinceData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Zone'
                      label='Zone'
                      name={'business_office_zone'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        zone?.map((zoneData) => ({
                          label:
                            typeof zoneData.title === 'string'
                              ? zoneData.title
                              : zoneData.title.toString(),
                          value:
                            typeof zoneData.id === 'string' ? zoneData.id : zoneData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select District'
                      label='District'
                      name={'business_office_district'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        district?.map((districtData) => ({
                          label:
                            typeof districtData.title === 'string'
                              ? districtData.title
                              : districtData.title.toString(),
                          value:
                            typeof districtData.id === 'string'
                              ? districtData.id
                              : districtData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select Municipality'
                      label='Municipality'
                      name={'business_office_municipality'}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={
                        municipality?.map((municipalityData) => ({
                          label:
                            typeof municipalityData.title === 'string'
                              ? municipalityData.title
                              : municipalityData.title.toString(),
                          value:
                            typeof municipalityData.id === 'string'
                              ? municipalityData.id
                              : municipalityData.id.toString(),
                        })) ?? []
                      }
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Ward Number'
                      name={'business_office_ward_number'}
                      label='Ward Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='City'
                      name={'business_office_city'}
                      label='City'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Street Name'
                      name={'business_office_street_name'}
                      label='Street Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='House Number'
                      name={'business_office_house_number'}
                      label='House Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Fax Number'
                      name={'business_office_fax_number'}
                      label='Fax Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Mobile Number'
                      name={'business_office_mobile_number'}
                      label='Mobile Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Email'
                      name={'business_office_email'}
                      label='Email'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Website'
                      name={'business_office_website'}
                      label='Website'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Area'
                      name='working_area'
                      label='Working Area, If defined'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='Nature of Business'
                      name='business_nature_id'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      options={menuOptions}
                      setFieldValue={setFieldValue}
                      values={values}
                      required
                    />
                  </div>
                  {/* <div className='col-md-6 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='List of Offices'
                  name='list_of_offices'
                  label='D. Offices at (List the places where it has offices)'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required={true}
                />
              </div> */}
                  {/* <div className='col-md-6 col-sm-4 col-xs-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='List of Branches'
                  name='branches'
                  label=' Number of branch office and main branch'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div> */}
                </div>
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  E. Weather Income Tax Assessed
                </label>
                <div className='row'>
                  {statusOptions?.map((status) => (
                    <div className='col-lg-1' key={status.value}>
                      <FormRadio
                        containerClassName='col-lg-12'
                        label='Select Status'
                        name='income_tax_assess'
                        type='radio'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        checkBoxText={status?.label}
                        value={status?.value}
                        required={true}
                      />
                    </div>
                  ))}
                </div>
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  If Yes, please furnish PAN/VAT Number
                </label>
                <div className='row'>
                  <div className='col-md-6 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='PAN No.'
                      name='pan_number'
                      label='PAN NO.'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className='col-md-6 col-sm-6 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='VAT No.'
                      name='vat_number'
                      label='VAT NO.'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                {/* <div className='col-lg-6'>
              <EditableTables tables={tableConfigs} />
            </div> */}

                <div className='my-5 px-5'>
                  <button className='btn btn-primary mx-5' disabled={isSubmitting}>
                    Perform Screening
                  </button>
                  <button className='btn btn-primary' disabled={isSubmitting}>
                    Save Screening Response
                  </button>
                </div>
               
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  Acknowledgement
                </label>
                <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
                  <FormCheckbox
                    labelClassName='col-sm-12'
                    containerClassName='col-sm-12'
                    name='screening_verify'
                    touched={touched}
                    errors={errors}
                    onChange={handleChange}
                    checkBoxText={
                      'I hereby verified screening result and I will be responsible in further for this.'
                    }
                    required={true}
                  />
                </div>
                <div className='row py-4 align-items-center col-sm-12 col-xs-12'>
                  <FormCheckbox
                    labelClassName='col-sm-12'
                    containerClassName='col-sm-12'
                    name='screening_verify'
                    touched={touched}
                    errors={errors}
                    onChange={handleChange}
                    checkBoxText={
                      'I hereby verified sanction screening result and I will be responsible in further for this.'
                    }
                    required={true}
                  />
                </div>
              </div>

              <div className='d-flex justify-content-end my-5 px-5'>
                <div className='card-footer text-end'>
                  <button type='button' className='btn btn-secondary me-2'>
                    Cancel
                  </button>
                  {isSave && postdataloading ? (
                    <button
                      type='submit'
                      className='btn btn-primary me-2'
                      disabled={postdataloading}
                    >
                      Submitting...
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className='btn btn-primary me-2'
                      onClick={() => {
                        setIsSave(true)
                      }}
                    >
                      Submit
                    </button>
                  )}

                  {!isSave && postdataloading ? (
                    <button
                      type='submit'
                      className='btn btn-warning me-2'
                      disabled={postdataloading}
                    >
                      Saving as Draft...
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className='btn btn-warning me-2'
                      onClick={() => {
                        setIsSave(false)
                      }}
                    >
                      Save as Draft
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default reviewAndUpdateCorporate
