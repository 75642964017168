import axios from 'axios'
import { useQuery } from 'react-query'
import { GET_ALL_LINKED_ENTITIES } from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'LinkedEntitiesList'
interface IParamsModelLinkedEntites{
    status?: string,
    search?: string,
    page?: number,
    limit?: number,
    orderBy?: string,
    order?: string,
}
export const useGetAllLinkedEntitesLists = (params: IParamsModelLinkedEntites) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(GET_ALL_LINKED_ENTITIES, {
      params,
    })
    return data
  })
}
