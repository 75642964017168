  import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const postScreeningCheck = async (payload: any) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/screening-check`, payload)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostScreeningCheck = (setComplianceData: any) => {
  return useMutation(postScreeningCheck, {
    onSuccess: (response) => {
      if (response) {
        setComplianceData(response?.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostScreeningCheck

// import {useMutation} from 'react-query'
// import {toast} from 'react-toastify'

// const postmockScreeningCheck = async (payload: any) => {
//   // Mock data
//   const mockResponse = {
//     resCod: '200',
//     resDesc: 'Check Compliance Screening.',
//     data: {
//       screening_lists: [
//         {
//           screen_no: 8787999,
//           ofac_key: 'NBA-5805',
//           ent_num: null,
//           name: 'Shankar Pokhrel',
//           typeV: null,
//           address: 'Dang',
//           city: null,
//           state: null,
//           zip: null,
//           country: 'Nepal',
//           type_sort: null,
//           from_file: 'NBA',
//           source: 'NBA PEP List',
//           manual_ofac_id: null,
//           intEnt: null,
//           name2: 'Shankar Pokhrel',
//           DOB: null,
//           Metaphone: 'xnkrpkhrl',
//           Alternative_Script: null,
//           SoundexAplha: 'SANCARPACHRAL',
//           DOB_YEAR: null,
//           DOB_MONTH: null,
//           Other_Name: null,
//           insertion_time: '2024-07-11T21:30:27.907',
//           modification_time: '2024-07-11T21:30:27.907',
//           ACCUITY_UPDATE: null,
//           is_deleted: false,
//         },
//       ],
//       meta: {
//         current_page: 1,
//         perPage: 10,
//         total: 3,
//       },
//     },
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => resolve(mockResponse), 1000) // Simulate network delay
//   })
// }

// const postScreeningCheck = () => {
//   return useMutation(postmockScreeningCheck, {
//     onSuccess: (response: any) => {
//       if (response?.Code == '0') {
//         const customerData = response?.data?.[0]
//         console.log(customerData)
//         toast.success(response?.Message)
//       }
//     },
//     onError: (error: Error) => {
//       toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
//     },
//   })
// }

// export default postScreeningCheck
