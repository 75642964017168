import axios from 'axios'
import {useMutation, useQuery, UseQueryResult} from 'react-query'
import {GET_MASTER_DATA} from '../api/apiEndPoints/EndPoints'
import {toast} from 'react-toastify'

const QUERY_KEY = 'masterdata'

interface ErrorResponse {
  message: string
}

const fetchMasterData = async (): Promise<any> => {
  try {
    const {data} = await axios.get(GET_MASTER_DATA)
    return data
  } catch (error: any) {
    throw new Error(`Error fetching master data: ${error.message}`)
  }
}

export const useGetAllMasterData = (): UseQueryResult<any, ErrorResponse> => {
  return useQuery<any, ErrorResponse>(QUERY_KEY, fetchMasterData)
}

// const Master_key = 'masterdatabyID'
// const extractOptions = (masterData: any) => {
//   const options: {[key: string]: any[]} = {}
//   masterData?.data?.forEach((item: any) => {
//     const key = Object.keys(item)[0]
//     if (key) {
//       options[key] = item[key]
//     }
//   })
//   return options
// }

// export const useGetMasterDataByID = (params: string, isOpen: boolean) => {
//   return useQuery(
//     [Master_key, params],
//     async () => {
//       const {data} = await axios.get(GET_MASTER_DATA, {params})
//       const allMasterDataOptions = extractOptions(data)

//       return allMasterDataOptions
//     },
//     {
//       onError: (error) => {
//         console.error('Error fetching account schema:', error)
//       },
//       enabled: isOpen,
//     }
//   )
// }

const postMasterDataFilter = async (id: string) => {
  try {
    const response = await axios.get(`${GET_MASTER_DATA}`, {params: {account_type: [id]}})
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

export const useGetMasterDataFilter = (setMasterFilterData: React.Dispatch<any>) => {
  return useMutation((id: string) => postMasterDataFilter(id), {
    onSuccess: (response: any) => {
      const extractOptions = (masterData: any) => {
        const options: {[key: string]: any[]} = {}
        masterData?.data?.forEach((item: any) => {
          const key = Object.keys(item)[0]
          if (key) {
            options[key] = item[key]
          }
        })
        return options
      }

      const allMasterDataOptions = extractOptions(response)
      setMasterFilterData(allMasterDataOptions)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default useGetMasterDataFilter
