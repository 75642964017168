import {useRef, useEffect} from 'react'
import clsx from 'clsx'
import {AsideMenuMain} from './AsideMenuMain'
import {useSelector} from 'react-redux'
import {IModuleModel} from 'src/app/modules/siteSettings/components/moduleManager/Model'
import {isEmpty} from 'lodash'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'
import {useLocation} from 'react-router'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const module: any = useSelector((state: any) => state.auth.module)

  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [pathname])
  const subRoutesToExclude = [
    '/account/offline-account/individual_preliminary_form',
    '/account-opening-requests',
    '/new-cif-new-account',
    '/new-cif-new-account/create-new-account',
    '/account/offline-account/new-cif-new-account',
    '/new-cif-new-account/create-new-account/express-individual-preliminary-form',
    '/new-cif-new-account/create-new-account/normal-individual-preliminary-form',
    '/new-cif-new-account/create-new-account/express-corporate-preliminary-form',
    '/new-cif-new-account/create-new-account/normal-corporate-preliminary-form',
    '/new-cif-new-account/create-new-account/preliminary-form',
    '/new-cif-new-account/review-and-update/express-corporate-preliminary-form/:id',
    '/new-cif-new-account/review-and-update/normal-corporate-preliminary-form/:id',
    '/new-cif-new-account/review-and-update/normal-preliminary-form/:id',
    '/corporate-branch-checker-normal/review/:id',
    '/branch-checker-normal/review/:id',
    '/new-cif-new-account/qa-review-and-update/normal-preliminary-form/:id',
    '/new-cif-new-account/normal-preliminary-form/document-upload/:id',
    '/new-cif-new-account/corporate/upload-documents/:id',
    '/corporate-qa-normal/review/:id',
    '/new-cif-new-account/individual-joint-minor/kyc/:id',

    '/new-cif-new-account/corporate/kyc/:id',
    '/new-cif-and-account/cpu-checker/:id',
    '/cpu-maker/new-cif-and-account-list',
    '/new-cif-new-account/review-individual-joint-minor/kyc/:id',
    '/new-cif-and-account/individual/cpu-checker/:id',
    // '/update-kyc',
    // '/qa/account-opening-lists',

    // '/new-cif-new-accounts',
  ]
  // const routeData = !isEmpty(module?.moduleData)
  //   ? module?.moduleData?.map((parentData: any) => ({
  //     route: parentData.alias,
  //     title: parentData.name,
  //     subRoutes: parentData?.children
  //       ?.filter((item: any) => {
  //         return !item?.alias?.includes('update-faqs/:id')
  //       })
  //       .map((childrenData: IModuleModel) => ({
  //         route: childrenData.alias,
  //         title: childrenData.name,
  //       })),
  //   }))
  //   : []
  const routeData = !isEmpty(module?.moduleData)
    ? module?.moduleData.map((parentData: any) => ({
        route: parentData.alias,
        title: parentData.name,
        subRoutes: parentData?.children
          ?.filter((item: any) => {
            return !subRoutesToExclude.includes(item?.alias)
          })
          .map((childrenData: IModuleModel) => ({
            route: childrenData.alias,
            title: childrenData.name,
          })),
      }))
    : []
  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        {/* <AsideMenuMain /> */}

        <AsideMenuMain data={routeData} />
      </div>
    </div>
  )
}

export {AsideMenu}
