import axios from 'axios'
import {useQuery, UseQueryResult} from 'react-query'
import {GET_ALL_MASTER_DATA_ZONE_OPTIONS} from '../../api/apiEndPoints/EndPoints'

const QUERY_KEY = 'zonemasterdata'

interface ErrorResponse {
  message: string
}

const fetchZoneMasterData = async (): Promise<any> => {
  try {
    const {data} = await axios.get(GET_ALL_MASTER_DATA_ZONE_OPTIONS)
    return data
  } catch (error: any) {
    throw new Error(`Error fetching master data: ${error.message}`)
  }
}

export const useGetAllZoneData = (): UseQueryResult<any, ErrorResponse> => {
  return useQuery<any, ErrorResponse>(
    QUERY_KEY,
    fetchZoneMasterData
    // {staleTime: 6000000}
  )
}
