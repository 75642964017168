import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'
import {
  GET_NEW_CIF,
  GET_NORMAL_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'ListingNewCIFNewAccount'

export const useGetIndividualJointMinorNewCIFandAccountList = (params: ParamsModel) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(GET_NORMAL_NEW_CIF_NEW_ACCOUNT, {
      params,
    })
    return data
  })
}

const QUERY_KEYCIF = 'ListingNewCIFNewAccount'

export const useGetIndividualJointMinorNewCIF = (params: ParamsModel) => {
  return useQuery([QUERY_KEYCIF, params], async () => {
    const {data} = await axios.get(GET_NEW_CIF, {
      params,
    })
    return data
  })
}
