import axios from 'axios'
import {useQuery} from 'react-query'
import {
  GET_CORPORATE_NEW_CIF_BY_ID,
  GET_CORPORATE_NEW_CIF_NEW_ACCOUNT_BY_ID,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${GET_CORPORATE_NEW_CIF_NEW_ACCOUNT_BY_ID}`
const fetchCorporatePreliminariesDetailsById = async (id: any) => {
  try {
    const {data} = await axios.get(API_URL.replace(':id', id))
    return data
  } catch (error: any) {
    throw new Error(
      `Error fetching branch checker new CIF and account list by ID: ${error.message}`
    )
  }
}

const useGetCorporatePreliminariesDetailsById = (id: any) => {
  return useQuery(['corporateAccountList', id], () =>
    fetchCorporatePreliminariesDetailsById(id)
  )
}

export default useGetCorporatePreliminariesDetailsById

const fetchCorporatePreliminariesDetailsByIdCIF = async (id: any) => {
  try {
    const {data} = await axios.get(GET_CORPORATE_NEW_CIF_BY_ID.replace(':id', id))
    return data
  } catch (error: any) {
    throw new Error(
      `Error fetching branch checker new CIF and account list by ID: ${error.message}`
    )
  }
}

export const useGetCorporatePreliminariesDetailsByIdCIF = (id: any) => {
  return useQuery(['corporatePeliminariesDetailsByIdCIF', id], () =>
    fetchCorporatePreliminariesDetailsByIdCIF(id)
  )
}
