import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UploadState {
  isModalOpen: boolean
}

const initialState: UploadState = {
  isModalOpen: false,
}

const ModalSlice = createSlice({
  name: 'modalOpenSlice',
  initialState,
  reducers: {
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload
    },
  },
})

export const {setModalOpen} = ModalSlice.actions
export default ModalSlice.reducer
