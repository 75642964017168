import type {CollapseProps} from 'antd'
import {Collapse} from 'antd'
import {useParams} from 'react-router-dom'
import useGetLinkedEntitiesDetails from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesById'
import DetailsRow from '../../components/DetailsRow'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import ReviewStatusFormCPUChecker from '../../components/ReviewStatusFormCPUChecker'
import DetailView from 'src/app/modules/linkedEntities/common/components/detailView/detailView'
import {Spinner} from 'react-bootstrap-v5'
const CPUCheckerCorporateReviewCase = () => {
  const {id}: any = useParams()
  const {data: linkedEntities, isLoading: isDataloader} = useGetLinkedEntitiesDetails(id)
  const refinedLinkedEntitiesData = linkedEntities?.data
  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })
  const linkedRelationships =
    refinedLinkedEntitiesData?.relationship_data?.linked_relationships || []

  const items: CollapseProps['items'] = linkedRelationships.map(
    (
      relationship: {
        id: any
        relation_cif_number: string
        relationship_id: any
        relation_name: string
      },
      index: number
    ) => ({
      key: relationship.id,
      label: `${index + 1}. CIF Number: ${relationship.relation_cif_number}`,
      children: (
        <div className='row mb-4'>
          <div className='col-md-3'>
            <DetailView
              label={'Relation CIF Number'}
              value={relationship?.relation_cif_number || '-'}
            />
          </div>
          <div className='col-md-3'>
            <DetailView label={'Full Name'} value={relationship?.relation_name || '-'} />
          </div>
          <div className='col-md-3'>
            <DetailView
              label={'Declared Relation'}
              value={relationship?.relationship_id?.title || '-'}
            />
          </div>
        </div>
      ),
    })
  )


  const customExpandIcon = (panelProps: any) => (
    <span style={{color: '#1890ff', cursor: 'pointer'}}>
      {panelProps.isActive ? 'Hide Details' : 'View Details'}
    </span>
  )

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-9'>
          <h3 className='heading__component__title fw-bolder text-uppercase'>Linked Entities</h3>
          <h3 className='fw-bolder mt-5 mb-5'>Primary CIF Details</h3>
          {isDataloader ? (
            <>
              <Spinner animation={'border'} />
            </>
          ) : (
            <div className='row mb-4'>
              <div className='col-md-3'>
                <DetailView
                  label='Name of Institution/ Company'
                  value={refinedLinkedEntitiesData?.primary_customer_name || '-'}
                />
              </div>
              <div className='col-md-3'>
                <DetailView
                  label='Primary CIF Number'
                  value={refinedLinkedEntitiesData?.primary_cif_number || '-'}
                />
              </div>
              <div className='col-md-3'>
                <DetailView
                  label='Primary Customer Email'
                  value={refinedLinkedEntitiesData?.primary_customer_email || '-'}
                />
              </div>
              <div className='col-md-3'>
                <DetailView
                  label='Primary Account Number'
                  value={refinedLinkedEntitiesData?.primary_account_number || '-'}
                />
              </div>
            </div>
          )}
          <div className='mt-5'>
            <h3 className='fw-bolder mt-5 mb-5'>Linked Entites Lists</h3>
            <Collapse
              items={items}
              defaultActiveKey={['1']}
              expandIcon={customExpandIcon}
              expandIconPosition='end'
            />
          </div>
        </div>
      </div>
      <ReviewStatusFormCPUChecker />
    </div>
  )
}

export default CPUCheckerCorporateReviewCase
