import {FieldArray, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as placeReducer from 'src/app/modules/common'
import {
  OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_SUB_TITLE,
  OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_TITLE,
} from 'src/app/modules/new-cif-new-account/bankMaker/createNewAccountCorporate/constants/Contants'
import usePostPreliminariesDetails from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostPreliminariesDetails'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {RootState} from 'src/setup'

const NewCifNewAccountExpressIndividual = () => {
  const dispatch = useDispatch()

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: postIndividualExpressData, isLoading} = usePostPreliminariesDetails()

  const [masterData, setMasterData] = useState<any>()

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone} = useSelector((state: RootState) => state.place)

  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])

  const salutationOptions = [
    {label: 'Mr.', value: 'Mr.'},
    {label: 'Mrs', value: 'Mrs'},
  ]

  const accountTypeOptions = [
    {label: 'Individual', value: 'Individual'},
    {label: 'Joint', value: 'Joint'},
    {label: 'Minor', value: 'Minor'},
  ]

  const genderOptions = [
    {label: 'Upto Rs. 1 Lakhs', value: 'Upto Rs. 1 Lakhs'},
    {label: 'Upto Rs. 5 Lakhs', value: 'Upto Rs. 5 Lakhs'},
    {label: 'Upto Rs. 10 Lakhs', value: 'Upto Rs. 10 Lakhs'},
    {label: 'Upto Rs. 25 Lakhs', value: 'Upto Rs. 25 Lakhs'},
    {label: 'Upto Rs. 25 Lakhs', value: 'Upto Rs. 25 Lakhs'},
    {label: 'More than Rs. 25 Lakhs', value: 'More than Rs. 25 Lakhs'},
  ]

  const identificationOptions = [
    {label: 'National Id', value: 'National Id'},
    {label: 'Citizenship', value: 'Citizenship'},
    {label: 'Driving Licence', value: 'Driving Licence'},
    {label: 'Passport', value: 'Passport'},
    {label: 'Voter Id', value: 'Voter Id'},
    {label: 'Others', value: 'Others'},
  ]
  const RiskRating = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'Hight', value: 'High'},
  ]

  const handleSubmit = (values: any) => {
    const payload = {...values, form_addresses: {...values?.form_addresses?.[0]}}

    postIndividualExpressData(payload)
  }
  const initialValues = {
    opening_mode: 'normal',
    action: 'save and continue',
    account_type_id: 'c2b3aa2f-6ba0-4bf5-a8fe-3dee0f731812',
    salutation: 'Mr.',
    first_name: 'John',
    middle_name: 'Michael',
    last_name: 'Doe',
    gender: 'Male',
    marital_status: 'Single',
    date_of_birth_ad: '1990-01-01',
    date_of_birth_bs: '2046-09-17',
    nationality_id: '',
    id_type_id: '550e8400-e29b-41d4-a716-446655440000',
    identification_number: '1234567890',
    issuing_office: '550e8400-e29b-41d4-a716-446655440021',
    id_issued_date: '2010-01-01',
    id_expiry_date: '2025-01-01',
    issuing_district: '41a1a74b-f5f9-4860-8972-c3a20dc44a28',
    recommended_local_body: 'Kathmandu Metropolitan City',
    recommended_date: '2023-01-01',
    account_scheme_id: '',
    father_name: 'Robert Doe',
    grandfather_name: 'James Doe',
    screening_id: '23435',
    blacklist_screening: true,
    sanction_screening: false,
    customer_category_id: 'd1e26b95-4030-11ef-9071-02420a000905',
    aml_risk_rating: 'medium',
    occupation_id: 'c5d6cb08-f686-4be5-80cd-174228e8fb6b',
    other_occupation: 'Freelancer',
    income_source_id: 'ffae19fa-793f-4d51-b793-a4d7124575f2',
    other_income_source: 'Investments',
    total_annual_income: '100000',
    expected_monthly_income: '8000',
    form_addresses: [
      {
        permanent_country: '48691d0c-2489-4718-8596-10833123fce9 ',
        permanent_province: '9c5bd94d-2e19-4588-988a-c57759316b8a',
        permanent_zone: '0f53936e-efce-4aa9-9846-09089c4aa719',
        permanent_district: '41a1a74b-f5f9-4860-8972-c3a20dc44a28',
        permanent_municipality: 'fee3a02b-6ae4-406b-938b-cc06dc2444af',
        permanent_ward_number: '12',
        permanent_city: 'Kathmandu',
        permanent_street_name: 'Naxal',
        permanent_house_number: '10',
        permanent_fax_number: '87653421',
        permanent_mobile_number: '9328319197`',
        permanent_email: 'example@yopmail.com',
        permanent_google_plus_code: '7MRJ+6P Kathmandu',
        permanent_outside_country_post_box: '12345',
        permanent_outside_country_full_address: 'Naxal, Kathmandu, Nepal',
        is_present_address_different: true,
        present_country: '48691d0c-2489-4718-8596-10833123fce9 ',
        present_province: '9c5bd94d-2e19-4588-988a-c57759316b8a',
        present_zone: '0f53936e-efce-4aa9-9846-09089c4aa719',
        present_city: 'Lalitpur',
        present_district: '41a1a74b-f5f9-4860-8972-c3a20dc44a28',
        present_municipality: 'fee3a02b-6ae4-406b-938b-cc06dc2444af',
        present_ward_number: '15',
        present_street_name: 'Pulchowk',
        present_house_number: '20',
        present_fax_number: '87654321',
        present_mobile_number: '9873652622',
        present_email: 'present@yopmail.com',
        present_google_plus_code: '7MRJ+6P Lalitpur',
        present_outside_country_post_box: '54321',
        present_outside_country_full_address: 'Pulchowk, Lalitpur, Nepal',
      },
    ],
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body border-top pt-9 pb-9'>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          // validationSchema={NORMAL_INDIVIDUAL_VALIDATION}
        >
          {({values, errors, handleChange, touched, setFieldValue, isSubmitting}) => (
            <Form className='bg-white'>
              <div className='d-flex justify-content-start mb-3 text-align-center'>
                <h3 style={{marginRight: '0.8rem'}}>
                  {OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_TITLE}
                </h3>
                <span>{OFFLINE_ACCOUNT_OPENING_EXPRESS_INDIVIDUAL_SUB_TITLE}</span>
              </div>
              {/* Customer Name */}
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='First Name'
                    name='first_name'
                    label='A.Customer Name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Middle Name'
                    name='middle_name'
                    label='Middle Name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Last Name'
                    name='last_name'
                    label='Last Name'
                    onChange={handleChange}
                    errors={errors}
                    required
                    touched={touched}
                  />
                </div>
              </div>
              {/* Gender, Marital Status, Date of Birth */}
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Gender'
                    name='gender'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={masterData?.data[14]?.genders?.map((item: any) => ({
                      label: item.title?.toLocaleUpperCase() || '',
                      value: item.id,
                    }))}
                    // options={salutationOptions}
                    setFieldValue={setFieldValue}
                    required
                    values={values}
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    B. Marital Status
                  </label>
                  <div className='d-flex ms-5'>
                    {masterData?.data[15]?.marital_status?.map(({title, id}: any) => (
                      <FormRadio
                        key={id}
                        containerClassName=''
                        label='Marital Status'
                        name='marital_status'
                        type='radio'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        checkBoxText={title}
                        value={id}
                      />
                    ))}
                  </div>
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='date'
                    name='date_of_birth_ad'
                    label='C. Date of Birth(AD)'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                {/*date_of_birth_bs*/}
              </div>
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='date'
                    name='date_of_birth_bs'
                    label='Date of Birth(BS)'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>

                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    name='nationality_id'
                    label='D. Nationality'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={masterData?.data[18]?.nationalities?.map((item: any) => ({
                      label: item.title?.toLocaleUpperCase() || '',
                      value: item.id,
                    }))}
                    values={values}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
              </div>
              {/* Identification Number */}
              <div className='row'>
                <div className='col-md-12 col-sm-4 col-xs-12 pb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Identification
                  </label>
                  <div className='d-flex'>
                    {identificationOptions.map(({label, value}) => (
                      <FormRadio
                        key={value}
                        containerClassName=''
                        label='Identification'
                        name='identification'
                        type='radio'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        checkBoxText={label}
                        value={value}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className='row'>
                {/* Issuing District */}
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Issuing District'
                    name='issuing_district'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    values={values}
                    options={
                      district?.map((districtData) => ({
                        label:
                          typeof districtData.title === 'string'
                            ? districtData.title
                            : districtData.title.toString(),
                        value:
                          typeof districtData.id === 'string'
                            ? districtData.id
                            : districtData.id.toString(),
                      })) ?? []
                    }
                    // options={salutationOptions}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
                {/* Local Bodies */}
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Recommended Local Body'
                    name='recommended_local_body'
                    label='Recommended Local Body'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='date'
                    name='recommended_date'
                    label='Recommeded Date'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-6 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    name='account_scheme_id'
                    label='F. Account Scheme'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12 pb-5'>
                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    G. Account Type
                  </label>
                  <div className='d-flex'>
                    {accountTypeOptions.map(({label, value}) => (
                      <FormRadio
                        key={value}
                        containerClassName=''
                        label='Marital Status'
                        name='marital_status'
                        type='radio'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        checkBoxText={label}
                        value={value}
                      />
                    ))}
                  </div>
                </div>
              </div>
              {/* Father's Name Grand Fathers Name and Risk Rating */}
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder="Father's Name"
                    name='father_name'
                    label="Father's Name"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder="GrandFather's Name"
                    name='grandfather_name'
                    label="GrandFather's Name"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Risk Rating'
                    name='aml_risk_rating'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={RiskRating}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
              </div>
              {/* Father's Name Grand Fathers Name and Risk Rating */}
              {/* Occupation Details */}
              <div className='row'>
                <div className='col-md-6 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Occupation'
                    name='occupation_id'
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    options={masterData?.data[9]?.occupations?.map((item: any) => ({
                      label: item.title?.toLocaleUpperCase() || '',
                      value: item.id,
                    }))}
                    setFieldValue={setFieldValue}
                    required
                  />
                </div>
                <div className='col-md-6 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Other Occupation'
                    name='other_occupation'
                    label='Other Occupation'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              {/* Occupation Details */}
              {/* Income source */}
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Income Source'
                    name='income_source_id'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={masterData?.data[11]?.income_sources?.map((item: any) => ({
                      label: item.title?.toLocaleUpperCase() || '',
                      value: item.id,
                    }))}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Other Income Source'
                    name='other_income_source'
                    label='Other Income Source'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Total Annual Income'
                    name='total_annual_income'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={genderOptions}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select'
                    label='Total Annual Income'
                    name='total_annual_income'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={genderOptions}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
              </div>
              {/* Income source */}
              {/* Permanent Address Section */}

              <FieldArray
                name='form_addresses'
                render={(arrayHelpers) => (
                  <div>
                    {values.form_addresses && values.form_addresses.length > 0
                      ? values.form_addresses.map((friend, index) => (
                          <div key={index}>
                            <div className='row'>
                              <h5 className='form-section-heading'>Permanent Address</h5>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Country'
                                  label='Country'
                                  name={`form_addresses.[${index}].permanent_country`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={masterData?.data[19]?.countries.map((item: any) => ({
                                    label: item.title?.toLocaleUpperCase() || '',
                                    value: item.id,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].permanent_country}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Province'
                                  label='Province'
                                  name={`form_addresses.[${index}].permanent_province`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    province?.map((provinceData) => ({
                                      label:
                                        typeof provinceData.title === 'string'
                                          ? provinceData.title
                                          : provinceData.title.toString(),
                                      value:
                                        typeof provinceData.id === 'string'
                                          ? provinceData.id
                                          : provinceData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].permanent_province}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Zone'
                                  label='Zone'
                                  name={`form_addresses.[${index}].permanent_zone`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    zone?.map((zoneData) => ({
                                      label:
                                        typeof zoneData.title === 'string'
                                          ? zoneData.title
                                          : zoneData.title.toString(),
                                      value:
                                        typeof zoneData.id === 'string'
                                          ? zoneData.id
                                          : zoneData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].permanent_zone}
                                  required
                                />
                              </div>

                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select District'
                                  label='District'
                                  name={`form_addresses.[${index}].permanent_district`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    district?.map((districtData) => ({
                                      label:
                                        typeof districtData.title === 'string'
                                          ? districtData.title
                                          : districtData.title.toString(),
                                      value:
                                        typeof districtData.id === 'string'
                                          ? districtData.id
                                          : districtData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].permanent_district}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Ward Number'
                                  name={`form_addresses.[${index}].permanent_ward_number`}
                                  label='Ward Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={2}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent City'
                                  name={`form_addresses.[${index}].permanent_city`}
                                  label='Permanent City'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Street Name'
                                  name={`form_addresses.[${index}].permanent_street_name`}
                                  label='Street Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent House Number'
                                  name={`form_addresses.[${index}].permanent_house_number`}
                                  label='Permanent House Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent Fax Number'
                                  name={`form_addresses.[${index}].permanent_fax_number`}
                                  label='Permanent Fax Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent Mobile Number'
                                  name={`form_addresses.[${index}].permanent_mobile_number`}
                                  label='Permanent Mobile Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Google Code'
                                  name={`form_addresses.[${index}].permanent_google_plus_code`}
                                  label='Google Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent Outside Country Post Box'
                                  name={`form_addresses.[${index}].permanent_outside_country_post_box`}
                                  label='Permanent Outside Country Post Box'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Permanent Outside Country Full Address'
                                  name={`form_addresses.[${index}].permanent_outside_country_full_address`}
                                  label='Permanent Outside Country Full Address'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            {/* Permanent Address Section */}
                            <div>
                              <h5 className='form-section-heading'>N. Present Address</h5>
                              <FormCheckbox
                                labelClassName='col-sm-12'
                                containerClassName='col-sm-12'
                                name={`form_addresses.[${index}].same_as_permanent_address`}
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                checkBoxText={'Same As Permanent Address'}
                                required={true}
                              />
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Country'
                                  label='Country'
                                  name={`form_addresses.[${index}].present_country`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={masterData?.data[19]?.countries.map((item: any) => ({
                                    label: item.title?.toLocaleUpperCase() || '',
                                    value: item.id,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].present_country}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Province'
                                  label='Province'
                                  name={`form_addresses.[${index}].present_province`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    province?.map((provinceData) => ({
                                      label:
                                        typeof provinceData.title === 'string'
                                          ? provinceData.title
                                          : provinceData.title.toString(),
                                      value:
                                        typeof provinceData.id === 'string'
                                          ? provinceData.id
                                          : provinceData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].present_province}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select Zone'
                                  label='Zone'
                                  name={`form_addresses.[${index}].present_zone`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    zone?.map((zoneData) => ({
                                      label:
                                        typeof zoneData.title === 'string'
                                          ? zoneData.title
                                          : zoneData.title.toString(),
                                      value:
                                        typeof zoneData.id === 'string'
                                          ? zoneData.id
                                          : zoneData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].present_zone}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select District'
                                  label='District'
                                  name={`form_addresses.[${index}].present_district`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={
                                    province?.map((provinceData) => ({
                                      label:
                                        typeof provinceData.title === 'string'
                                          ? provinceData.title
                                          : provinceData.title.toString(),
                                      value:
                                        typeof provinceData.id === 'string'
                                          ? provinceData.id
                                          : provinceData.id.toString(),
                                    })) ?? []
                                  }
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  arrValue={values.form_addresses[index].present_district}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='present Municipality'
                                  name={`form_addresses.[${index}].present_municipality`}
                                  label='present Municipality'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='present Ward Number'
                                  name={`form_addresses.[${index}].present_ward_number`}
                                  label='present Ward Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={2}
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='present City'
                                  name={`form_addresses.[${index}].present_city`}
                                  label='present City'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Street Name'
                                  name={`form_addresses.[${index}].present_street_name`}
                                  label='Street Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='House Number'
                                  name={`form_addresses.[${index}].present_house_number`}
                                  label='House Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Fax Number'
                                  name={`form_addresses.[${index}].present_fax_number`}
                                  label='Fax Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Mobile Number'
                                  name={`form_addresses.[${index}].present_mobile_number`}
                                  label='Mobile Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Email'
                                  name={`form_addresses.[${index}].present_email`}
                                  label='Email'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Google Plus Code'
                                  name={`form_addresses.[${index}].present_google_plus_code`}
                                  label='Google Plus Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Outside Country Post Box'
                                  name={`form_addresses.[${index}].present_outside_country_post_box`}
                                  label='Outside Country Post Box'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Outside Country Full Address'
                                  name={`form_addresses.[${index}].present_outside_country_full_address`}
                                  label='Outside Country Full Address'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                    {/* <div>
                      <button type='submit'>Submit</button>
                    </div> */}
                  </div>
                )}
              />

              <div className='row'>
                <h5 className='form-section-heading'>Permanent Address</h5>

                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select Country'
                    label='Country'
                    name={`form_addresses.permanent_country`}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={masterData?.data[19]?.countries.map((item: any) => ({
                      label: item.title?.toLocaleUpperCase() || '',
                      value: item.id,
                    }))}
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select Province'
                    label='Province'
                    name='form_addresses.permanent_province'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={
                      province?.map((provinceData) => ({
                        label:
                          typeof provinceData.title === 'string'
                            ? provinceData.title
                            : provinceData.title.toString(),
                        value:
                          typeof provinceData.id === 'string'
                            ? provinceData.id
                            : provinceData.id.toString(),
                      })) ?? []
                    }
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormSelect
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    placeholder='Select Zone'
                    label='District'
                    name='form_addresses.permanent_zone'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    options={
                      district?.map((districtData) => ({
                        label:
                          typeof districtData.title === 'string'
                            ? districtData.title
                            : districtData.title.toString(),
                        value:
                          typeof districtData.id === 'string'
                            ? districtData.id
                            : districtData.id.toString(),
                      })) ?? []
                    }
                    setFieldValue={setFieldValue}
                    values={values}
                    required
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Ward Number'
                    name='form_addresses.permanent_ward_number'
                    label='Ward Number'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Permanent City'
                    name='form_addresses.permanent_city'
                    label='Permanent City'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Street Name'
                    name='form_addresses.permanent_street_name'
                    label='Street Name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
              </div>

              <div className='d-flex justify-content-end my-5 px-5'>
                {isLoading ? (
                  <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    Submitting...
                  </button>
                ) : (
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default NewCifNewAccountExpressIndividual
