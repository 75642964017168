import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
    POST_CPU_MAKER_LINKED_ENTITIES_DATA
} from 'src/app/queries/api/apiEndPoints/EndPoints'

type LinkedEntityData = {
  action: string;
  primary_cif_number: string;
  primary_account_number: string;
  primary_customer_name: string;
  primary_customer_email: string;
  branch_code: string;
  linked_relations: Array<{
    relation_cif_number: string;
    relationship_id: string;
    unlink_flag?: boolean;
    reason?: string;
    comment?: string;
    unlink_documents?: string[];
  }>;
}

const postLinkedEntities = async (payload: LinkedEntityData) => {
  try {
    const response = await axios.post(POST_CPU_MAKER_LINKED_ENTITIES_DATA, payload)
    return response.data
  } catch (error: any) {
    // Enhanced error logging for better insights
    console.error('Error posting linked entities:', error);
    // Use optional chaining and a fallback to handle errors safely
    throw error?.response?.data || error?.message || 'An unexpected error occurred'
  }
}

const usePostCPUMakerLinkedEntities = () => {
  const queryClient = useQueryClient()

  return useMutation(postLinkedEntities, {
    onSuccess: (data) => {
      toast.success(data?.resDesc ?? 'Linked entities stored successfully.')
      queryClient.invalidateQueries('LinkedEntitiesList')
    },
    onError: (error: any) => {
      // Improved error handling to check for specific error structures
      if (error?.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error?.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default usePostCPUMakerLinkedEntities;
