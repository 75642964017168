import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postCheckAll = async (payload: typePayload) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/check-all
    `,
      payload
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCheckAll = (
  setCheckAllData?: any,
  setcheckAllDataMinor?: any,
  setcheckAllDataJoint?: any,
  setAccumulatedFilePaths?: any,
  update?: any
) => {
  return useMutation(postCheckAll, {
    onSuccess: (response: any, index: any, data: any) => {
      if (response) {
        if (update?.current === 'default') {
          setCheckAllData(response?.data)
        }
        if (update?.current === 'minor') {
          setcheckAllDataMinor(response?.data)
        }

        if (update?.current === 'joint') {
          setcheckAllDataJoint((prevData: any) => [
            ...(Array.isArray(prevData) ? prevData : []), // Ensure prevData is an array
            {
              ...response?.data,
              cif_number: index?.unique_id_no,
            },
          ])
        }
        if (response?.data?.data?.file_path) {
          setAccumulatedFilePaths &&
            setAccumulatedFilePaths((prev: any) => [
              ...prev,
              {
                file_name: response?.data?.data?.file_name,
                file_path: response?.data?.data?.file_path,
              },
            ])
        }

        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCheckAll

//

// if (response?.data?.data?.file_path) {
//   setAccumulatedFilePaths((prev: any) => [
//     ...prev,
//     {
//       file_name: response?.data?.data?.file_name,
//       file_path: response?.data?.data?.file_path,
//     },
//   ])
// }

// import {useMutation} from 'react-query'
// import {toast} from 'react-toastify'

// interface Iprops {
//   setCheckAllData: any
// }
// const postMockPdfCheck = async (payload: any) => {

//   const mockResponse: any = {
//     resCod: '200',
//     resDesc: 'Operation Successful',
//     data: {
//       pdfFilePath: 'https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf',
//     },
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => resolve(mockResponse), 1000) // Simulate network delay
//   })
// }

// const usePostCheckAll = ({
//   setCheckAllData,
//   setcheckAllDataMinor,
//   setcheckAllDataJoint,
//   setAccumulatedFilePaths,
//   update,
// }: any) => {
//   return useMutation(postMockPdfCheck, {
//     onSuccess: (response: any, index: any, data: any) => {
//       if (response) {
//         if (update.current === 'default') {
//           setCheckAllData(response)
//         } else if (update.current === 'minor') {
//           setcheckAllDataMinor(response)
//         } else if (update.current === 'joint') {
//           setcheckAllDataJoint((prevData: any) => [
//             ...prevData,
//             {...response.data, cif_number: index.unique_id_no},
//           ])
//         }

//         // let modifiedFilePath = ''
//         // if (checkAllData?.data?.data?.file_path) {
//         //   const parts = checkAllData.data.data.file_path.split('/public/')
//         //   modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`

//         //   // Add the current file to the accumulated file paths
//         //   setAccumulatedFilePaths((prev) => [
//         //     ...prev,
//         //     {
//         //       file_name: modifiedFilePath.split('/').pop(),
//         //       file_path: modifiedFilePath,
//         //     },
//         //   ])
//         // }

//         if (response?.data?.pdfFilePath) {
//           setAccumulatedFilePaths((prev: any) => [
//             ...prev  ,
//             {
//               file_name: response?.data?.pdfFilePath.split('/').pop(),
//               file_path: response?.data?.pdfFilePath,
//             },
//           ])
//         }

//         toast.success(response?.resDesc)
//       }
//     },
//     onError: (error: Error) => {
//       toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
//     },
//   })
// }

// export default usePostCheckAll
