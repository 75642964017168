import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import useGetPreliminariesDetailsById, {
  useGetPreliminariesDetailsByIdCIF,
} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePatchKycDetailsIndividual from 'src/app/queries/new-cif-new-account-queries/cpuMaker/kycIndividual/patch/usePatchKycDetailsIndividual'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import AccountStatusLog from '../../../../common/statusLogTable/AccountStatusLog'
import CpuMakerAccountOpeningForm from '../../../newCifNewAccountOpeningForm/cpuMakerAccountOpeningForm'
import KycFormNormalIndividualJointMinorCIF from './kycFormIndividualJointMinor'
import {UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'

const KycUpdateFormNormalIndividualJointMinorCIF = () => {
  interface IIDPROPS {
    id: string
  }
  const {id} = useParams<any>()
  const history = useHistory()
  const [btnFlag, setBtnFlag] = useState('')
  const {data: preliminaryDetailsByID, isLoading: detailsLoading} =
    useGetPreliminariesDetailsByIdCIF(id)
  const dataStatus = preliminaryDetailsByID?.data
  const {mutate: updateKycDetails, isLoading: updateLoading} = usePatchKycDetailsIndividual()
  const [kycFormSubmitted, setKycFormSubmitted] = useState(false)

  const [activeKey, setActiveKey] = useState('updateKyc')

  const data = preliminaryDetailsByID?.data
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div className='card mb-5 mb-xl-10' style={{position: 'sticky', top: '80px'}}>
            <div className='card-body  '>
              {' '}
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body border-top '>
              <div className='d-flex justify-content-start mb-3 text-align-center'>
                <h3 style={{marginRight: '0.8rem'}}>CIF FORM</h3>
                <span>Individual/ Minor</span>
              </div>
              <Tabs
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k ?? 'updateKyc')}
                id='cpuMaker-new-cif-tabs'
                className='mb-5'
                data-cy='new-cif-list-tabs'
              >
                <Tab eventKey='updateKyc' title='Update CIF' data-cy='tab-kyc-form'>
                  <div data-cy='tab-content-kyc-form'>
                    <KycFormNormalIndividualJointMinorCIF
                      id={id}
                      data={dataStatus}
                      setActiveKey={setActiveKey}
                      setKycFormSubmitted={setKycFormSubmitted}
                    />
                  </div>
                </Tab>
                {/* <Tab
                  eventKey='accountOpening'
                  title='Account Opening'
                  data-cy='tab-account-opening'
                >
                  <div data-cy='tab-content-account-opening'>
                    <CpuMakerAccountOpeningForm />
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
        <AccountStatusLog data={dataStatus} />
        <UserActivityLogAll caseId={id} modalName={'Amnil\\SharedModels\\NewCIF'} />
      </div>
    </>
  )
}

export default KycUpdateFormNormalIndividualJointMinorCIF
