import {Form, Formik} from 'formik'
import {useState} from 'react'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {useUpdateCorporateAccountPreliminaryCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporatePreliminaries'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {RootState} from 'src/setup'

import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import {adToBs} from '@sbmdkl/nepali-date-converter'
import {CORPORATE_NORMAL_FORM_VALIDATION} from '../utils/validations/ValidationSchemas'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'

interface RouteParams {
  id: string
}
const DocumentUploadForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: updateExpressCorporateAccountPreliminary} =
    useUpdateCorporateAccountPreliminaryCIF()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)

  const [masterData, setMasterData] = useState<any>()
  const [isSave, setIsSave] = useState(false)

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone, municipality} = useSelector((state: RootState) => state.place)
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])

  const handleSubmit = (values: any, actions: any) => {
    const payload = {...values, id}
    payload.action = isSave ? 'save_and_continue' : 'save'
    updateExpressCorporateAccountPreliminary(payload, {
      onSuccess: () => {
        actions.setSubmitting(false)
        history.go(-1)
      },
      onError: (error) => {
        actions.setSubmitting(false)
      },
    })
    // history.push('new-cif-new-account-lists')
  }

  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterDataLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <section>
            <div className='row'>
              {' '}
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={corporateDataById?.data?.branch_code}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={corporateDataById?.data?.customer_code}
              />
              <h3 className='fw-bolder mb-4 text-capitalize'>Company Information</h3>
              <FormDetails
                key='institution_name'
                label='Name of Institution'
                value={corporateDataById?.data?.institution_name}
              />
              {getFieldValue(corporateDataById?.data?.constitution_id, 'title') ===
              'Other (specify)' ? (
                <FormDetails
                  key='other_constitution'
                  label='Other Constitution'
                  value={corporateDataById?.data?.other_constitution}
                />
              ) : getFieldValue(corporateDataById?.data?.constitution_id, 'title') ===
                'FOREIGN ENTERPRISE' ? (
                <>
                  <FormDetails
                    key='foreign_company_name'
                    label='Foreign Institution Name'
                    value={corporateDataById?.data.foreign_company_name}
                  />
                  <FormDetails
                    key='foreign_company_address'
                    label='Foreign Institution Address'
                    value={corporateDataById?.data?.foreign_company_address}
                  />
                </>
              ) : (
                <FormDetails
                  key='constitution_id'
                  label='Constitution'
                  value={getFieldValue(corporateDataById?.data?.constitution_id, 'title')}
                />
              )}
              <FormDetails
                key='registration_number'
                label='Registration Number'
                value={corporateDataById?.data?.registration_number}
              />
              <FormDetails
                key='registration_type'
                label='Registered With'
                value={getFieldValue(corporateDataById?.data?.registration_with_id, 'title')}
              />
              <FormDetails
                key='registration_date_ad'
                label='Registration Date (AD)'
                value={corporateDataById?.data?.registration_date_ad}
              />
              <FormDetails
                key='registration_date_bs'
                label='Registration Date (BS)'
                value={corporateDataById?.data?.registration_date_bs}
              />
              <FormDetails
                key='registration_expiry_date_ad'
                label='Registration Expiry Date (AD)'
                value={corporateDataById?.data?.registration_expiry_date_ad}
              />
              <FormDetails
                key='registration_expiry_date_bs'
                label='Registration Expiry Date (BS)'
                value={corporateDataById?.data?.registration_expiry_date_bs}
              />
              <FormDetails
                key='aml_risk_rating'
                label='AML Risk Rating'
                value={getFieldValue(corporateDataById?.data?.aml_risk_rating, 'title')}
              />
              <FormDetails
                key='sub_risk_category_id'
                label='AML Risk Sub-Category Rating'
                value={getFieldValue(corporateDataById?.data?.sub_risk_category_id, 'title')}
              />
            </div>
          </section>
          <hr />
          <section>
            <h3 className='fw-bolder mb-4 text-capitalize'>Registered Address</h3>
            <div className='row'>
              <FormDetails
                key='country'
                label='Country'
                value={corporateDataById?.data?.registered_office_country?.title}
              />
              {corporateDataById?.data?.registered_office_country.title === 'NEPAL' ? (
                <>
                  <FormDetails
                    key='province'
                    label='Province'
                    value={corporateDataById?.data?.registered_office_province.title}
                  />
                  <FormDetails
                    key='zone'
                    label='Zone'
                    value={corporateDataById?.data?.registered_office_zone?.title}
                  />
                  <FormDetails
                    key='district'
                    label='District'
                    value={corporateDataById?.data?.registered_office_district?.title}
                  />
                  <FormDetails
                    key='municipality'
                    label='Municipality'
                    value={corporateDataById?.data?.registered_office_municipality?.title}
                  />

                  <FormDetails
                    key='ward_no'
                    label='Ward Number'
                    value={corporateDataById?.data?.registered_office_ward_number}
                  />
                  <FormDetails
                    key='registered_office_street_name'
                    label=' Tole'
                    value={corporateDataById?.data?.registered_office_street_name}
                  />
                  <FormDetails
                    key='registered_office_house_number'
                    label=' House Number'
                    value={corporateDataById?.data?.registered_office_house_number}
                  />

                  <FormDetails
                    key='registered_office_mobile_number'
                    label=' Mobile Number'
                    value={corporateDataById?.data?.registered_office_mobile_number}
                  />
                  <FormDetails
                    key='registered_office_email'
                    label=' Email'
                    value={corporateDataById?.data?.registered_office_email}
                  />
                </>
              ) : (
                <>
                  <FormDetails
                    key='registered_office_outside_country_post_box'
                    label='Foreign Country Post Box No.'
                    value={corporateDataById?.data?.registered_office_outside_country_post_box}
                  />
                  <FormDetails
                    key='registered_office_outside_country_full_address'
                    label='Foreign Country Address  '
                    value={corporateDataById?.data?.registered_office_outside_country_full_address}
                  />
                  <FormDetails
                    key='registered_office_mobile_number'
                    label=' Mobile Number'
                    value={corporateDataById?.data?.registered_office_mobile_number}
                  />
                  <FormDetails
                    key='registered_office_email'
                    label=' Email'
                    value={corporateDataById?.data?.registered_office_email}
                  />
                </>
              )}
            </div>
          </section>
          <hr />
          <section>
            <div className='row'>
              <h3 className='fw-bolder mb-4 text-capitalize'>Business Details</h3>
              <FormDetails
                key='business_nature_id'
                label='Nature of Industry/ Business'
                value={corporateDataById?.data?.business_nature_id?.title}
              />
              {corporateDataById?.data?.pan_number && (
                <FormDetails
                  key='pan_number'
                  label='Pan Number'
                  value={corporateDataById?.data?.pan_number}
                />
              )}
              {corporateDataById?.data?.vat_number && (
                <FormDetails
                  key='vat_number'
                  label='Vat Number'
                  value={corporateDataById?.data?.vat_number}
                />
              )}
              <FormDetails
                key='estimated_annual_sales_revenue'
                label='Estimated Annual Sales / Revenue'
                value={corporateDataById?.data?.expected_annual_turnover}
              />
              {/* <FormDetails
                key='expected_annual_transaction_number'
                label='Annual Transaction Number'
                value={corporateDataById?.data?.expected_annual_transaction_number?.title}
              /> */}
            </div>
          </section>
          <hr />
          <section>
            <div className='row'>
              <h3 className='fw-bolder mb-4 text-capitalize'>Risk Info</h3>

              <FormDetails
                key='pep_status'
                label='Pep Status'
                value={corporateDataById?.data?.pep_status}
              />
              {corporateDataById?.data?.pep_information && (
                <FormDetails
                  key='pep_information'
                  label='Pep Information'
                  value={corporateDataById?.data?.pep_information}
                />
              )}
            </div>
          </section>
          <hr />
        </div>
      </div>
    </>
  )
}

export default DocumentUploadForm
