import {Form, Formik} from 'formik'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import Modal from 'rsuite/Modal'
import {StateParamsModel} from 'src/app/modules/common/Model'
import checkMultiLanguage from 'src/app/modules/common/checkMultiLanguage'
import * as user from 'src/app/modules/userMgmt'
import * as departmentRedux from 'src/app/modules/userMgmt/components/departments'
import * as branchRedux from 'src/app/modules/network/components/branch'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import useGetUserById from 'src/app/queries/userManagement/user/get/useGetUserById'

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType: string
  editSelectedData?: any
}

interface Province {
  id: string
  title: string
  titleNp: string
  code: number
}

interface BranchWithProvince {
  province: Province
}

interface Branch {
  id?: string
}

export interface User {
  name: string
  branch_id: string
  userName: string
  email: string
  mobile_number: string
  departmentId: string
  grievance_manager: boolean
  vendor: boolean
  provinces: string | string[] | {id: string}[]
  branches: string | string[] | {id: string}[]
}

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Must not contain number or symbol'),
  userName: Yup.string()
    .required('Username is required')
    .matches(/^[a-zA-Z0-9_ ]+$/, 'Must not contain any symbol'),
  email: Yup.string()
    .required('Email is required')
    .email('Please provide valid email')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please provide valid email'),
  mobile_number: Yup.string()
    .required('Mobile No. is required')
    .matches(/^(98|97)([0-9]{8})$/, 'Enter valid phone number')
    .max(10, 'Phone number should not exceed 10 digits'),
  branch_id: Yup.string().required('Main Branch is required.'),
  branches: Yup.array()
    .min(1, 'At least one branch must be selected')
    .of(Yup.string().required('Branch is required'))
    .required('Please select at least one branch'),
})

const AddUser = ({open, params, handleClose, actionType, editSelectedData}: Props) => {
  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const userId = editSelectedData?.id
  const {data: userDetailsById} = useGetUserById(userId)
  const userData = userDetailsById?.data
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedBranch, setSelectedBranch] = useState('')
  const {loading, success} = useSelector((state: any) => state.userManagement)
  const {loading: branchLoading, success: successBranch} = useSelector((state: any) => state.branch)
  const {
    data: {department},
  } = useSelector((state: any) => state.departments)

  const {
    data: {branch},
  } = useSelector((state: any) => state.branch)

  useEffect(() => {
    dispatch(departmentRedux.actions.getDepartment())
    dispatch(branchRedux.actions.getAllBranchList())
    dispatch(branchRedux.actions.getBranchData())
  }, [dispatch])

  const departmentOptions = department.map((items: {[key: string]: string}) => ({
    label: items.name,
    value: items.id,
  }))
  const branchOptions = branch.map((items: {[key: string]: string}) => ({
    label: items.title,
    value: items.id,
  }))

  useEffect(() => {
    if (success) {
      dispatch(user?.actions.getUsers(params))
      isEmpty(editSelectedData)
        ? toast.success('User added successfully')
        : toast.success('User edited successfully')
      dispatch(user?.actions?.resetUsers())
      handleClose()
    }
  }, [success, dispatch, editSelectedData, handleClose, params])

  const multiLanguage = checkMultiLanguage()

  return (
    <div className='modal-container' data-cy='modal-user-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Header>
          <Modal.Title data-cy='modal-title'>{actionType} User </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: userData?.name || '',
                userName: userData?.userName || '',
                email: userData?.email || '',
                mobile_number: userData?.mobile_number || '',
                departmentId: userData?.departmentId || '',
                branch_id: userData?.branch?.id || '',
                branches:
                  userData?.associated_branches?.branch?.map((branch: any) => branch.id) || [],
                provinces:
                  userData?.associated_provinces?.province?.map((province: any) => province.id) ||
                  [],
                grievance_manager: editSelectedData?.grievance_manager || false,
                vendor: editSelectedData?.vendor || false,
              }}
              validationSchema={FORM_VALIDATION}
              onSubmit={(values) => {
                const transformedValues = {
                  ...values,
                  provinces: Array.isArray(values.provinces)
                    ? values.provinces.map((id: any) => ({id}))
                    : values.provinces
                    ? [{id: values.provinces}]
                    : [],
                  branches: Array.isArray(values.branches)
                    ? values.branches.map((id: any) => ({id}))
                    : values.branches
                    ? [{id: values.branches}]
                    : [],
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(user.actions.updateUsers(transformedValues, editSelectedData?.id))
                } else {
                  dispatch(user.actions.addUsers(transformedValues))
                }
              }}
            >
              {({touched, handleChange, errors, setFieldValue, values}) => {
                useEffect(() => {
                  if (values.branch_id) {
                    setSelectedBranch(values.branch_id)
                  }
                }, [values.branch_id])

                useEffect(() => {
                  if (!isEmpty(editSelectedData)) {
                    const fields = [
                      'name',
                      'userName',
                      'email',
                      'mobile_number',
                      'departmentId',
                      'branch_id',
                      'branches',
                      'provinces',
                      'grievance_manager',
                      'vendor',
                    ]
                    fields.forEach((field) => setFieldValue(field, editSelectedData[field], false))
                  }
                }, [editSelectedData, setFieldValue])

                return (
                  <Form>
                    <div className='card-body border-top pt-5' data-cy='card-user-body'>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Full Name'
                            label='Name'
                            name='name'
                            disabled={!isEmpty(editSelectedData)}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            value={values.name}
                            data-cy='add-user-full-name-text-field'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-md-12'
                            type='text'
                            placeholder='Username'
                            label='Username'
                            name='userName'
                            disabled={!isEmpty(editSelectedData)}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            value={values.userName}
                            data-cy='add-user-name-text-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            containerClassName='col-md-12'
                            type='email'
                            placeholder='Email'
                            name='email'
                            disabled={!isEmpty(editSelectedData)}
                            label='Email'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            value={values.email}
                            data-cy='add-user-name-email-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12 pb-0 mb-0'>
                          <FormTextBox
                            containerClassName='col-12'
                            type='text'
                            placeholder='Enter Mobile Number'
                            label='Mobile Number'
                            name='mobile_number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            value={values.mobile_number}
                            data-cy='add-user-number-text-field'
                          />
                          <div className='d-flex justify-content-end'>
                            <em>
                              <small className='d-block'>
                                <span className='text-danger' data-cy='text-user-danger'>
                                  Note:{' '}
                                </span>{' '}
                                Mobile number should starts with 98|97
                              </small>
                            </em>
                          </div>
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Department'
                            name='departmentId'
                            isClearable={true}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={departmentOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            arrValue={values.departmentId}
                            data-cy='add-user-department-dropdown-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Main Branch'
                            name='branch_id'
                            isClearable={true}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={branchOptions}
                            values={values}
                            required={true}
                            setFieldValue={setFieldValue}
                            arrValue={values.branch_id}
                            data-cy='add-user-branch-dropdown-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Branch'
                            name='branches'
                            isClearable={true}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={branchOptions}
                            values={values}
                            required={true}
                            multiple
                            setFieldValue={setFieldValue}
                            arrValue={values.branches}
                            data-cy='add-user-branches-dropdown-field'
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select'
                            label='Provinces'
                            name='provinces'
                            isClearable={true}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={provinceOptions}
                            values={values}
                            setFieldValue={setFieldValue}
                            arrValue={values.provinces}
                            data-cy='add-user-provinces-dropdown-field'
                          />
                        </div>
                        <div className='d-flex justify-content-end px-9 '>
                          <button
                            type='submit'
                            disabled={loading}
                            className='btn btn-primary btn-sm ms-3'
                            data-cy='modal-submit'
                          >
                            Submit
                          </button>
                          <button
                            type='button'
                            onClick={handleClose}
                            className='btn btn-secondary btn-sm ms-3'
                            data-cy='modal-cancel'
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddUser
