import RemindOutlineIcon from '@rsuite/icons/RemindOutline'
import Modal from 'rsuite/Modal'

interface Props {
  isOpen?: boolean
  handleClick: () => void
  handleClose: () => void
  title?: string
  isDeleteLoading?: boolean
}
const DeleteModalSchema = ({isOpen, handleClick, handleClose, title, isDeleteLoading}: Props) => {
  return (
    <>
      <Modal
        backdrop='static'
        role='alertdialog'
        open={isOpen}
        size='xs'
        enforceFocus={false}
        data-cy='modal'
      >
        <Modal.Body>
          <div className='d-flex'>
            <RemindOutlineIcon
              style={{
                color: '#ffb300',
                fontSize: 24,
              }}
            />
            <h4 data-cy='modal-title'>
              <strong className='ms-3'>Are you sure you want to {title || 'Delete'}?</strong>
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClick}
            className='btn btn-primary btn-sm ms-3'
            data-cy='modal-submit'
            disabled={isDeleteLoading}
          >
            {isDeleteLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></span>
                Deleting...
              </>
            ) : (
              'Ok'
            )}
          </button>
          <button
            onClick={handleClose}
            className='btn btn-secondary btn-sm ms-3'
            data-cy='modal-cancel'
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteModalSchema
