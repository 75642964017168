export const TableParams = {
  page: 1,
  limit: 10,
  status: undefined,
  search: '',
  order: 'desc',
  orderBy: '',
}

export const TableParamsSchema = {
  schema_type: '',
  accountCategoryId: '',
  page: 1,
  limit: 10,
  status: undefined,
  search: '',
  order: 'desc',
  orderBy: '',
}

export const TableParamsRiskSubCategory = {
  page: 1,
  limit: 10,
  status: undefined,
  search: '',
  risk_category_id: '',
  order: 'desc',
  orderBy: '',
}

export const TableParamsPepSubCategory = {
  page: 1,
  limit: 10,
  status: undefined,
  search: '',
  pep_category_id: '',
  order: 'desc',
  orderBy: '',
}

export const updateKycParams = {
  cif_number: '',
  is_corporate: true,
}
