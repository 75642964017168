import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  GET_NEW_CIF_Corporate,
  UPDATE_NEW_CIF_BM,
  UPDATEKYCDETAILS,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

// type typePayload = {
//   id: string
//   data: {
//     action?: string
//     remarks?: string
//   }
// }

const patchKYCUpdateIndividual = async (payload: any) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATEKYCDETAILS}/${id}/update-kyc-details`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePatchKycDetailsIndividual = () => {
  const queryClient = useQueryClient()
  return useMutation(patchKYCUpdateIndividual, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        // toast.success(response?.data?.resDesc ?? 'success!')

        queryClient.invalidateQueries('getIndividualAccountLists')
        queryClient.invalidateQueries('ListingNewCIFNewAccount')
      }
    },
    onError: (errors: Error) => {
      toast.error(`Error: ${errors?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePatchKycDetailsIndividual

const patchKYCUpdateIndividualCIF = async (payload: any) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATE_NEW_CIF_BM}/${id}/update-kyc-details`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

export const usePatchKycDetailsIndividualCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(patchKYCUpdateIndividualCIF, {
    onSuccess: (response) => {
      // toast.success(response?.data?.resDesc ?? 'success!')
      // queryClient.invalidateQueries('getIndividualAccountLists')
      queryClient.invalidateQueries('preliminariesDetailsByIdCIF')
    },
    onError: (errors: Error) => {
      toast.error(`Error: ${errors?.message ?? 'An error occurred'}`)
    },
  })
}
