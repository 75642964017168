import {adToBs} from '@sbmdkl/nepali-date-converter'
import {Form, Formik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostAccountPreliminaryBranchCheckeReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostIndividualBranchCheckerReview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'
import {REVIEW_VALIDATION_SCHEMA} from '../../../qa/utils/ValidationSchemas'

interface RouteParams {
  id: string
}
const getStringValue = (value: any) => {
  if (typeof value === 'string') return value
  if (typeof value === 'number') return value.toString()
  if (value && typeof value === 'object' && 'title' in value) return value.title
  return ''
}

const NormalIndividualJointReview = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()

  const {
    mutate: branchCheckerPreliminaryReview,
    data: respData,
    isLoading: postRespLoading,
  } = usePostAccountPreliminaryBranchCheckeReview()

  const {data: preliminariesDetailsById, isLoading} = useGetPreliminariesDetailsById(id)

  const [allMasterDataOptions, setAllMasterDataOptions] = useState<any>(null)

  const {data: allMasterData} = useGetAllMasterData()
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [flag, setFlag] = useState<string>('default')
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])

  // const [enquiryData, setEnquiryData] = useState<any>()
  // const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)

  useEffect(() => {
    if (allMasterData) {
      setAllMasterDataOptions(allMasterData)
    }
  }, [allMasterData])

  const hadleBack = () => {
    history.go(-1)
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  const initialValues = preliminariesDetailsById?.data || {}
  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    branchCheckerPreliminaryReview(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleScreening = async () => {
    setScreeningLoading(true) // Set screening loading to true

    update.current = 'default'
    const checkAllPayload: any = {
      customer_name: initialValues?.full_name,
      citizenship: initialValues?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      if (checkAllDataJoint && checkAllDataJoint[index]) {
        let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
        if (parts && parts.length > 1) {
          const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

          setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

          newPaths.forEach((path: any) => {
            window.open(path, '_blank')
          })
        }
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: any) => {
    ;('Enquiry FUnc')
    let checkAllPayload: any = {}
    if (update.current === 'minor') {
      const guardian_full_name: string = [
        initialValues?.guardian_first_name,
        initialValues?.guardian_middle_name,
        initialValues?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        date_of_birth_bs: initialValues?.date_of_birth_bs || '',
        unique_id_no: initialValues?.unique_id_no || '',
        citizenship: initialValues?.identification_number,
        gender: initialValues?.gender || '',
        model_name: 'NewCIFNewAccount',
      }

      await mutateCheckAll(checkAllPayload, {
        onSuccess: (data) => {
          setEnquiryLoading(false)
          setLoadingIndex(null)
        },
        onError: (error) => {
          console.error('Error:', error)
          setEnquiryLoading(false)
          setLoadingIndex(null)
          clickedIndex.shift()
        },
      })
    } else if (update.current === 'joint') {
      const partner = initialValues?.account_holders?.account_holders[index]

      const partners_full_name: string = [
        partner?.first_name,
        partner?.middle_name,
        partner?.last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: partners_full_name,
        date_of_birth_bs: partner?.date_of_birth_bs || '',
        unique_id_no: partner?.unique_id_no || '',
        citizenship: partner?.citizenship || '',
        gender: partner?.gender || '',
        model_name: 'NewCIFNewAccount',
      }

      await mutateCheckAll(checkAllPayload, {
        onSuccess: (data) => {
          setEnquiryLoading(false)
          setLoadingIndex(null)
        },
        onError: (error) => {
          console.error('Error:', error)
          setEnquiryLoading(false)
          setLoadingIndex(null)
          clickedIndex.shift()
        },
      })
    }
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }
  return (
    <div className='container'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <section className=''>
            <div className='row'>
              <h3 className='fw-bolder mb-4 text-capitalize'>Account Details</h3>

              {/* <h2 style={{color: 'brown'}}>Account Details</h2> */}
              <FormDetails
                key='account_type_id'
                label='Account Type'
                value={getStringValue(initialValues?.account_type_id?.cbs_code)}
              />
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={getStringValue(initialValues.branch_code)}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={getStringValue(initialValues.customer_code)}
              />
              <FormDetails
                key='aml_risk_rating'
                label='AML Risk Rating'
                value={getStringValue(initialValues.aml_risk_rating)}
              />
              <FormDetails
                key='sub_risk_category_id'
                label='Risk Rating SubCategory'
                value={getStringValue(initialValues.sub_risk_category_id?.title)}
              />
            </div>
          </section>
          <hr />
          <section className=''>
            <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
            <div className='row'>
              <FormDetails
                key='salutation'
                label='Salutation'
                value={getStringValue(initialValues?.salutation)}
              />
              <FormDetails
                key='first_name'
                label='First Name'
                value={getStringValue(initialValues.first_name)}
              />
              <FormDetails
                key='middle_name'
                label='Middle Name'
                value={getStringValue(initialValues.middle_name)}
              />
              <FormDetails
                key='last_name'
                label='Last Name'
                value={getStringValue(initialValues.last_name)}
              />
              <FormDetails
                key='gender'
                label='Gender'
                value={getStringValue(initialValues.gender)}
              />
              <FormDetails
                key='marital_status'
                label='Marital Status'
                value={getStringValue(initialValues.marital_status)}
              />
              {initialValues?.marital_status?.title === 'MARRIED' && (
                <>
                  <FormDetails
                    key='spouse_name'
                    label='Spouse Name'
                    value={initialValues?.spouse_name}
                  />
                  <FormDetails
                    key='father_in_law'
                    label='Father In Law Name'
                    value={initialValues?.father_in_law}
                  />
                </>
              )}
              <FormDetails
                key='date_of_birth_ad'
                label='Date of Birth AD'
                value={getStringValue(initialValues.date_of_birth_ad)}
              />
              <FormDetails
                key='date_of_birth_bs'
                label='Date of Birth BS'
                value={getStringValue(initialValues.date_of_birth_bs)}
              />
              <FormDetails
                key='nationality_id'
                label='Nationality'
                value={getStringValue(initialValues.nationality_id)}
              />
              {initialValues.refugee_details && (
                <FormDetails
                  key='refugee_details'
                  label='Others'
                  value={
                    getStringValue(
                      initialValues.refugee_details === 'REFUGEES'
                        ? 'REFUGEES'
                        : 'NON CITIZENSHIP HOLDER'
                    ) || '-'
                  }
                />
              )}

              <FormDetails
                key='id_type_id'
                label='ID Type'
                value={getStringValue(initialValues.id_type_id)}
              />
              <FormDetails
                key='id_issued_date'
                label='ID Issued Date (BS)'
                value={getStringValue(adToBs(initialValues?.id_issued_date_ad))}
              />
              <FormDetails
                key='id_issued_date_ad'
                label='ID Issued Date (AD)'
                value={getStringValue(initialValues?.id_issued_date_ad)}
              />
              {initialValues?.id_type_id?.title !== 'BIRTH CERTIFICATE' &&
                initialValues?.id_type_id?.title !== 'PAN CARD' &&
                initialValues?.id_type_id?.title !== 'CITIZENSHIP' && (
                  <>
                    <FormDetails
                      key='id_expiry_date'
                      label='ID Expiry Date (BS)'
                      value={getStringValue(initialValues?.id_expiry_date)}
                    />
                    <FormDetails
                      key='id_expiry_date_ad'
                      label='ID Expiry Date (AD)'
                      value={getStringValue(initialValues?.id_expiry_date_ad)}
                    />
                  </>
                )}
              <FormDetails
                key='identification_number'
                label='Identification Number'
                value={getStringValue(initialValues.identification_number)}
              />
              {initialValues.national_id_number && (
                <FormDetails
                  key='national_id_number'
                  label='National Id Number'
                  value={getStringValue(initialValues.national_id_number)}
                />
              )}

              <FormDetails
                key='father_name'
                label='Father Name'
                value={getStringValue(initialValues.father_name)}
              />
              <FormDetails
                key='grandfather_name'
                label='Grandfather Name'
                value={getStringValue(initialValues.grandfather_name)}
              />
            </div>
          </section>
          <hr />
          <section className=''>
            <h3 className='fw-bolder mb-4 text-capitalize'>Occupation Details</h3>
            <div className='row'>
              {/* <h2 style={{color: 'brown'}}>Occupation Details</h2> */}
              <FormDetails
                key='occupation_id'
                label='Occupation'
                value={getStringValue(initialValues?.occupation_id)}
              />
              {initialValues?.other_occupation && (
                <FormDetails
                  key='other_occupation'
                  label='Other Occupation'
                  value={getStringValue(initialValues?.other_occupation)}
                />
              )}
              <FormDetails
                key='income_source_id'
                label='Income Source'
                value={getStringValue(initialValues?.income_source_id)}
              />
              {initialValues?.other_income_source && (
                <FormDetails
                  key='other_income_source'
                  label='Other Income Source'
                  value={getStringValue(initialValues?.other_income_source)}
                />
              )}
              <FormDetails
                key='total_annual_income'
                label='Total Annual Income'
                value={getStringValue(initialValues?.total_annual_income)}
              />
              <FormDetails
                key='expected_annual_turnover'
                label='Expected Annual Transaction In Amount'
                value={getStringValue(initialValues?.expected_annual_turnover)}
              />
            </div>
          </section>
          <hr />
          <section className=''>
            <h3 className='fw-bolder mb-4 text-capitalize'>Communication/ Present Address</h3>
            <div className='row'>
              {/* <h2 style={{color: 'brown'}}>Communication/Present Address Details</h2> */}
              <FormDetails
                key='present_country'
                label='Country'
                value={getStringValue(initialValues?.present_country?.title)}
              />
              {getStringValue(initialValues?.present_country) === 'NEPAL' ? (
                <>
                  <FormDetails
                    key='present_province'
                    label='Province'
                    value={getStringValue(initialValues?.present_province?.title)}
                  />
                  <FormDetails
                    key='present_zone'
                    label='Zone'
                    value={getStringValue(initialValues?.present_zone?.title)}
                  />
                  <FormDetails
                    key='present_district'
                    label='District'
                    value={getStringValue(initialValues?.present_district?.title)}
                  />
                  <FormDetails
                    key='present_municipality'
                    label='Municipality'
                    value={getStringValue(initialValues?.present_municipality?.title)}
                  />
                  <FormDetails
                    key='present_city'
                    label='City'
                    value={getStringValue(initialValues?.present_city)}
                  />
                  <FormDetails
                    key='present_street_name'
                    label='Street'
                    value={getStringValue(initialValues?.present_street_name)}
                  />

                  {initialValues?.present_fax_number && (
                    <FormDetails
                      key='present_fax_number'
                      label='Fax Number'
                      value={getStringValue(initialValues?.present_fax_number)}
                    />
                  )}
                  <FormDetails
                    key='present_house_number'
                    label='House Number'
                    value={getStringValue(initialValues?.present_house_number)}
                  />
                  <FormDetails
                    key='present_ward_number'
                    label='Ward Number'
                    value={getStringValue(initialValues?.present_ward_number)}
                  />
                  <FormDetails
                    key='present_mobile_number'
                    label='Mobile Number'
                    value={getStringValue(initialValues?.present_mobile_number)}
                  />
                  <FormDetails
                    key=''
                    label='Email Address'
                    value={getStringValue(initialValues?.present_email)}
                  />
                </>
              ) : (
                <>
                  <FormDetails
                    key='registered_office_outside_country_post_box'
                    label='Foreign Country Post Box No.'
                    value={getStringValue(initialValues?.present_outside_country_post_box)}
                  />
                  <FormDetails
                    key='registered_office_outside_country_full_address'
                    label='Foreign Country Address  '
                    value={getStringValue(initialValues?.present_outside_country_full_address)}
                  />
                  <FormDetails
                    key='registered_office_mobile_number'
                    label=' Mobile Number'
                    value={getStringValue(initialValues?.present_mobile_number)}
                  />
                  <FormDetails
                    key='registered_office_email'
                    label=' Email'
                    value={getStringValue(initialValues?.present_email)}
                  />
                </>
              )}
            </div>
          </section>
          <hr />
          {initialValues?.account_type_id?.title === 'Minor' && (
            <>
              <section className='row'>
                <h3 className='fw-bolder mb-4 text-capitalize'>Guardian's Details</h3>
                <div className=''>
                  {/* <h2 style={{color: 'brown'}}>Guardian's Details</h2> */}
                  <FormDetails
                    key='guardian_cif_number'
                    label='Guardian CIF Number'
                    value={initialValues?.guardian_cif_number}
                  />
                  {checkAllDataMinor ? (
                    <Button
                      className='mb-5 ms-3'
                      onClick={() => {
                        handleOpenPdf2()
                      }}
                    >
                      View PDF
                    </Button>
                  ) : enquiryLoading ? (
                    <Spinner animation='border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      className='mb-5'
                      onClick={() => {
                        handleGuardianScreening(initialValues?.guardian_cif_number)
                      }}
                    >
                      Enquiry
                    </Button>
                  )}
                </div>
                <FormDetails
                  key='guardian_id_number'
                  label='Guardian ID Number'
                  value={initialValues?.guardian_id_number}
                />
                <FormDetails
                  key='guardian_first_name'
                  label='Guardian First Name'
                  value={initialValues?.guardian_first_name}
                />
                <FormDetails
                  key='guardian_middle_name'
                  label='Guardian Middle Name'
                  value={initialValues?.guardian_middle_name}
                />
                <FormDetails
                  key='guardian_last_name'
                  label='Guardian Last Name'
                  value={initialValues?.guardian_last_name}
                />
                <FormDetails
                  key='guardian_relationship_id'
                  label='Guardian Relationship'
                  value={initialValues?.guardian_relationship_id?.title}
                />
                <FormDetails
                  key='guardian_address'
                  label='Guardian Address'
                  value={initialValues?.guardian_address}
                />
                {/*<FormDetails*/}
                {/*  key='minor_certificate_number'*/}
                {/*  label='Minor Certificate Number'*/}
                {/*  value={initialValues?.minor_certificate_number}*/}
                {/*/>*/}
                <FormDetails
                  key='certificate_issued_by'
                  label='Minor Certificate Issued By'
                  value={initialValues?.certificate_issued_by?.title}
                />
                {/*<FormDetails*/}
                {/*  key='minor_certificate_issue_date_bs'*/}
                {/*  label='Minor Certificate Issue Date (BS)'*/}
                {/*  value={initialValues?.minor_certificate_issue_date_bs}*/}
                {/*/>*/}
                {/*<FormDetails*/}
                {/*  key='minor_certificate_issue_date_ad'*/}
                {/*  label='Minor Certificate Issue Date (AD)'*/}
                {/*  value={initialValues?.minor_certificate_issue_date_ad}*/}
                {/*/>*/}
              </section>
              <hr />
            </>
          )}
          {initialValues?.account_type_id?.title === 'Joint' && (
            <div>
              <section className=''>
                <h3 className='fw-bolder mb-4 text-capitalize'>Partner's Details</h3>
                <div className='row'>
                  {initialValues?.account_holders?.account_holders.map(
                    (holder: any, index: any) => (
                      <div key={index} className='row'>
                        <div>
                          <FormDetails
                            key={`cif_number_${index}`}
                            label='CIF Number'
                            value={holder.cif_number}
                          />

                          {clickedIndex.includes(index) ? (
                            loadingIndex === index ? (
                              <Spinner animation='border' role='status' size='sm'>
                                <span className='sr-only'>Loading...</span>
                              </Spinner>
                            ) : (
                              <Button className='mb-5 ms-3' onClick={() => handleOpenPdf2(index)}>
                                View PDF
                              </Button>
                            )
                          ) : (
                            <Button
                              className='mb-5'
                              onClick={() => handleButtonClick(holder?.cif_number, index)}
                            >
                              Enquiry
                            </Button>
                          )}

                          {/* {clickedIndex.includes(index) && loadingIndex === null ? (
                          <Button
                            className='mb-5 ms-3'
                            onClick={() => {
                              handleOpenPdf2(index)
                            }}
                          >
                            View PDF
                          </Button>
                        ) : loadingIndex === index ? (
                          <Spinner animation='border' role='status' size='sm'>
                            <span className='sr-only'>Loading...</span>
                          </Spinner>
                        ) : (
                          <Button
                            className='mb-5'
                            onClick={() => {
                              handleButtonClick(holder?.cif_number, index)
                            }}
                          >
                            Enquiry
                          </Button>
                        )} */}
                          {/*  */}
                        </div>
                        <FormDetails
                          key={`id_type_id_${index}`}
                          label='Identification Type'
                          value={holder.id_type_id?.title}
                        />
                        <FormDetails
                          key={`id_number_${index}`}
                          label='Identification Number'
                          value={holder.id_number}
                        />
                        <FormDetails
                          key={`first_name_${index}`}
                          label='First Name'
                          value={holder.first_name}
                        />
                        <FormDetails
                          key={`middle_name_${index}`}
                          label='Middle Name'
                          value={holder.middle_name}
                        />
                        <FormDetails
                          key={`last_name_${index}`}
                          label='Last Name'
                          value={holder.last_name}
                        />
                      </div>
                    )
                  )}
                </div>
              </section>
              <hr />
            </div>
          )}
          {screeningPerformed ? (
            checkAllData && (
              <Button type='button' onClick={handleOpenPdf} className='col-md-3 col-sm-6 col-xs-12'>
                View Details
              </Button>
            )
          ) : screeningLoading ? (
            <div>
              <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Button type='button' onClick={handleScreening} className='col-md-3 col-sm-6 col-xs-12'>
              Perform Screening
            </Button>
          )}
          <div className='my-7'>
            <h3>Screening Files</h3>
            {initialValues?.screening_files?.screening_files?.length > 0 ? (
              initialValues?.screening_files?.screening_files?.map((file: any, index: any) => (
                <>
                  <button
                    key={index}
                    onClick={() => handlePdfFileClick(file.file_path)}
                    style={{display: 'block', marginBottom: '10px'}}
                  >
                    {file.file_name}
                  </button>
                </>
              ))
            ) : (
              <p>No files available</p>
            )}
          </div>
        </div>
      </div>
      <div className='modal-container pt-10'>
        <div className='card'>
          <div className='card-body'>
            <Formik
              initialValues={{status: '', remarks: ''}}
              onSubmit={handleSubmit}
              validationSchema={REVIEW_VALIDATION_SCHEMA}
            >
              {({handleChange, errors, touched, values, setFieldValue}) => (
                <Form className='bg-white'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        label='Status Type'
                        name='status'
                        isClearable={true}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={[
                          {label: 'FORWARD TO BRANCH MAKER', value: 'approve'},
                          {label: 'REJECT', value: 'reject'},
                          {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
                        ]}
                        required={true}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <FormTextBox
                      containerClassName='col-md-12'
                      labelClassName='col-md-12'
                      type='text'
                      placeholder='Remarks'
                      label='Remarks'
                      name='remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      as='textarea'
                    />
                  </div>
                  <div className='d-flex justify-content-end mt-3'>
                    <button type='button' onClick={hadleBack} className='btn btn-secondary mx-4'>
                      Back
                    </button>
                    <button type='submit' className='btn btn-primary' disabled={postRespLoading}>
                      {postRespLoading ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className='pt-10'>
        <AccountStatusLog data={initialValues} />
        <UserActivity caseId={id} />
      </div>
    </div>
  )
}

export default NormalIndividualJointReview
