import axios from 'axios'
import {useQuery} from 'react-query'
import {toast} from 'react-toastify'
import {GET_LINKED_ENTITIES_DATA} from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'LinkedEntitiesList'

interface IParamsLinkedEntities {
  account_number?: string
  cif_number?: string
  is_corporate: boolean
}

interface IQueryOptions {
  enabled: boolean
}

export const useGetLinkedEntitesData = (params: IParamsLinkedEntities, options: IQueryOptions) => {
  return useQuery(
    [QUERY_KEY, params.account_number, params.cif_number, params.is_corporate],
    async () => {
      const queryParams: any = {
        is_corporate: params.is_corporate,
      }

      // Only add account_number or cif_number if they are provided
      if (params.account_number) {
        queryParams.account_number = params.account_number
      }
      if (params.cif_number) {
        queryParams.cif_number = params.cif_number
      }

      const {data} = await axios.get(GET_LINKED_ENTITIES_DATA, {
        params: queryParams,
      })
      return data
    },
    {
      ...options,
      onError: (error: any) => {
        const errorMessage =
          error.response?.data?.message || error.message || 'Failed to fetch data'
        toast.error(errorMessage)
      },
    }
  )
}
