import React, {useEffect, useState} from 'react'
import {FieldArray} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {Button, Spinner} from 'react-bootstrap-v5'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'

const AccountHoldersData = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  customerEnquiry,
  enquiryData,
  mutateCheckAll,
  handlePdf,
  update,
  checkAllData,
  setScreeningPerformed,
  pdfLoading,
  corporateDataById,
}: any) => {
  // Ensure that account_holders array has at least one object
  useEffect(() => {
    if (values?.account_holders?.length === 0) {
      setFieldValue('account_holders', [
        {
          first_name: '',
          middle_name: '',
          last_name: '',
          cif_number: '',
          id_number: '',
          id_type_id: '',
          // account_relation_id: '',
        },
      ])
    }
  }, [setFieldValue, values?.account_holders?.length])

  // Existing useEffect for handling corporateDataById
  useEffect(() => {
    const data = corporateDataById?.data?.account_holders?.account_holders[0]
    if (data) {
      setFieldValue(`account_holders.${0}.first_name`, data?.first_name || '')
      setFieldValue(`account_holders.${0}.middle_name`, data?.middle_name || '')
      setFieldValue(`account_holders.${0}.last_name`, data?.last_name || '')
      setFieldValue(`account_holders.${0}.cif_number`, data?.cif_number || '')
      setFieldValue(`account_holders.${0}.id_number`, data?.id_number || '')
      setFieldValue(`account_holders.${0}.id_type_id`, data?.id_type_id || '')
      setFieldValue(`account_holders.${0}.account_relation_id`, data?.account_relation_id || '')
    }
  }, [corporateDataById, setFieldValue])

  const [currentIndex, setCurrentIndex] = useState<any>()
  const {allMasterDataOptions, isLoading} = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  useEffect(() => {
    if (enquiryData) {
      const data = enquiryData
      const checkAllPayload: any = {
        customer_name: data?.data?.generalDetails[0]?.CUST_NAME,
        date_of_birth_bs: data?.data?.generalDetails[0]?.DATE_OF_BIRTH,
        unique_id_no: data?.data?.generalDetails[0]?.CUST_ID,
        citizenship: data?.data?.generalDetails[0]?.IDNO,
        gender: data?.data?.generalDetails[0]?.CUST_SEX,
        model_name: 'NewCIFNewAccount',
      }
      if (update.current === 'joint') {
        mutateCheckAll(checkAllPayload, {
          onSuccess: () => (update.current = 'default'),
          onError: () => (update.current = 'default'),
        })
      }

      const fullName = data?.data?.generalDetails[0]?.CUST_NAME || ''
      const nameParts = fullName.split(' ')
      const first_name = nameParts[0] || ''
      const middle_name = nameParts?.length > 2 ? nameParts.slice(1, -1).join(' ') : ''
      const last_name = nameParts[nameParts?.length - 1] || ''

      setFieldValue(`account_holders.${currentIndex}.first_name`, first_name || '')
      setFieldValue(`account_holders.${currentIndex}.middle_name`, middle_name || '')
      setFieldValue(`account_holders.${currentIndex}.last_name`, last_name || '')

      const enteredCifNumber = values.account_holders[currentIndex]?.cif_number
      setFieldValue(`account_holders.${currentIndex}.cif_number`, enteredCifNumber)

      const idType = data?.data?.generalDetails[0]?.IDTYPE
      const matchedOption = allMasterDataOptions?.id_types?.find(
        (option: any) => option.code === idType
      )
      if (matchedOption) {
        setFieldValue(`account_holders.${currentIndex}.id_type_id`, matchedOption.id)
      }

      setFieldValue(
        `account_holders.${currentIndex}.id_number`,
        data?.data?.generalDetails[0]?.IDNO || ''
      )
    }
  }, [enquiryData, currentIndex, setFieldValue])

  const handleEnquiry = (index: number) => {
    setCurrentIndex(index)
    const cifNumber = values.account_holders[index]?.cif_number
    if (cifNumber) {
      customerEnquiry(
        {cifNumber, index},
        {
          onSuccess: () => setLoadingIndex(null),
          onError: (error: any) => {
            setLoadingIndex(null)
            console.error('Error:', error)
          },
        }
      )
    }
    update.current = 'joint'
    setScreeningPerformed(true)
  }

  const [loadingIndex, setLoadingIndex] = useState<number | null>(null)

  const handleButtonClick = async (index: number) => {
    setLoadingIndex(index)
    try {
      await handleEnquiry(index)
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  return (
    <>
      <div className='border-top pt-9 pb-9'>
        <FieldArray name='account_holders'>
          {({remove, push}) => (
            <div>
              {values?.account_holders?.map((accountHolders: any, index: any) =>
                corporateDataById?.data?.account_type_id?.id ===
                allMasterDataOptions?.account_types?.find(
                  (acctype: any) => acctype.cbs_code === 'Corporate'
                )?.id ? (
                  <div>
                    <div className='col-md-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Corporate Account test Holder Name'
                        name={`account_holders.${index}.first_name`}
                        label='Name of Institution'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        value={values?.first_name}
                        uppercase={true}
                        maxLength={50}
                        disabled
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='row mb-3' key={index}>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Account Holder First Name'
                        name={`account_holders.${index}.first_name`}
                        label='First Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        value={values?.first_name}
                        uppercase={true}
                        maxLength={50}
                        disabled
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Account Holders Middle Name'
                        name={`account_holders.${index}.middle_name`}
                        label='Middle Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        uppercase={true}
                        maxLength={50}
                        disabled
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Account Holders Last Name'
                        name={`account_holders.${index}.last_name`}
                        label='Last Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={50}
                        uppercase={true}
                        disabled
                      />
                    </div>

                    {/* <div className='col-md-4 d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-danger me-2'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div> */}
                  </div>
                )
              )}
              {/*}             <button
                type='button'
                className='btn btn-primary me-2'
                onClick={() =>
                  push({
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    cif_number: '',
                    id_number: '',
                    id_type_id: '',
                  })
                }
              >
                Add More
              </button>*/}
            </div>
          )}
        </FieldArray>
      </div>
    </>
  )
}

export default AccountHoldersData
