import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postCibScreeningCheck = async (payload: typePayload) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/cib-screening-check
    `,
      payload
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCibScreeningCheck = (setCibScreeningData: any) => {
  return useMutation(postCibScreeningCheck, {
    onSuccess: (response) => {
      if (response) {
        setCibScreeningData(response?.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCibScreeningCheck

// import {useMutation} from 'react-query'
// import {toast} from 'react-toastify'

// const postmockCibCheck = async (payload: any) => {
//   // Mock data
//   const mockResponse = {
//     resCod: '200',
//     resDesc: 'Operation Successfull',
//     data: {
//       screening_lists: [
//         {
//           name: 'AYUSH KC',
//           DOB: '2051-06-29',
//           gender: 'M',
//           fatherName: 'GUNENDRA KUMAR KHATRI',
//           citizenshipNumber: '131033/252',
//           citizenshipIssuedDate: '2068-06-26',
//           citizenshipIssuedDistrict: 'Okhaldhunga',
//           passportNumber: '',
//           passportExpiryDate: '',
//           drivingLicenseNumber: '',
//           drivingLicenseIssuedDate: '',
//           voterIdNumber: '',
//           voterIdIssuedDate: null,
//           PAN: '',
//           PANIssuedDate: null,
//           PANIssuedDistrict: '',
//           indianEmbassyNumber: '',
//           indianEmbassyRegDate: '',
//           sector: '',
//           blacklistNumber: '',
//           blacklistedDate: '',
//           blacklistType: '',
//           panNumber: '',
//           companyRegNumber: '',
//           companyRegDate: '',
//           companyRegAuth: '',
//         },
//       ],
//       meta: {
//         current_page: 1,
//         perPage: 10,
//         total: 3,
//       },
//     },
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => resolve(mockResponse), 1000) // Simulate network delay
//   })
// }

// const usePostCibScreeningCheck = () => {
//   return useMutation(postmockCibCheck, {
//     onSuccess: (response: any) => {
//       if (response?.Code == '0') {
//         const customerData = response?.data?.[0]
//         console.log(customerData)
//         toast.success(response?.Message)
//       }
//     },
//     onError: (error: Error) => {
//       toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
//     },
//   })

// }

// export default usePostCibScreeningCheck
