import {adToBs} from '@sbmdkl/nepali-date-converter'
import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostIndividualQAReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostIndividualQAReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {REVIEW_VALIDATION_SCHEMA} from '../../utils/ValidationSchemas'
import {AccountStatusLog, UserActivity} from 'src/app/modules/common/components/AccountStatusLog'

interface RouteParams {
  id: string
}

const QANormalReviewAndUpdate = () => {
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: preliminariesDatas, isLoading} = useGetPreliminariesDetailsById(id)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')

  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const {
    mutate: QAUnitPostUpdate,
    data: respData,
    isLoading: postRespLoading,
  } = usePostIndividualQAReview()

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    QAUnitPostUpdate(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  const allStatusType = [
    {label: 'FORWARD TO CPU MAKER', value: 'approve'},
    {label: 'REJECT', value: 'reject'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
  ]
  const data = preliminariesDatas?.data

  const handleScreening = () => {
    setScreeningLoading(true)

    const checkAllPayload: any = {
      customer_name: data?.full_name,
      citizenship: data?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: number) => {
    let checkAllPayload: any = {}

    if (update.current === 'minor') {
      const guardian_full_name: string = [
        data?.guardian_first_name,
        data?.guardian_middle_name,
        data?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        citizenship: data?.identification_number,
        model_name: 'NewCIFNewAccount',
      }
    } else if (update.current === 'joint') {
      const partner = data?.account_holders?.account_holders.find(
        (holder: any) => holder.cif_number === cif_number
      )

      const partners_full_name: string = [
        partner?.first_name,
        partner?.middle_name,
        partner?.last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: partners_full_name,
        date_of_birth_bs: partner?.date_of_birth_bs || '',
        unique_id_no: partner?.unique_id_no || '',
        citizenship: partner?.citizenship || '',
        gender: partner?.gender || '',
        model_name: 'NewCIFNewAccount',
      }
    }

    setEnquiryLoading(true)
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setEnquiryLoading(false) // Reset loading state on success
        setLoadingIndex(null)
      },
      onError: (error) => {
        console.error('Error:', error)
        setEnquiryLoading(false) // Reset loading state on error
        setLoadingIndex(null)
        clickedIndex.shift()
      },
    })
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = async (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      await handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }
  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div className='card mb-5 ' style={{position: 'sticky', top: '80px'}}>
            <div className='card-body '>
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body'>
              <div className=''>
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Account Details</h3>
                  <div className='row'>
                    <FormDetails
                      key='opening_mode'
                      label='Opening Mode'
                      value={data?.opening_mode}
                    />
                    <FormDetails
                      key='account_type_id'
                      label='Account Type'
                      value={data?.account_type_id?.title}
                    />
                    <FormDetails key='branch_code' label='Branch Code' value={data?.branch_code} />
                    <FormDetails
                      key='customer_code'
                      label='Customer Code'
                      value={data?.customer_code}
                    />
                    <FormDetails
                      key='aml_risk_rating'
                      label='AML Risk Rating'
                      value={data?.aml_risk_rating?.title}
                    />
                    <FormDetails
                      key='sub_risk_category_id'
                      label='AML Risk Sub Rating'
                      value={data?.sub_risk_category_id?.title}
                    />
                  </div>
                </section>
                <hr />
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
                  <div className='row'>
                    <FormDetails key='salutation' label='First Name' value={data?.salutation} />
                    <FormDetails key='first_name' label='First Name' value={data?.first_name} />
                    <FormDetails
                      key='middle_name'
                      label='Middle Name'
                      value={data?.middle_name || '-'}
                    />
                    <FormDetails key='last_name' label='Last Name' value={data?.last_name} />
                    <FormDetails key='gender' label='Gender' value={data?.gender} />
                    <FormDetails
                      key='marital_status'
                      label='Marital Status'
                      value={data?.marital_status?.title}
                    />
                    {data?.marital_status?.title === 'MARRIED' && (
                      <>
                        <FormDetails
                          key='spouse_name'
                          label='Spouse Name'
                          value={data?.spouse_name}
                        />
                        <FormDetails
                          key='father_in_law'
                          label='Father In Law Name'
                          value={data?.father_in_law}
                        />
                      </>
                    )}
                    <FormDetails
                      key='date_of_birth_ad'
                      label='Date of Birth (AD)'
                      value={data?.date_of_birth_ad}
                    />
                    <FormDetails
                      key='date_of_birth_bs'
                      label='Date of Birth (BS)'
                      value={data?.date_of_birth_bs}
                    />
                    <FormDetails
                      key='nationality_id'
                      label='Nationality'
                      value={data?.nationality_id?.title}
                    />
                    {data.refugee_details && (
                      <FormDetails
                        key='refugee_details'
                        label='Others'
                        value={
                          data.refugee_details === 'REFUGEES'
                            ? 'REFUGEES'
                            : 'NON CITIZENSHIP HOLDER'
                        }
                      />
                    )}

                    <FormDetails key='id_type_id' label='ID Type' value={data?.id_type_id?.title} />
                    <FormDetails
                      key='identification_number'
                      label='Identification Number'
                      value={data?.identification_number}
                    />
                    <FormDetails
                      key='id_issued_date'
                      label='ID Issued Date (BS)'
                      value={adToBs(data?.id_issued_date_ad) as string}
                    />
                    <FormDetails
                      key='id_issued_date_ad'
                      label='ID Issued Date (AD)'
                      value={data?.id_issued_date_ad}
                    />
                    {data?.national_id_number && (
                      <FormDetails
                        key='national_id_number'
                        label='National Id Number'
                        value={data?.national_id_number}
                      />
                    )}
                    {data?.id_type_id?.title !== 'PAN CARD' &&
                      data?.id_type_id?.title !== 'BIRTH CERTIFICATE' &&
                      data?.id_type_id?.title !== 'CITIZENSHIP' && (
                        <>
                          <FormDetails
                            key='id_expiry_date'
                            label='ID Expiry Date (BS)'
                            value={data?.id_expiry_date}
                          />
                          <FormDetails
                            key='id_expiry_date_ad'
                            label='ID Expiry Date (AD)'
                            value={data?.id_expiry_date_ad}
                          />
                        </>
                      )}
                    <FormDetails
                      key='father_name'
                      label="Father's Name"
                      value={data?.father_name}
                    />
                    <FormDetails
                      key='grandfather_name'
                      label="Grandfather's Name"
                      value={data?.grandfather_name}
                    />
                  </div>
                </section>
                <hr />
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Income Details</h3>
                  <div className='row'>
                    <FormDetails
                      key='occupation_id'
                      label='Occupation'
                      value={data?.occupation_id?.title}
                    />
                    {data?.other_occupation && (
                      <FormDetails
                        key='other_occupation'
                        label='Other Occupation'
                        value={data?.other_occupation}
                      />
                    )}
                    <FormDetails
                      key='income_source_id'
                      label='Income Source'
                      value={data?.income_source_id?.title}
                    />
                    {data?.other_income_source && (
                      <FormDetails
                        key='other_income_source'
                        label='Other Income Source'
                        value={data?.other_income_source}
                      />
                    )}
                    <FormDetails
                      key='total_annual_income'
                      label='Total Annual Income'
                      value={data?.total_annual_income?.title}
                    />
                    <FormDetails
                      key='expected_annual_turnover'
                      label='Expected Annual Transaction In Amount'
                      value={data?.expected_annual_turnover}
                    />
                  </div>
                </section>
                <hr />
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Communication/ Present Address</h3>
                  <div className='row'>
                    {/* <h2 style={{color: 'brown'}}>Communication/Present Address Details</h2> */}
                    <FormDetails
                      key='present_country'
                      label='Country'
                      value={data?.present_country?.title}
                    />
                    {data?.present_country?.title === 'NEPAL' ? (
                      <>
                        <FormDetails
                          key='present_province'
                          label='Province'
                          value={data?.present_province?.title}
                        />
                        <FormDetails
                          key='present_zone'
                          label='Zone'
                          value={data?.present_zone?.title}
                        />
                        <FormDetails
                          key='present_district'
                          label='District'
                          value={data?.present_district?.title}
                        />
                        <FormDetails
                          key='present_municipality'
                          label='Municipality'
                          value={data?.present_municipality?.title}
                        />
                        <FormDetails
                          key='present_city'
                          label='City'
                          value={data?.present_city?.title}
                        />
                        <FormDetails
                          key='present_street_name'
                          label='Street'
                          value={data?.present_street_name}
                        />

                        {data?.present_fax_number && (
                          <FormDetails
                            key='present_fax_number'
                            label='Present Fax Number'
                            value={data?.present_fax_number}
                          />
                        )}
                        <FormDetails
                          key='present_house_number'
                          label='House Number'
                          value={data?.present_house_number}
                        />
                        <FormDetails
                          key='present_ward_number'
                          label='Ward Number'
                          value={data?.present_ward_number}
                        />
                        <FormDetails
                          key='present_mobile_number'
                          label='Mobile Number'
                          value={data?.present_mobile_number}
                        />
                        <FormDetails key='' label='Email Address' value={data?.present_email} />
                      </>
                    ) : (
                      <>
                        <FormDetails
                          key='registered_office_outside_country_post_box'
                          label='Foreign Country Post Box No.'
                          value={data?.present_outside_country_post_box}
                        />
                        <FormDetails
                          key='registered_office_outside_country_full_address'
                          label='Foreign Country Address  '
                          value={data?.present_outside_country_full_address}
                        />
                        <FormDetails
                          key='registered_office_mobile_number'
                          label=' Mobile Number'
                          value={data?.present_mobile_number}
                        />
                        <FormDetails
                          key='registered_office_email'
                          label=' Email'
                          value={data?.present_email}
                        />
                      </>
                    )}
                  </div>
                </section>
                <hr />
                {data?.account_type_id?.title === 'Minor' && (
                  <>
                    <section className=''>
                      <h3 className='fw-bolder mb-4 text-capitalize'>Guardian's Details</h3>
                      <div className='row'>
                        <div>
                          <FormDetails
                            key='guardian_cif_number'
                            label='Guardian CIF Number'
                            value={data?.guardian_cif_number}
                          />
                          {checkAllDataMinor ? (
                            <Button
                              className='mb-5 ms-3'
                              onClick={() => {
                                handleOpenPdf2()
                              }}
                            >
                              View PDF
                            </Button>
                          ) : enquiryLoading ? (
                            <Spinner animation='border' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </Spinner>
                          ) : (
                            <Button
                              className='mb-5'
                              onClick={() => {
                                handleGuardianScreening(data?.guardian_cif_number)
                              }}
                            >
                              Enquiry
                            </Button>
                          )}
                        </div>

                        <FormDetails
                          key='guardian_id_number'
                          label='Guardian ID Number'
                          value={data?.guardian_id_number}
                        />
                        <FormDetails
                          key='guardian_first_name'
                          label='Guardian First Name'
                          value={data?.guardian_first_name}
                        />
                        <FormDetails
                          key='guardian_middle_name'
                          label='Guardian Middle Name'
                          value={data?.guardian_middle_name}
                        />
                        <FormDetails
                          key='guardian_last_name'
                          label='Guardian Last Name'
                          value={data?.guardian_last_name}
                        />
                        <FormDetails
                          key='guardian_relationship_id'
                          label='Guardian Relationship'
                          value={data?.guardian_relationship_id?.title}
                        />
                        <FormDetails
                          key='guardian_address'
                          label='Guardian Address'
                          value={data?.guardian_address}
                        />
                        {/*<FormDetails*/}
                        {/*  key='minor_certificate_number'*/}
                        {/*  label='Minor Birth Certificate Number'*/}
                        {/*  value={data?.minor_certificate_number}*/}
                        {/*/>*/}
                        <FormDetails
                          key='certificate_issued_by'
                          label='Minor Certificate Issued By'
                          value={data?.certificate_issued_by?.title}
                        />
                        {/*<FormDetails*/}
                        {/*  key='minor_certificate_issue_date_bs'*/}
                        {/*  label='Minor Certificate Issue Date (BS)'*/}
                        {/*  value={data?.minor_certificate_issue_date_bs}*/}
                        {/*/>*/}
                        {/*<FormDetails*/}
                        {/*  key='minor_certificate_issue_date_ad'*/}
                        {/*  label='Minor Certificate Issue Date (AD)'*/}
                        {/*  value={data?.minor_certificate_issue_date_ad}*/}
                        {/*/>*/}
                      </div>
                    </section>
                    <hr />
                  </>
                )}
                {data?.account_type_id?.title === 'Joint' && (
                  <>
                    <section className=''>
                      <h3 className='fw-bolder mb-4 text-capitalize'>Partner's Details</h3>
                      <div className='row'>
                        {data?.account_holders?.account_holders.map((holder: any, index: any) => (
                          <div key={index} className='row'>
                            <div>
                              <FormDetails
                                key={`cif_number_${index}`}
                                label='CIF Number'
                                value={holder.cif_number}
                              />
                              {clickedIndex.includes(index) ? (
                                loadingIndex === index ? (
                                  <Spinner animation='border' role='status' size='sm'>
                                    <span className='sr-only'>Loading...</span>
                                  </Spinner>
                                ) : (
                                  <Button
                                    className='mb-5 ms-3'
                                    onClick={() => handleOpenPdf2(index)}
                                  >
                                    View PDF
                                  </Button>
                                )
                              ) : (
                                <Button
                                  className='mb-5'
                                  onClick={() => handleButtonClick(holder?.cif_number, index)}
                                >
                                  Enquiry
                                </Button>
                              )}

                              {/* {checkAllDataJoint ? (
                                <Button
                                  className='mb-5 ms-3'
                                  onClick={() => {
                                    handleOpenPdf2()
                                  }}
                                >
                                  View PDF
                                </Button>
                              ) : loadingIndex === index ? (
                                <Spinner animation='border' role='status' size='sm'>
                                  <span className='sr-only'>Loading...</span>
                                </Spinner>
                              ) : (
                                <Button
                                  className='mb-5'
                                  onClick={() => {
                                    handleButtonClick(holder?.cif_number, index)
                                  }}
                                >
                                  Enquiry
                                </Button>
                              )} */}
                            </div>
                            <FormDetails
                              key={`id_type_id_${index}`}
                              label='Identification Type'
                              value={holder.id_type_id?.title}
                            />
                            <FormDetails
                              key={`id_number_${index}`}
                              label='Identification Number'
                              value={holder.id_number}
                            />
                            <FormDetails
                              key={`first_name_${index}`}
                              label='First Name'
                              value={holder.first_name}
                            />
                            <FormDetails
                              key={`middle_name_${index}`}
                              label='Middle Name'
                              value={holder.middle_name}
                            />
                            <FormDetails
                              key={`last_name_${index}`}
                              label='Last Name'
                              value={holder.last_name}
                            />
                          </div>
                        ))}
                      </div>
                    </section>
                    <hr />
                  </>
                )}

                <div className='row'>
                  {screeningPerformed ? (
                    checkAllData && (
                      <Button
                        type='button'
                        onClick={handleOpenPdf}
                        className='col-md-3 col-sm-6 col-xs-12'
                      >
                        View Details
                      </Button>
                    )
                  ) : screeningLoading ? (
                    <Spinner animation='border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      type='button'
                      onClick={handleScreening}
                      className='col-md-6 col-sm-6 col-xs-12'
                    >
                      Perform Screening
                    </Button>
                  )}
                  <div className='my-7'>
                    <h3>Screening Files</h3>
                    {data?.screening_files?.screening_files?.length > 0 ? (
                      data?.screening_files?.screening_files?.map((file: any, index: any) => (
                        <button
                          key={index}
                          onClick={() => handlePdfFileClick(file.file_path)}
                          style={{display: 'block', marginBottom: '10px'}}
                        >
                          {file.file_name}
                        </button>
                      ))
                    ) : (
                      <p>No files available</p>
                    )}
                  </div>
                </div>
                <div className='modal-container pt-10'>
                  <div className='card'>
                    <div className='card-body'>
                      <Formik
                        initialValues={{status: '', remarks: ''}}
                        onSubmit={(values) => {
                          handleSubmit(values)
                        }}
                        validationSchema={REVIEW_VALIDATION_SCHEMA}
                      >
                        {({handleChange, errors, touched, values, setFieldValue}) => (
                          <Form>
                            <div className='row '>
                              <div className='col-md-8'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-md-12'
                                  label='Status Type'
                                  name='status'
                                  // isClearable={true}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allStatusType?.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                  }))}
                                  required={true}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                              <FormTextBox
                                containerClassName=''
                                labelClassName='col-md-12'
                                type='text'
                                placeholder='Remarks'
                                label='Remarks'
                                name='remarks'
                                disabled={false}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                data-cy='add-id-number-text-field'
                                as='textarea'
                              />
                            </div>
                            <div className='d-flex justify-content-end mt-3'>
                              <button
                                type='button'
                                onClick={handleBack}
                                className='btn btn-secondary mx-4'
                              >
                                Back
                              </button>
                              <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={postRespLoading}
                              >
                                {postRespLoading ? 'Submitting...' : 'Submit'}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-10'>
        <AccountStatusLog data={data} />
        <UserActivity caseId={id} />
      </div>
    </>
  )
}

export default QANormalReviewAndUpdate
