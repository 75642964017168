import axios from 'axios'
import {useQuery} from 'react-query'
import {DashBoardCount} from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'getDashBoardCount'
interface IParams {
  model_name: string
}
export const useGetCount = (params: IParams) => {
  return useQuery(
    [QUERY_KEY, params],
    async () => {
      const {data} = await axios.get(DashBoardCount, {params})
      return data
    },
    {
      onError: (error) => {
        console.error('Error fetching account schema:', error)
      },
    }
  )
}
