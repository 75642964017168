import React, {useEffect} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'

const OtherBanksTable = ({values, handleChange, errors, touched}: any) => {
  const {setFieldValue} = useFormikContext()

  // useEffect(() => {
  //   if (values.other_banks.length === 0) {
  //     setFieldValue('other_banks', [
  //       {
  //         bank_name: '',
  //         saving_ac: '',
  //         current_ac: '',
  //         fixed_ac: '',
  //         working_capital: '',
  //         term_loan: '',
  //         others: '',
  //       },
  //     ])
  //   }
  // }, [setFieldValue, values.other_banks])

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='border-top pt-9 pb-9'>
        <h3 className='fw-bolder mb-4 text-capitalize'>V. Dealing with other Banks, If Any</h3>

        <FieldArray name='other_banks'>
          {({remove, push}) => (
            <div>
              {values?.other_banks?.length > 0 ? (
                values?.other_banks?.map((bank: any, index: any) => (
                  <div className='row mb-3' key={index}>
                    <div className='col-md-4'>
                      {/* <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Bank Name'
                        name={`other_banks.${index}.bank_name`}
                        label='Bank Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      /> */}
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Bank Name'
                        name={`other_banks.${index}.bank_name`}
                        onChange={handleChange}
                        arrValue={values?.other_banks[index].bank_name}
                        errors={errors}
                        touched={touched}
                        options={allMasterDataOptions?.dealing_banks?.map((status: any) => ({
                          value: status.title || status.value,
                          label: status.title || status.label,
                        }))}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>

                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Saving Account'
                        name={`other_banks.${index}.saving_ac`}
                        label='Saving Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Current Account'
                        name={`other_banks.${index}.current_ac`}
                        label='Current Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Fixed Account'
                        name={`other_banks.${index}.fixed_ac`}
                        label='Fixed Account'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Working Capital'
                        name={`other_banks.${index}.working_capital`}
                        label='Working Capital'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12 '
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Term Loan'
                        name={`other_banks.${index}.term_loan`}
                        label='Term Loan'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-4'>
                      <FormTextBox
                        labelClassName='col-lg-12 '
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Others'
                        name={`other_banks.${index}.others`}
                        label='Others'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-2 d-flex align-items-center'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>click "Add Dealing with Other Bank" button to add details</p>
              )}
              <button
                type='button'
                className='dt-btn dt-btn-primary'
                onClick={() =>
                  push({
                    bank_name: '',
                    saving_ac: '',
                    current_ac: '',
                    fixed_ac: '',
                    working_capital: '',
                    term_loan: '',
                    others: '',
                  })
                }
              >
                Add Bank
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export default OtherBanksTable
