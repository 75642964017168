const FormDetails = ({label, value}: {label: string; value: string}) => {
  return (
    <div className='col-md-4 col-12'>
      <span className='form-review-field fw-bolder fs-6 opacity-50'>{label}</span>
      <p className=''> {value || ''}</p>
    </div>
  )
}

export default FormDetails
