import {tuple} from 'rsuite/esm/@types/utils'
import * as Yup from 'yup'

// Function to check if a string is a valid UUID
const isValidUUID = (value: any) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(value)
}

const isCountryNepal = (value: string) => value === '48691d0c-2489-4718-8596-10833123fce9'
const isAnyFieldFilled = (obj: any) =>
  !!(
    obj.saving_ac ||
    obj.current_ac ||
    obj.fixed_ac ||
    obj.working_capital ||
    obj.term_loan ||
    obj.others
  )

// const account_type_id = (values: any) => ''
export const CPUMAKER_SAVE_AND_CONTINUE = Yup.object().shape({
  issuing_district: Yup.string().required('The issuing district is required.'),
  businesses: Yup.array().of(
    Yup.object().shape({
      name_of_institution: Yup.string().matches(
        /^[A-Za-z\s]+$/,
        'Name should not contain any  numbers'
      ),
      address: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name should not contain any numbers'),

      telephone_number: Yup.string().matches(/^[0-9,]+$/, 'Mobile number must must be number'),
      designation: Yup.string(),

      email: Yup.string(),
      expected_annual_income: Yup.string(),
    })
  ),

  issuing_office: Yup.string().required('The issuing office is required.'),

  id_issued_date: Yup.string(),
  form_addresses: Yup.array().of(
    Yup.object().shape({
      permanent_country: Yup.string().required('The permanent country field is required.'),
      permanent_province: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Province is required.'),
        otherwise: Yup.string().nullable(),
      }),
      address_type: Yup.string().required('Address Type is Required'),
      permanent_zone: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Zone is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_district: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('District is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_municipality: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Municipality is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_city: Yup.string().when('permanent_country', {
        is: isCountryNepal,
        then: Yup.string().required('Permanent City is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_ward_number: Yup.string(),
      // .required('The permanent ward number is required.'),
      permanent_email: Yup.string().required('Email is required.'),
      // permanent_outside_country_full_address: Yup.string()
      //   .required('The full address is required.')
      //   .nullable(),
      // permanent_outside_country_post_box: Yup.string()
      //   .required('The post box No. is required.')
      //   .nullable(),
      permanent_outside_country_full_address: Yup.string().when('permanent_country', {
        is: !isCountryNepal,
        then: Yup.string().required('The full address is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_outside_country_post_box: Yup.string().when('permanent_country', {
        is: !isCountryNepal,
        then: Yup.string().required('The post box No. is required.'),
        otherwise: Yup.string().nullable(),
      }),
      permanent_house_number: Yup.string(),
      // .required('The permanent house number is required.'),
      // permanent_mobile_number: Yup.string()
      //   // .required('The permanent mobile number is required.')
      //   .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 and must be 10 digits')
      //   .typeError('Must be a numeric value.'),
      permanent_mobile_number: Yup.string()
        .required('The mobile number is required.')
        .test(
          'dots-or-mobile',
          'Mobile number must start with 98 or 97 and be 10 digits, or it must consist dot (.)',
          (value) => {
            if (!value) return false

            if (/^\.+$/.test(value) && value.length > 0) {
              return true
            }

            const mobileNumberRegex = /^(98|97)\d{8}$/
            return mobileNumberRegex.test(value)
          }
        ),
      permanent_street_name: Yup.string()
        .when('permanent_country', {
          is: isCountryNepal,
          then: Yup.string().required('Street Name is required.'),
          otherwise: Yup.string().nullable(),
        })
        .max(50, 'The Present Street Name must not exceed 50 characters.')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'This Field should only contain alphabets,[./,-]'),
      // present_street_name: Yup.string()
      //   .required('The Present Street Name is required.')
      //   .matches(
      //     /^[A-Za-z\s\-\/]+$/,
      //     'This Field should only contain alphabets,[./,-]'
      //   )
      //   .max(50, 'The Present Street Name must not exceed 50 characters.'),
      // present_fax_number: Yup.string()
      //   .matches(/^\d+$/, 'The Present Fax Number should only contain digits.')
      //   .max(10, 'The Present Fax Number must not exceed 50 digits.'),
      permanent_fax_number: Yup.string()
        .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
        .max(10, 'The Permanent Fax Number must not exceed 50 digits.'),
    })
  ),
  family_details: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
        .max(50, 'Name must not exceed 50 characters.'),

      relationship_id: Yup.string(),
      // profession: Yup.string().required('Other Account Purpose is required.'),
    })
  ),

  other_banks: Yup.array().of(
    Yup.object().shape({
      bank_name: Yup.string()
        .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
        .max(50, 'Name must not exceed 50 characters.')
        .required('Bank Name is required.'),
      saving_ac: Yup.string()
        .matches(/^[,0-9a-zA-Z _-]+$/, 'Saving Account should contain only letters and spaces')
        .max(50, 'Saving Account cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {current_ac, fixed_ac, working_capital, term_loan, others} = this.parent
          return value || current_ac || fixed_ac || working_capital || term_loan || others
        }),
      current_ac: Yup.string()
        .matches(/^[,0-9a-zA-Z _-]+$/, 'Current Account should contain only letters and spaces')
        .max(50, 'Current Account cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {saving_ac, fixed_ac, working_capital, term_loan, others} = this.parent
          return value || saving_ac || fixed_ac || working_capital || term_loan || others
        }),
      fixed_ac: Yup.string()
        .matches(/^[,0-9a-zA-Z _-]+$/, 'Fixed Account should contain only letters and spaces')
        .max(50, 'Fixed Account cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {saving_ac, current_ac, working_capital, term_loan, others} = this.parent
          return value || saving_ac || current_ac || working_capital || term_loan || others
        }),
      working_capital: Yup.string()
        .matches(/^[,0-9a-zA-Z _-]+$/, 'Working Capital should contain only letters and spaces')
        .max(50, 'Working Capital cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {saving_ac, current_ac, fixed_ac, term_loan, others} = this.parent
          return value || saving_ac || current_ac || fixed_ac || term_loan || others
        }),
      term_loan: Yup.string()
        .matches(/^[0-9,a-zA-Z _-]+$/, 'Term Loan should contain only letters and spaces')
        .max(50, 'Term Loan cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {saving_ac, current_ac, fixed_ac, working_capital, others} = this.parent
          return value || saving_ac || current_ac || fixed_ac || working_capital || others
        }),
      others: Yup.string()
        .matches(/^[,0-9a-zA-Z _-]+$/, 'Other should contain only letters and spaces')
        .max(50, 'Other cannot exceed 50 characters')
        .nullable()
        .test('at-least-one', 'At least one account must be filled.', function (value) {
          const {saving_ac, current_ac, fixed_ac, working_capital, term_loan} = this.parent
          return value || saving_ac || current_ac || fixed_ac || working_capital || term_loan
        }),
    })
  ),
  other_account_purpose: Yup.string().when('account_purpose_id', {
    is: '9682d56b-9d08-4ea5-a0b9-e72a5df0397a',
    then: Yup.string()
      .required('Other Account Purpose is required.')
      .matches(/^[^\d]*$/, 'Purposes must not contain any numbers.'),
    otherwise: Yup.string().notRequired(),
  }),

  expected_annual_transaction_amount: Yup.string()
    .required('Expected Annual Transaction Amount is required.')
    .matches(/^[0-9,.]+$/, 'Amount must only be numbers.'),
  expected_annual_transaction_number: Yup.string().required(
    'Expected Annual Transaction Number is required.'
  ),
  remarks: Yup.string().required('Remarks is required.'),
  pep_status: Yup.string().required('Pep Status is required.'),
  pep_relationship: Yup.string().when('pep_status', {
    is: 'Yes',
    then: Yup.string().required('PEP Relationship is required'),
  }),
  pep_position: Yup.string().when('pep_status', {
    is: 'Yes',
    then: Yup.string().required('PEP Postion is required.'),
  }),
  pep_retirement_date: Yup.string().when('pep_status', {
    is: 'Yes',
    then: Yup.string().required('PEP retirement date is required.'),
  }),
  beneficial_owner: Yup.string().required('Beneficial Owner is required.'),
  beneficial_owner_name: Yup.string().when('beneficial_owner', {
    is: 'Yes',
    then: Yup.string()
      .required('Beneficial Owner Name is required.')
      .matches(
        /^[A-Za-z\s]+$/,
        'Beneficial Owner Name must contain only alphabetic characters and spaces.'
      ),
    otherwise: Yup.string().notRequired(),
  }),
  beneficial_owner_occupation: Yup.string().when('beneficial_owner', {
    is: 'Yes',
    then: Yup.string().required('Beneficial Occupation is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  beneficial_owner_relation: Yup.string().when('beneficial_owner', {
    is: 'Yes',
    then: Yup.string().required('Beneficial Owner Relation is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  pan_number: Yup.string()
    // .required('The PAN number is required.')
    .matches(/^\d{9}$/, 'PAN number must be exactly 9 digits and should not have characters.')
    .max(9, 'PAN number must be 9 characters long.'),
  // pan_registration_date: Yup.string().required('The PAN registration date is required.'),
  visa_issue_date: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Issue Date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  visa_expiry_date: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Expiry Date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  visa_arrival_date: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Arrival Date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  work_permit_id: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Permit ID is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  work_permit_issued_date: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Permit issued date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  work_permit_expiry_date: Yup.string().when('present_country', {
    is: !'48691d0c-2489-4718-8596-10833123fce9 ',
    then: Yup.string().required('Visa Permit expiry date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  landlord_mobile_number: Yup.string()
    .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 and must be 10 digits')
    .typeError('Landlord mobile number must be a number'),
  crime_details: Yup.string().when('crime_conviction', {
    is: true,
    then: Yup.string().required('Crime Details are required.'),
    otherwise: Yup.string().notRequired(),
  }),
  permit_details: Yup.string().when('residential_permit_foreign_country', {
    is: true,
    then: Yup.string().required('Permit Details are required.'),
    otherwise: Yup.string().notRequired(),
  }),
  blacklist_release_date: Yup.string().when('blacklisting_inclusion', {
    is: true,
    then: Yup.string().required('Blacklist Release Date is required.'),
    otherwise: Yup.string().notRequired(),
  }),
  aml_risk_rating: Yup.string().required('AML Risk Rating is required.'),
  facta_declarations: Yup.array().of(
    Yup.object().shape({
      us_person: Yup.string()
        .trim()
        .required('US Person is required')
        .notOneOf([''], 'US Person is required'), // Ensures empty string triggers validation
      us_indicia: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string()
          .trim()
          .required('US Indicia is required')
          .notOneOf([''], 'US Indicia is required'),
        otherwise: Yup.string().notRequired(),
      }),
      us_citizenship: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string().required('US Citizenship is required'),
        otherwise: Yup.string().notRequired(),
      }),
      us_birth_country: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string().required('US Birth Country is required'),
        otherwise: Yup.string().notRequired(),
      }),
      us_residence: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string().required('US Residence is required'),
        otherwise: Yup.string().notRequired(),
      }),
      tin_number: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string().required('TIN Number is required'),
        otherwise: Yup.string().notRequired(),
      }),
      w9_form_submitted: Yup.string().when('us_person', {
        is: 'Yes',
        then: Yup.string().required('W9 Form Submitted is required'),
        otherwise: Yup.string().notRequired(),
      }),
      us_telephone_number: Yup.string()
        .matches(/^[0-9,]+$/, 'US telephone number must only be numbers.')
        .max(10, 'US telephone number must not exceed 10 digits.')
        .when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US telephone number is required.'),
          otherwise: Yup.string().notRequired(),
        }),
      joint_us_person: Yup.string().when('account_type_id', {
        is: 'Joint',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_us_indicia: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_us_citizenship: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_us_birth_country: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_us_residence: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_tin_number: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_w9_form_submitted: Yup.string().when(['account_type_id', 'joint_us_person'], {
        is: (account_type_id: any, joint_us_person: any) =>
          account_type_id === 'Joint' && joint_us_person === 'Yes',
        then: Yup.string().required('Required for joint account.'),
        otherwise: Yup.string().notRequired(),
      }),
      joint_us_telephone_number: Yup.string()
        .matches(/^[0-9,]+$/, 'Joint US telephone number must only be numbers.')
        .max(10, 'Joint US telephone number must not exceed 10 digits.')
        .when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
    })
  ),

  // total_annual_income: Yup.string()
  //   .required('The total annual income is required.')
  //   .matches(/^\d+$/, 'Total annual income must be a number.')
  //   .test(
  //     'max',
  //     'Total annual income must not exceed 1,000,000,000',
  //     (value) => !value || parseInt(value) <= 100000000000000
  //   ),

  // expected_monthly_income: Yup.string()
  //   .required('The expected annual turnover is required.')
  //   .matches(/^\d+$/, 'Expected annual turnover must be a number.')
  //   .test(
  //     'max',
  //     'Expected annual turnover must not exceed 1,000,000,000',
  //     (value) => !value || parseInt(value) <= 10000000000000000
  //   ),
  landlord_address: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Landlord Address Should only contain Characters'
  ),
  landlord_name: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Landlord Name Should only contain Characters'
  ),
  religion_id: Yup.string().required('The religion field is required.'),
  education_id: Yup.string().required('The education field is required.'),
  account_purpose_id: Yup.string().required('The account purpose field is required.'),
  // customer_category_id: Yup.string().required('The customer category field is required.'),
  has_existing_account: Yup.boolean().required('This field is required'),

  existing_account_number: Yup.string().when('has_existing_account', {
    is: true,
    then: Yup.string()
      .matches(/^\d+$/, 'Account number can only contain numbers')
      .required('Account number is required')
      .min(5, 'Account number must be at least 5 digits long')
      .max(12, 'Account number can be at most 12 digits long'),
    otherwise: Yup.string().nullable(),
  }),

  relation_with_existing_account: Yup.string().when('has_existing_account', {
    is: true,
    then: Yup.string().required('Relation with existing account is required'),
    otherwise: Yup.string().nullable(),
  }),
  constitution_id: Yup.string().required('Consititution Id is required'),

  bank_code_id: Yup.string().required('Bank Code is required'),

  customer_group_id: Yup.string().required('Customer Group is required'),

  customer_status_id: Yup.string().required('Customer Status is required'),

  // customer_free_text: Yup.string()
  //   .required('Customer Status Details are required')
  //   .max(20, 'Customer Status Details cannot be longer than 20 characters'),
  customer_free_text: Yup.string()
    .required('Customer Status Details is required')
    .max(50, 'Customer Status Details must be at most 20 characters long')
    .matches(/^[a-zA-Z\s]*$/, 'Customer Status Details must only contain Letters'),

  cdd_edd_date: Yup.date().required('CDD & EDD date is required').typeError('Invalid date format'),

  annual_turn_over_as_on: Yup.date()
    .required('Annual Turnover As On date is required')
    .typeError('Invalid date format'),
})

export const CPUMAKER_SAVE = Yup.object().shape({
  form_addresses: Yup.array().of(
    Yup.object().shape({
      permanent_province: Yup.string().required('The permanent province is required.'),
      permanent_zone: Yup.string().required('The permanent zone is required.'),
      permanent_district: Yup.string().required('The permanent district is required.'),
      permanent_municipality: Yup.string().required('The permanent municipality is required.'),
      permanent_street_name: Yup.string()
        .required('The Present Street Name is required.')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'This Field should only contain alphabets,[./,-]')
        .max(50, 'The Present Street Name must not exceed 50 characters.'),
      present_street_name: Yup.string()
        .required('The Present Street Name is required.')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'This Field should only contain alphabets,[./,-]')
        .max(50, 'The Present Street Name must not exceed 50 characters.'),
      present_fax_number: Yup.string()
        .matches(/^\d+$/, 'The Present Fax Number should only contain digits.')
        .max(50, 'The Present Fax Number must not exceed 50 digits.'),
      permanent_fax_number: Yup.string()
        .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
        .max(50, 'The Permanent Fax Number must not exceed 50 digits.'),
    })
  ),
})
const alphabeticRegex = /^[,a-zA-Z _-]+$/

export const ACCOUNT_OPENING_FORM_VALIDATION_SAVE_AND_CONTINUE = Yup.object().shape({
  // certificate_issued_by:Yup.string().required('The certificate issued by must be provided.'),
  // minor_proof_of_guardianship:Yup.string().required('The minor proof of guardianship must be provided.'),
  // minor_certificate_number:Yup.string().required('The minor certificate number must be provided.'),
  account_holders: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string()
        .required('First Name is required')
        .max(50, 'First Name cannot exceed 50 characters')
        .nullable(),
      middle_name: Yup.string().max(50, 'Middle Name cannot exceed 50 characters').nullable(),
      last_name: Yup.string()
        // .required('Last Name is required')
        .max(50, 'Last Name cannot exceed 50 characters')
        .nullable(),
      // cif_number: Yup.string()
      //   .required('CIF Number is required')
      //   .matches(/^\d+$/, 'CIF Number must be numeric'),
      // id_number: Yup.string().required('ID Number is required'),
      // id_type_id: Yup.string().required('ID Type is required'),
    })
  ),
  ancillary_services: Yup.array().of(
    Yup.object().shape({
      status: Yup.string().required('Ancillaries Status is required.'),
      service_id: Yup.string().when('status', {
        is: 'Yes',
        then: Yup.string().required('Required.'),
      }),
      reason: Yup.string()
        .when('status', {
          is: 'No',
          then: Yup.string().required('Reason is required.'),
        })
        .nullable(),
    })
  ),
  // account_title: Yup.string()
  //   .required('The account title field is required.'),
  // account_type_id: Yup.string().required('The account title must be selected.'),
  nominee_first_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      .required('Nominee first name must be provided.')
      .matches(alphabeticRegex, 'Nominee first name must contain only alphabetic characters.'),
    otherwise: Yup.string().notRequired(),
  }),

  nominee_middle_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().matches(
      alphabeticRegex,
      'Nominee middle name must contain only alphabetic characters.'
    ),
    otherwise: Yup.string().notRequired(),
  }),

  nominee_last_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      // .required('Nominee last name must be provided.')
      .matches(alphabeticRegex, 'Nominee last name must contain only alphabetic characters.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_citizenship_number: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      .required('Nominee identification number must be provided.')
      .matches(/^[0-9\/-]+$/, 'Nominee Citizenship number must be number.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_address: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee address must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_relationship_id: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee relation must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_place_of_issue: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee place of issue must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  introducer_first_name: Yup.string()
    // .required('Introducer first name must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'First name should only contain characters.'),
  introducer_last_name: Yup.string()
    // .required('Introducer last name must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'Last name should only contain characters.'),
  introducer_middle_name: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Middle name should only contain characters.'
  ),

  introducer_customer_id: Yup.string()
    // .required('Introducer customer ID must be provided.')
    .matches(/^\d{1,15}$/, 'Introducer customer ID must be exactly 15 digits and a number.'),
  introducer_address: Yup.string()
    // .required('Introducer customer address must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'Address should only contain characters.'),
  introducer_confirmation_period: Yup.string()
    // .required('Introducer confirmation period must be provided.')
    .matches(/^\d{1,2}$/, 'Introducer confirmation period must be exactly 2 digits and a number.'),

  cheque_book_name: Yup.string()
    .required('Cheque book name must be provided.')
    .matches(
      /^[a-zA-Z _\-\/]+$/,
      'Cheque Book Name should only contain letters, spaces, underscores, hyphens, or slashes.'
    )
    .max(70, 'Only up to 70 characters are allowed'),
  mode_of_operation: Yup.string().required('Mode of operation must be provided.'),
  loan_detail_id: Yup.string().required('Loan Details is required'),
  deposit_detail_id: Yup.string().required('Loan Details is required'),
  account_scheme_id: Yup.string().required('Account Scheme is required'),
  blacklist_screening: Yup.boolean().required('Required'),
  sanction_screening: Yup.boolean().required('Required'),
  adverse_media: Yup.boolean(),
  duplication_screening: Yup.boolean().required('Required'),
  regulatory_block: Yup.boolean(),
  initial_deposit: Yup.string()
    .required('Initial deposit must be provided.')
    .matches(/^[0-9,.]+$/, 'Initial deposit must be a number.')
    .max(10, 'Name must not exceed 10 characters.'),
  account_currency_id: Yup.string().required('The account currency must be selected.'),
  account_category_id: Yup.string().required('The account category must be provided.'),
})
