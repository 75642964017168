import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const VERIFY_OTP = `${BASE_API_URL}/new-cif-new-account/verify-otp/`

const postVerifyOTP = async (newCIFNewAccount: string, code: string, caseType: string) => {
  try {
    return await axios.post(`${VERIFY_OTP}${newCIFNewAccount}/${code}/${caseType}`)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
    } else {
      console.log(error)
    }
  }
}

export const usePostVerifyOTP = () =>
  useMutation({
    mutationFn: async ({
      newCIFNewAccount,
      code,
      caseType,
    }: {
      newCIFNewAccount: string
      code: string
      caseType: string
    }) => postVerifyOTP(newCIFNewAccount, code, caseType),
  })

const VERIFY_OTP_CORPORATE = `${BASE_API_URL}/corporate-new-cif-new-account/verify-otp/`

const postVerifyOTPCorporate = async (newCIFNewAccount: string, code: string, caseType: string) => {
  try {
    return await axios.post(`${VERIFY_OTP_CORPORATE}${newCIFNewAccount}/${code}/${caseType}`)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      toast.error(error.response?.data?.message)
    } else {
      console.log(error)
    }
  }
}

export const usePostVerifyOTPCorporate = () =>
  useMutation({
    mutationFn: async ({
      newCIFNewAccount,
      code,
      caseType,
    }: {
      newCIFNewAccount: string
      code: string
      caseType: string
    }) => postVerifyOTPCorporate(newCIFNewAccount, code, caseType),
  })
