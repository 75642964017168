import React from 'react'
import {ErrorMessage, FormikErrors} from 'formik'
import Select from 'react-select'

interface Props {
  containerClassName?: string
  label?: string
  name: string
  type?: string
  placeholder?: string
  onChange: any
  className?: string
  errors: FormikErrors<any>
  touched: any
  labelClassName?: string
  onBlur?: any
  required?: boolean
  disabled?: boolean
  multiple?: boolean
  options: {label: string; value: string}[] | undefined
  values?: any
  style?: any
  setFieldValue: any
  arrValue?: string
  defaultValue?: any
  isClearable?: any
  colors?: any
}

const FormSelect = (props: Props) => {
  const {
    containerClassName,
    label,
    name,
    type,
    className,
    errors,
    touched,
    labelClassName,
    required,
    options,
    onBlur,
    placeholder,
    onChange,
    disabled,
    values,
    setFieldValue,
    multiple,
    arrValue,
    style,
    defaultValue,
    isClearable,
    colors,
    ...rest
  } = props

  const selectStyles = {
    menuList: (styles: any) => {
      return {
        ...styles,
        maxHeight: 155,
      }
    },
  }

  return (
    <div className={label ? 'row mb-6' : 'col-lg-6 '}>
      {label && (
        <label className={`mb-1 fw-bolder fs-6 ${labelClassName || 'col-lg-4'}`}>
          <span className={required ? 'required' : ''}>{label}</span>
        </label>
      )}
      <div className={containerClassName ?? 'col-lg-6 '} data-cy={`${name}-checkbox-field`}>
        {multiple ? (
          <Select
            {...rest}
            id='color'
            className='customSelect'
            classNamePrefix='cusom-react-select'
            menuPlacement='bottom'
            styles={selectStyles}
            name={name}
            options={options}
            onChange={(option) => {
              let selectIds = option.map((item) => item?.value)
              setFieldValue(name, selectIds)
            }}
            value={options?.filter((obj) => values?.[name]?.includes(obj?.value))}
            isMulti={multiple}
            isDisabled={disabled}
            defaultValue={defaultValue}
            data-cy={`${name}-checkbox-field`}
          />
        ) : (
          <Select
            {...rest}
            id='color'
            className='customSelect'
            classNamePrefix='cusom-react-select'
            menuPlacement='bottom'
            styles={{
              ...selectStyles,
              control: (provided, state) => ({
                ...provided,
                backgroundColor: disabled ? '#ffc700' : 'white',
                color: 'black',
                border: state.isFocused ? '1px solid #ddd' : '1px solid #ccc',
                boxShadow: state.isFocused ? '0 0 0 1px #ddd' : 'none',
                minHeight: '48px',
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: '0 8px',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'black',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
            name={name}
            isClearable={isClearable}
            options={options}
            isDisabled={disabled}
            onBlur={onBlur}
            onChange={(option) => {
              if (option?.isReset) {
                setFieldValue(option?.resetValue, '')
              }
              setFieldValue(name, option?.value)
              onChange(option?.value)
            }}
            value={
              arrValue || values?.[name]
                ? options?.find((obj) => obj?.value === (arrValue ? arrValue : values?.[name]))
                : null
            }
            defaultValue={defaultValue}
            data-cy={`${name}-checkbox-field`}
          />
        )}
        <div className='fv-plugins-message-container text-danger fw-bolder small text-start mt-1'>
          <ErrorMessage name={name} component='div' className='field-error-message' />
        </div>
      </div>
    </div>
  )
}

export default FormSelect
