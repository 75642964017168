import React, {useEffect, useRef, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {usePostAccountOpeningForm} from 'src/app/queries/new-cif-new-account-queries/cpuMaker/post/usePostAccountOpening'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {Button, Spinner} from 'react-bootstrap-v5'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import {useHistory, useParams} from 'react-router-dom'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import {ACCOUNT_OPENING_FORM_VALIDATION_SAVE_AND_CONTINUE} from '../../utils/ValidationSchema'
import AccountHoldersData from '../kycUpdateDetailsFormCPUMaker/normal/IndividualJointMinorKYC/additionalFormFields/accountHolders'
import usePostCustomerEnquiryInfo from 'src/app/queries/apiMS/usePostCUstomerEnquiryInfo'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import usePostKYCAdditionalDetails from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostAdditionalForm'
import usePatchKycDetailsIndividual from 'src/app/queries/new-cif-new-account-queries/cpuMaker/kycIndividual/patch/usePatchKycDetailsIndividual'
import Ancillaries from '../kycUpdateDetailsFormCPUMaker/normal/IndividualJointMinorKYC/additionalFormFields/ancillaries'
import axios from 'axios'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import {toast} from 'react-toastify'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'

const CpuMakerAccountOpeningForm = ({kycData}: any) => {
  interface IIDPROPS {
    id: string
  }

  const {id} = useParams<IIDPROPS>()
  const [btnFlag, setBtnFlag] = useState('')
  const {data: preliminaryDetailsByID, isLoading: detailsLoading} =
    useGetPreliminariesDetailsById(id)

  const {mutate: addAddtionalDetails} = usePostKYCAdditionalDetails()
  const {mutate: updateKycDetails, isLoading: updateLoading} = usePatchKycDetailsIndividual()
  const [sameAsPermanentAddress, setSameAsPermanentAddress] = useState(false)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const {mutate: mutateCheckAll, isLoading: pdfLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })
  const history = useHistory()
  const defaultCurrency = allMasterDataOptions.currencies?.find(
    (currency: any) => currency?.currency_code === 'NPR'
  )

  const initialValues = {
    account_title: preliminaryDetailsByID?.data?.account_opening?.account_title || '',
    initial_deposit: preliminaryDetailsByID?.data?.account_opening?.initial_deposit || '',
    account_category_id:
      preliminaryDetailsByID?.data?.account_opening?.account_category_id?.id || '',
    account_currency_id:
      defaultCurrency?.id || preliminaryDetailsByID?.data?.account_opening?.account_currency_id?.id,
    account_purpose_id: preliminaryDetailsByID?.data?.account_purpose_id?.id || '',
    other_account_purpose: preliminaryDetailsByID?.data?.other_account_purpose || '',

    other_account_currency:
      preliminaryDetailsByID?.data?.account_opening?.other_account_currency || defaultCurrency?.id,
    nomination: preliminaryDetailsByID?.data?.account_opening?.nomination ? 'true' : 'false',
    nominee_first_name: preliminaryDetailsByID?.data?.account_opening?.nominee_first_name || '',
    nominee_middle_name: preliminaryDetailsByID?.data?.account_opening?.nominee_middle_name || '',
    nominee_last_name: preliminaryDetailsByID?.data?.account_opening?.nominee_last_name || '',
    nominee_citizenship_number:
      preliminaryDetailsByID?.data?.account_opening?.nominee_citizenship_number || '',
    nominee_address: preliminaryDetailsByID?.data?.account_opening?.nominee_address || '',
    nominee_relationship_id:
      preliminaryDetailsByID?.data?.account_opening?.nominee_relationship_id?.id || '',
    nominee_place_of_issue:
      preliminaryDetailsByID?.data?.account_opening?.nominee_place_of_issue?.id || '',
    guardian_first_name: preliminaryDetailsByID?.data?.guardian_first_name || '',
    guardian_middle_name: preliminaryDetailsByID?.data?.guardian_middle_name || '',
    guardian_last_name: preliminaryDetailsByID?.data?.guardian_last_name || '',
    guardian_relationship_id: preliminaryDetailsByID?.data?.guardian_relationship_id?.id || '',
    guardian_address: preliminaryDetailsByID?.data?.guardian_address || '',
    minor_certificate_number: preliminaryDetailsByID?.data?.minor_certificate_number || '',
    certificate_issued_by: preliminaryDetailsByID?.data?.certificate_issued_by?.id || '', // minor_proof_of_guardianship: preliminaryDetailsByID?.data?.minor_proof_of_guardianship || '',
    introducer_first_name:
      preliminaryDetailsByID?.data?.account_opening?.introducer_first_name || '',
    introducer_middle_name:
      preliminaryDetailsByID?.data?.account_opening?.introducer_middle_name || '',
    introducer_last_name: preliminaryDetailsByID?.data?.account_opening?.introducer_last_name || '',
    introducer_customer_id:
      preliminaryDetailsByID?.data?.account_opening?.introducer_customer_id || '',
    introducer_address: preliminaryDetailsByID?.data?.account_opening?.introducer_address || '',
    introducer_confirmation_period:
      preliminaryDetailsByID?.data?.account_opening?.introducer_confirmation_period || '',

    cheque_book_name: preliminaryDetailsByID?.data?.full_name || '',
    mode_of_operation: preliminaryDetailsByID?.data?.account_opening?.mode_of_operation || '',
    other_mode_of_operation:
      preliminaryDetailsByID?.data?.account_opening?.other_mode_of_operation || '',
    blacklist_screening: preliminaryDetailsByID?.data?.account_opening?.blacklist_screening || '',
    sanction_screening: preliminaryDetailsByID?.data?.account_opening?.sanction_screening || '',
    duplication_screening:
      preliminaryDetailsByID?.data?.account_opening?.duplication_screening || '',
    adverse_media: preliminaryDetailsByID?.data?.account_opening?.adverse_media || false,
    regulatory_block: preliminaryDetailsByID?.data?.account_opening?.regulatory_block || false,
    account_type_id: preliminaryDetailsByID?.data?.account_type_id?.id || '',
    loan_detail_id: preliminaryDetailsByID?.data?.account_opening?.loan_detail_id?.id || '',
    deposit_detail_id: preliminaryDetailsByID?.data?.account_opening?.deposit_detail_id?.id || '',
    account_scheme_id: preliminaryDetailsByID?.data?.account_opening?.account_scheme_id?.id || '',
    account_holders:
      kycData?.account_type_id ===
      allMasterDataOptions?.account_types?.find((acctype: any) => acctype.cbs_code === 'Joint')?.id
        ? preliminaryDetailsByID?.data?.account_holders?.account_holders?.map(
            (accountHolder: any) => ({
              first_name: accountHolder?.first_name || '',
              middle_name: accountHolder?.middle_name || '',
              last_name: accountHolder?.last_name || '',
              cif_number: accountHolder?.cif_number,
              id_number: accountHolder?.id_number,
              id_type_id: accountHolder?.id_type_id?.id,
              account_relation_id: accountHolder?.account_relation_id?.id,
            })
          )
        : [
            {
              first_name: preliminaryDetailsByID?.data?.first_name,
              middle_name: preliminaryDetailsByID?.data?.middle_name,
              last_name: preliminaryDetailsByID?.data?.last_name,
              cif_number: '',
              id_number: '',
              id_type_id: '',
              account_relation_id: '',
            },
          ],

    ancillary_services: preliminaryDetailsByID?.data?.ancillary_services?.ancillary_services?.map(
      (service: any) => {
        return {
          status: service?.service !== null ? 'Yes' : 'No',
          reason: service?.reason || '',
          service_id: service?.service?.id || '',
        }
      }
    ) || [
      {
        status: '',
        reason: '',
        service_id: '',
      },
    ],
  }


  const {mutate: postAccountOpening} = usePostAccountOpeningForm()
  const transformValues = (values: any) => {
    return {
      // account_holders: values?.account_holders,
      family_details: kycData?.family_details,
      facta_declarations: kycData?.facta_declarations,
      businesses: kycData?.businesses,
      ancillary_services: values?.ancillary_services,
    }
  }

  const handleSubmit = (values: any, actions: any) => {
    const kycPayload = {
      ...kycData, // Specific fields needed for KYC
      id: id,
      form_addresses: {...kycData?.form_addresses?.[0]},
      action: 'save_and_continue',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }

    // Prepare specific payload for Account Opening
    const accountOpeningPayload = {
      ...values,
      ancillary_services: values?.ancillary_services,
      action: 'save_and_continue',
      id: id,
      nomination: values.nomination === 'true',
    }
    const additionalFormPayload: any = transformValues(values)
    addAddtionalDetails(
      {id, additionalFormPayload},
      {
        onSuccess: () => {
          // Then call updateKycDetails API
          updateKycDetails(kycPayload, {
            onSuccess: () => {
              // Finally call postAccountOpening API
              postAccountOpening(accountOpeningPayload, {
                onSuccess: () => {
                  toast.success('CIF and Account Details Saved Successfully')
                  // if (btnFlag === 'save_and_continue') {
                  // } else {
                  //   toast.success('CIF and Account Details Saved as Draft Successfully')
                  history.push('/new-cif-new-accounts')
                  // }
                  actions.setSubmitting(false)
                },
                onError: (error: any) => {
                  actions.setSubmitting(false)
                  toast.error('Failed to save account opening details')
                  console.error('Post Account Opening Error:', error)
                },
              })
            },
            onError: (error) => {
              actions.setSubmitting(false)
              toast.error('Failed to save CIF details')
              console.error('Update KYC Details Error:', error)
            },
          })
        },
        onError: (error) => {
          actions.setSubmitting(false)
          toast.error('Failed to add additional details')
          console.error('Add Additional Details Error:', error)
        },
      }
    )
  }
  const handleBack = () => {
    history.push('/new-cif-new-accounts')
  }

  const [enquiryData, setEnquiryData] = useState<any>()
  const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)
  {
    /*setEnquiryData*/
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      window.open(file, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleButtonClick = (flag: string) => {
    setBtnFlag(flag)
  }

  const halfLength = Math.ceil(allMasterDataOptions?.salutations?.length / 2)
  const firstRowSalutations = allMasterDataOptions?.salutations?.slice(0, halfLength)
  const secondRowSalutations = allMasterDataOptions?.salutations?.slice(halfLength)
  const nepalID = '48691d0c-2489-4718-8596-10833123fce9 '
  const nationality_id = '4785497c-56b4-4745-a15b-6bbd20ada494'

  const [showCIFCheck, setShowCIFCheck] = useState(false)

  const [accountTypeCode, setAccountTypeCode] = useState<string | null>(null)
  const [schemaData, setSchemaData] = useState<any>(null)
  useEffect(() => {
    if (accountTypeCode) {
      const fetchSchemaData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/account-schemas`, {
              params: {schema_type: accountTypeCode},
            })
            .then((res: any) => {
              setSchemaData(res?.data)
            })
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchSchemaData()
    }
  }, [accountTypeCode])

  const getAccountTypeId = (e: any, values: any) => {
    const presentAccountType = e
    const accountType = allMasterDataOptions?.account_categories
    const matchingZone = accountType?.find((account: any) => account?.id === presentAccountType)
    if (matchingZone) {
      setAccountTypeCode(matchingZone?.cbs_code)
    } else {
      console.log('No matching zone found.')
    }
  }

  const handleScreeningClick = () => {
    setShowCIFCheck((prevState) => !prevState)
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf2 = () => {
    if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  if (detailsLoading) {
    return <div>Loading...</div>
  }

  const handleScreening = () => {
    setScreeningPerformed(true)
    const checkAllPayload: any = {
      customer_name: preliminaryDetailsByID?.data?.full_name,
      citizenship: preliminaryDetailsByID?.data?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data: any) => {
        setScreeningLoading(false)
      },
      onError: (error: any) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnChange={true}
      validationSchema={ACCOUNT_OPENING_FORM_VALIDATION_SAVE_AND_CONTINUE}
    >
      {({handleChange, errors, touched, values, setFieldValue, isSubmitting}: any) => {
        useEffect(() => {
          setFieldValue({
            account_purpose_id: preliminaryDetailsByID?.data?.account_purpose_id?.id || '',
          })
        }, [])

        useEffect(() => {
          const combinedNames = values?.account_holders
            .map((holder: any) =>
              `${holder.first_name || ''} ${holder.middle_name || ''} ${
                holder.last_name || ''
              }`.trim()
            )
            .filter((name: any) => name !== '') // Filter out empty strings
            .join('/')

          setFieldValue('cheque_book_name', combinedNames)
        }, [values.account_holders, setFieldValue])
        return (
          <Form className='bg-white'>
            <div className='row'>
              <div className='col-md-6 col-xs-12 mb-5'>
                <label className='form-label fw-bolder text-dark fs-6 required'>
                  Select Account Type
                </label>
                <div className='d-flex'>
                  {isLoading ? (
                    <Spinner animation={'border'} />
                  ) : (
                    allMasterDataOptions?.account_types
                      ?.filter((type: {title: string}) =>
                        ['Individual', 'Joint', 'Minor']?.includes(type.title)
                      )
                      ?.map(({id, title}: any) => (
                        <>
                          <FormRadio
                            key={id}
                            containerClassName=''
                            label='Account Type'
                            name='account_type_id'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            value={id}
                            checkBoxText={title}
                            checked={values?.account_type_id === id}
                            disabled={true}
                          />
                        </>
                      ))
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <h3 className='fw-bolder mb-4 text-capitalize'>A. Customer Name</h3>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Account Title'
                  name='account_title'
                  label='Account Title'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  value={values?.account_title}
                  // disabled={true}
                />
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>B. Account Type</h3>
              <div className='col-md-6 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select'
                  label='Account Type'
                  name='account_category_id'
                  onChange={(w: any) => {
                    handleChange(w)
                    getAccountTypeId(w, values)
                  }}
                  errors={errors}
                  values={values}
                  touched={touched}
                  options={allMasterDataOptions?.account_categories?.map((accat: any) => ({
                    value: accat?.id || accat.value,
                    label: accat?.title || accat.label,
                  }))}
                  setFieldValue={setFieldValue}
                  required
                />
              </div>

              <div className='col-md-6 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select'
                  label='Account Scheme'
                  name='account_scheme_id'
                  onChange={handleChange}
                  errors={errors}
                  values={values}
                  touched={touched}
                  options={
                    schemaData?.data?.account_schemas?.length > 0
                      ? schemaData?.data?.account_schemas?.map((accur: any) => ({
                          value: accur.id || accur.value,
                          label: accur.title || accur.label,
                        }))
                      : allMasterDataOptions?.account_schemas?.map((scheme: any) => ({
                          value: scheme?.id || scheme?.value,
                          label: scheme?.title || scheme?.label,
                        }))
                  }
                  setFieldValue={setFieldValue}
                  required
                />
              </div>

              <h3 className='fw-bolder mb-4 text-capitalize'>C. Initial Deposit</h3>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Initial Deposit'
                  name='initial_deposit'
                  label='Initial Deposit'
                  value={values?.initial_deposit}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                />
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>D. Account Currency</h3>
              <div className='col-md-6 col-sm-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Account Currency'
                  name='account_currency_id'
                  label='Account Currency'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  arrValue={values?.account_currency_id}
                  options={allMasterDataOptions?.currencies?.map((currencies: any) => ({
                    value: currencies.id || currencies.value,
                    label: currencies.title || currencies.label,
                  }))}
                  setFieldValue={setFieldValue}
                />
              </div>
              {allMasterDataOptions?.currencies
                ?.find((currencies: any) => currencies.id === values.account_currency_id)
                ?.title.toLowerCase() === 'others' && (
                <div className='col-md-6 col-sm-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Other Account Currency'
                    name='other_account_currency'
                    label='Other Account Currency'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required
                  />
                </div>
              )}
              <h3 className='fw-bolder mb-4 text-capitalize'>E. Purpose of Account </h3>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <FormSelect
                  labelClassName='col-md-12'
                  containerClassName='col-lg-12'
                  placeholder='Select'
                  name='account_purpose_id'
                  label='Account Purpose'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                  arrValue={values?.account_purpose_id}
                  options={allMasterDataOptions?.purposes?.map((purposes: any) => ({
                    value: purposes.id || purposes.value,
                    label: purposes.title || purposes.label,
                  }))}
                  setFieldValue={setFieldValue}
                />
              </div>
              {allMasterDataOptions?.purposes
                ?.find(
                  (purposes: any) =>
                    purposes.id === preliminaryDetailsByID?.data?.account_purpose_id?.id
                )
                ?.title.toLowerCase() === 'others' && (
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Other Account Purpose'
                    name='other_account_purpose'
                    label='Other Account Purpose'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    uppercase={true}
                    value={preliminaryDetailsByID?.data?.other_account_purpose}
                  />
                </div>
              )}
              <h3 className='fw-bolder mb-4 text-capitalize'>F. Name of Account Holder's </h3>
              <div className='row'>
                <AccountHoldersData
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  enquiryData={enquiryData}
                  customerEnquiry={customerEnquiry}
                  mutateCheckAll={mutateCheckAll}
                  handlePdf={handleOpenPdf2}
                  update={update}
                  checkAllData={checkAllDataJoint}
                  setScreeningPerformed={setScreeningPerformed}
                  pdfLoading={pdfLoading}
                />
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>G. nominee </h3>
              <div className='col-lg-12 col-sm-12 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select'
                  name='nomination'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={[
                    {label: 'Yes', value: 'true'},
                    {label: 'No', value: 'false'},
                  ]}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div className='row'>
                {values.nomination === 'true' && (
                  <>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Nominee First Name'
                        name='nominee_first_name'
                        label='Nominee First Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Nominee Middle Name'
                        name='nominee_middle_name'
                        label='Nominee Middle Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        // required
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Nominee Last Name'
                        name='nominee_last_name'
                        label='Nominee Last Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        maxLength={20}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Nominee Citizenship Number'
                        name='nominee_citizenship_number'
                        label='Nominee Citizenship Number'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        maxLength={10}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Nominee Address'
                        name='nominee_address'
                        label='Nominee Address'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        maxLength={50}
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Nominee Relationship'
                        name='nominee_relationship_id'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        options={allMasterDataOptions?.relationships?.map(
                          (nominee_relation: any) => ({
                            value: nominee_relation.id || nominee_relation.value,
                            label: nominee_relation.title || nominee_relation.label,
                          })
                        )}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Nominees Citizenship Place of Issuing District'
                        name='nominee_place_of_issue'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        options={districtOptions}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                  </>
                )}
              </div>
              <div className='row'>
                {allMasterDataOptions?.account_types
                  ?.find((account_types: any) => account_types.id === values?.account_type_id)
                  ?.title.toLowerCase() === 'minor' && (
                  <>
                    <h3 className='fw-bolder mb-4 text-capitalize'>H. Guardian Details </h3>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Guardian First Name'
                        name='guardian_first_name'
                        label='Guardian First Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        value={values?.guardian_first_name}
                        required
                        disabled={true}
                        maxLength={30}
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Guardian Middle Name'
                        name='guardian_middle_name'
                        label='Guardian Middle Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={true}
                        maxLength={20}
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Guardian Last Name'
                        name='guardian_last_name'
                        label='Guardian Last Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        disabled={true}
                        maxLength={20}
                        className={'bg-warning w-100 p-3 rounded-2'}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Guardian Relationship'
                        name='guardian_relationship_id'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        options={allMasterDataOptions?.guardian_relationships?.map(
                          (guardian_relationship_id: any) => ({
                            value: guardian_relationship_id.id || guardian_relationship_id.value,
                            label: guardian_relationship_id.title || guardian_relationship_id.label,
                          })
                        )}
                        setFieldValue={setFieldValue}
                        required
                        disabled={true}
                      />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Guardian Address'
                        name='guardian_address'
                        label='Guardian Address'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                        disabled={true}
                        maxLength={30}
                      />
                    </div>
                    {/*<div className='col-md-6 col-sm-12'>*/}
                    {/*  <FormTextBox*/}
                    {/*    labelClassName='col-lg-12'*/}
                    {/*    containerClassName='col-lg-12'*/}
                    {/*    type='text'*/}
                    {/*    placeholder='Minor Certificate Number'*/}
                    {/*    name='minor_certificate_number'*/}
                    {/*    label='Minor Certificate Number'*/}
                    {/*    onChange={handleChange}*/}
                    {/*    errors={errors}*/}
                    {/*    touched={touched}*/}
                    {/*    required*/}
                    {/*    maxLength={10}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className='col-md-6 col-sm-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        placeholder='Certificatge Issued By'
                        label='Certificate Issued By'
                        name={`certificate_issued_by`}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={allMasterDataOptions?.individual_unique_ids?.map(
                          (certificateIssue: any) => ({
                            value: certificateIssue.id || certificateIssue.value,
                            label: certificateIssue.title || certificateIssue.label,
                          })
                        )}
                        setFieldValue={setFieldValue}
                        values={values}
                        arrValue={values.certificate_issued_by}
                        required
                        disabled
                      />
                    </div>
                    {/* <div className='col-md-6 col-sm-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Minor Proof of Guardianship'
                        name='minor_proof_of_guardianship'
                        label='Minor Proof of Guardianship'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div> */}
                  </>
                )}
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>I. Ancillaries</h3>
              <Ancillaries
                values={values}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              <h3 className='fw-bolder mb-4 text-capitalize'>J. Introducer's Details </h3>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer First Name'
                  name='introducer_first_name'
                  label='Introducer First Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // required
                  maxLength={20}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer Middle Name'
                  name='introducer_middle_name'
                  label='Introducer Middle Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer Last Name'
                  name='introducer_last_name'
                  label='Introducer Last Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // required
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer Customer ID'
                  name='introducer_customer_id'
                  label='Introducer Customer ID'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // required
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer Address'
                  name='introducer_address'
                  label='Introducer Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  // required
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Introducer Confirmation Period'
                  name='introducer_confirmation_period'
                  label='Introducer Confirmation Period'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  maxLength={2}
                  // required
                />
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>K. Mode of Account Operation </h3>
              <div className='col-md-6 col-sm-4 col-xs-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Mode of Operation'
                  name='mode_of_operation'
                  label='Mode Of Operation'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={[
                    {label: 'Single', value: 'single'},
                    {label: 'Jointly', value: 'jointly'},
                    {
                      label: 'Special Instruction (Any one of us)',
                      value: 'special_instruction',
                    },
                    {label: 'Any Other (Specify)', value: 'other'},
                  ]}
                  setFieldValue={setFieldValue}
                  values={values}
                  required
                />
              </div>
              {values.mode_of_operation === 'other' && (
                <div className='col-md-6 col-sm-4 col-xs-12'>
                  <FormTextBox
                    labelClassName='col-lg-12'
                    containerClassName='col-lg-12'
                    type='text'
                    placeholder='Other Mode of Operation'
                    name='other_mode_of_operation'
                    label='Other Mode of Operation(If any)'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              )}

              <input type='hidden' name='tax_group_id' value={values.tax_group_id} />
              <div className='col-md-6 col-sm-12'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Cheque Book Name'
                  name='cheque_book_name'
                  label='Cheque Book Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  value={values?.cheque_book_name}
                  required
                  disabled={
                    allMasterDataOptions?.account_types
                      ?.find((account_type: any) => account_type.id === values?.account_type_id)
                      ?.title.toLowerCase() !== 'joint'
                  }
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Loan Details'
                  name='loan_detail_id'
                  label='Loan Details'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  values={values}
                  options={allMasterDataOptions?.loan_details?.map((loanDetails: any) => ({
                    value: loanDetails?.id || loanDetails.value,
                    label: loanDetails?.title || loanDetails.label,
                  }))}
                  setFieldValue={setFieldValue}
                  required
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Deposit Details'
                  name='deposit_detail_id'
                  label='Deposit Details'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  values={values}
                  options={allMasterDataOptions?.deposit_details?.map((depositDetails: any) => ({
                    value: depositDetails.id || depositDetails.value,
                    label: depositDetails.title || depositDetails.label,
                  }))}
                  setFieldValue={setFieldValue}
                />
              </div>
              <h3 className='fw-bolder mb-4 text-capitalize'>Self Declaration </h3>
              <div className='col-md-6 col-sm-12'>
                <FormCheckbox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  name='blacklist_screening'
                  label='Blacklist Screening'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  checkBoxText='Blacklist Screening'
                  checked={values.blacklist_screening}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormCheckbox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  name='sanction_screening'
                  label='Sanction Screening'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  checkBoxText='Sanction Screening'
                  checked={values.sanction_screening}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormCheckbox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  name='duplication_screening'
                  label='Duplication Screening'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  required
                  checkBoxText='Duplication Screening'
                  checked={values.duplication_screening}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormCheckbox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  name='adverse_media'
                  label='Adverse Media'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  checkBoxText='Adverse Media'
                  checked={values.adverse_media}
                />
              </div>
              <div className='col-md-6 col-sm-12'>
                <FormCheckbox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  name='regulatory_block'
                  label='Regulatory Block'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  checkBoxText='Regulatory Block'
                  checked={values.regulatory_block}
                />
              </div>
            </div>
            {/* <div className='row'>
              {screeningPerformed ? (
                checkAllData && (
                  <Button
                    type='button'
                    onClick={handleOpenPdf}
                    className='col-md-3 col-sm-6 col-xs-12'
                  >
                    View Details
                  </Button>
                )
              ) : screeningLoading ? (
                <Spinner animation='border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                <Button
                  type='button'
                  onClick={handleScreening}
                  className='col-md-3 col-sm-6 col-xs-12'
                >
                  Perform Screening
                </Button>
              )}
              <div className='my-7'>
                <h3>Screening Files</h3>
                {preliminaryDetailsByID?.data?.screening_files?.screening_files?.length > 0 ? (
                  preliminaryDetailsByID?.data?.screening_files?.screening_files?.map(
                    (file: any, index: any) => (
                      <button
                        key={index}
                        onClick={() => handlePdfFileClick(file.file_path)}
                        style={{display: 'block', marginBottom: '10px'}}
                      >
                        {file.file_name}
                      </button>
                    )
                  )
                ) : (
                  <p>No files available</p>
                )}
              </div>
            </div> */}
            <div className='d-flex justify-content-end my-5 px-5'>
              <div className='me-3'>
                <button
                  className='btn btn-primary'
                  disabled={isSubmitting}
                  onClick={() => handleBack()}
                >
                  Back
                </button>
              </div>
              <div className='me-3'>
                <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CpuMakerAccountOpeningForm
