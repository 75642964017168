import React, {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import CorporateLinkedEntitiesDetails from './corporateLinkedEntities'
import ToBeLinkedEntities from './linkedEntitiesInfos'
import {Form, Formik} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import * as Yup from 'yup'

const ViewCustomerDetails = ({data, customerEntitiesData, onSubmitValues}: any) => {
  const customerName = customerEntitiesData?.AccountDetail[0]?.ACCT_NAME
  const initialValues = {
    customer_name: customerName,
    citizenship: '',
    pan_number: '',
    model_name: 'LinkedEntities',
  }

  const validationSchema = Yup.object().shape({
    citizenship: Yup.string().when([], {
      is: () => !onSubmitValues?.is_corporate, // If not corporate, citizenship is required
      then: Yup.string()
        .required('Citizenship number is required')
        .max(30, 'Max length is 30 characters'),
      otherwise: Yup.string().nullable(), // If corporate, citizenship is not required
    }),
    pan_number: Yup.string().when([], {
      is: () => onSubmitValues?.is_corporate, // If corporate, pan_number is required
      then: Yup.string().required('Pan number is required').max(30, 'Max length is 30 characters'),
      otherwise: Yup.string().nullable(), // If not corporate, pan_number is not required
    }),
  })

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const handleScreening = async (values: any) => {
    setScreeningLoading(true)

    update.current = 'default'

    // Prepare payload based on corporate or individual
    const checkAllPayload: any = {
      customer_name: customerEntitiesData?.AccountDetail[0]?.ACCT_NAME,
      pan_number: onSubmitValues?.is_corporate ? values?.pan_number : '',
      citizenship: !onSubmitValues?.is_corporate ? '' : values?.citizenship,
      model_name: 'LinkedEntities',
    }

    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data: any) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error: any) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleSubmit = (values: any) => {
    handleScreening(values) // Pass the form values to the screening function
  }

  return (
    <div>
      {data && customerEntitiesData && (
        <div>
          <p className='heading__component__title fw-bolder text-uppercase'>Customer Details</p>
          <div className='row align-items-center'>
            {/* Primary CIF Number */}
            <div className='col-md-3'>
              <h6>Primary CIF Number</h6>
              <p>{customerEntitiesData?.AccountDetail[0]?.CUST_ID || '-'}</p>
            </div>

            {/* Customer Full Name */}
            <div className='col-md-3'>
              {!onSubmitValues?.is_corporate ? (
                <>
                  <h6>Customer Full Name</h6>
                  <p>{customerEntitiesData?.AccountDetail[0]?.ACCT_NAME || '-'}</p>
                </>
              ) : (
                <>
                  <h6>Name of Institution/Company</h6>
                  <p>{customerEntitiesData?.AccountDetail[0]?.ACCT_NAME || '-'}</p>
                </>
              )}
            </div>

            <div className='col-md-6'>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({errors, handleChange, touched, values}) => {
                  return (
                    <Form>
                      <div className='row'>
                        <div className='col-md-6'>
                          {!onSubmitValues?.is_corporate ? (
                            <div className='mb-3'>
                              <FormTextBox
                                uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Enter Citizenship Number'
                                name='citizenship'
                                label='Citizenship Number'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched.citizenship}
                                required
                                maxLength={30}
                              />
                            </div>
                          ) : (
                            <div className='mb-3'>
                              <FormTextBox
                                uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Enter Pan Number'
                                name='pan_number'
                                label='Pan Number'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched.pan_number}
                                required
                                maxLength={30}
                              />
                            </div>
                          )}
                        </div>

                        <div className='col-md-2 d-flex align-items-center'>
                          {screeningPerformed ? (
                            checkAllData && (
                              <Button
                                type='button'
                                onClick={handleOpenPdf}
                                // className='col-md-3 col-sm-6 col-xs-12'
                              >
                                View Details
                              </Button>
                            )
                          ) : screeningLoading ? (
                            <div>
                              <Spinner animation='border' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <Button
                              type='submit'
                              // className='col-md-3 col-sm-6 col-xs-12'
                            >
                              Perform Screening
                            </Button>
                          )}
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>

          {onSubmitValues.is_corporate ? (
            <CorporateLinkedEntitiesDetails
              onSubmitValues={onSubmitValues}
              screeningPerformed={screeningPerformed}
              accumulatedFilePaths={accumulatedFilePaths}
            />
          ) : (
            <ToBeLinkedEntities
              onSubmitValues={onSubmitValues}
              screeningPerformed={screeningPerformed}
              accumulatedFilePaths={accumulatedFilePaths}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ViewCustomerDetails
