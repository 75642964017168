import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {enquiryList} from 'src/app/queries/api/apiEndPoints/EndPoints'

const postEnquiry = async (payload: string) => {
  try {
    const response = await axios.post(`${enquiryList}`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostEnquiryList = () => {
  return useMutation((payload: any) => postEnquiry(payload), {
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostEnquiryList
