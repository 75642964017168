import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import {RootState} from 'src/setup'

import axios from 'axios'
import {toast} from 'react-toastify'
import usePostCustomerEnquiryInfo from 'src/app/queries/apiMS/usePostCUstomerEnquiryInfo'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import useGetCorporatePreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import usePostAdditionalForms from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/useAdditionalForms'
import usePostAccountOpening from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCorporateAccountOpeningDetails'
import useUpdateCorporateKycDetails from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporateKYC'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import {ACCOUNT_OPENING_VALIDATION} from '../../../bankMaker/createNewAccountCorporate/utils/validations/ValidationSchemas'
import AccountHoldersData from '../kycUpdateDetailsFormCPUMaker/normal/IndividualJointMinorKYC/additionalFormFields/accountHolders'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'

interface RouteParams {
  id: string
}
const NewCifNewAccountCorporateCpu = ({kycDetails}: any) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()

  const {data: allMasterData} = useGetAllMasterData()

  const {mutate: openAccount, isLoading, isError, error} = usePostAccountOpening()
  const {data: corporateDataById, isLoading: getLoading} =
    useGetCorporatePreliminariesDetailsById(id)

  const [masterData, setMasterData] = useState<any>()
  const [isSave, setIsSave] = useState(false)
  const [activeTab, setActiveTab] = useState<any>('basicInfo') // Added state for managing active tab

  const [accountTypeCode, setAccountTypeCode] = useState<string | null>(null)
  const [schemaData, setSchemaData] = useState<any>(null)
  const {mutate: updateCorporateKyc, isLoading: isSubmitLoading} = useUpdateCorporateKycDetails()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (accountTypeCode) {
      const fetchSchemaData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/account-schemas`, {
              params: {schema_type: accountTypeCode},
            })
            .then((res: any) => {
              setSchemaData(res?.data)
            })
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchSchemaData()
    }
  }, [accountTypeCode])


  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])

  const {district, province, zone, municipality} = useSelector((state: RootState) => state.place)
  useEffect(() => {
    dispatch(placeReducer.action.getDistrict())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getMunicipality())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getProvince())
  }, [])
  useEffect(() => {
    dispatch(placeReducer.action.getZone())
  }, [])

  const transformValues = (values: any) => {
    return {
      // account_holders: values?.account_holders,
      directors: kycDetails.directors,
      share_holders: kycDetails.share_holders,
      facta_declarations: kycDetails.facta_declarations,
      other_banks: kycDetails.other_banks,
    }
  }
  const {mutate: addToAdditionalForm, isLoading: isLoadingSubmit} = usePostAdditionalForms()

  // const handleSubmit = (values: any, {setSubmitting}: any) => {
  //   const payload = {
  //     ...values,
  //     id: id,
  //     nomination: values.nomination === 'true',
  //   }
  //   const additionalFormPayload: any = transformValues(values)
  //   addToAdditionalForm(
  //     {id, additionalFormPayload},
  //     {
  //       onSuccess: () => {
  //         // If addToAdditionalForm is successful, execute updateCorporateKyc
  //         openAccount(
  //           {id, data: payload},
  //           {
  //             onSuccess: (response) => {
  //               toast.success(
  //                 response?.resDesc ?? 'Corporate account Opening form submitted successfully!'
  //               )
  //               history.push('/new-cif-new-accounts')
  //             },
  //             onError: () => {
  //               setSubmitting(false)
  //             },
  //           }
  //         )
  //       },
  //       onError: (error) => {
  //         setSubmitting(false)
  //       },
  //     }
  //   )
  // }

  const handleSubmit = (values: any, actions: any) => {
    const accountOpeningPayload = {
      ...values,
      id: id,
      nomination: values.nomination === 'true',
      action: 'save_and_continue',
    }
    const kycPayload = {
      ...kycDetails,
      id: id,
      action: 'save_and_continue',
      is_file_path_exists: false,
      files: [],
    }

    const additionalFormPayload: any = transformValues(values)

    const onErrorHandler = () => {
      actions.setSubmitting(false)
    }

    // Sequentially call APIs: addToAdditionalForm -> updateCorporateKyc -> openAccount
    addToAdditionalForm(
      {id, additionalFormPayload},
      {
        onSuccess: () => {
          // Call updateCorporateKyc after addToAdditionalForm is successful
          updateCorporateKyc(kycPayload, {
            onSuccess: () => {
              // Call openAccount after updateCorporateKyc is successful
              openAccount(
                {id, data: accountOpeningPayload},
                {
                  onSuccess: (response) => {
                    // toast.success(
                    //   response?.resDesc ?? 'Corporate account Opening form submitted successfully!'
                    // )
                    history.push('/new-cif-new-accounts')
                    actions.setSubmitting(false)
                  },
                  onError: onErrorHandler,
                }
              )
            },
            onError: onErrorHandler,
          })
        },
        onError: onErrorHandler,
      }
    )
  }

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  const {
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterDataLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const defaultCurrency = allMasterDataOptions.currencies?.find(
    (currency: any) => currency?.currency_code === 'NPR'
  )
  const initialValues = {
    account_title: corporateDataById?.data?.account_opening?.account_title || '',
    initial_deposit: corporateDataById?.data?.account_opening?.initial_deposit || '',
    account_category_id: corporateDataById?.data?.account_opening?.account_category_id?.id || '',
    account_scheme_id: corporateDataById?.data?.account_opening?.account_scheme_id?.id || '',
    account_currency_id:
      defaultCurrency?.id || corporateDataById?.data?.account_opening?.account_currency_id?.id,
    other_account_currency: corporateDataById?.data?.account_opening?.other_account_currency || '',
    account_purpose_id: corporateDataById?.data?.account_opening?.account_purpose_id?.id || '',
    nomination: corporateDataById?.data?.account_opening?.nomination ? 'true' : 'false' || 'false',
    nominee_first_name: corporateDataById?.data?.account_opening?.nominee_first_name || '',
    nominee_middle_name: corporateDataById?.data?.account_opening?.nominee_middle_name || '',
    nominee_last_name: corporateDataById?.data?.account_opening?.nominee_last_name || '',
    nominee_citizenship_number:
      corporateDataById?.data?.account_opening?.nominee_citizenship_number || '',
    nominee_address: corporateDataById?.data?.account_opening?.nominee_address || '',
    nominee_relationship_id:
      corporateDataById?.data?.account_opening?.nominee_relationship_id?.id || '',
    nominee_place_of_issue:
      corporateDataById?.data?.account_opening?.nominee_place_of_issue?.id || '',
    introducer_first_name: corporateDataById?.data?.account_opening?.introducer_first_name || '',
    introducer_middle_name: corporateDataById?.data?.account_opening?.introducer_middle_name || '',
    introducer_last_name: corporateDataById?.data?.account_opening?.introducer_last_name || '',
    introducer_customer_id: corporateDataById?.data?.account_opening?.introducer_customer_id || '',
    introducer_address: corporateDataById?.data?.account_opening?.introducer_address || '',
    introducer_confirmation_period:
      corporateDataById?.data?.account_opening?.introducer_confirmation_period || '',

    cheque_book_name:
      corporateDataById?.data?.account_opening?.cheque_book_name ||
      corporateDataById?.data?.institution_name,
    mode_of_operation: corporateDataById?.data?.account_opening?.mode_of_operation || '',
    other_mode_of_operation:
      corporateDataById?.data?.account_opening?.other_mode_of_operation || '',
    blacklist_screening: corporateDataById?.data?.account_opening?.blacklist_screening || false,
    sanction_screening: corporateDataById?.data?.account_opening?.sanction_screening || false,
    duplication_screening: corporateDataById?.data?.account_opening?.duplication_screening || false,
    adverse_media: corporateDataById?.data?.account_opening?.adverse_media || false,
    regulatory_block: corporateDataById?.data?.account_opening?.regulatory_block || false,
    deposit_detail_id: corporateDataById?.data?.account_opening?.deposit_detail_id?.id || '',
    loan_detail_id: corporateDataById?.data?.account_opening?.loan_detail_id?.id || '',
    account_holders:
      [
        {
          first_name: corporateDataById?.data?.institution_name,
          middle_name: '',
          last_name: '  ',
          cif_number: '',
          id_number: '',
          id_type_id: '',
          account_relation_id: '',
        },
      ] ||
      corporateDataById?.data?.account_holders?.account_holders?.map((accountHolder: any) => ({
        first_name: corporateDataById?.data?.institution_name,
        middle_name: accountHolder?.middle_name,
        last_name: accountHolder?.last_name,
        cif_number: accountHolder?.cif_number,
        id_number: accountHolder?.id_number,
        id_type_id: accountHolder?.id_type_id,
        account_relation_id: accountHolder?.accountHolder?.account_relation_id?.id,
      })),
  }

  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>()
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll, isLoading: pdfLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const [enquiryData, setEnquiryData] = useState<any>()
  const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)

  const handleOpenPdf2 = () => {
    if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  if (getLoading) {
    return <div>Loading...</div>
  }

  const getAccountTypeId = (e: any, values: any) => {
    const presentAccountType = e
    const accountType = allMasterDataOptions?.account_categories
    const matchingZone = accountType?.find((account: any) => account?.id === presentAccountType)
    if (matchingZone) {
      setAccountTypeCode(matchingZone?.cbs_code)
    } else {
      console.log('No matching zone found.')
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ACCOUNT_OPENING_VALIDATION}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          isSubmitting,
          touched,
          handleChange,
          handleSubmit,
          errors,
          setFieldValue,
          values,
          setSubmitting,
        }: any) => {
          return (
            <Form className='bg-white'>
              <div className='card mb-5 mb-xl-10 '>
                <div className='card-body border-top pt-9 pb-9'>
                  <div className='d-flex justify-content-start mb-3 text-align-center'>
                    <h3 style={{marginRight: '0.8rem'}}>{`CORPORATE PRELIMINARY Form`}</h3>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Account Title'
                        name='account_title'
                        label='Account Title'
                        value={values?.account_title}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required={true}
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Account Type'
                        name='account_category_id'
                        onChange={(w: any) => {
                          handleChange(w)
                          getAccountTypeId(w, values)
                        }}
                        errors={errors}
                        values={values}
                        touched={touched}
                        options={allMasterDataOptions?.account_categories?.map((accat: any) => ({
                          value: accat.id || accat.value,
                          label: accat.title || accat.label,
                        }))}
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Account Scheme'
                        name='account_scheme_id'
                        onChange={handleChange}
                        errors={errors}
                        values={values}
                        touched={touched}
                        options={
                          schemaData?.data?.account_schemas.length > 0
                            ? schemaData?.data?.account_schemas?.map((accur: any) => ({
                                value: accur.id || accur.value,
                                label: accur.title || accur.label,
                              }))
                            : allMasterDataOptions?.account_schemas?.map((AccSchema: any) => ({
                                value: AccSchema?.id || AccSchema?.value,
                                label: AccSchema?.title || AccSchema?.label,
                              }))
                        }
                        setFieldValue={setFieldValue}
                        required
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Initial Deposit'
                        name='initial_deposit'
                        label='Initial Deposit'
                        onChange={handleChange}
                        value={values?.initial_deposit}
                        errors={errors}
                        touched={touched}
                        required
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        label='Account Currency'
                        name='account_currency_id'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={allMasterDataOptions?.currencies?.map((accur: any) => ({
                          value: accur.id || accur.value,
                          label: accur.title || accur.label,
                        }))}
                        setFieldValue={setFieldValue}
                        required
                        values={values}
                      />
                    </div>
                    {/* <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Other Currency'
                        name='other_account_currency'
                        label='Other Currency (If any)'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div> */}
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        name='account_purpose_id'
                        label='Account Purpose'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        values={values}
                        options={allMasterDataOptions?.purposes?.map((purposes: any) => ({
                          value: purposes?.id || purposes?.value,
                          label: purposes?.title || purposes?.label,
                        }))}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {allMasterDataOptions?.purposes
                      ?.find((purposes: any) => purposes.id === values?.account_purpose_id?.id)
                      ?.title.toLowerCase() === 'others' && (
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='Other Account Purpose'
                          name='other_account_purpose'
                          label='Other Account Purpose'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          uppercase={true}
                          value={values}
                        />
                      </div>
                    )}
                    <h3 className='fw-bolder mb-4 text-capitalize'>Name of Account Holder's </h3>
                    <div className='row'>
                      <AccountHoldersData
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        enquiryData={enquiryData}
                        customerEnquiry={customerEnquiry}
                        mutateCheckAll={mutateCheckAll}
                        handlePdf={handleOpenPdf2}
                        update={update}
                        checkAllData={checkAllDataJoint}
                        setScreeningPerformed={setScreeningPerformed}
                        pdfLoading={pdfLoading}
                        corporateDataById={corporateDataById}
                      />
                    </div>
                  </div>

                  <>
                    <h3 className='fw-bolder mb-4 text-capitalize'>Nomination </h3>
                    <div className='col-lg-12 col-sm-12 col-xs-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        placeholder='Select'
                        name='nomination'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={[
                          {label: 'Yes', value: 'true'},
                          {label: 'No', value: 'false'},
                        ]}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>

                    {values.nomination === 'true' && (
                      <>
                        <div className='row'>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='First Name'
                              name='nominee_first_name'
                              label='First Name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                            />
                          </div>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Middle Name'
                              name='nominee_middle_name'
                              label='Middle Name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Last Name'
                              name='nominee_last_name'
                              label='Last Name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Citizenship Number'
                              name='nominee_citizenship_number'
                              label='Citizenship Number'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              value={values.nominee_citizenship_number}
                              required
                            />
                          </div>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Address'
                              name='nominee_address'
                              label='Address'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                            />
                          </div>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Place of issue'
                              name='nominee_place_of_issue'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={districtOptions}
                              setFieldValue={setFieldValue}
                              values={values}
                              required
                            />
                          </div>
                          <div className='col-md-6 col-sm-4 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Relation To Me'
                              name='nominee_relationship_id'
                              label='Relation To Me'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allMasterDataOptions?.relationships?.map(
                                (relation: any) => ({
                                  value: relation.id || relation.value,
                                  label: relation.title || relation.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              values={values}
                              required
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>

                  <h3 className='fw-bolder mb-4 mt-5 text-capitalize'>Introducer's Details </h3>
                  <div className='row'>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='First Name'
                        name='introducer_first_name'
                        label='First Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Middle Name'
                        name='introducer_middle_name'
                        label='Middle Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Last Name'
                        name='introducer_last_name'
                        label='Last Name'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Customer ID'
                        name='introducer_customer_id'
                        label='Customer ID'
                        onChange={handleChange}
                        value={values?.introducer_customer_id}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Address'
                        name='introducer_address'
                        label='Address'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <label className='form-label fw-bolder text-dark fs-6 '>
                      Confirm identity of the applicant is known to the customer
                    </label>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Confirmation Period'
                        name='introducer_confirmation_period'
                        label='Confirmation'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={2}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-4 col-xs-12'>
                      <FormSelect
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Mode of Operation'
                        name='mode_of_operation'
                        label='Mode Of Operation'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={[
                          {label: 'Single', value: 'single'},
                          {label: 'Jointly', value: 'jointly'},
                          {
                            label: 'Special Instruction (Any one of us)',
                            value: 'special_instruction',
                          },
                          {label: 'Any Other (Specify)', value: 'other'},
                        ]}
                        setFieldValue={setFieldValue}
                        values={values}
                        required
                      />
                    </div>
                    {values.mode_of_operation === 'other' && (
                      <div className='col-md-6 col-sm-4 col-xs-12'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='Other Mode of Operation'
                          name='other_mode_of_operation'
                          label='Other Mode of Operation(If any)'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    )}
                  </div>

                  <div className='col-md-6 col-sm-4 col-xs-12'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Cheque Book'
                      name='cheque_book_name'
                      label='Cheque Book Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      value={values?.cheque_book_name}
                      disabled
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Loan Details'
                      name='loan_detail_id'
                      label='Loan Details'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      values={values}
                      options={allMasterDataOptions?.loan_details?.map((loanDetails: any) => ({
                        value: loanDetails.id || loanDetails.value,
                        label: loanDetails.title || loanDetails.label,
                      }))}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Deposit Details'
                      name='deposit_detail_id'
                      label='Deposit Details'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      values={values}
                      options={allMasterDataOptions?.deposit_details?.map(
                        (depositDetails: any) => ({
                          value: depositDetails.id || depositDetails.value,
                          label: depositDetails.title || depositDetails.label,
                        })
                      )}
                      setFieldValue={setFieldValue}
                    />
                  </div>

                  <label className='form-label fw-bolder text-dark fs-6 required'>
                    Acknowledgement
                  </label>
                  <div className='row py-4  col-sm-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      name='blacklist_screening'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checkBoxText='I hereby verified blacklist screening result and I will be responsible in further for this.'
                      required={true}
                      checked={values.blacklist_screening}
                    />
                  </div>

                  <div className='row py-4 align-items-center col-lg-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg  -12'
                      name='sanction_screening'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checkBoxText='I hereby verified sanction screening result and I will be responsible in further for this.'
                      required={true}
                      checked={values.sanction_screening}
                    />
                  </div>
                  <div className='row py-4 align-items-center col-lg-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      name='duplication_screening'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checkBoxText='I hereby verified duplicate screening result and I will be responsible in further for this.'
                      required={true}
                      checked={values.duplication_screening}
                    />
                  </div>
                  <div className='row py-4 align-items-center col-lg-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      name='adverse_media'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checkBoxText='I hereby verified adverse media screening result and I will be responsible in further for this.'
                      required={true}
                      checked={values.adverse_media}
                    />
                  </div>
                  <div className='row py-4 align-items-center col-lg-12 col-xs-12'>
                    <FormCheckbox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      name='regulatory_block'
                      touched={touched}
                      errors={errors}
                      onChange={handleChange}
                      checkBoxText='I hereby verified regulatory block result and I will be responsible in further for this.'
                      required={true}
                      checked={values.regulatory_block}
                    />
                  </div>

                  <div className='d-flex justify-content-end my-5 px-5'>
                    <div className='card-footer text-end'>
                      <button
                        type='button'
                        className='btn btn-secondary me-2'
                        onClick={() => handleBack()}
                      >
                        Cancel
                      </button>

                      {isLoadingSubmit ? (
                        <button
                          type='button'
                          className='btn btn-primary'
                          disabled={isLoadingSubmit}
                        >
                          Submitting
                        </button>
                      ) : (
                        <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default NewCifNewAccountCorporateCpu
