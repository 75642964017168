import {FieldArray, Form, Formik} from 'formik'
import React from 'react'
import {Button} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {IParams} from 'src/app/modules/linkedEntities/common/interface/Interfaces'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import usePatchLinkedEntitiesDetails from 'src/app/queries/linkedEntitiesQuery/patch/usePatchLinkedEntitiesDetails'
import usePostCPUMakerLinkedEntities from 'src/app/queries/linkedEntitiesQuery/post/usePostCPUMakerLinkedEntities'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {validationSchemaIndividual} from '../../../utils/validationSchemas/ValidationSchemas'

interface ToBeLinkedEntitiesRevertCaseProps {
  onSubmitValues: any
}

const ToBeLinkedEntitiesRevertCase: React.FC<ToBeLinkedEntitiesRevertCaseProps> = ({
  onSubmitValues,
}) => {
  const {id} = useParams<IParams>()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const history = useHistory()
  const {mutate: patchCPUMakerLinkedEntities} = usePatchLinkedEntitiesDetails()
  const userBranchCode = user?.data?.branch?.code
  const {data: onSubmitCIFDatas} = useGetLinkedEntitesData(onSubmitValues, {
    enabled: Boolean(onSubmitValues?.cif_number),
  })
  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })
  const refinedData = onSubmitCIFDatas?.data
  const primaryCifNumber = refinedData?.GeneralDetails[0]?.CUST_ID
  const customerName = refinedData?.GeneralDetails[0]?.CUST_NAME
  const is_CorporateFlag = onSubmitValues?.is_corporate
  const is_CorporateFlagString =
    is_CorporateFlag === true || is_CorporateFlag === false ? is_CorporateFlag.toString() : ''
  const initialValues = {
    action: 'save_and_continue',
    is_corporate: is_CorporateFlagString,
    primary_customer_name: customerName,
    primary_cif_number: primaryCifNumber,
    branch_code: userBranchCode,
    primary_account_number: '',
    primary_customer_email: '',
    unlink_flag: false,
    linked_relations: onSubmitCIFDatas?.data?.EntityRelationShip?.map((relation: any) => ({
      relation_cif_number: relation?.CUST_ID || '',
      relationship_id: relation?.relationship_id || '',
      relation_name: relation?.PERSON_RELTN_NAME || '',
    })) || [
      {
        relation_cif_number: '',
        relationship_id: '',
        relation_name: '',
      },
    ],
  }

  const handleSearchDetails = (relation_cif_number: any, isCorporate: any) => {
    try {
    } catch (error) {}
  }

  const handleSubmit = (values: any, {setSubmitting}: any) => {
    const payload = {
      ...values,
      id,
      action: values.action,
    }
    patchCPUMakerLinkedEntities(payload, {
      onSuccess: () => {
        setSubmitting(false)
        history.go(-1)
      },
      onError: (error: any) => {
        setSubmitting(false)
      },
    })
  }

  return (
    <div className='mt-5'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchemaIndividual}
      >
        {({errors, handleChange, touched, values, setFieldValue}) => (
          <Form>
            <FieldArray
              name='linked_relations'
              render={(arrayHelpers) => (
                <>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='heading__component__title fw-bolder text-uppercase'>
                      Entities To Be Linked
                    </h3>
                    <Button
                      type='button'
                      className='btn'
                      onClick={() =>
                        arrayHelpers.push({relation_cif_number: '', relationship_id: ''})
                      }
                    >
                      Add Relation
                    </Button>
                  </div>
                  {values.linked_relations &&
                    values.linked_relations.length > 0 &&
                    values.linked_relations.map((relation: any, index: any) => (
                      <div key={index} className='row mb-3 align-items-center'>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation CIF Number'
                            name={`linked_relations.${index}.relation_cif_number`}
                            label='Relation CIF Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormTextBox
                            uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Enter Relation Name'
                            name={`linked_relations.${index}.relation_name`}
                            label='Relation Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            value={values?.linked_relations[index]?.relation_name}
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Relationship Declaration'
                            name={`linked_relations.${index}.relationship_id`}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.linked_relations[index]?.relationship_id}
                            options={allMasterDataOptions?.relationships?.map(
                              (relationShip: any) => ({
                                value: relationShip.id || relationShip.value,
                                label: relationShip.title || relationShip.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-3 col-sm-4 col-xs-12 d-flex justify-content-between'>
                          <Button
                            type='button'
                            className='btn btn-primary'
                            onClick={() =>
                              handleSearchDetails(
                                values.linked_relations[index]?.relation_cif_number,
                                onSubmitValues.is_corporate
                              )
                            }
                          >
                            Search
                          </Button>
                          {index > 0 && (
                            <Button
                              type='button'
                              className='btn btn-danger'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      </div>
                    ))}
                </>
              )}
            />
            <div className='text-end'>
              <Button
                type='button'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => {
                  setFieldValue('action', 'save') // Set action to 'save' for draft
                  handleSubmit(values, {setSubmitting: () => {}}) // Call handleSubmit with the modified action
                }}
              >
                Save as Draft
              </Button>
              <Button
                type='submit'
                style={{margin: '15px 10px 15px 6px'}}
                onClick={() => setFieldValue('action', 'save_and_continue')} // Set action for submission
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ToBeLinkedEntitiesRevertCase
