import axios from 'axios'
import {useQuery, UseQueryResult} from 'react-query'
import {
  GET_ALL_MASTER_DATA_DOCUMENT,
  GET_ALL_MASTER_DATA_ZONE_OPTIONS,
} from '../../api/apiEndPoints/EndPoints'

const QUERY_KEY = 'DocumentType'

interface ErrorResponse {
  message: string
}

const fetchDocumentMasterData = async (): Promise<any> => {
  try {
    const {data} = await axios.get(GET_ALL_MASTER_DATA_DOCUMENT)
    return data
  } catch (error: any) {
    throw new Error(`Error fetching master data: ${error.message}`)
  }
}

export const useGetDOcumentMasterData = (): UseQueryResult<any, ErrorResponse> => {
  return useQuery<any, ErrorResponse>(QUERY_KEY, fetchDocumentMasterData)
}
