import {Form, Formik} from 'formik'
import moment from 'moment'
import {FC, useState} from 'react'
import usePostCibScreeningCheck from 'src/app/queries/apiMS/usePostCibscreeningCheck'
import usePostDuplicateCheck from 'src/app/queries/apiMS/usePostDuplicateCheck'
import usePostScreeningCheck from 'src/app/queries/apiMS/usePostScreeningCheck'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import * as Yup from 'yup'
import CibCheck from './cibCheck'
import ComplianceCheck from './complianceCheck'
import DuplicationCheck from './duplicationCheck'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {Button, Spinner} from 'react-bootstrap-v5'

const isValidDateFormat = (value: any, format: string) => {
  return moment(value, format, true).isValid()
}

const FORM_VALIDATION = Yup.object().shape({
  customer_name: Yup.string().required("Please input the applicant's name!"),
})

const CIFCheck = ({
  mutateCheckAll,
  model_name,
  checkAllData,
  handleOpenPdf,
  setScreeningPerformed,
  is_corporate,
}: any) => {
  const [duplicationData, setDuplicationData] = useState<any>(null)
  const [screeningData, setScreeningData] = useState<any>(null)
  const [cibScreeningData, setCibScreeningData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false) // Loading state

  const {mutate: mutateDuplicate, isLoading: isLoadingDuplicateCheck} =
    usePostDuplicateCheck(setDuplicationData)
  const {mutate: mutateScreeningCheck, isLoading: isLoadingScreeningCheck} =
    usePostScreeningCheck(setScreeningData)
  const {mutate: mutateCibScreeningCheck, isLoading: isLoadingCIBScreeningCheck} =
    usePostCibScreeningCheck(setCibScreeningData)

  const Gender = [
    {value: 'M', label: 'Male'},
    {value: 'F', label: 'Female'},
  ]

  const handleSubmit = async (values: any) => {
    setLoading(true) // Set loading state to true
    setScreeningPerformed && setScreeningPerformed(true)

    const {customer_name, citizenship} = values

    const duplicatePayload: any = {customer_name}
    const screeningPayload: any = {customer_name}
    const cibScreeningPayload: any = {customer_name, citizenship}
    const checkAllPayload: any = {
      customer_name,
      model_name,
      citizenship,
    }

    try {
      await Promise.all([
        mutateDuplicate(duplicatePayload),
        mutateScreeningCheck(screeningPayload),
        mutateCibScreeningCheck(cibScreeningPayload),
        model_name && mutateCheckAll(checkAllPayload),
      ])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false) // Reset loading state
    }
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-body pt-6 pb-6'>
          <div className='g-0 border-bottom heading__component__title fw-bolder mb-5 pb-3'>
            Customer Information Verification
          </div>
          <Formik
            initialValues={{
              customer_name: '',
              citizenship: '',
              pan_number: '',
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
          >
            {({handleChange, errors, touched}) => (
              <Form className='row g-3'>
                <div className='col-md-4'>
                  <FormTextBox
                    containerClassName='col-md-12'
                    labelClassName='col-md-12'
                    type='text'
                    label="Customer's Name"
                    name='customer_name'
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    required={true}
                    uppercase={true}
                  />
                </div>

                {is_corporate === true ? (
                  <div className='col-md-4'>
                    <FormTextBox
                      containerClassName='col-md-12'
                      labelClassName='col-md-12'
                      type='text'
                      label='Pan No.'
                      name='pan_number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={false}
                    />
                  </div>
                ) : (
                  <div className='col-md-4'>
                    <FormTextBox
                      containerClassName='col-md-12'
                      labelClassName='col-md-12'
                      type='text'
                      label='Citizenship No.'
                      name='citizenship'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={false}
                    />
                  </div>
                )}

                <div className='col-12 d-flex justify-content-end'>
                  {checkAllData ? (
                    <button className='me-5 btn btn-primary' onClick={handleOpenPdf}>
                      View PDF
                    </button>
                  ) : (
                    <button
                      type='submit'
                      className='btn btn-danger btn-sm'
                      disabled={
                        isLoadingDuplicateCheck ||
                        isLoadingScreeningCheck ||
                        isLoadingCIBScreeningCheck
                      }
                    >
                      {isLoadingDuplicateCheck ||
                      isLoadingScreeningCheck ||
                      isLoadingCIBScreeningCheck
                        ? 'Submitting...'
                        : 'Submit'}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>

          {duplicationData && <DuplicationCheck data={duplicationData} />}
          {screeningData && <ComplianceCheck data={screeningData} />}
          {cibScreeningData && <CibCheck data={cibScreeningData} />}
        </div>
      </div>
    </>
  )
}

export default CIFCheck
