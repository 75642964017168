import {IDetails} from 'src/app/modules/linkedEntities/common/interface/Interfaces'

const IndividualDetailsView = ({label, value}: IDetails) => {
  return (
    <div>
      <h6>{label}</h6>
      <p>{value}</p>
    </div>
  )
}

export default IndividualDetailsView
