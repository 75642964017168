import axios from 'axios'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQueryClient,
} from 'react-query'
import {toast} from 'react-toastify'
import {PUT_MARITAL_STATUS, PUT_SALUTATIONS} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {ISalutations} from '../post/usePostSalutation'
export interface IPayload {
  title: string
  cbs_code: string
  status: boolean
  order?: number
}

interface IMutate {
  payload: ISalutations
  id: string
}

const putSaluations = async (payload: ISalutations, id: string) => {
  try {
    const response = await axios.put(`${PUT_SALUTATIONS}`.replace(':id', id), payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePutSalutations = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
) => {
  const queryClient = useQueryClient()

  return useMutation(({payload, id}: IMutate) => putSaluations(payload, id), {
    onSuccess: async (response) => {
      if (response?.resCod == 200) {
        toast.success(response.resDesc)
        setOpen(false)
        queryClient.invalidateQueries('getSalutationsList')
        refetch()
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePutSalutations
