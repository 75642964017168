import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {GET_NORMAL_NEW_CIF_NEW_ACCOUNT} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
// import { ParamsModel } from 'src/app/modules/common/Model'

const QUERY_KEY = 'branchMakerNewCIFAndAccountList'

export const useGetUserActivity = (params: any) => {
  return useQuery([QUERY_KEY, params], async () => {
    const {data} = await axios.get(`${BASE_API_URL}/activity-logs`, {
      params,
    })
    return data
  })
}
