import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  NORMAL_NEW_CIF_BRANCH_CHECKER_REVIEW,
  NORMAL_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postAccountPreliminaryReview = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(
      `${NORMAL_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW}/${id}/branch-checker-review`,
      data
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostAccountPreliminaryBranchCheckeReview = () => {
  const queryClient = useQueryClient()
  return useMutation(postAccountPreliminaryReview, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        toast.success(response?.data?.resDesc ?? 'Review  status submitted successfully!')
        // queryClient.invalidateQueries('corporateAccountList')
        // queryClient.invalidateQueries('ListingNewCIFNewAccount')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostAccountPreliminaryBranchCheckeReview

const postAccountPreliminaryReviewCIF = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(
      `${NORMAL_NEW_CIF_BRANCH_CHECKER_REVIEW}/${id}/branch-checker-review`,
      data
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

export const usePostAccountPreliminaryBranchCheckeReviewCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(postAccountPreliminaryReviewCIF, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        toast.success(response?.data?.resDesc ?? 'Review  status submitted successfully!')
        // queryClient.invalidateQueries('corporateAccountList')
        // queryClient.invalidateQueries('ListingNewCIFNewAccount')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}
