import {useState} from 'react'
import {Table} from 'rsuite'
import {useGetUserActivity} from 'src/app/queries/new-cif-new-account-queries/getUserActivity'

export const UserActivityLogAll = ({caseId, modalName}: any) => {
  const [params, setParams] = useState({
    //   model_name: 'Amnil\\SharedModels\\NewCIFNewAccountKyc',
    model_name: modalName,
    model_id: caseId,
    page: 1,
    limit: 10,
    status: '',
    search: '',
    order: 'desc',
    orderBy: 'updated_at',
  })
  const {data: UserActivityList} = useGetUserActivity(params)
  const {Column, HeaderCell, Cell} = Table

  const CustomCell = ({rowData, dataKey, ...props}: any) => (
    <Cell {...props}>{rowData[dataKey]}</Cell>
  )

  const SnCell = ({rowIndex, ...props}: any) => <Cell {...props}>{rowIndex + 1}</Cell>

  const ChangesCell = ({rowData, dataKey, ...props}: any) => {
    const jsonData = rowData[dataKey] ? JSON.stringify(rowData[dataKey], null, 2) : 'No changes'
    return (
      <Cell {...props}>
        <pre
          style={{
            wordWrap: 'break-word',
            overflowY: 'auto',
            height: '300px',
            maxHeight: '300px',
            padding: '10px',
            background: '#f8f9fa',
            borderRadius: '4px',
          }}
        >
          {jsonData}
        </pre>
      </Cell>
    )
  }

  return (
    <div className='shadow p-3 bg-white rounded my-8'>
      <div className='heading__component__title fs-2 fw-bolder mb-2 text-center'>
        USER ACTIVITY LOG
      </div>
      <div className='datatable'>
        <Table
          data={UserActivityList?.data?.activity_logs || []}
          wordWrap='break-word'
          rowHeight={90}
          autoHeight
        >
          <Column align='left' width={50}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>SN</HeaderCell>
            <SnCell />
          </Column>

          <Column align='left' width={200}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
              Description
            </HeaderCell>
            <CustomCell dataKey='description' />
          </Column>

          <Column align='left' width={100}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
              Modified By
            </HeaderCell>
            <CustomCell dataKey='causer_id' />
          </Column>

          <Column align='left' width={100}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
              Modified At
            </HeaderCell>
            <CustomCell dataKey='updated_date' />
          </Column>

          <Column align='left' width={350}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>
              Previous Data
            </HeaderCell>
            <ChangesCell dataKey='old_data' />
          </Column>

          <Column align='left' width={350}>
            <HeaderCell style={{backgroundColor: '#BA131A', color: 'white'}}>New Data</HeaderCell>
            <ChangesCell dataKey='new_data' />
          </Column>
        </Table>
      </div>
    </div>
  )
}
