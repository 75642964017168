import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postDuplicateCheck = async (payload: typePayload) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/duplicate-check`, payload)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostDuplicateCheck = (setDuplicationData: any) => {
  return useMutation(postDuplicateCheck, {
    onSuccess: (response) => {
      if (response) {
        setDuplicationData(response?.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostDuplicateCheck

// import {useMutation} from 'react-query'
// import {toast} from 'react-toastify'

// const postDuplicateCheck = async (payload: any) => {
//   // Mock data
//   const mockResponse: any = {
//     resCod: '200',
//     resDesc: 'Check Customer Duplication.',
//     data: {
//       duplication_lists: [
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//         {
//           customer_id: '000179974',
//           customer_name: 'SHANKER POKHREL',
//           citizenship_number: '16998',
//           citizenship_issue_district: 'KATHMANDU',
//           citizenship_issue_date: '12-03-2059',
//           registration_number: null,
//           registration_district: null,
//           registration_issue_date: null,
//           gender: 'M',
//           marital_status: 'UNMARRIED',
//           email: 'shankerpokhrel@gmail.com',
//           date_of_birth: '16-06-1984',
//           father_name: 'DAN BAHADUR POKHREL',
//           grandfather_name: 'HEERA BDR POKHREL',
//           spouse: null,
//           mobile: '9851061560',
//           phone: '01-4355273',
//           occupation: 'SALARIED - GOVT/PSU/OHERS',
//           permanent_address: 'DHAPASI, BASUNDHARA-9',
//           permanent_district: 'KATHMANDU',
//           permanent_municipality_vdc: 'BASUNDHARA',
//           permanent_ward_number: '09',
//           temporary_address: 'DHAPASI, BASUNDHARA-9',
//           temporary_district: 'KATHMANDU',
//           customer_group: '1.INDIVIDUAL',
//           company_registrar_office: null,
//           pan: null,
//           pan_issued_date: null,
//         },
//       ],
//       meta: {
//         current_page: 1,
//         perPage: 10,
//         total: 1,
//       },
//     },
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => resolve(mockResponse), 1000) // Simulate network delay
//   })
// }

// const usePostDuplicateCheck = (setDuplicationData) => {
//   return useMutation(postDuplicateCheck, {
//     onSuccess: (response: any) => {
//       if (response) {
//         toast.success(response?.Message)
//         setDuplicationData(response)
//       }
//     },
//     onError: (error: Error) => {
//       toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
//     },
//   })
// }

// export default usePostDuplicateCheck
