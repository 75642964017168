import axios from 'axios'
import {useQuery, useQueryClient} from 'react-query'
import {
  GET_NEW_CIF,
  GET_NORMAL_NEW_CIF_NEW_ACCOUNT_BY_ID,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${GET_NORMAL_NEW_CIF_NEW_ACCOUNT_BY_ID}`

const fetchPreliminariesDetailsById = async (id: any) => {
  try {
    const {data} = await axios.get(API_URL.replace(':id', id))
    return data
  } catch (error: any) {
    throw new Error(
      `Error fetching branch checker new CIF and account list by ID: ${error.message}`
    )
  }
}

const useGetPreliminariesDetailsById = (id: any) => {
  const queryClient = useQueryClient()

  const invalidateQuery = () => {
    queryClient.invalidateQueries(['preliminariesDetailsById', id])
  }

  const query = useQuery(['preliminariesDetailsById', id], () => fetchPreliminariesDetailsById(id))

  return {...query, invalidateQuery}
}

export default useGetPreliminariesDetailsById

const fetchPreliminariesDetailsByIdCIF = async (id: any) => {
  try {
    const {data} = await axios.get(`${GET_NEW_CIF}/${id}`)
    return data
  } catch (error: any) {
    throw new Error(
      `Error fetching branch checker new CIF and account list by ID: ${error.message}`
    )
  }
}

export const useGetPreliminariesDetailsByIdCIF = (id: any) => {
  const queryClient = useQueryClient()

  const invalidateQuery = () => {
    queryClient.invalidateQueries(['preliminariesDetailsByIdCIF', id])
  }

  const query = useQuery(['preliminariesDetailsByIdCIF', id], () =>
    fetchPreliminariesDetailsByIdCIF(id)
  )

  return {...query, invalidateQuery}
}
