import {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {FaEye, FaPlus} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useGetIndividualJointMinorNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetBranchCheckerLists'
import {useGetCorporateNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporatePreliminariesAccountList'
import CheckPermissions from '../common/checkPermission'
import IndividualJointMinorTable from './bankMaker/createNewCIFAndAccount/components/tables/IndividualJointMinorTable'
import CorporateBankTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateBankTable'
import CorporateCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateCompleteTable'
import CorporateBankCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/corporateCompleteTable'
import IndividualJointMinorCompleteTable from './bankMaker/createNewCIFAndAccount/components/tables/individualCompleteTable'

const NewCIFAccount = () => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('individualJointMinor')
  const roleName = useSelector((state: any) => state.auth?.rolePermissions?.name)

  const [params, setParams] = useState({
    user_type: roleName?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/_two/g, ''),
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const [completeParams, setCompleteParams] = useState({
    user_type:  '',
    account_status: 'completed',
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    search: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: newCorporateCIFandAccountListData, isLoading: corporateLoading} =
    useGetCorporateNewCIFandAccountList(activeKey.includes('completed') ? completeParams : params)

  const {data: newCIFandAccountListData, isLoading: individualLoading} =
    useGetIndividualJointMinorNewCIFandAccountList(
      activeKey.includes('completed') ? completeParams : params
    )
  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='new-cif-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>New Account Lists</div>
            </div>
            <div className='col-lg-6'>
              <div className='d-flex justify-content-end'>
                <CheckPermissions type='Add' moduleName='New CIF New Accounts'>
                  {roleName === 'Branch Maker' ? (
                    <div
                      className='sub_button'
                      onClick={() => history.push('/new-cif-new-accounts/account-opening-requests')}
                    >
                      <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                        <FaPlus className='me-2' />
                        Create New CIF / Account
                      </button>
                    </div>
                  ) : null}
                </CheckPermissions>
                <div
                  className='sub_button'
                  onClick={() => history.push('/new-cif-new-accounts/rejected-account-lists')}
                >
                  <button className='dt-btn dt-btn-primary' data-cy='add-button'>
                    <FaEye className='me-2' />
                    View Rejected Accounts
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinor')}
            id='branchMaker-new-cif-account-tabs'
            className='mb-5'
            data-cy='new-cif-account-list-tabs'
          >
            <Tab eventKey='individualJointMinor' title='Pending Individual/Joint/Minor'>
              <div className='card-body bg-white'>
                <IndividualJointMinorTable
                  newCIFandAccountListData={newCIFandAccountListData}
                  setParams={setParams}
                  params={params}
                  loading={individualLoading}
                  roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
                />
              </div>
            </Tab>
            <Tab eventKey='corporateBank' title='Pending Corporate'>
              <div className='card-body bg-white'>
                <CorporateBankTable
                  newCorporateCIFandAccountListData={newCorporateCIFandAccountListData}
                  setParams={setParams}
                  params={params}
                  loading={corporateLoading}
                  roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
                />
              </div>
            </Tab>
            <Tab eventKey='completedCorporate' title='Completed Corporate '>
              <div className='card-body bg-white'>
                <CorporateBankCompleteTable
                  newCorporateCIFandAccountListData={newCorporateCIFandAccountListData}
                  setParams={setCompleteParams}
                  params={completeParams}
                  loading={corporateLoading}
                  roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
                />
              </div>
            </Tab>
            <Tab eventKey='completedIndividual' title='Completed Individual/joint/minor '>
              <div className='card-body bg-white'>
                {newCIFandAccountListData ? (
                  <IndividualJointMinorCompleteTable
                    newCIFandAccountListData={newCIFandAccountListData}
                    setParams={setCompleteParams}
                    params={completeParams}
                    loading={individualLoading}
                    roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
                  />
                ) : (
                  <p>No data available for completed individual accounts.</p>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default NewCIFAccount
