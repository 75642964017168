import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  CORPORATE_NEW_CIF_NEW_ACCOUNT_BRANCH_CHECKER_REVIEW,
  INDIVIDUAL_NEW_CIF_NEW_ACCOUNT_QA_REVIEW,
  POST_ADDITIONAL_FORMS_CIF,
  POST_CORPORATE_NEW_CIF_NEW_ACCOUNT,
  UPDATE_CORPORATE_NEW_CIF,
  UPDATE_NEW_CIF_BM,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postCorporateAccountPreliminaryReview = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(
      `${INDIVIDUAL_NEW_CIF_NEW_ACCOUNT_QA_REVIEW}/${id}/qa-review`,
      data
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostIndividualQAReview = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccountPreliminaryReview, {
    onSuccess: (response) => {
      if (response?.data?.resCod == 200) {
        toast.success(response?.data?.resDesc ?? 'QA Review  status submitted successfully!')
        queryClient.invalidateQueries('getIndividualAccountLists')
        queryClient.invalidateQueries('preliminariesDetailsById')
        queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostIndividualQAReview

const postCorporateAccountPreliminaryReviewCIF = async (payload: typePayload) => {
  const {id, ...data} = payload
  try {
    const response = await axios.post(`${UPDATE_NEW_CIF_BM}/${id}/qa-review`, data)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

export const usePostIndividualQAReviewCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporateAccountPreliminaryReviewCIF, {
    onSuccess: (response) => {
      toast.success(response?.data?.resDesc ?? 'QA Review  status submitted successfully!')
      queryClient.invalidateQueries('preliminariesDetailsByIdCIF')
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}
