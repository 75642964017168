import React, {useState} from 'react'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Table} from 'rsuite'

const Cell = Table.Cell

const StatusLog = ({data}: any) => {
  const BadgeCell = ({rowData, dataKey, color, ...props}: any) => {
    return (
      <Cell {...props}>
        <span className={`badge badge-${color}`}>{rowData[dataKey]}</span>
      </Cell>
    )
  }

  const SnCell = ({rowIndex, ...props}: any) => {
    return <Cell {...props}>{rowIndex + 1}</Cell>
  }

  const columns = [
    {
      label: 'SN',
      width: 60,
      cell: <SnCell />,
    },
    {
      label: 'Current Account Status',
      dataKey: 'current_status',
      cell: <BadgeCell dataKey='current_status' color='primary' />,
      width: 200,
    },
    {
      label: 'Previous Account Status',
      dataKey: 'previous_status',
      cell: <BadgeCell dataKey='previous_status' color='secondary' />,
      width: 200,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      width: 200,
    },
    {
      label: 'Created By',
      dataKey: 'created_by',
      cell: <Cell>{(rowData) => <span>{rowData?.created_by}</span>}</Cell>,
      width: 200,
    },
    {
      label: 'Created At',
      dataKey: 'created_at',
      cell: <Cell dataKey='created_at' />,
      width: 200,
    },
  ]
  return (
    <div className='shadow p-3 bg-white rounded'>
      <div className='g-0 border-bottom heading__component__title fw-bolder mb-4 text-center'>
        ACCOUNT STATUS LOG FOR THIS CUSTOMER
      </div>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={false} />
      </div>
    </div>
  )
}

export default StatusLog
