import {Form, Formik} from 'formik'
import {useState} from 'react'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'

import {adToBs} from '@sbmdkl/nepali-date-converter'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import * as placeReducer from 'src/app/modules/common'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {useUpdateCorporateAccountPreliminaryCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/update/useUpdateCorporatePreliminaries'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import moment from 'moment'
import {Button, Spinner} from 'react-bootstrap-v5'
import CIFCheck from 'src/app/pages/dashboard/component.tsx/CIFCheck'
import {CORPORATE_NORMAL_FORM_VALIDATION} from '../../../utils/validations/ValidationSchemas'
import {nanoid} from '@reduxjs/toolkit'
import axios from 'axios'
import {Modal} from 'rsuite'

import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import {toast} from 'react-toastify'
import {useGetAllRiskCategoriesList} from 'src/app/queries/getMasterData/riskCategories/get/useGetAllRiskCategoriesList'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {useQueryClient} from 'react-query'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import {usePostToGenerateCodeCorporate} from 'src/app/queries/new-cif-new-account-queries/usePostGenerateOtp'
import VerificationForm from 'src/app/modules/new-cif-new-account/common/optVerification'

interface RouteParams {
  id: string
}
const NormalCorporateReviewAndUpdateCIF = () => {
  const history = useHistory()
  const {id} = useParams<RouteParams>()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code
  const queryClient = useQueryClient()

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: updateCorporateAccountPreliminary, isLoading: postdataloading} =
    useUpdateCorporateAccountPreliminaryCIF()
  const {
    data: corporateDataById,
    isLoading,
    refetch,
  } = useGetCorporatePreliminariesDetailsByIdCIF(id)

  const [isSave, setIsSave] = useState(true)

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [flag, setFlag] = useState<any>()
  const {mutate: mutateCheckAll} = usePostCheckAll([setCheckAllData, setcheckAllDataMinor, flag])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [verificationType, setVerificationType] = useState(null)
  const [verificationDetails, setVerificationDetails] = useState({
    email: '',
    mobile: '',
  })
  const [uniqueID, setUniqueID] = useState('')
  const [regenrateID, setRegenrateID] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)
  const [accountTypeId, setAccountTypeId] = useState<string | null>(null)
  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading: masterDataLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const [isDisabled, setISDisabled] = useState<boolean>(false)

  useEffect(() => {
    const corporateAccountType = allMasterDataOptions?.account_types?.find(
      (item: any) => item?.cbs_code.toLowerCase() === 'corporate'
    )
    if (corporateAccountType) {
      setAccountTypeId(corporateAccountType?.id)
    }
  }, [allMasterDataOptions])

  const handleSubmit = (values: any, actions: any) => {
    let modifiedFilePath = ''
    let accumulatedFilePaths: {file_name: any; file_path: any}[] = []
    if (checkAllData?.data?.data?.file_path) {
      const parts = checkAllData.data.data.file_path.split('/public/')
      modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      // Add the current file to the accumulated file paths
      accumulatedFilePaths.push({
        file_name: modifiedFilePath.split('/').pop(),
        file_path: modifiedFilePath,
      })
    }

    const capitalizeName = (name: string) => {
      return name.replace(/\b\w/g, (char: string) => char.toUpperCase())
    }

    const generatedShortName = values.institution_name?.split(' ')[0]
    const payload = {
      ...values,
      id,
      verification_type: verificationType,
      institution_name: values?.institution_name?.toUpperCase(),
      registration_date_ad: values?.registration_date_ad
        ? moment(values?.registration_date_ad).format('YYYY-MM-DD')
        : '',
      registration_expiry_date_ad: values?.registration_expiry_date_ad
        ? moment(values?.registration_expiry_date_ad).format('YYYY-MM-DD')
        : '',
      // other_constitution: values?.other_constitution
      //   ? capitalizeName(values?.other_constitution)
      //   : '',
      // foreign_company_name: values?.foreign_company_name
      //   ? capitalizeName(values?.foreign_company_name)
      //   : '',
      // foreign_company_address: values?.foreign_company_address
      //   ? capitalizeName(values?.foreign_company_address)
      //   : '',
      // registered_office_street_name: capitalizeName(values?.registered_office_street_name),
      // pep_information: values?.pep_information ? capitalizeName(values?.pep_information) : '',
      account_type_id: accountTypeId,
      action: isSave ? 'save_and_continue' : 'save',
      short_name: generatedShortName || '',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    updateCorporateAccountPreliminary(payload, {
      onSuccess: (response: any) => {
        actions.setSubmitting(false)
        toast.success(response?.resDesc)
        refetch()
        history.go(-1)
      },
      onError: (error) => {
        actions.setSubmitting(false)
      },
    })
    // history.push('new-cif-new-account-lists')
  }

  const {data: zoneData} = useGetAllZoneData()
  const [zoneCode, setZoneCode] = useState<string | null>(null)
  const [districtData, setDistrictData] = useState<any>(null)

  useEffect(() => {
    if (zoneCode) {
      const fetchDistrictData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/district`, {
              params: {zoneCode},
            })
            .then((res: any) => {
              setDistrictData(res?.data)
            })
          // setDistrictData(data) // Store the district data in state
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchDistrictData()
    }
  }, [zoneCode])

  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(id ? id : generatedID)
  }, [regenrateID, id])

  const {mutate: generateOtp, isLoading: generateOtpLoading} = usePostToGenerateCodeCorporate()

  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('registered_office_outside_country_post_box', '')
      setFieldValue('registered_office_outside_country_full_address', '')
      setFieldValue('registered_office_mobile_number', '')
      setFieldValue('registered_office_email', '')
      setTouched({
        registered_office_outside_country_post_box: false,
        registered_office_outside_country_full_address: false,
        registered_office_mobile_number: false,
        registered_office_email: false,
      })
    } else {
      setFieldValue('registered_office_province', '')
      setFieldValue('registered_office_zone', '')
      setFieldValue('registered_office_district', '')
      setFieldValue('registered_office_municipality', '')
      setFieldValue('registered_office_city', '')
      setFieldValue('registered_office_ward_number', '')
      setFieldValue('registered_office_street_name', '')
      setFieldValue('registered_office_house_number', '')
      setFieldValue('registered_office_mobile_number', '')
      setFieldValue('registered_office_email', '')
      setTouched({
        registered_office_province: false,
        registered_office_zone: false,
        registered_office_district: false,
        registered_office_municipality: false,
        registered_office_city: false,
        registered_office_ward_number: false,
        registered_office_street_name: false,
        registered_office_house_number: false,
        registered_office_mobile_number: false,
        registered_office_email: false,
      })
    }
  }

  const {data: riskData} = useGetAllRiskCategoriesList()
  const [riskCode, setRiskCode] = useState<string | null>(null)
  const [riskSubCat, setRiskSubCat] = useState<any>(null)
  useEffect(() => {
    if (riskCode) {
      fetchRiskData()
    }
  }, [riskCode])

  const fetchRiskData = async () => {
    try {
      await axios
        .get(`${BASE_API_URL}/risk-sub-categories`, {
          params: {risk_category_id: riskCode},
        })
        .then((res: any) => {
          setRiskSubCat(res?.data)
        })
    } catch (error) {
      console.error('Error fetching district data:', error)
    }
  }

  const handleBack = () => {
    history.go(-1)
  }

  const getMunicipilities = (id: string, setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue('registered_office_province', response?.data?.district?.province?.id)
        setFieldValue('registered_office_zone', response?.data?.district?.zone?.id)
        setFieldValue('registered_office_district', response?.data?.district?.id)
      },
    })
  }

  const initialValues: any = {
    action: '',
    branch_code: corporateDataById?.data?.branch_code || userBranchCode,
    customer_code: corporateDataById?.data?.customer_code,
    short_name: '',
    // account_type_id: accountTypeId || '',
    institution_name: corporateDataById?.data?.institution_name || '',
    constitution_id: corporateDataById?.data?.constitution_id?.id || '',
    other_constitution: corporateDataById?.data?.other_constitution || '',
    foreign_company_name: corporateDataById?.data?.foreign_company_name || '',
    foreign_company_address: corporateDataById?.data?.foreign_company_address || '',
    registration_number: corporateDataById?.data?.registration_number || '',
    registration_with_id: corporateDataById?.data?.registration_with_id?.id || '',
    registration_date_ad: corporateDataById?.data?.registration_date_ad || '',
    registration_date_bs: corporateDataById?.data?.registration_date_bs || '',
    registration_expiry_date_ad: corporateDataById?.data?.registration_expiry_date_ad || '',
    registration_expiry_date_bs: corporateDataById?.data?.registration_expiry_date_bs || '',
    registered_office_country:
      corporateDataById?.data?.registered_office_country?.id ||
      allMasterDataOptions?.countries?.find((item: any) => item.cbs_code === 'NP')?.id,
    registered_office_zone: corporateDataById?.data?.registered_office_zone?.id || '',
    registered_office_province: corporateDataById?.data?.registered_office_province?.id || '',
    registered_office_district: corporateDataById?.data?.registered_office_district?.id || '',
    registered_office_city: corporateDataById?.data?.registered_office_city?.id || '',
    registered_office_municipality:
      corporateDataById?.data?.registered_office_municipality?.id || '',
    registered_office_ward_number: corporateDataById?.data?.registered_office_ward_number || '',
    registered_office_street_name: corporateDataById?.data?.registered_office_street_name || '',
    registered_office_house_number: corporateDataById?.data?.registered_office_house_number || '',
    registered_office_landline: corporateDataById?.data?.registered_office_landline || '',
    registered_office_mobile_number: corporateDataById?.data?.registered_office_mobile_number || '',
    registered_office_email: corporateDataById?.data?.registered_office_email || '',
    registered_office_fax_number: corporateDataById?.data?.registered_office_fax_number || '',
    registered_office_website: corporateDataById?.data?.registered_office_website || '',
    registered_office_outside_country_post_box:
      corporateDataById?.data?.registered_office_outside_country_post_box || '',
    registered_office_outside_country_full_address:
      corporateDataById?.data?.registered_office_outside_country_full_address || '',
    business_office_country: corporateDataById?.data?.business_office_country?.id || '',
    business_office_zone: corporateDataById?.data?.business_office_zone?.id || '',
    business_office_province: corporateDataById?.data?.business_office_province?.id || '',
    business_office_district: corporateDataById?.data?.business_office_district?.id || '',
    business_office_city: corporateDataById?.data?.business_office_city || '',
    business_office_municipality: corporateDataById?.data?.business_office_municipality?.id || '',
    business_office_ward_number: corporateDataById?.data?.business_office_ward_number || '',
    business_office_street_name: corporateDataById?.data?.business_office_street_name || '',
    business_office_house_number: corporateDataById?.data?.business_office_house_number || '',
    business_office_landline: corporateDataById?.data?.business_office_landline || '',
    business_office_mobile_number: corporateDataById?.data?.business_office_mobile_number || '',
    business_office_email: corporateDataById?.data?.business_office_email || '',
    business_office_fax_number: corporateDataById?.data?.business_office_fax_number || '',
    business_office_website: corporateDataById?.data?.business_office_website || '',
    working_area: corporateDataById?.data?.working_area || '',
    business_nature_id: corporateDataById?.data?.business_nature_id?.id || '',
    income_tax_assess: corporateDataById?.data?.income_tax_assess || '',
    pan_number: corporateDataById?.data?.pan_number || '',
    vat_number: corporateDataById?.data?.vat_number || '',
    expected_annual_turnover: corporateDataById?.data?.expected_annual_turnover || null,
    expected_annual_transaction_number:
      corporateDataById?.data?.expected_annual_transaction_number?.id || '',
    location_map: corporateDataById?.data?.location_map || '',
    screening_id: corporateDataById?.data?.screening_id || '123',
    blacklist_screening: corporateDataById?.data?.blacklist_screening || true,
    sanction_screening: corporateDataById?.data?.sanction_screening || false,
    // customer_category_id: corporateDataById?.data?.customer_category_id || '',
    aml_risk_rating: corporateDataById?.data?.aml_risk_rating?.id || '',
    sub_risk_category_id: corporateDataById?.data?.sub_risk_category_id?.id || '',
    pep_status: corporateDataById?.data?.pep_status || '',
    pep_information: corporateDataById?.data?.pep_information || '',
    crime_conviction: corporateDataById?.data?.crime_conviction || false,
    crime_details: corporateDataById?.data?.crime_details || '',
    blacklisting_inclusion: corporateDataById?.data?.blacklisting_inclusion || false,
    blacklist_release_date: corporateDataById?.data?.blacklist_release_date || '2002-02-02',
    tax_type: '',
  }

  const [showCIFCheck, setShowCIFCheck] = useState(false)

  const handleScreeningClick = () => {
    setShowCIFCheck((prevState) => !prevState)
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleChangeBoolean = (event: any, setFieldValue: any) => {
    const {name, value} = event.target
    setFieldValue(name, value === 'true')
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      window.open(file, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const getZoneCode = (e: any, values: any) => {
    const presentZone = e
    const zoneList = zoneData?.data?.zone
    const matchingZone = zoneList?.find((zone: any) => zone?.id === presentZone)
    if (matchingZone) {
      setZoneCode(matchingZone.code)
    } else {
      console.log('No matching zone found.')
    }
  }

  // useEffect(() => {
  //   let categoryId = allMasterDataOptions?.risk_categories?.find(
  //     (option: any) => option?.title === 'LOW RISK'
  //   )?.id
  //   setRiskCode(categoryId)
  //   fetchRiskData()
  // }, [])

  // const getRiskCode = (e: any, values?: any) => {
  //   const presentRisk = e
  //   const riskList = riskData?.data?.risk_categories
  //   const matchingRisk = riskList?.find((risk: any) => risk?.id === presentRisk)
  //   if (matchingRisk) {
  //     setRiskCode(matchingRisk?.id)
  //   } else {
  //     console.log('No matching risk found.')
  //   }
  // }

  const getSubRiskCode = (id: string, setFieldValue: any) => {
    const selectedSubCategory = allMasterDataOptions.risk_sub_categories?.find(
      (option: any) => option?.id === id
    )
    const riskCategoryId = selectedSubCategory?.risk_category?.id || ''
    // const riskCategoryTitle = selectedSubCategory?.risk_category?.title || 'null'
    const riskCategoryTitle = selectedSubCategory?.title || 'null'

    setFieldValue('aml_risk_rating', riskCategoryId)
    if (riskCategoryTitle == 'Others') {
      setISDisabled(false)
    } else if (riskCategoryTitle == 'null') {
      setISDisabled(false)
    } else {
      setISDisabled(true)
    }
  }


  const openVerificationModal = (type: any) => {
    setVerificationType(type)
    setIsVerificationModalOpen(true)
    if (type === 'email_verification') {
      generateOtp({
        payload: {
          type: 'email_verification',
          case: 'update',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: '',
        },
      })
    } else if (type === 'mobile_verification') {
      generateOtp({
        payload: {
          type: 'mobile_verification',
          case: 'update',
          temp_customer_id: uniqueID,
          email: '',
          mobile_number: verificationDetails?.mobile,
        },
      })
    } else {
      generateOtp({
        payload: {
          type: 'both',
          case: 'update',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: verificationDetails?.mobile,
        },
      })
    }
  }
  return (
    <>
      <div>
        {isLoading || masterDataLoading ? (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body text-center '>
              <h3>
                <Spinner animation='border' variant='danger' className='mb-3' />
                <br />
                Please Wait...
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className=''>
              {/* <div className='text-end'>
                <Button onClick={handleScreeningClick} className='mb-3'>
                  {showCIFCheck ? 'Hide CIF Check' : 'Show CIF Check'}
                </Button>
              </div> */}

              {/* {showCIFCheck && ( */}
              <CIFCheck
                is_corporate={true}
                mutateCheckAll={mutateCheckAll}
                model_name='NewCIF'
                checkAllData={checkAllData}
                handleOpenPdf={handleOpenPdf}
                setScreeningPerformed={setScreeningPerformed}
              />
              {/* )} */}
            </div>

            <Formik
              initialValues={initialValues}
              // validationSchema={isSave ? CORPORATE_NORMAL_FORM_VALIDATION : undefined}
              validationSchema={
                isSave ? CORPORATE_NORMAL_FORM_VALIDATION(allMasterDataOptions) : undefined
              }
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
            >
              {({
                values,
                isSubmitting,
                touched,
                handleChange,
                handleSubmit,
                errors,
                setFieldValue,
                setTouched,
              }) => {
                const otpVerification = (type: any) => {
                  setVerificationType(type)
                  setIsVerificationModalOpen(true)
                  if (type === 'email_verification') {
                    generateOtp({
                      payload: {
                        type: 'email_verification',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: values?.registered_office_email,
                        mobile_number: '',
                      },
                    })
                  } else if (type === 'mobile_verification') {
                    generateOtp({
                      payload: {
                        type: 'mobile_verification',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: '',
                        mobile_number: values?.registered_office_mobile_number,
                      },
                    })
                  } else {
                    generateOtp({
                      payload: {
                        type: 'both',
                        case: 'update',
                        temp_customer_id: uniqueID,
                        email: values?.registered_office_email,
                        mobile_number: values?.registered_office_mobile_number,
                      },
                    })
                  }
                }
                useEffect(() => {
                  if (initialValues.vat_number) {
                    setFieldValue('tax_type', 'vat')
                  } else if (initialValues.pan_number) {
                    setFieldValue('tax_type', 'pan')
                  }
                }, [initialValues.vat_number, initialValues.pan_number, setFieldValue])

                useEffect(() => {
                  if (values.tax_type === 'pan') {
                    setFieldValue(
                      'vat_number',
                      initialValues.vat_number ? initialValues.vat_number : ''
                    )
                  } else if (values.tax_type === 'vat') {
                    setFieldValue(
                      'pan_number',
                      initialValues.pan_number ? initialValues.pan_number : ''
                    )
                  }
                }, [
                  values.tax_type,
                  initialValues.pan_number,
                  initialValues.vat_number,
                  setFieldValue,
                ])

                return (
                  <Form className='bg-white'>
                    <div className='card mb-5 mb-xl-10'>
                      <div className='card-body border-top '>
                        <div className='d-flex border-bottom mb-4 pb-2'>
                          <h1 className=' heading__component__title fw-bolder text-uppercase'>{`CORPORATE PRELIMINARY Form`}</h1>
                        </div>
                        <div className=''>
                          <div className='row'>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Branch Code'
                                name='branch_code'
                                label='Branch Code'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                maxLength={6}
                                disabled
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Customer Code'
                                name='customer_code'
                                label='Customer Code'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className=''>
                          <div className='row'>
                            <section className='py-3'>
                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                A. Name of institution:
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Institution Name'
                                  name='institution_name'
                                  label='Name of Institution'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required={true}
                                  uppercase={true}
                                />
                              </div>
                            </section>
                            <hr />
                            <section className='py-3'>
                              <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                B. Constitution:
                              </h1>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Constitution'
                                  name='constitution_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  options={allMasterDataOptions?.constitutions?.map(
                                    (item: any) => ({
                                      label: item?.title?.toLocaleUpperCase() || '',
                                      value: item?.id,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  required
                                />
                              </div>

                              {allMasterDataOptions?.constitutions?.find(
                                (item: any) => item?.id === values?.constitution_id
                              )?.title === 'Other (specify)' && (
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Other Constitutions'
                                    name='other_constitution'
                                    label='Other Constitution'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    // uppercase={true}
                                  />
                                </div>
                              )}

                              {allMasterDataOptions?.constitutions?.find(
                                (item: any) => item?.id === values.constitution_id
                              )?.title === 'FOREIGN ENTERPRISE' && (
                                <>
                                  <label className='form-label fw-bolder text-dark fs-6 '>
                                    if the associates of foreign company/entity, details of foreign
                                    company/entity
                                  </label>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='name'
                                      name='foreign_company_name'
                                      label='Foreign Company Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      // uppercase={true}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Address'
                                      name='foreign_company_address'
                                      label='Foreign Company Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      // uppercase={true}
                                    />
                                  </div>
                                </>
                              )}
                            </section>
                          </div>
                        </div>
                        <hr />
                        <section className='py-3'>
                          <h1 className=' heading__component__title fw-bolder text-uppercase'>
                            C. Details of Registration:
                          </h1>{' '}
                          <div className='row'>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Registration'
                                name='registration_number'
                                label='i. Registration Number'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required={true}
                                maxLength={20}
                                // uppercase={true}
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select'
                                label='ii. Registered With'
                                name='registration_with_id'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={allMasterDataOptions?.corporate_registrations?.map(
                                  (item: any) => ({
                                    label: item?.title?.toLocaleUpperCase() || '',
                                    value: item?.id,
                                  })
                                )}
                                setFieldValue={setFieldValue}
                                values={values}
                                required
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                label='iii. Registration Date (AD)'
                                name='registration_date_ad'
                                placeholderText='YYYY-MM-DD'
                                setFieldValue={setFieldValue}
                                value={values?.registration_date_ad}
                                // minDate={values?.start_date}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                // disabled={values.start_date ? false : true}
                                required
                                // isClearable={isEndDateNotRequired}
                                data-cy='end-date-drop-down'
                                data={'registration_date_bs'}
                              />
                            </div>

                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormNepaliCalendarDatePicker
                                nepaliFormName='registration_date_bs'
                                englishFormName='registration_date_ad'
                                label='iv. Registration Date (BS)'
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                required
                              />
                            </div>

                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormDatepicker
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                label='v. Registration Expiry Date (AD)'
                                name='registration_expiry_date_ad'
                                placeholderText='YYYY-MM-DD'
                                setFieldValue={setFieldValue}
                                value={values?.registration_expiry_date_ad}
                                // minDate={values?.start_date}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                // disabled={values.start_date ? false : true}
                                // required
                                // isClearable={isEndDateNotRequired}
                                data-cy='end-date-drop-down'
                                data={'registration_expiry_date_bs'}
                              />
                            </div>

                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormNepaliCalendarDatePicker
                                nepaliFormName='registration_expiry_date_bs'
                                englishFormName='registration_expiry_date_ad'
                                label='vi. Registration Expiry Date (BS)'
                                setFieldValue={setFieldValue}
                                touched={touched}
                                values={values}
                                // required
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select'
                                label='vii. Nature of Business'
                                name='business_nature_id'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={allMasterDataOptions?.business_natures?.map(
                                  (item: any) => ({
                                    label: item?.title?.toLocaleUpperCase() || '',
                                    value: item?.id,
                                  })
                                )}
                                setFieldValue={setFieldValue}
                                values={values}
                                required
                              />
                            </div>
                          </div>
                        </section>
                        <hr />

                        <section className='py-3'>
                          <h3 className=' heading__component__title fw-bolder text-uppercase'>
                            D. Registered Address:
                          </h3>{' '}
                          <div className='row'>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select Country'
                                label='Country'
                                name={'registered_office_country'}
                                onChange={(e: any) => {
                                  handleChange(e)
                                  onCountryChange(e, setFieldValue, setTouched)
                                }}
                                errors={errors}
                                touched={touched}
                                options={allMasterDataOptions?.countries?.map((country: any) => ({
                                  value: country?.id || country.value,
                                  label: country?.title || country.label,
                                  code: country?.cbs_code,
                                }))}
                                setFieldValue={setFieldValue}
                                values={values}
                                required
                              />
                            </div>
                            {allMasterDataOptions?.countries?.find(
                              (item: any) => item?.id === values?.registered_office_country
                            )?.title === 'NEPAL' ? (
                              <>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select Province'
                                    label='Province'
                                    name={'registered_office_province'}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={provinceOptions}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    required
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select Zone'
                                    label='Zone'
                                    name='registered_office_zone'
                                    onChange={(w: any) => {
                                      handleChange(w)
                                      getZoneCode(w, values)
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    options={zoneOptions}
                                    setFieldValue={setFieldValue}
                                    required
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select District'
                                    label='District'
                                    name={'registered_office_district'}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    options={
                                      !districtData
                                        ? districtOptions.map((district: any) => ({
                                            value: district?.id || district?.value,
                                            label: district?.title || district?.label,
                                          }))
                                        : districtData?.data?.district?.map((district: any) => ({
                                            value: district?.id || district?.value,
                                            label: district?.title || district?.label,
                                          }))
                                    }
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    required
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    placeholder='Select Municipality'
                                    label='Municipality'
                                    name={'registered_office_municipality'}
                                    // onChange={handleChange}
                                    onChange={(e: any) => {
                                      handleChange(e)
                                      if (districtData) {
                                        return
                                      } else {
                                        getMunicipilities(e, setFieldValue)
                                      }
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    options={municipalitiesOptions}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    required
                                  />
                                </div>

                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormSelect
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Registered Office City'
                                    name={`registered_office_city`}
                                    label='Registered Office City'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    options={allMasterDataOptions?.cities?.map((city: any) => ({
                                      value: city?.id || city.value,
                                      label: city?.title || city.label,
                                    }))}
                                    setFieldValue={setFieldValue}
                                    required
                                  />
                                </div>

                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Ward Number'
                                    name={'registered_office_ward_number'}
                                    label='Ward Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    maxLength={2}
                                    // uppercase={true}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Tole'
                                    name={'registered_office_street_name'}
                                    label='Tole'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    maxLength={20}
                                    // uppercase={true}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='House Number'
                                    name={'registered_office_house_number'}
                                    label='House Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={10}
                                    // uppercase={true}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Mobile Number'
                                    name={'registered_office_mobile_number'}
                                    label='Mobile Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    maxLength={10}
                                    // uppercase={true}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Email'
                                    name={'registered_office_email'}
                                    label='Email'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={50}
                                    // uppercase={true}
                                    required
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Post Box No.'
                                    name='registered_office_outside_country_post_box'
                                    label='Post Box No.'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={12}
                                    // uppercase={true}
                                    required
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Full Address'
                                    name='registered_office_outside_country_full_address'
                                    label='Full Address'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={50}
                                    // uppercase={true}
                                    required
                                  />
                                </div>

                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Mobile Number'
                                    name={'registered_office_mobile_number'}
                                    label='Mobile Number'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    // uppercase={true}
                                  />
                                </div>
                                <div className='col-md-4 col-sm-4 col-xs-12'>
                                  <FormTextBox
                                    labelClassName='col-lg-12'
                                    containerClassName='col-lg-12'
                                    type='text'
                                    placeholder='Email'
                                    name={'registered_office_email'}
                                    label='Email'
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    maxLength={50}
                                    // uppercase={true}
                                    required
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </section>
                        <hr />

                        <section className='py-3'>
                          <h3 className=' heading__component__title fw-bolder text-uppercase '>
                            E. PAN/VAT Details:
                          </h3>{' '}
                          <div className='row'>
                            <div className='col-md-6 col-sm-6 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select Type'
                                label='Select Type'
                                name='tax_type'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={[
                                  {value: 'pan', label: 'PAN'},
                                  {value: 'vat', label: 'VAT'},
                                ]}
                                setFieldValue={setFieldValue}
                                values={values}
                                required
                              />
                            </div>
                            {values.tax_type === 'pan' && (
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='PAN Number'
                                  name='pan_number'
                                  label='PAN Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={9}
                                  // uppercase={true}
                                />
                              </div>
                            )}

                            {values.tax_type === 'vat' && (
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='VAT Number'
                                  name='vat_number'
                                  label='VAT Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={9}
                                  // uppercase={true}
                                />
                              </div>
                            )}
                          </div>
                        </section>
                        <hr />
                        <section className='py-3'>
                          <h3 className=' heading__component__title fw-bolder text-uppercase'>
                            F. Expected Annual Turnover:
                          </h3>{' '}
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Annual Turnover'
                              name='expected_annual_turnover'
                              label='Expected Annual Turnover'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                              maxLength={50}
                              // uppercase={true}
                            />
                          </div>
                          <h3 className=' heading__component__title fw-bolder text-uppercase'>
                            G. Expected Annual Transaction Number:
                          </h3>{' '}
                          <div className='col-md-6 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Expected Annual Transaction Number'
                              name='expected_annual_transaction_number'
                              label='Expected Annual Transaction Number'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              arrValue={values.expected_annual_transaction_number}
                              options={allMasterDataOptions?.annual_transactions?.map(
                                (transaction: any) => ({
                                  value: transaction?.id || transaction.value,
                                  label: transaction?.title || transaction.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                        </section>
                        <hr />
                        <section className='py-3'>
                          <h3 className=' heading__component__title fw-bolder text-uppercase'>
                            H. PEP Information & risk rating:
                          </h3>{' '}
                          <div className='row'>
                            <div className='col-md-6 col-12'>
                              <FormSelect
                                name='pep_status'
                                label='PEP Confirmation'
                                labelClassName='col-12'
                                containerClassName='col-12'
                                options={[
                                  {label: 'YES', value: 'Yes'},
                                  {label: 'NO', value: 'No'},
                                ]}
                                setFieldValue={setFieldValue}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                                // disabled
                                required
                              />
                            </div>
                            {values.pep_status === 'Yes' && (
                              <div className='col-md-6 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='PEP Information'
                                  name='pep_information'
                                  label='PEP information'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  // uppercase={true}
                                />
                              </div>
                            )}
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select Risk Rating'
                                label='Risk Rating'
                                name='aml_risk_rating'
                                // onChange={(w: any) => {
                                //   handleChange(w)
                                //   getRiskCode(w, values)
                                // }}
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={allMasterDataOptions?.risk_categories?.map(
                                  (option: any) => ({
                                    value: option?.id || option.value,
                                    label: option?.title || option.label,
                                  })
                                )}
                                values={values}
                                setFieldValue={setFieldValue}
                                required
                                disabled={isDisabled}
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select Risk Rating SubCategory'
                                label='Risk Rating SubCategory'
                                name='sub_risk_category_id'
                                onChange={(w: any) => {
                                  handleChange(w)
                                  getSubRiskCode(w, setFieldValue)
                                }}
                                errors={errors}
                                touched={touched}
                                options={allMasterDataOptions?.risk_sub_categories?.map(
                                  (option: any) => ({
                                    value: option?.id || option.value,
                                    label: option?.title || option.label,
                                  })
                                )}
                                values={values}
                                setFieldValue={setFieldValue}
                                required
                              />
                            </div>
                          </div>
                        </section>
                        <hr />

                        <hr />

                        <section className='py-3'>
                          <h3 className='fw-bolder mb-4 text-capitalize'>Screening Files</h3>
                          {corporateDataById?.data?.screening_files?.screening_files?.length > 0 ? (
                            corporateDataById?.data?.screening_files?.screening_files?.map(
                              (file: any, index: any) => (
                                <button
                                  key={index}
                                  onClick={() => handlePdfFileClick(file.file_path)}
                                  style={{display: 'block', marginBottom: '10px'}}
                                >
                                  {file.file_name}
                                </button>
                              )
                            )
                          ) : (
                            <p>No files available</p>
                          )}
                        </section>
                        <hr />
                        <section className='py-3'>
                          <h3 className='fw-bolder mb-4 text-capitalize required'>
                            Acknowledgement
                          </h3>
                          <div className='row py-2 align-items-center col-sm-12 col-xs-12'>
                            <FormCheckbox
                              labelClassName='col-sm-12'
                              containerClassName='col-sm-12'
                              name='blacklist_screening'
                              touched={touched}
                              errors={errors}
                              onChange={handleChange}
                              checkBoxText={
                                'I hereby verified blacklist screening result and I will be responsible in further for this.'
                              }
                              required={true}
                              checked={values?.blacklist_screening}
                            />
                          </div>
                          <div className='row py-2 align-items-center col-sm-12 col-xs-12'>
                            <FormCheckbox
                              labelClassName='col-sm-12'
                              containerClassName='col-sm-12'
                              name='sanction_screening'
                              touched={touched}
                              errors={errors}
                              onChange={handleChange}
                              checkBoxText={
                                'I hereby verified sanction screening result and I will be responsible in further for this.'
                              }
                              required={true}
                              checked={values?.sanction_screening}
                            />
                          </div>
                        </section>
                        <hr />
                      </div>
                      <>
                        {(values?.registered_office_email ||
                          values?.registered_office_mobile_number) && (
                          <div className='row mb-4 pb-4 border-bottom align-items-center'>
                            {values?.registered_office_email && (
                              <div className='col-md-4 mb-2'>
                                {!isEmailVerified ? (
                                  <button
                                    type='button'
                                    onClick={() => {
                                      setVerificationDetails((prev) => ({
                                        ...prev,
                                        email: values?.registered_office_email,
                                      }))
                                      otpVerification('email_verification')
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Verify Email
                                  </button>
                                ) : (
                                  <div className='heading__component__title fw-bolder mb-5'>
                                    Email Address Verified
                                  </div>
                                )}
                              </div>
                            )}
                            {values.registered_office_mobile_number && (
                              <div className='col-md-4 mb-2'>
                                <button
                                  type='button'
                                  onClick={() => {
                                    setVerificationDetails((prev) => ({
                                      ...prev,
                                      mobile_number: values.registered_office_mobile_number,
                                    }))
                                    otpVerification('mobile_verification')
                                  }}
                                  className='btn btn-primary'
                                >
                                  Verify Mobile Number
                                </button>
                              </div>
                            )}
                            {values.registered_office_email &&
                              values.registered_office_mobile_number && (
                                <div className='col-md-4 mb-2'>
                                  <button
                                    type='button'
                                    onClick={() => {
                                      setVerificationDetails((prev) => ({
                                        ...prev,
                                        email: values.registered_office_email,
                                        mobile_number: values.registered_office_mobile_number,
                                      }))
                                      otpVerification('both')
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Verify Both
                                  </button>
                                </div>
                              )}
                          </div>
                        )}
                      </>
                      <div className='card-footer text-end'>
                        <button
                          type='button'
                          className='btn btn-secondary me-2'
                          onClick={handleBack}
                        >
                          Back
                        </button>

                        {isSave && postdataloading ? (
                          <button
                            type='submit'
                            className='btn btn-primary me-2'
                            disabled={postdataloading}
                          >
                            Submitting...
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-primary me-2'
                            onClick={() => {
                              setIsSave(true)
                            }}
                            disabled={isSubmitting || screeningPerformed === false}
                          >
                            Submit
                          </button>
                        )}

                        {!isSave && postdataloading ? (
                          <button
                            type='submit'
                            className='btn btn-warning me-2'
                            disabled={postdataloading}
                          >
                            Saving as Draft...
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-warning me-2'
                            onClick={() => {
                              setIsSave(false)
                            }}
                          >
                            Save as Draft
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </>
        )}
      </div>
      <Modal
        className='w-55'
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      >
        <Modal.Body>
          {verificationType && (
            <VerificationForm
              accountType={corporateDataById?.data?.account_type_id?.cbs_code}
              setIsVerificationModalOpen={setIsVerificationModalOpen}
              uniqueId={uniqueID}
              loading={generateOtpLoading}
              variant={verificationType === 'both' ? 'both' : verificationType}
              openVerificationModal={openVerificationModal}
              setIsEmailVerified={setIsEmailVerified}
              caseType={'update'}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setIsVerificationModalOpen(false)} className='btn btn-secondary'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NormalCorporateReviewAndUpdateCIF
