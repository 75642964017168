import {useContext, ReactNode, useState, useEffect} from 'react'
import {isEmpty} from 'lodash'
import {useSelector} from 'react-redux'
import {IAuthState} from '../auth'
import {ActiveMenuContex} from 'src/cms/layout/MasterLayout'
interface Props {
  type: string
  children: ReactNode
  moduleName?: string
}

const CheckPermissions = ({type, children, moduleName}: Props) => {
  const auth: IAuthState = useSelector((state: any) => state.auth)

  const activeMenu: any = useContext(ActiveMenuContex)

  const staticPermission = [
    {
      name: 'View New Cif Account',
      id: '111',
      description: 'View New Cif Account',
    },
  ]

  const [allPermission, setAllPermissions] = useState(auth?.rolePermissions?.permissions)

  useEffect(() => {
    if (auth?.rolePermissions) {
      setAllPermissions([...staticPermission, ...auth?.rolePermissions?.permissions])
    }
  }, [auth?.rolePermissions?.permissions])

  const hasPermission = moduleName
    ? allPermission?.find((permission) => permission?.name === `${type} ${moduleName}`)
    : auth?.rolePermissions?.permissions.find(
        (permission) => permission?.name === `${type} ${activeMenu?.currentActiveMenu}`
      )
  return <>{!isEmpty(hasPermission) && children}</>
}

export default CheckPermissions
