import axios from 'axios'
import {useMutation, useQuery} from 'react-query'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {GET_MASTER_DATA} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {MasterDataStore} from 'src/setup/redux/MasterDataOption'

const postMasterData = async () => {
  try {
    const response = await axios.get(`${GET_MASTER_DATA}`)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const useGetMasterData = () => {
  const dispatch = useDispatch()

  return useMutation(() => postMasterData(), {
    onSuccess: (response: any) => {
      const extractOptions = (masterData: any) => {
        const options: {[key: string]: any[]} = {}
        masterData?.data?.forEach((item: any) => {
          const key = Object.keys(item)[0]
          if (key) {
            options[key] = item[key]
          }
        })
        return options
      }

      const allMasterDataOptions = extractOptions(response)
      dispatch(MasterDataStore(allMasterDataOptions))
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default useGetMasterData

export const useGetMasterDataOptions = () => {
  return useQuery(
    ['MasterOption'],
    async () => {
      const {data} = await axios.get(GET_MASTER_DATA)
      return data
    },
    {
      onError: (error) => {
        console.error('Error fetching account schema:', error)
      },
      onSuccess: (response: any) => {
        const dispatch = useDispatch()

        const extractOptions = (masterData: any) => {
          const options: {[key: string]: any[]} = {}
          masterData?.data?.forEach((item: any) => {
            const key = Object.keys(item)[0]
            if (key) {
              options[key] = item[key]
            }
          })
          return options
        }

        const allMasterDataOptions = extractOptions(response)
        dispatch(MasterDataStore(allMasterDataOptions))
      },
    }
  )
}
