import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface UploadState {
  accountTypeID: string
}

const initialState: UploadState = {
  accountTypeID: '',
}

const AccountTypeIDSlice = createSlice({
  name: 'accountTypeIDMaster',
  initialState,
  reducers: {
    setAccountTypeId(state, action: PayloadAction<string>) {
      state.accountTypeID = action.payload
    },
  },
})

export const {setAccountTypeId} = AccountTypeIDSlice.actions
export default AccountTypeIDSlice.reducer
