import axios from 'axios'
import {useMutation, useQuery} from 'react-query'
import {toast} from 'react-toastify'
import {GET_Municipilities} from 'src/app/queries/api/apiEndPoints/EndPoints'

const postMunicipilities = async (id: string) => {
  try {
    const response = await axios.get(`${GET_Municipilities}/${id}`)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const useGetMunicipilitiesById = (
  setMunicipalitiesData: React.Dispatch<React.SetStateAction<any>>
) => {
  return useMutation((id: string) => postMunicipilities(id), {
    onSuccess: (response) => {
      setMunicipalitiesData(response?.data)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default useGetMunicipilitiesById
