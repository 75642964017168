import {Form, Formik} from 'formik'
import {useMemo, useRef, useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import {getRelationshipNameByCode} from 'src/app/modules/common/SplitFunction'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import ToBeLinkedEntities from './linkedEntitiesInfos'
import CorporateLinkedEntitiesDetails from './corporateLinkedEntities'
import {validationSchemaBranchMaker} from '../utils/validationSchemas/ValidationSchemas'
import ViewCustomerDetails from './viewCustomerDetails'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'

const CreatingLinkEntitiesCPUMaker = () => {
  const [onSubmitValues, setOnSubmitValues] = useState<{
    account_number: string
    is_corporate: boolean
  }>({
    account_number: '',
    is_corporate: false,
  })
  const {allMasterDataOptions} = usePlaceMasterData({allMasterData: true})
  const [params, setParams] = useState({account_number: '', is_corporate: false})
  const {data, isLoading: isSubmittingValues} = useGetLinkedEntitesData(params, {
    enabled: Boolean(params.account_number),
  })

  const initialValues = {
    is_corporate: '',
    account_number: '',
  }

  const customerEntitiesData = data?.data
  const handleSubmit = (values: any, {setSubmitting}: any) => {
    const isCorporate = values.is_corporate === 'Corporate'
    const formattedParams = {
      account_number: values.account_number.trim(),
      is_corporate: isCorporate,
    }
    setOnSubmitValues(formattedParams)
    setParams(formattedParams)
    setSubmitting(false)
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <h3 className='heading__component__title fw-bolder text-uppercase'>Linked Entities</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaBranchMaker}
        >
          {({errors, handleChange, touched, values}) => (
            <Form>
              <div className='mb-5'>
                <h3 style={{color: 'brown'}}>
                  <i>Please Select Account Schema</i>
                </h3>
                <div className='row'>
                  <div className='col-md-6 col-xs-12 mb-5'>
                    <div className='d-flex'>
                      {allMasterDataOptions?.account_types
                        ?.filter((type: {title: string}) =>
                          ['Individual', 'Corporate']?.includes(type.title)
                        )
                        ?.map(({id, title}: any) => (
                          <FormRadio
                            key={id}
                            containerClassName=''
                            label='Account Type'
                            name='is_corporate'
                            type='radio'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            value={title}
                            checkBoxText={title}
                            checked={values.is_corporate === title}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                <FormTextBox
                  uppercase={true}
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Enter Primary Account Number'
                  name='account_number'
                  label='Account Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched.account_number}
                  required
                  maxLength={30}
                />
              </div>
              <div className='text-end'>
                <Button
                  type='submit'
                  className='btn btn-primary'
                  disabled={isSubmittingValues || !values.account_number || !values.is_corporate}
                >
                  {isSubmittingValues ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <ViewCustomerDetails
          data={data}
          customerEntitiesData={customerEntitiesData}
          onSubmitValues={onSubmitValues}
        />
      </div>
    </div>
  )
}

export default CreatingLinkEntitiesCPUMaker
